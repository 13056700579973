import React from 'react';
import { HookFormAutocomplete } from '../../common/components';
import { CircularProgress } from '@mui/material';
import { GET_SPECIALIZATIONS_QUERY } from '../../clinics/gql/Query';
import { useSubscription } from '@apollo/client';
import { capitalize } from 'lodash';

export default function SpecializationOptions({ prefix, control, errors }) {
    const { loading, data } = useSubscription(GET_SPECIALIZATIONS_QUERY, { variables: { limit: 100 } });
  
    if (loading) return <CircularProgress />;
  
    return (
      <HookFormAutocomplete
        id="specializations"
        name="specializations"
        placeholder="Enter specializations"
        label="Specializations *"
        schema="specializations"
        prefix={prefix}
        control={control}
        errors={errors}
        autocompleteProps={{
          includeInputInList: true,
          multiple: true,
          freeSolo: true,
          fullWidth: true,
        }}
        options={
          data && data.hasOwnProperty('rows')
            ? data.rows.map(i => ({ value: i.name, label: capitalize(i.name) }))
            : []
        }
        getOptionLabel={option => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.label;
        }}
        renderOption={(props, option) => (
          <li key={option.value} {...props}>
            {capitalize(option.label)}
          </li>
        )}
      />
    );
  }
import { gql } from '@apollo/client';

const GET_SPECIALIZE_CLINIC_FIELDS = gql`
  fragment ClinicReferralFields on clinics {
    id
    name
    full_address
    provider provider_clinics {
      item: provider {
        title
        firstname
        lastname
        specializations: provider_specializations{
          item: specialization {
            id
            name
          }
        }
      }
    }
    specializations: clinic_specializations {
      item: specialization {
        id
        name
      }
    }
    documents: clinic_referral_documents {
      item: document {
        id
        name
      }
    }
    requiredDocuments: clinic_referral_documents(where: {type: {_eq: "required"}}) {
      item: document {
        id
        name
      }
    }
    optionalDocuments: clinic_referral_documents(where: {type: {_eq: "optional"}}) {
      item: document {
        id
        name
      }
    }
  }
`;

const GET_PROVIDER_FIELDS = gql`
  fragment ProviderFields on Provider {
    id
    uuid
    title
    display_name
    firstname
    lastname
    can_receive
    specializations {
      name
    }
    documents {
      id
      required
      document{
        id
        name
      }
      question {
        id
        uuid
        question
      }
    }
    clinics {
      id
      name
      organization {
        name
      }
      full_address
      clinic_users{
        user{
          uuid
        }
      }
    }
  }
`;

const GET_SPECIALIZATION_FIELDS = gql`
  fragment SpecializationFields on specializations {
    id
    name
  }
`;

const GET_REFERRAL_FIELDS_BY_UUID = gql`
  fragment ReferralFields on Referral {
    status
    extra_info
    is_fax
    comment
    sent_at
    referrer {
      name
      referrer_name
      referrer_email
      extra_info
      metadata {
        clinic_receiver_id
        clinic_name
        physician
        contact
        fax
        office_phone
        contact_email
        provider_uuid
        address
      }
      full_address
      clinic_users{
        user{
          uuid
        }
      }
    }
    reason
    appointment {
      scheduled_by
      appointment_date
    }
    receiver {
      id
      name
      full_address
      extra_info
      organization {
        name
      }
    }
    provider {
      id
      display_name
      specializations {
        name
      }
      documents {
        id
        required
        question {
          id
          uuid
          question
        }
        document{
          id
          name
        }
      }
    }
  }
`;

const GET_REFERRAL_NOTES_FIELDS = gql`
  fragment ReferralNotesFields on referral_notes {
    id
    created_at
    user {
      firstname
      lastname
    }
    note
    group: messaging_group {
      name
    }
  }
`;

export { GET_PROVIDER_FIELDS, GET_SPECIALIZE_CLINIC_FIELDS, GET_SPECIALIZATION_FIELDS, GET_REFERRAL_NOTES_FIELDS, GET_REFERRAL_FIELDS_BY_UUID };

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { IconLockOpen } from '@tabler/icons';
import React from 'react';
import { useContext } from 'react';
import { ROLE_ADMIN, ROLE_PSR, ROLE_NURSE, ROLE_MED_DIRECTOR, ROLE_MED_ASSISTANT } from '../auth/constants/Role';
import AuthContext from '../auth/context/AuthContext';
const roles = [ROLE_ADMIN, ROLE_PSR, ROLE_NURSE, ROLE_MED_DIRECTOR, ROLE_MED_ASSISTANT];
export default function ManageRoles() {
  const currentUser = useContext(AuthContext);
  return (
    <Card>
      <CardContent>
        <Box
          avail
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid container direction="column">
            <Grid item>
              <Typography gutterBottom variant="h4" style={{ marginTop: '20px' }}>
                Roles:
              </Typography>
              <List>
                {roles.map(role => {
                  return (
                    <ListItem key={role.label}>
                      <ListItemAvatar>
                        <Avatar>
                          <IconLockOpen />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={role.label} />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}

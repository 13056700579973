import CONFIG from './../config';
import {
    ApolloClient,
    InMemoryCache,
    HttpLink,
    split,
} from '@apollo/client';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { getUser } from '../features/auth/hooks/useAuth';
import Cookies from 'js-cookie';

const GRAPHQL_URL = `${CONFIG.API_BASE_URL}/graphql`;
const WS_PROTOCOL = CONFIG.API_PROTOCOL === 'https' ? 'wss' : 'ws';
const GRAPHQL_HEADERS = CONFIG.GRAPHQL_SECRET
    ? { 'x-hasura-admin-secret': CONFIG.GRAPHQL_SECRET }
    : {};

// Create a WebSocket link
const wsLink = new GraphQLWsLink({
    uri: `${WS_PROTOCOL}://${GRAPHQL_URL}`,
    options: {
        reconnect: true,
        timeout: 30000,
        connectionParams: () => {
            return {
                // for admin authentication, not advisable
                // TODO: need to change this to web hook authentication
                headers: GRAPHQL_HEADERS,
            };
        },
    }
});

// Create an http link
const httpLink = new HttpLink({
    uri: `${CONFIG.GRAPHQL_PROTOCOL}://${GRAPHQL_URL}`,
    credentials: 'include', // this is needed for cookie authentication
    // headers: {
    //     'Accept-encoding': 'gzip',
    // }
});

const authLink = setContext((_, { headers }) => {
    let user = getUser();
    const csrfToken = Cookies.get('XSRF-TOKEN');
    if(user != null){
        return {
            headers: {
                ...GRAPHQL_HEADERS, // for admin authentication, not advisable
                ...headers,
                "Authorization": "Bearer " + user._token,
                "Accept": 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            },
        };
    } else {
        return {
            headers: {
                ...GRAPHQL_HEADERS, // for admin authentication, not advisable
                ...headers,
                "Accept": 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            },
        };
    }
    
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const splitLink = split(
    // split based on operation type
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink
);

const clientGraphql = new ApolloClient({
    link: authLink.concat(splitLink),
    cache: new InMemoryCache(),
});

export default clientGraphql;
// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { AllNotifications } from './';
import {Authenticated} from '../auth';

export default {
  path: 'notifications',
  name: 'Notifications',
  component: Authenticated,
  childRoutes: [
    { path: '', component: AllNotifications, props: {title: 'All Notifications', subtitle: 'Showing all your notifications'}  },
  ],
};

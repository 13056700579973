import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    useMediaQuery,
    Stack,
    Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    HookFormPasswordField,
    MyButton,
    MyTypography,
    MyAlert,
} from '../common/components';
import { useHistory } from 'react-router-dom';
import AuthPageContainer from './AuthPageContainer';
import Swal from 'sweetalert2';
import { isEmpty } from 'lodash';
import {default as useQueryParams} from '../common/hooks/useQuery';
import { useResetPassword } from './redux/resetPassword';

const validationSchema = yup.object().shape({
    password: yup.string().min(10, 'Password must be at least 10 characters').required('Password is required'),
    password_confirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
});


export default function ResetPassword({match}) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();
  const {token} = match.params;
  const {email} = useQueryParams(true);
  

  const {resetPassword, resetPasswordError, dismissResetPasswordError, resetPasswordPending} = useResetPassword();

  const cancel = () => {
    window.location.href = '/login';
};

  const { handleSubmit, control, setValue, trigger, formState: {errors}, reset } = useForm({
      defaultValues: {
          email: ''
      },
      resolver: yupResolver(validationSchema),
      shouldFocusError: true,
  });

  const onSubmit = (data) => {
    dismissResetPasswordError();
    resetPassword(data).then((response)=>{
      Swal.fire({
        icon: 'success',
        text: response.status,
        showConfirmButton: true,
      }).then(() => {
        window.location.href = '/login';
      });
    });
  };

  useEffect(()=>{
    if(!isEmpty(email)){
      setValue("email", email);
      trigger("email");
    }
  },[email, setValue, trigger]);

  useEffect(()=>{
    if(!isEmpty(token)){
      setValue("token", token);
      trigger("token");
    }
  },[token, setValue, trigger]);

  return (
    <AuthPageContainer>
        <Stack
            direction={matchDownSM ? 'column-reverse' : 'row'}
            alignItems="center"
            justifyContent="center"
        >
            <Box>
                    <Stack alignItems="center" justifyContent="center" spacing={1}>
                        <MyTypography color={theme.palette.secondary.main}
                                      gutterBottom
                                      variant={matchDownSM ? 'h3' : 'h2'}>
                            Reset Password
                        </MyTypography>
                        <MyTypography
                            variant="caption"
                            fontSize="14px"
                            textAlign={ 'center'}
                        >
                            Enter your new password for your account : {email}
                        </MyTypography>
                    </Stack>
                </Box>
        </Stack>

        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack className="forgot-password"
                   direction="column"
                   spacing={2}
                   sx={{
                       px: matchDownSM ? 1 : 3,
                       py: 4,
                   }}>
                {
                    resetPasswordError &&
                    <MyAlert severity="error"
                             sx={{mb: 2}}>{resetPasswordError.message}</MyAlert>
                }

<HookFormPasswordField
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter password"
                    label="New Password"
                    schema="password"
                    control={control}
                    errors={errors}
                    autoFocus
                    fullWidth
                />

<HookFormPasswordField
                    id="password_confirmation"
                    name="password_confirmation"
                    type="password_confirmation"
                    placeholder="Enter confirm password"
                    label="Confirm New Password"
                    schema="password_confirmation"
                    control={control}
                    errors={errors}
                    fullWidth
                />

                <MyButton type="submit"
                          variant="contained"
                          size="large"
                          loading={resetPasswordPending}
                          disableElevation>Reset Password</MyButton>
                <MyButton variant="standard"
                              color="secondary"
                              size="large"
                              onClick={cancel}
                              disableElevation>Back to Login</MyButton>
            </Stack>
        </form>
    </AuthPageContainer>
  );
};
import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { MyTypography } from '../../common/components';
import 'yup-phone';
import { gridSpacing } from '../../../common/themes/constants';
import { isEmpty } from 'lodash';
import NotificationOptions from './NotificationOptions';
import clientGraphql from '../../../common/apollo-graphql';

export const initialValues = {
  notification: parseInt('1', 2),
};

export default function UpdateUserNotificationForm({
  notificationSettings = [],
  clinicsNotificationSettings = [],
  handRefetch = null,
  userData = null,
  onSubmit,
  save_clinic_notification_settings,
}) {
  const [notificationsBinary, setNotificationsBinary] = useState({});

  useEffect(() => {
    if (!isEmpty(userData)) {
      const notificationBi = {
        ...Object.fromEntries(
          userData.notifications.map(v => [v.label.replace(' ', '_'), v.value]),
        ),
      };
      setNotificationsBinary(notificationBi);
      //   updateNotificationValue();
    }
  }, [setNotificationsBinary, userData]);

  useEffect(() => {
    if (!isEmpty(notificationsBinary) && !isEmpty(userData)) {
      updateNotificationValue();
    }
  }, [userData, updateNotificationValue, notificationsBinary]);

  const handleNotificationChange = useCallback(
    (value, notification) => {
      var binary = notificationsBinary;
      notification.value = !notification.value;
      binary[notification.label.replace(' ', '_')] = notification.value;
      setNotificationsBinary(binary);
      updateNotificationValue();
    },
    [updateNotificationValue, notificationsBinary],
  );

  const handleClinicNotificationChange = useCallback(
    (index, notification) => {
      notification.value = !notification.value;

      save_clinic_notification_settings({
        client: clientGraphql,
        variables: { clinicId: notification.id, notificationSettings: notification.value ? 1 : 0 },
      }).then(res => {
        handRefetch();
      });
    },
    [handRefetch, save_clinic_notification_settings],
  );

  const updateNotificationValue = useCallback(() => {
    var strNotification = '';
    Object.keys(notificationsBinary)
      .reverse()
      .forEach(function(key, index) {
        if (notificationsBinary[key]) {
          strNotification += '1';
        } else {
          strNotification += '0';
        }
      });
    var notificationInt = parseInt(strNotification, 2);

    onSubmit({ notification: notificationInt });
  }, [notificationsBinary, onSubmit]);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MyTypography gutterBottom variant="h4" style={{ marginTop: '20px' }}>
          Email Notifications Settings
        </MyTypography>
      </Grid>
      <NotificationOptions
        placeholder="Enter the Notications"
        label="Notifications"
        notificationSettings={clinicsNotificationSettings}
        handleChange={handleClinicNotificationChange}
        size={6}
      />
      <Grid item xs={12}>
        <MyTypography gutterBottom variant="h4" style={{ marginTop: '20px' }}>
          Notifications
        </MyTypography>
      </Grid>
      <NotificationOptions
        placeholder="Enter the Notications"
        label="Notifications"
        notificationSettings={notificationSettings}
        handleChange={handleNotificationChange}
        size={12}
      />
    </Grid>
  );
}

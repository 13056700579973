import React, { useEffect, useState } from 'react';
import { Stack, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import {  MyButton, MyTypography } from '../../../common/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { gridSpacing } from '../../../../common/themes/constants';
import Swal from 'sweetalert2';
import { GET_NOTIFICATIONS_QUERY } from '../../gql/Query';
import clientGraphql from '../../../../common/apollo-graphql';
import { useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { SAVE_USER_CLINIC_MUTATION } from '../../gql/Mutation';
import RegisterUserFromOtherClinicForm, {registerUserValidationSchema,initialValues} from '../../../auth/RegisterUser/RegisterUserFromOtherClinicForm';
import { GET_USERS } from '../../../user/gql/Query';

const validationSchema = registerUserValidationSchema;

export default function AddUserFromOtherClinicDialog({ open, onClose, clinicId }) {
  const [parsedNotificationData, setParsedNotificationData] = useState({});
  const { loading, data: dataUsers } = useQuery(GET_USERS, {
    client: clientGraphql,
      variables: {
        without_clinic_id: clinicId,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
  });

  const { data: dataNotificationSettings } = useQuery(GET_NOTIFICATIONS_QUERY, {
    client: clientGraphql,
  });

  const [
    save_user_clinic,
    { data: saveUserClinicData, loading: saveUserClinicLoading, error: saveUserClinicError },
  ] = useMutation(SAVE_USER_CLINIC_MUTATION, {
    client: clientGraphql,
  });

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const onSubmit = data => {
    var newData = JSON.parse(JSON.stringify(data));
    newData['userId'] = '';
    newData['clinicId'] = parseInt(clinicId);
    const prov_index = dataUsers.rows.findIndex(
      user => user.email === data.userId,
    );
    if (prov_index >= 0) {
      newData['userId'] = parseInt(dataUsers.rows[prov_index].id);
    }

    save_user_clinic({
      client: clientGraphql,
      variables: { ...newData },
    });
  };

  useEffect(() => {
    if (!isEmpty(saveUserClinicError)) {
      const errors = !isEmpty(saveUserClinicError.graphQLErrors)
        ? saveUserClinicError.graphQLErrors[0]
        : {};
      if (
        !isEmpty(errors.extensions) &&
        !isEmpty(errors.extensions.validation) &&
        JSON.stringify(errors.extensions.validation).includes('user')
      ) {
        Swal.fire({
          icon: 'error',
          text: JSON.stringify(errors.extensions.validation),
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Error Message: ' + saveUserClinicError.message,
          showConfirmButton: true,
        });
      }
    }
    if (!isEmpty(saveUserClinicData)) {
      Swal.fire({
        icon: 'success',
        text: 'Successfully added a user',
        showConfirmButton: true,
      }).then(() => {
        reset();
        onClose(true);
      });
    }
  }, [saveUserClinicData, saveUserClinicError, onClose, reset]);


  useEffect(() => {
    if (!isEmpty(dataNotificationSettings)) {
        const newData = dataNotificationSettings.user_notification_settings.map(notification => {
            return { value: true,
            label: notification };
        });
        setParsedNotificationData(newData);
    }
  }, [dataNotificationSettings, setParsedNotificationData]);


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={open} onClose={onClose} disablePortal={true}>
        <DialogContent>
          <Grid container direction="column" spacing={gridSpacing}>
            <Grid item xs={10}>
              <MyTypography variant="h3">Add Existing User to Clinic</MyTypography>
            </Grid>
            <Grid item xs={10}>
              <MyTypography variant="subtitle">Search the registered user using the email used to register.</MyTypography>
            </Grid>
            <Grid item xs={10}>
              {!isEmpty(dataUsers) ? (<RegisterUserFromOtherClinicForm control={control}
                errors={errors}
                setValue={setValue}
                trigger={trigger}
                dataUsers={dataUsers}
                permissions={[]}/>):null
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 3 }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <MyButton onClick={onClose} disabled={saveUserClinicLoading} fullWidth={false}>
              Cancel
            </MyButton>
            <MyButton
              type="submit"
              variant="contained"
              loading={saveUserClinicLoading}
              fullWidth={false}
            >
              Add User
            </MyButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </form>
  );
}

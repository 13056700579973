import React from 'react';
import Cleave from 'cleave.js/dist/cleave-react';
import CONFIG from '../../../../config';

const TWO_FACTOR_CODE_LENGTH = CONFIG.TWO_FACTOR_CODE_LENGTH || 6;

export default React.forwardRef(function TwoFactorCodeMask(props) {
    const { inputRef, ...other } = props;

    return (
        <Cleave
            {...other}
            ref={(ref) => {
                if (inputRef) {
                    inputRef(ref ? ref.inputElement : null);
                }
            }}
            options={{
                blocks: [TWO_FACTOR_CODE_LENGTH],
                numericOnly: true,
                swapHiddenInput: true,
            }}
        />
    );
});

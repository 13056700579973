import { gql } from '@apollo/client';

export const REFERRAL_PATIENT_MINI_DETAIL_FRAGMENT = gql`
  fragment ReferralPatientMiniDetailsFragment on PatientMiniDetail {
    full_name
    phone
    diagnosis
  }
`;

export const REFERRAL_PATIENT_DETAIL_FRAGMENT = gql`
  fragment ReferralPatientDetailsFragment on PatientDetail {
    full_name
    phone
    ssn
    birthdate
    full_address
    insurance_provider
    policy_number
    diagnosis
  }
`;
import React, {useState, useEffect, useContext, useMemo} from 'react';
import PageContent from '../common/MainLayout/Page';
import {useQuery} from '@apollo/client';
import {allNotificationsQuery} from './gql/Query';
import {allNotificationsFilter} from './gql/Filter';
import AuthContext from '../auth/context/AuthContext';
import NotificationList from './NotificationSection/NotificationList';
import {MyProgress} from '../common/components';
import MyNotification from './models/Notification';
import NotificationsContext from './context/NotificationsProvider';
import {Paper} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DateTimeUtils from '../common/services/DateTimeUtils';

export default function AllNotifications({title}) {
    const {id: userId, loggedInAt} = useContext(AuthContext);
    const [notifications, setNotifications] = useState([]);
    const {onRead} = useContext(NotificationsContext);
    const theme = useTheme();

    const lastLogin = useMemo(() => {
        return DateTimeUtils.parse(loggedInAt);
    }, [loggedInAt]);

    const { data, loading } = useQuery(allNotificationsQuery, {variables: {filter: allNotificationsFilter(userId, lastLogin)}});

    useEffect(() => {
        if (data && data.items) {
            setNotifications(data.items.map(item => {
                return {
                    model: new MyNotification(item),
                };
            }));
        } else {
            setNotifications([]);
        }
    }, [data]);

    return (
        <PageContent contentClass="notifications-all-notifications"
                     contentSX={{
                         bgcolor: theme.palette.grey['50'],
                     }}
                     title={title}>
            <Paper
                sx={{
                    bgcolor: theme.palette.grey['50'],
                }}>
            {
                loading &&
                <MyProgress />
            }
            {
                (data && !loading) &&
                <NotificationList onRead={onRead}
                                  notifications={notifications}
                                  maxWidth="100%" />
            }
            </Paper>
        </PageContent>
    );
};

import React, { useEffect, useState } from 'react';
import { Stack, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { MyButton, MyTypography } from '../../../common/components';
import RegisterUserForm, {
  initialValues,
  registerUserValidationSchema,
} from '../../../auth/RegisterUser/RegisterUserForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { gridSpacing } from '../../../../common/themes/constants';
import Swal from 'sweetalert2';
import { GET_NOTIFICATIONS_QUERY } from '../../gql/Query';
import clientGraphql from '../../../../common/apollo-graphql';
import { useMutation, useQuery } from '@apollo/client';
import { capitalize, isEmpty } from 'lodash';
import { SAVE_CLINIC_USER_MUTATION } from '../../gql/Mutation';

const validationSchema = registerUserValidationSchema;

export default function AddClinicUserDialog({ open, onClose, clinicId, providers = [] }) {
  const [parsedNotificationData, setParsedNotificationData] = useState({});
  
  const { data: dataNotificationSettings } = useQuery(GET_NOTIFICATIONS_QUERY, {
    client: clientGraphql,
  });

  const [
    save_clinic_user,
    { data: saveClinicUserData, loading: saveClinicUserLoading, error: saveClinicUserError },
  ] = useMutation(SAVE_CLINIC_USER_MUTATION, {
    client: clientGraphql,
  });

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const onSubmit = data => {
    var newData = JSON.parse(JSON.stringify(data));
    newData['provider_ids'] = [];
    data.provider_ids.map(prov_name => {
      const prov_index = providers.findIndex(
        provider => capitalize(provider.display_name) === capitalize(prov_name),
      );
      if (prov_index >= 0) {
        newData['provider_ids'].push(parseInt(providers[prov_index].id));
      }
    });

    save_clinic_user({
      client: clientGraphql,
      variables: { clinic_id: clinicId, user: newData },
    });
  };

  useEffect(() => {
    if (!isEmpty(saveClinicUserError)) {
      const errors = !isEmpty(saveClinicUserError.graphQLErrors)
        ? saveClinicUserError.graphQLErrors[0]
        : {};
      if (
        !isEmpty(errors.extensions) &&
        !isEmpty(errors.extensions.validation) &&
        JSON.stringify(errors.extensions.validation).includes('user')
      ) {
        Swal.fire({
          icon: 'error',
          text: JSON.stringify(errors.extensions.validation),
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Error Message: ' + saveClinicUserError.message,
          showConfirmButton: true,
        });
      }
    }
    if (!isEmpty(saveClinicUserData)) {
      Swal.fire({
        icon: 'success',
        text: 'Successfully created a user',
        showConfirmButton: true,
      }).then(() => {
        reset();
        onClose(true);
      });
    }
  }, [saveClinicUserData, saveClinicUserError, onClose, reset]);


  useEffect(() => {
    if (!isEmpty(dataNotificationSettings)) {
        const newData = dataNotificationSettings.user_notification_settings.map(notification => {
            return { value: true,
            label: notification };
        });
        setParsedNotificationData(newData);
    }
  }, [dataNotificationSettings, setParsedNotificationData]);


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={open} onClose={onClose} disablePortal={true}>
        <DialogContent>
          <Grid container direction="column" spacing={gridSpacing}>
            <Grid item xs={10}>
              <MyTypography variant="h3">Add User</MyTypography>
            </Grid>
            <Grid item xs={10}>
              <RegisterUserForm
                control={control}
                errors={errors}
                providers={providers}
                setValue={setValue}
                trigger={trigger}
                permissions={[]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 3 }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <MyButton onClick={onClose} disabled={saveClinicUserLoading} fullWidth={false}>
              Cancel
            </MyButton>
            <MyButton
              type="submit"
              variant="contained"
              loading={saveClinicUserLoading}
              fullWidth={false}
            >
              Add User
            </MyButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </form>
  );
}

import React, { useEffect, useState } from 'react';
import { Stack, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { MyButton, MyTypography } from '../../../common/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { gridSpacing } from '../../../../common/themes/constants';
import Swal from 'sweetalert2';
import { GET_NOTIFICATIONS_QUERY } from '../../gql/Query';
import clientGraphql from '../../../../common/apollo-graphql';
import { useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { UPDATE_CLINIC_USER_MUTATION } from '../../gql/Mutation';
import UpdateUserForm, {
    initialValues,
    registerUserValidationSchema,
  } from '../../../auth/RegisterUser/UpdateUserForm';

const validationSchema = registerUserValidationSchema;

export default function UpdateClinicUserDialog({ open, onClose, clinicId, userData=null }) {
    const [parsedData, setParsedData] = useState({});
    const [parsedNotificationData, setParsedNotificationData] = useState({});

  const { data: dataNotificationSettings } = useQuery(GET_NOTIFICATIONS_QUERY, {
    client: clientGraphql,
  });

  const [
    save_clinic_user,
    { data: saveClinicUserData, loading: saveClinicUserLoading, error: saveClinicUserError },
  ] = useMutation(UPDATE_CLINIC_USER_MUTATION, {
    client: clientGraphql,
  });

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const onSubmit = data => {
    var newData = JSON.parse(JSON.stringify(data));

    save_clinic_user({
      client: clientGraphql,
      variables: { userUuid: userData.uuid, user: newData, clinicId: clinicId },
    });
  };

  useEffect(() => {
    if (!isEmpty(saveClinicUserError)) {
      const errors = !isEmpty(saveClinicUserError.graphQLErrors)
        ? saveClinicUserError.graphQLErrors[0]
        : {};
      if (
        !isEmpty(errors.extensions) &&
        !isEmpty(errors.extensions.validation) &&
        JSON.stringify(errors.extensions.validation).includes('user')
      ) {
        Swal.fire({
          icon: 'error',
          text: JSON.stringify(errors.extensions.validation),
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Error Message: ' + saveClinicUserError.message,
          showConfirmButton: true,
        });
      }
    }
    if (!isEmpty(saveClinicUserData)) {
      Swal.fire({
        icon: 'success',
        text: 'Successfully updated the user',
        showConfirmButton: true,
      }).then(() => {
        reset();
        onClose(true);
      });
    }
  }, [saveClinicUserData, saveClinicUserError, onClose, reset]);

  useEffect(() => {
    if (!isEmpty(dataNotificationSettings) && isEmpty(userData)) {
        const newData = dataNotificationSettings.user_notification_settings.map(notification => {
            return { value: true,
            label: notification };
        });
        setParsedNotificationData(newData);
    } else if (!isEmpty(dataNotificationSettings) && !isEmpty(userData)) {
        const newData = userData.notifications.map(notification => {
            return { value: notification.value === 1,
            label: notification.label };
        });
        setParsedNotificationData(newData);
    }
  }, [dataNotificationSettings, setParsedNotificationData, userData]);

  useEffect(() => {
    if (!isEmpty(userData)) {
        const newData = userData.permissions.map(permission => {
            return { value: permission.value === 1,
            label: permission.label };
        });
        setParsedData(newData);
    }
  }, [setParsedData, userData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={open} onClose={onClose} disablePortal={true}>
        <DialogContent>
          <Grid container direction="column" spacing={gridSpacing}>
            <Grid item xs={10}>
              <MyTypography variant="h3">Update User</MyTypography>
            </Grid>
            <Grid item xs={10}>
              <UpdateUserForm
                control={control}
                errors={errors}
                setValue={setValue}
                trigger={trigger}
                permissions={!isEmpty(parsedData) ? parsedData : []}
                notificationSettings={!isEmpty(parsedNotificationData) ? parsedNotificationData : []}
                userData={userData}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 3 }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <MyButton onClick={onClose} disabled={saveClinicUserLoading} fullWidth={false}>
              Cancel
            </MyButton>
            <MyButton
              type="submit"
              variant="contained"
              loading={saveClinicUserLoading}
              fullWidth={false}
            >
              Update User
            </MyButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default function componentStyleOverrides(theme) {
    const bgColor = theme.colors?.grey50;
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: '4px',
                    textTransform: 'none',
                },
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.primaryContrastText,
                    background: theme.colors?.primaryMain,
                    // padding: '24px',
                },
                title: {
                    color: 'inherit',
                    fontSize: '1rem',
                },
                subheader: {
                    color: theme.colors?.primary100,
                    fontSize: '0.75rem',
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&.Mui-selected': {
                        color: theme.menuSelected,
                        backgroundColor: theme.menuSelectedBack,
                        '&:hover': {
                            backgroundColor: theme.menuSelectedBack
                        },
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    },
                    '&:hover': {
                        backgroundColor: theme.menuSelectedBack,
                        color: theme.menuSelected,
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textDark
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textDark,
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem'
                    },
                    height: 'auto'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: bgColor,
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.colors?.grey400
                    },
                    '&:hover $notchedOutline': {
                        borderColor: theme.colors?.primaryLight
                    },
                    '&.MuiInputBase-multiline': {
                        padding: 1
                    }
                },
                input: {
                    fontWeight: 500,
                    background: bgColor,
                    padding: '15.5px 14px',
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiAppBar: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    background: theme?.colors?.primaryDark,
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme.colors?.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.colors?.primaryLight
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: 1
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.colors?.primaryLight,
                    background: theme.colors?.primary200
                }
            }
        },
                MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.colors?.grey700
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    // color: theme.colors?.textDark,
                },
                columnHeaders: {
                    //backgroundColor: theme.colors?.primaryLight,
                    //color: theme.colors?.grey900,
                    //fontSize: '1.05rem',
                    //fontWeight: '1200',
                },
                row: {
                    '&:hover': {
                        // backgroundColor: theme.menuSelectedBack,
                        cursor: 'pointer'
                    }
                },
                cell: {
                    '&:focus': {
                        outline: 'none',
                    },
                    indicator: {
                        backgroundColor: theme.colors?.primaryMain,
                        height: 0,
                    },
                }
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: theme.colors?.grey700,
                    fontSize: '1.10rem',
                    fontWeight: 'bold',
                    borderColor: theme.colors?.primaryMain,
                    border: '1px solid',
                    borderBottom: '3px solid',
                    marginRight: '5px',
                },
                selected: {
                    backgroundColor: theme.colors?.primaryMain,
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: '800px',
                },
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: theme.colors?.grey700,
                    fontSize: '1.10rem',
                    fontWeight: 'bold',
                    borderColor: theme.colors?.primaryMain,
                    backgroundColor: theme.colors?.primaryMain,
                },
            }
        }
    };
}

import React, {useEffect, useState} from 'react';
import AuthPageContainer from './AuthPageContainer';
import {HookFormTextField, MyAlert, MyButton, MyTypography} from '../common/components';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    useMediaQuery,
    Stack,
    Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {useVerifyTwoFactor} from './redux/hooks';
import TwoFactorCodeMask from '../common/components/mask/TwoFactorCodeMask';
import CONFIG from '../../config';
import {logout, USER_KEY} from './hooks/useAuth';
import {checkAuth} from './redux/logIn';
import {TWO_FACTOR_EXPIRED_ERROR_CODE} from './constants/TwoFactor';

const TWO_FACTOR_CODE_LENGTH = CONFIG.TWO_FACTOR_CODE_LENGTH || 6;
const twoFactorCodePlaceholder = "".padStart(TWO_FACTOR_CODE_LENGTH, '#');

const twoFactorErrMessage = 'Please enter two factor code';
const validationSchema = yup.object().shape({
    two_factor_code: yup.number().typeError(twoFactorErrMessage).required(twoFactorErrMessage),
});

export default function VerifyTwoFactor() {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [error, setError] = useState(null);

    const {resendTwoFactor, verifyTwoFactor, verifyTwoFactorError, verifyTwoFactorPending, verifyTwoFactorSuccess, dismissVerifyTwoFactorError} = useVerifyTwoFactor();

    const { handleSubmit, control, formState: {errors}, reset } = useForm({
        defaultValues: {
            two_factor_code: '',
        },
        resolver: yupResolver(validationSchema),
        shouldFocusError: true,
    });

    const clearErrors = () => {
        setError(null);
        dismissVerifyTwoFactorError();
    };

    const onSubmit = (data) => {
        clearErrors();

        verifyTwoFactor(data)
            .then(() => {
                window.location.href = '/dashboard';
            })
            .catch(err => {
                if (err && err.status) {
                    switch(err.status) {
                        case 409:
                            window.location.href = '/account/activate';
                            break;
                        case 401:
                            window.location.href = '/login';
                            break;
                        default:
                            // display error
                    }
                }
            });
    };

    useEffect(() => {
        checkAuth()
            .then(() => {
                window.location.href = '/dashboard';
            })
            .catch((err) => {
                if (err.status === 401 || (err.data && err.data.code && err.data.code === TWO_FACTOR_EXPIRED_ERROR_CODE)) {
                    window.location.href = '/login';
                }
            });
    }, []);

    useEffect(() => {
        if (!verifyTwoFactorError) return;

        if (verifyTwoFactorError.message) {
            setError(verifyTwoFactorError.message);

            for (let i in verifyTwoFactorError.data) {
                setError(i, {type: 'custom', message: verifyTwoFactorError.data[i]});
            }
        } else if (verifyTwoFactorError.data) {
            const keys = Object.keys(verifyTwoFactorError.data);
            const firstKey = keys[0];
            setError(verifyTwoFactorError.data[firstKey]);
        } else {
            setError('Invalid two factor code');
        }
    }, [verifyTwoFactorError, setError]);

    useEffect(() => {
        return () => {
            reset();
            dismissVerifyTwoFactorError();
        };
    }, [reset, dismissVerifyTwoFactorError]);

    return (
        <AuthPageContainer>
            <Stack
                direction={matchDownSM ? 'column-reverse' : 'row'}
                alignItems="center"
                justifyContent="center"
            >
                <Box>
                    <Stack alignItems="center" justifyContent="center" spacing={1}>
                        <MyTypography color={theme.palette.secondary.main}
                                      gutterBottom
                                      variant={matchDownSM ? 'h3' : 'h2'}>
                            Two Factor Verification
                        </MyTypography>
                        <MyTypography
                            variant="caption"
                            fontSize="14px"
                            textAlign={matchDownSM ? 'center' : 'inherit'}
                        >
                            You have received an email which contains two factor login code.
                            If you haven't received it, press <a onClick={resendTwoFactor} href="#">here</a>.
                        </MyTypography>
                    </Stack>
                </Box>
            </Stack>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack className="auth-log-in"
                       direction="column"
                       spacing={2}
                       sx={{
                           px: matchDownSM ? 1 : 3,
                           py: 4,
                       }}>
                    {
                        verifyTwoFactorSuccess &&
                        <MyAlert severity="success"
                                 sx={{mb: 2}}>
                            {
                                verifyTwoFactorSuccess.message
                                    ? verifyTwoFactorSuccess.message
                                    : ((verifyTwoFactorSuccess.data && verifyTwoFactorSuccess.data.message) ? verifyTwoFactorSuccess.data.message : 'Something went wrong')
                            }
                        </MyAlert>
                    }

                    {
                        error &&
                        <MyAlert severity="error"
                                 sx={{mb: 2}}>{error}</MyAlert>
                    }

                    <HookFormTextField
                        id="two_factor_code"
                        name="two_factor_code"
                        type="number"
                        placeholder={twoFactorCodePlaceholder}
                        label={null}
                        schema="two_factor_code"
                        control={control}
                        errors={errors}
                        InputProps={{
                            inputComponent: TwoFactorCodeMask,
                        }}
                        autoFocus
                        fullWidth
                    />

                    <MyButton type="submit"
                              variant="contained"
                              size="large"
                              loading={verifyTwoFactorPending}
                              disableElevation>Verify</MyButton>

                    <MyButton variant="standard"
                              color="secondary"
                              size="large"
                              onClick={logout}
                              disableElevation>Logout</MyButton>
                </Stack>
            </form>
        </AuthPageContainer>
    );
};

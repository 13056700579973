import React, { useState, useEffect, useCallback } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  useMediaQuery,
  Box,
  Stack,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  Dialog,
} from '@mui/material';
import { capitalize, isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { gridSpacing } from '../../common/themes/constants';
import { MyTypography, HookFormTextField, MyButton, MyAlert } from '../common/components';
import { VERIFY_PUBLIC_REFERRAL, VERIFY_PUBLIC_REFERRAL_FULL } from './gql/Mutation';
import clientGraphqlPublic from '../../common/apollo-graphql-public';
import { useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AuthPageContainer from '../auth/AuthPageContainer';
import Swal from 'sweetalert2';
import Referral from '../referral/models/Referral';
import { ReviewItem } from '../clinics/Management/EnrollPsr/ReviewInformation';
import { IconDownload } from '@tabler/icons';
import { REFERRAL_ANSWERS_PUBLIC_QUERY } from './gql/Query';
import StringUtils from '../common/services/StringUtils';
import PublicReviewReferralNotes from './PublicReviewReferralNotes';
import MyPaperComponent from '../common/components/MyPaperComponent';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PublicUploadReferralDocuments from './PublicUploadReferralDocuments';
import FileUtils from '../common/services/FileUtils';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is required'),
  code: yup.string(),
  referralUuid: yup.string(),
});

export default function PublicReviewReferral() {
  const history = useHistory();
  let { id, code } = useParams();
  const [isRefresh, setIsRefresh] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [details, setDetails] = useState({});
  const [queryData, setQueryData] = useState({});
  const [parsedData, setParsedData] = useState({});
  const [uploadedFileObjects, setUploadedFileObjects] = useState({});
  const [openUploadDocuments, setOpenUploadDocuments] = useState(false);
  const handleOpenUploadDocuments = () => setOpenUploadDocuments(true);
  const handleCloseUploadDocuments = useCallback(() => {
    setOpenUploadDocuments(false);
  }, []);

  const [verify_public_referral, { data: loadedData, loading, error }] = useMutation(
    VERIFY_PUBLIC_REFERRAL,
    {
      client: clientGraphqlPublic,
    },
  );

  const [public_docs] = useMutation(VERIFY_PUBLIC_REFERRAL, {
    client: clientGraphqlPublic,
  });

  const [verify_public_referral_full] = useMutation(VERIFY_PUBLIC_REFERRAL_FULL, {
    client: clientGraphqlPublic,
  });

  const data = loading || error ? undefined : loadedData;

  const [referral_answers, { data: data_referral_answers }] = useLazyQuery(
    REFERRAL_ANSWERS_PUBLIC_QUERY,
    {
      variables: {
        uuid: id,
      },
      client: clientGraphqlPublic,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      refetchOnWindowFocus: false,
    },
  );

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      email: '',
      code: '',
      referralUuid: '',
    },
    resolver: yupResolver(validationSchema),
    shouldFocusError: true,
  });

  const onSubmit = data => {
    var newData = JSON.parse(JSON.stringify(data));
    newData['referralUuid'] = id;
    newData['code'] = code;
    setQueryData({ ...newData });
    verify_public_referral({
      client: clientGraphqlPublic,
      variables: newData,
    })
      .then(res => {
        referral_answers({
          client: clientGraphqlPublic,
          variables: { referralUuid: id },
        });
        setParsedData({ ...res.data.referral, referral: new Referral(res.data.referral) });
        var uploadedFiles = {};
        if (!isEmpty(res.data.referral.documents)) {
          res.data.referral.documents.map(document => {
            if (!(document.name in uploadedFiles)) {
              uploadedFiles[document.name] = [];
            }
            uploadedFiles[document.name].push(document);
          });
        }
        setUploadedFileObjects(uploadedFiles);
        Swal.fire({
          icon: 'success',
          text: 'Successfully retrieved referral.',
          showConfirmButton: true,
        });
      })
      .catch(error => {});
  };

  const verifyMethod = useCallback(() => {
    public_docs({
      client: clientGraphqlPublic,
      variables: queryData,
    })
      .then(res => {
        var uploadedFiles = {};
        if (!isEmpty(res.data.referral.documents)) {
          res.data.referral.documents.map(document => {
            if (!(document.name in uploadedFiles)) {
              uploadedFiles[document.name] = [];
            }
            uploadedFiles[document.name].push(document);
          });
        }
        setUploadedFileObjects(uploadedFiles);
      })
      .catch(error => {});
  }, [public_docs, queryData, setUploadedFileObjects]);

  const referralAnswers = useCallback(
    (question, name) => {
      var result = 'No';
      if (!isEmpty(data_referral_answers) && !isEmpty(data_referral_answers.answers)) {
        data_referral_answers.answers.map(ref => {
          if (ref.question.toLowerCase() === question.toLowerCase() || name === ref.name) {
            result = ref.answer;
            return;
          }
        });
      }

      return result;
    },
    [data_referral_answers],
  );

  useEffect(() => {
    if (isRefresh) {
      verifyMethod();
      setIsRefresh(false);
    }
  }, [isRefresh, verifyMethod, setIsRefresh]);

  const showPatientDetailHandler = e => {
    e.stopPropagation();
    if (!isShow && isEmpty(details)) {
      verify_public_referral_full({
        client: clientGraphqlPublic,
        variables: queryData,
      })
        .then(res => {
          setDetails({ ...res.data.referral.patient.details });
        })
        .catch(error => {
          console.log(error);
        });
    }
    setIsShow(!isShow);
  };

  return (
    <>
      {!isEmpty(loadedData) ? (
        <Card style={{ padding: '50px 50px 0 50px' }}>
          <CardHeader
            title={
              !isEmpty(parsedData)
                ? parsedData.receiver.name + ' - ' + ' Dr. ' + parsedData.provider.display_name
                : ''
            }
            subheader={
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                {!isEmpty(parsedData) && !isEmpty(parsedData.receiver.full_address) && (
                  <li>{parsedData.receiver.full_address}</li>
                )}
                {!isEmpty(parsedData) &&
                  !isEmpty(parsedData.referral.getReceiverClinicOfficePhone()) && (
                    <li>{'OFFICE PHONE: ' + parsedData.referral.getReceiverClinicOfficePhone()}</li>
                  )}
                {!isEmpty(parsedData) && !isEmpty(parsedData.referral.getReceiverClinicFax()) && (
                  <li>{'FAX: ' + parsedData.referral.getReceiverClinicFax()}</li>
                )}
              </ul>
            }
          />
          <CardContent>
            <Grid container spacing={gridSpacing} style={{ marginTop: 1 }}>
              <Grid item direction="column" container style={{ paddingTop: 0 }}>
                <Grid item md={12} xs={12}>
                  <Stack direction="row" spacing={1}>
                    <MyTypography variant="h4">Patient Demographics</MyTypography>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <MyTypography variant="h4">Referral Status: </MyTypography>
                    <MyTypography variant="h4" style={{ fontWeight: 'normal' }}>
                      {!isEmpty(parsedData) && parsedData.referral.getReferrerStatus()}
                    </MyTypography>
                  </Stack>
                </Grid>
              </Grid>
              {!isEmpty(parsedData) &&
                (!isEmpty(parsedData.appointment) || !isEmpty(parsedData.reason)) && (
                  <Grid item md={12} xs={12} style={{ paddingTop: 0 }}>
                    <Stack direction="row" spacing={1}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {!isEmpty(parsedData) && !isEmpty(parsedData.appointment) ? (
                        <>
                          <MyTypography variant="h4">Appointment Date: </MyTypography>
                          <MyTypography variant="h4" style={{ fontWeight: 'normal' }}>
                            {parsedData.referral.getDateOrReason()}
                          </MyTypography>
                        </>
                      ) : null}
                      {!isEmpty(parsedData.referral) && !isEmpty(parsedData.reason) ? (
                        <>
                          <MyTypography variant="h4">Decline Reason: </MyTypography>
                          <MyTypography variant="h4" style={{ fontWeight: 'normal', color: 'red' }}>
                            {parsedData.referral.getDateOrReason()}
                          </MyTypography>
                        </>
                      ) : null}
                    </Stack>
                  </Grid>
                )}

              <>
                {!isEmpty(parsedData) && !isEmpty(parsedData.patient) && (
                  <Grid item container xs={12}>
                    {!isEmpty(parsedData.patient.full_name) ? (
                      <ReviewItem label="Full Name" value={parsedData.patient.full_name} />
                    ) : null}
                    {!isEmpty(parsedData.patient) &&
                    !isEmpty(parsedData.patient.details) &&
                    !isEmpty(parsedData.patient.details.phone) ? (
                      <ReviewItem label="Phone" value={parsedData.patient.details.phone} />
                    ) : null}

                    {isShow && (
                      <>
                        {!isEmpty(details.ssn) ? (
                          <ReviewItem label="SSN" value={details.ssn} />
                        ) : null}
                        {!isEmpty(details.birthdate) ? (
                          <ReviewItem label="Birthdate" value={details.birthdate} />
                        ) : null}
                        {!isEmpty(details.full_address) ? (
                          <ReviewItem label="Address" value={details.full_address} />
                        ) : null}
                        {!isEmpty(details.insurance_provider) ? (
                          <ReviewItem
                            label="Insurance Provider"
                            value={details.insurance_provider}
                          />
                        ) : null}
                        {!isEmpty(details.policy_number) ? (
                          <ReviewItem label="Policy Number" value={details.policy_number} />
                        ) : null}
                      </>
                    )}
                    <Grid item xs={12}>
                      <Button
                        color="secondary"
                        style={{ padding: '0' }}
                        onClick={showPatientDetailHandler}
                      >
                        {!isShow ? 'Show More' : 'Show Less'}
                      </Button>
                    </Grid>
                  </Grid>
                )}
                <Grid item md={12} xs={12}>
                  <Divider sx={{ my: 1.5 }} />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Stack direction="column">
                    <MyTypography variant="h4">Referral Details</MyTypography>
                  </Stack>
                </Grid>

                <Grid item container md={12} xs={12}>
                  {!isEmpty(parsedData) &&
                    !isEmpty(parsedData.diagnosis) &&
                    !isEmpty(parsedData.diagnosis.description) && (
                      <ReviewItem label="Diagnosis" value={parsedData.diagnosis.description} />
                    )}
                  {!isEmpty(parsedData) && (
                    <ReviewItem label="Comment" value={parsedData.referral?.getComment()} />
                  )}
                </Grid>
              </>

              <Grid item md={12} xs={12}>
                <Divider />
              </Grid>
              <Grid
                container
                spacing={gridSpacing}
                key={'grid-container-' + Math.random()}
                style={{ paddingTop: 24, paddingLeft: 24 }}
              >
                <Grid item md={12} xs={12}>
                  <Stack direction="row">
                    <MyTypography variant="h4">Referral Documents</MyTypography>
                  </Stack>
                </Grid>
                {!isEmpty(uploadedFileObjects) && !isEmpty(data_referral_answers) ? (
                  Object.keys(uploadedFileObjects).map(key => {
                    return (
                      <Grid key={'grid-file-' + Math.random()} item sm={6} xs={12}>
                        {uploadedFileObjects['' + key].map((uploadedDoc, index) => {
                          return (
                            <>
                              {!isEmpty(uploadedDoc.questions) &&
                              !isEmpty(data_referral_answers) &&
                              !isEmpty(data_referral_answers.answers) &&
                              data_referral_answers.answers.filter(
                                ref =>
                                  ref.question.toLowerCase() ===
                                  uploadedDoc.questions[0].question.toLowerCase(),
                              ).length > 0 &&
                              uploadedDoc.questions[0].question !== '?' &&
                              index == 0 ? (
                                <MyTypography
                                  variant="h5"
                                  key={'question-question-' + Math.random()}
                                >
                                  {uploadedDoc.questions[0].question +
                                    '  ' +
                                    referralAnswers(
                                      uploadedDoc.questions[0].question,
                                      uploadedDoc.name,
                                    )}
                                </MyTypography>
                              ) : (
                                index == 0 && (
                                  <MyTypography variant="h5" key={'question-name-' + Math.random()}>
                                    {capitalize(uploadedDoc.name)}
                                  </MyTypography>
                                )
                              )}
                              <MyButton
                                style={{ justifyContent: 'left' }}
                                key={'file' + Math.random()}
                                onClick={() => FileUtils.downloadFile(uploadedDoc)}
                                color="primary"
                                variant="text"
                                startIcon={
                                  <IconDownload style={{ height: '16px', width: '16px' }} />
                                }
                              >
                                {(!isEmpty(uploadedDoc.referral_document_name)
                                  ? uploadedDoc.referral_document_name
                                  : uploadedDoc.name + (index > 0 ? '-' + index : '')) +
                                  StringUtils.getFileExtension('path', uploadedDoc.url)}
                              </MyButton>
                            </>
                          );
                        })}
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item md={12} xs={12}>
                    <MyTypography variant="h5" style={{ textAlign: 'center' }}>
                      No Uploaded Documents.
                    </MyTypography>
                  </Grid>
                )}
                {(parsedData.status === 'under_review' ||
                  parsedData.status === 'sent' ||
                  parsedData.status === 'accepted' ||
                  parsedData.status === 'scheduled') && (
                  <Grid item md={12} xs={12}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                      <MyButton
                        type="button"
                        color="success"
                        variant="outlined"
                        onClick={handleOpenUploadDocuments}
                        fullWidth={false}
                      >
                        Upload Documents
                      </MyButton>{' '}
                    </Stack>
                  </Grid>
                )}
              </Grid>
              {!isEmpty(parsedData) && !isEmpty(queryData) && (
                <>
                  <Grid item md={12} xs={12}>
                    <MyTypography variant="h4">Referral Log</MyTypography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <MyTypography variant="h6">
                      In this section you may exchange messages with the receiving clinic and view
                      the system log.
                    </MyTypography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <PublicReviewReferralNotes
                      referralUUID={id}
                      email={queryData.email}
                      code={queryData.code}
                      name={parsedData.referrer.name}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>{' '}
        </Card>
      ) : (
        <AuthPageContainer>
          <Stack
            direction={matchDownSM ? 'column-reverse' : 'row'}
            alignItems="center"
            justifyContent="center"
          >
            <Box>
              <Stack alignItems="center" justifyContent="center" spacing={1}>
                <MyTypography
                  color={theme.palette.secondary.main}
                  gutterBottom
                  variant={matchDownSM ? 'h3' : 'h2'}
                >
                  Review Your Submitted Referral
                </MyTypography>
                <MyTypography
                  variant="caption"
                  fontSize="14px"
                  textAlign={matchDownSM ? 'center' : 'inherit'}
                >
                  Enter your email to open the referral
                </MyTypography>
              </Stack>
            </Box>
          </Stack>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              className="auth-log-in"
              direction="column"
              spacing={2}
              sx={{
                px: matchDownSM ? 1 : 3,
                py: 4,
              }}
            >
              {error && (
                <MyAlert severity="error" sx={{ mb: 2 }}>
                  {error.message}
                </MyAlert>
              )}

              <HookFormTextField
                id="email"
                name="email"
                type="email"
                placeholder="Enter email address"
                label="Email address *"
                autoComplete="email"
                schema="email"
                control={control}
                errors={errors}
                autoFocus
                fullWidth
              />

              <MyButton
                type="submit"
                variant="contained"
                size="large"
                loading={loading}
                disableElevation
              >
                Login
              </MyButton>
            </Stack>
          </form>
        </AuthPageContainer>
      )}

      {!isEmpty(id) &&
      !isEmpty(parsedData) &&
      (parsedData.status === 'under_review' ||
        parsedData.status === 'sent' ||
        parsedData.status === 'accepted' ||
        parsedData.status === 'scheduled') ? (
        <Dialog
          open={openUploadDocuments}
          onClose={() => setOpenUploadDocuments(false)}
          PaperComponent={MyPaperComponent}
          aria-labelledby="draggable-dialog-title"
          style={{ minWidth: '800px' }}
        >
          <PublicUploadReferralDocuments
            uuid={id}
            parsedData={parsedData}
            setOpenDialog={handleCloseUploadDocuments}
            setIsRefresh={setIsRefresh}
            data_referral_answers={data_referral_answers}
            uploadedFileObjects={uploadedFileObjects}
          />
        </Dialog>
      ) : null}
    </>
  );
}

import { FormControlLabel, Grid, Switch } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';

export default function PermissionOptions({ permissions = [], handleChange }) {
  
    return (
      <>
        {!isEmpty(permissions) &&
          permissions.map(permission => {
            return (
              <Grid item key={`grid.${permission.label}`} sm={4} xs={12}>
                <FormControlLabel
                  key={`control.${permission}`}
                  control={
                    <Switch
                      checked={permission.value}
                      key={`switch.${permission.label}`}
                      onChange={event => handleChange(event.target.checked, permission)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={permission.label}
                />
              </Grid>
            );
          })}
      </>
    );
  }
import React from 'react';
import { CircularProgress } from '@mui/material';
import { GET_GROUPS_QUERY } from '../../clinics/gql/Query';
import { useQuery } from '@apollo/client';
import { capitalize } from 'lodash';
import clientGraphql from '../../../common/apollo-graphql';
import HookFormAutocompleteSingle from '../../common/components/hook-form/HookFormAutocompleteSingle';

export default function GroupOptions({ prefix, control, errors, schema, placeholder, label }) {
    const { loading, data } = useQuery(GET_GROUPS_QUERY, {  client: clientGraphql, variables: { keyword: ''} });
    if (loading) return <CircularProgress />;
  
    return (
      
      <HookFormAutocompleteSingle
        id={schema}
        name={schema}
        placeholder={placeholder}
        label={label}
        schema={schema}
        prefix={prefix}
        control={control}
        errors={errors}
        autocompleteProps={{
          freeSolo: true,
          fullWidth: true
        }}
        options={
          data && data.hasOwnProperty('rows')
            ? data.rows.map(i => ({ value: i.name, label: capitalize(i.name) }))
            : []
        }
        getOptionLabel={option => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.label;
        }}
        renderOption={(props, option) => (
          <li key={option.value} {...props}>
            {capitalize(option.label)}
          </li>
        )}
      />
    );
  }
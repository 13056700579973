import React, { useCallback, useEffect, useState } from 'react';
import {  Grid } from '@mui/material';
import { MyTypography } from '../../common/components';
import * as yup from 'yup';
import 'yup-phone';
import { gridSpacing } from '../../../common/themes/constants';
import { isEmpty } from 'lodash';
import PermissionOptions from './PermissionOptions';
import NotificationOptions from './NotificationOptions';
import { GET_NOTIFICATIONS_QUERY, GET_PERMISSIONS_QUERY } from '../../clinics/gql/Query';
import { useQuery } from '@apollo/client';
import clientGraphql from '../../../common/apollo-graphql';
import UserOptions from './UserOptions';

export const initialValues = {
  userId: '',
  permission: parseInt('100', 2),
  notification: parseInt('1', 2),
}

export const registerUserValidationSchema = yup.object().shape({
  userId: yup
    .string()
    .required('Please select a user')
});

export default function RegisterUserFromOtherClinicForm({
  control,
  errors,
  prefix = null,
  permissions = [],
  setValue,
  trigger,
  dataUsers=null
}) {
  const [permissionBinary, setPermissionBinary] = useState({});
  const [notificationsBinary, setNotificationsBinary] = useState({});
  const [parsedData, setParsedData] = useState({});
  const [parsedNotificationData, setParsedNotificationData] = useState({});

  const { data } = useQuery(GET_PERMISSIONS_QUERY, {
    client: clientGraphql,
  });
  const { data: dataNotificationSettings } = useQuery(GET_NOTIFICATIONS_QUERY, {
    client: clientGraphql,
  });

  useEffect(() => {
    if (!isEmpty(data)) {
        const newData = data.user_permission_settings.map(permission => {
          return { value: permission.toLowerCase().includes('schedule'),
            label: permission };
        });
        setParsedData(newData);
    }
  }, [data, setParsedData]);

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(permissions)) {
        const newData = data.user_permission_settings.map((permission, index) => {
          return { value: permissions[index].value,
            label: permission,
          name: permission };
        });
        setParsedData(newData);
    }
  }, [data, setParsedData, permissions]);

  useEffect(() => {
    if (!isEmpty(parsedData) && isEmpty(permissionBinary)) {
      setPermissionBinary({
        ...Object.fromEntries(parsedData.map(v => [v.label.replace(' ', '_'), v.value])),
      });
    }
  }, [parsedData, setPermissionBinary, permissionBinary]);

  useEffect(() => {
    if (!isEmpty(parsedNotificationData) && isEmpty(notificationsBinary)) {
      setNotificationsBinary({
        ...Object.fromEntries(parsedNotificationData.map(v => [v.label.replace(' ', '_'), v.value])),
      });
    }
  }, [parsedNotificationData, setNotificationsBinary, notificationsBinary,trigger, setValue, prefix]);

  useEffect(() => {
    if (!isEmpty(dataNotificationSettings)) {
        const newData = dataNotificationSettings.user_notification_settings.map(notification => {
            return { value: true,
            label: notification,
            name: notification };
        });
        setParsedNotificationData(newData);
    }
  }, [dataNotificationSettings, setParsedNotificationData]);


  const handlePermissionChange = useCallback(
    (value, permision) => {
      var binary = permissionBinary;
      permision.value = !permision.value;
      binary[permision.label.replace(' ', '_')] = permision.value;
      setPermissionBinary(binary);
      var strPermission = '';
      Object.keys(permissionBinary)
        .reverse()
        .forEach(function(key, index) {
          if (permissionBinary[key]) {
            strPermission += '1';
          } else {
            strPermission += '0';
          }
        });
      var permissionInt = parseInt(strPermission, 2);
      setValue(!isEmpty(prefix) ? `${prefix}.permission` : 'permission', permissionInt);
      trigger(!isEmpty(prefix) ? `${prefix}.permission` : 'permission');
    },
    [setValue, trigger, prefix, permissionBinary],
  );

  const handleNotificationChange = useCallback(
    (value, notification) => {
      var binary = notificationsBinary;
      notification.value = !notification.value;
      binary[notification.label.replace(' ', '_')] = notification.value;
      setNotificationsBinary(binary);
      var strNotification = '';

      Object.keys(notificationsBinary)
        .reverse()
        .forEach(function(key, index) {
          if (notificationsBinary[key]) {
            strNotification += '1';
          } else {
            strNotification += '0';
          }
        });
      var notificationInt = parseInt(strNotification, 2);
      setValue(!isEmpty(prefix) ? `${prefix}.notification` : 'notification', notificationInt);
      trigger(!isEmpty(prefix) ? `${prefix}.notification` : 'notification');
    },
    [setValue, trigger, prefix, notificationsBinary],
  );

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item sm={12} xs={12}>
        <UserOptions schema="userId"
          placeholder="Enter the User"
          label="User"
          data={dataUsers}
                    prefix={prefix}
                    control={control}
                    errors={errors}/>
      </Grid>

      <Grid item xs={12}>
        <MyTypography variant="h5" component="h5" color="text.primary">
          Permissions
        </MyTypography>
      </Grid>
      <PermissionOptions
        placeholder="Enter the Permissions"
        label="Permissions"
        permissions={parsedData}
        handleChange={handlePermissionChange}
      />
      <Grid item xs={12}>
        <MyTypography variant="h5" component="h5" color="text.primary">
          Notifications
        </MyTypography>
      </Grid>
      <NotificationOptions
        placeholder="Enter the Notications"
        label="Notifications"
        notificationSettings={parsedNotificationData}
        handleChange={handleNotificationChange}
      />
    </Grid>
  );
}

export const SEND_REFERRAL_BEGIN = 'SEND_REFERRAL_BEGIN';
export const SEND_REFERRAL_SUCCESS = 'SEND_REFERRAL_SUCCESS';
export const SEND_REFERRAL_FAILURE = 'SEND_REFERRAL_FAILURE';
export const SEND_REFERRAL_DISMISS_ERROR = 'SEND_REFERRAL_DISMISS_ERROR';
export const SAVE_REFERRAL_AS_DRAFT_BEGIN = 'SAVE_REFERRAL_AS_DRAFT_BEGIN';
export const SAVE_REFERRAL_AS_DRAFT_SUCCESS = 'SAVE_REFERRAL_AS_DRAFT_SUCCESS';
export const SAVE_REFERRAL_AS_DRAFT_FAILURE = 'SAVE_REFERRAL_AS_DRAFT_FAILURE';
export const SAVE_REFERRAL_AS_DRAFT_DISMISS_ERROR = 'SAVE_REFERRAL_AS_DRAFT_DISMISS_ERROR';
export const UPLOAD_REFERRAL_DOCUMENTS_BEGIN = 'UPLOAD_REFERRAL_DOCUMENTS_BEGIN';
export const UPLOAD_REFERRAL_DOCUMENTS_SUCCESS = 'UPLOAD_REFERRAL_DOCUMENTS_SUCCESS';
export const UPLOAD_REFERRAL_DOCUMENTS_FAILURE = 'UPLOAD_REFERRAL_DOCUMENTS_FAILURE';
export const UPLOAD_REFERRAL_DOCUMENTS_DISMISS_ERROR = 'UPLOAD_REFERRAL_DOCUMENTS_DISMISS_ERROR';
export const GET_REFERRAL_BEGIN = 'GET_REFERRAL_BEGIN';
export const GET_REFERRAL_SUCCESS = 'GET_REFERRAL_SUCCESS';
export const GET_REFERRAL_FAILURE = 'GET_REFERRAL_FAILURE';
export const GET_REFERRAL_DISMISS_ERROR = 'GET_REFERRAL_DISMISS_ERROR';
export const PROMOTE_REFERRAL_BEGIN = 'PROMOTE_REFERRAL_BEGIN';
export const PROMOTE_REFERRAL_SUCCESS = 'PROMOTE_REFERRAL_SUCCESS';
export const PROMOTE_REFERRAL_FAILURE = 'PROMOTE_REFERRAL_FAILURE';
export const PROMOTE_REFERRAL_DISMISS_ERROR = 'PROMOTE_REFERRAL_DISMISS_ERROR';
export const CREATE_REFERRAL_NOTE_BEGIN = 'CREATE_REFERRAL_NOTE_BEGIN';
export const CREATE_REFERRAL_NOTE_SUCCESS = 'CREATE_REFERRAL_NOTE_SUCCESS';
export const CREATE_REFERRAL_NOTE_FAILURE = 'CREATE_REFERRAL_NOTE_FAILURE';
export const CREATE_REFERRAL_NOTE_DISMISS_ERROR = 'CREATE_REFERRAL_NOTE_DISMISS_ERROR';
export const SET_APPOINTMENT_REFERRAL_BEGIN = 'SET_APPOINTMENT_REFERRAL_BEGIN';
export const SET_APPOINTMENT_REFERRAL_SUCCESS = 'SET_APPOINTMENT_REFERRAL_SUCCESS';
export const SET_APPOINTMENT_REFERRAL_FAILURE = 'SET_APPOINTMENT_REFERRAL_FAILURE';
export const SET_APPOINTMENT_REFERRAL_DISMISS_ERROR = 'SET_APPOINTMENT_REFERRAL_DISMISS_ERROR';
export const DECLINE_REFERRAL_BEGIN = 'DECLINE_REFERRAL_BEGIN';
export const DECLINE_REFERRAL_SUCCESS = 'DECLINE_REFERRAL_SUCCESS';
export const DECLINE_REFERRAL_FAILURE = 'DECLINE_REFERRAL_FAILURE';
export const DECLINE_REFERRAL_DISMISS_ERROR = 'DECLINE_REFERRAL_DISMISS_ERROR';
export const GET_REFERRAL_PATIENT_BEGIN = 'GET_REFERRAL_PATIENT_BEGIN';
export const GET_REFERRAL_PATIENT_SUCCESS = 'GET_REFERRAL_PATIENT_SUCCESS';
export const GET_REFERRAL_PATIENT_FAILURE = 'GET_REFERRAL_PATIENT_FAILURE';
export const GET_REFERRAL_PATIENT_DISMISS_ERROR = 'GET_REFERRAL_PATIENT_DISMISS_ERROR';
export const UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_BEGIN = 'UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_BEGIN';
export const UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_SUCCESS = 'UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_SUCCESS';
export const UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_FAILURE = 'UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_FAILURE';
export const UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_DISMISS_ERROR = 'UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_DISMISS_ERROR';
export const FAX_SEND_BEGIN = 'FAX_SEND_BEGIN';
export const FAX_SEND_SUCCESS = 'FAX_SEND_SUCCESS';
export const FAX_SEND_FAILURE = 'FAX_SEND_FAILURE';
export const FAX_SEND_DISMISS_ERROR = 'FAX_SEND_DISMISS_ERROR';


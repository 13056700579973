
const LEVEL_NOTIFICATION_ONLY = 1;
const LEVEL_ALERT_AND_NOTIFICATION = 3;

const NotifiableUser = "App\\Models\\User";

const usersNotificationsFilter = (userId) => {
    return {
        notifiable_id: {_eq: userId},
        notifiable_type: {_eq: NotifiableUser},
        level: {_in: [LEVEL_NOTIFICATION_ONLY, LEVEL_ALERT_AND_NOTIFICATION]}
    };
};

const unreadNotificationsFilter = (userId) => {
    const filter = usersNotificationsFilter(userId);
    return {
        ...filter,
        read_at: {_is_null: true},
    };
};

const latestNotificationsFilter = (userId, currentDate) => {
    const initialFilter = unreadNotificationsFilter(userId);
    return {
        ...initialFilter,
        created_at: {_gte: currentDate.format()}
    };
};

const allNotificationsFilter = (userId, now) => {
    const filter = usersNotificationsFilter(userId);
    const lastMonth = now.subtract(1, 'months');

    return {
        ...filter,
        created_at: {_gte: lastMonth.format()}
    };
};

export {
    usersNotificationsFilter,
    unreadNotificationsFilter,
    latestNotificationsFilter,
    allNotificationsFilter,
};

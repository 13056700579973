import React, { useContext} from 'react';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MyButton } from '../../../common/components';
import { isEmpty, omit } from 'lodash';
import Swal from 'sweetalert2';
import { gridSpacing } from '../../../../common/themes/constants';
import { useHistory } from 'react-router-dom';
import BackendErrorHandler from '../../../common/components/hook-form/BackendErrorHandler';
import { useMutation } from '@apollo/client';
import clientGraphql from '../../../../common/apollo-graphql';
import ClinicContext from '../../context/ClinicContext';
import { SAVE_REFERRAL_GROUP_MUTATION } from '../../gql/Mutation';
import CreateReferralGroupForm, {
  initialValues,
  createReferralGroupValidationSchema,
}  from './CreateReferralGroupForm';
import PageContent from '../../../common/MainLayout/Page';

export default function AddReferralGroup() {
  const [save_referral_network, { loading: saveReferralNetworkLoading, error: saveReferralNetworkError }] = useMutation(
    SAVE_REFERRAL_GROUP_MUTATION,
    {
      client: clientGraphql,
    },
  );
  const history = useHistory();

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    reset,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(createReferralGroupValidationSchema),
    shouldFocusError: true,
    mode: 'all',
    reValidateMode: 'onSubmit',
  });


  const onSubmit = data => {
    var newData = {};
    newData['name'] = data.referral_network;

    save_referral_network({
      client: clientGraphql,
      variables: {
        referralNetwork: {
          ...newData,
        },
      },
    }).then(res => {
      if (!isEmpty(res) && !isEmpty(res.data) && !isEmpty(res.data.save_referral_network)) {
        Swal.fire({
          icon: 'success',
          text: 'Successfully created a referral group',
          showConfirmButton: true,
        }).then(() => {
          history.push('/manage/referral_networks');
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Cannot create referral group',
            text: !isEmpty(error.data.message) ? error.data.message : JSON.stringify(error),
            showConfirmButton: true,
          });
        });
      }
    });
  };

  return (
    <PageContent contentClass="clinics-manage-enroll-referral-groups">
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackendErrorHandler error={saveReferralNetworkError} setError={setError} clearErrors={clearErrors} />

      <Stack direction="column" spacing={gridSpacing}>
        <CreateReferralGroupForm
          control={control}
          prefix={null}
          errors={errors}
        />

        <Stack direction="row" justifyContent="flex-end">
          <MyButton
            type="submit"
            variant="contained"
            size="large"
            disabled={!isValid}
            loading={saveReferralNetworkLoading}
            fullWidth={false}
          >
            Create Referral Group
          </MyButton>
        </Stack>
      </Stack>
    </form></PageContent>
  );
}

import { useQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import { isEmpty, omit } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import clientGraphql from '../../common/apollo-graphql';
import AuthContext from '../auth/context/AuthContext';
import ClinicDetails from '../clinics/Management/ClinicDetails/ClinicDetails';
import ClinicProviderListTable from '../clinics/Management/ClinicDetails/ClinicProviderListTable';
import { GET_CLINIC_DETAILS } from './gql/Query';
import useParsedTableData from '../common/hooks/useParsedTableData';
import { useDeleteClinic } from '../clinics/redux/hooks';

import Swal from 'sweetalert2';
import { MANAGE_NON_AFFILIATED_CLINICS_QUERY } from '../clinics/gql/Query';
import NonAffiliateClinicsTable from '../clinics/Management/ManageClinics/NonAfficiateClinicsTable';
// import PropTypes from 'prop-types';

export default function ViewClinic({history}) {
  const currentUser = useContext(AuthContext);
  const [providerData, setProviderData] = useState({ rows: [], count: 0 });
  const [formattedData, setFormattedData] = useState({});
  const { loading, data } = useQuery(GET_CLINIC_DETAILS, {
    client: clientGraphql,
    variables: { uuid: !isEmpty(currentUser) ? currentUser.uuid : null },
  });

  useEffect(() => {
    if (data) {
      const { clinic } = data;
      const newData = omit(clinic, [
        'id',
        '__typename',
        'aggr_users',
        'address_json',
        'full_address',
        'documents',
        'specializations',
        'users',
        'required_documents',
        'optional_documents',
        'providers',
      ]);
      setFormattedData({
        ...newData,
      });
      setProviderData({
        rows: data.clinic.providers.map(item => {
          return {
            ...item,
          };
        }),
        count: data.clinic.providers.count,
      });
    }

    return () => {
      setFormattedData({});
    };
  }, [data]);

  const {deleteClinic} = useDeleteClinic();

  const [keyword, setKeyword] = useState("");
  const { loading: clinicLoading, data: clinicData, refetch } = useQuery(MANAGE_NON_AFFILIATED_CLINICS_QUERY, {
    client: clientGraphql,
    variables: {
      keyword: keyword 
    },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
  });

  const handleOnAdd = useCallback(() => {
    history.push('/clinics/non-affiliate');
  }, [history]);

  const handleFilterChanged = useCallback(e => {
    setKeyword(e.target.value);
  }, []);

  const handleOnDelete = useCallback((rowId) => {
    Swal.fire({
        html: "You are about to archive this clinic record." ,
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: false,
        confirmButtonText: 'Archive',
        denyButtonText: 'Delete',
    }).then((result) => {
        let forceDelete;
        let type;

        if (result.isConfirmed) {
            forceDelete = false;
            type = 'archived';
        } else if (result.isDenied) {
            forceDelete = true;
            type = 'deleted';
        }

        if(type) {
          deleteClinic(rowId, { 'forceDelete': forceDelete }).then(res => {
            refetch();
            Swal.fire(
                'Clinic ' + type,
                '',
                'success'
            );
          });
        }
    });
  }, [deleteClinic, refetch]);

  const handleOnEdit = useCallback((rowId) => {
    history.push(`/clinic/${rowId}?edit=1`);
  }, [history]);

  const handleOnView = useCallback((rowId) => {
      history.push(`/clinic/${rowId}`);
  }, [history]);

  const handleOnReferralSettings = useCallback((rowId) => {
    history.push(`/clinic/${rowId}/settings`);
}, [history]);

  const handleOnSelectedRowsChanged = useCallback((rowId) => {
  }, []);

  const actions = useMemo(() => {
      return {onView: handleOnView, onEdit: handleOnEdit, onDelete: handleOnDelete, onReferralSettings: handleOnReferralSettings};
  }, [handleOnView, handleOnEdit, handleOnDelete, handleOnReferralSettings]);

  const {parsedData} = useParsedTableData(clinicData, actions);

  return (
    <Stack direction="column" spacing={1}>
      <ClinicDetails data={formattedData} />
      
      <NonAffiliateClinicsTable onFilterChanged={handleFilterChanged}
                    onSelectedRowsChanged={handleOnSelectedRowsChanged}
                    onAdd={handleOnAdd}
                    onDelete={handleOnDelete}
                    onEdit={handleOnEdit}
                    loading={loading}
                    data={parsedData} />

      <ClinicProviderListTable data={providerData} loading={loading} />
    </Stack>
  );
}

import { gql } from '@apollo/client';

const GET_ROLE_OPTIONS_QUERY = gql`
    query GetRoleOptions($excludedRoles: [String]!) {
      rows: roles(excluded_roles: $excludedRoles) {
        id
        name
      }
    }
`;

export {
    GET_ROLE_OPTIONS_QUERY,
};
import React from 'react';
import { Controller } from 'react-hook-form';
import HookFormUtils from './HookFormUtils';
import MyTextField from '../MyTextField';

export default function HookFormTextField({
    control,
    schema,
    variant,
    type='text',
    loading,
    errors,
    label,
    prefix=null,
    ...others
}) {
    const error = HookFormUtils.getFieldError(errors, schema, prefix);
    const _schema = HookFormUtils.getSchemaName(schema, prefix);

    return (
        <Controller
            control={control}
            name={_schema}
            render={({ field: { onChange, value } }) => (
                <MyTextField onChange={onChange}
                           name={_schema}
                           value={value}
                           type={type}
                           variant={variant || 'outlined'}
                           label={label}
                           error={error ? true : false}
                           helperText={error && error.message}
                           {...others}
                />
            )}
        />
    );
}
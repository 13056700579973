import React, {useMemo} from 'react';
import {
    CircularProgress,
    MenuItem,
} from '@mui/material';
import {HookFormSelect} from '../../common/components';
import * as Roles from '../constants/Role';
import {GET_ROLE_OPTIONS_QUERY} from '../gql/Query';
import { useQuery } from '@apollo/client';
import {mapKeys, startCase} from 'lodash';
import clientGraphql from '../../../common/apollo-graphql';

export default function RoleOptions({prefix=null, control, errors, disabled=false}) {

    const RoleOpts = useMemo(() => {
        return mapKeys(Roles, i => i.value);
    }, []);

    const { loading, data } = useQuery(
        GET_ROLE_OPTIONS_QUERY,
        {
            client: clientGraphql,
            variables: {
                excludedRoles: [Roles.ROLE_ADMIN['value']]
            }
        }
    );

    if (loading) return <CircularProgress />;

    return (
        <HookFormSelect
            id="role_id"
            type="select"
            select
            name="role_id"
            placeholder="Select role"
            label="Role *"
            schema="role_id"
            prefix={prefix}
            control={control}
            errors={errors}
            fullWidth
            disabled={disabled}
        >
            {
                data &&
                data.rows.map((option) => (
                    <MenuItem key={option.name} value={parseInt(option.id,10)}>
                        {startCase(option.name)}
                    </MenuItem>
                ))
            }
        </HookFormSelect>
    );
}
import React, {useState} from 'react';
import {
    Box,
    Stack,
    Stepper,
    Step,
    StepLabel,
} from '@mui/material';
import MyTypography from './MyTypography';
import MyButton from './MyButton';

export function MyStepperHeader({steps, activeStep}) {
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {
                    steps.map((step, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const {label} = step;

                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};

                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })
                }
            </Stepper>
        </Box>
    );
}

export default function MyStepper({
    steps,
    onSubmit,
    backButtonText='Back',
    nextButtonText='Next',
    submitButtonText='Submit'
}) {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {
                    steps.map((item, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const {label} = item;

                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};

                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })
                }
            </Stepper>

            <Stack direction="column">
                <MyTypography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</MyTypography>

                <Stack direction="column">
                    {steps[activeStep].content}
                </Stack>

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <MyButton
                        color="primary"
                        variant="outlined"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        fullWidth={false}
                        sx={{ mr: 1 }}
                    >
                        {backButtonText}
                    </MyButton>
                    {
                        activeStep < (steps.length - 1) &&
                        <MyButton onClick={handleNext}
                                  variant="contained"
                                  fullWidth={false}>
                            {nextButtonText}
                        </MyButton>
                    }
                    {
                        activeStep === (steps.length - 1) &&
                        <MyButton onClick={onSubmit}
                                  variant="contained"
                                  fullWidth={false}>
                            {submitButtonText}
                        </MyButton>
                    }
                </Box>
            </Stack>
        </Box>
    );

}
import React, {useState} from 'react';
import {
    InputAdornment,
    IconButton,
} from '@mui/material';
import {IconEye, IconEyeOff} from '@tabler/icons';
import {HookFormTextField} from '../index';

export default function HookFormPasswordField(props) {
    const [inputType, setInputType] = useState('password');

    return (
        <HookFormTextField
            {...props}
            type={inputType}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => setInputType(prevState => prevState === 'password' ? 'text' : 'password')}
                            aria-label="toggle password visibility"
                            edge="end"
                        >
                            {inputType !== 'password' ? <IconEyeOff /> : <IconEye />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}
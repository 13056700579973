import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { MyButton, MyTypography } from '../../../common/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { gridSpacing } from '../../../../common/themes/constants';
import Swal from 'sweetalert2';
import { GET_NOTIFICATIONS_QUERY } from '../../gql/Query';
import clientGraphql from '../../../../common/apollo-graphql';
import { useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { SAVE_USER_CLINIC_MUTATION } from '../../gql/Mutation';
import { GET_USERS } from '../../../user/gql/Query';
import User from '../../../auth/models/User';
import RegisterExistingUserForm, {
  registerUserValidationSchema,
  initialValues,
} from '../../../auth/RegisterUser/RegisterExistingUserForm';

const validationSchema = registerUserValidationSchema;

export default function AddExistingClinicUser({
  existingUsers = { rows: [], count: 0 },
  setExistingUsers,
  appendUser=null,
  clinicId,
}) {
  const [parsedNotificationData, setParsedNotificationData] = useState({});
  const { loading, data: dataUsers } = useQuery(GET_USERS, {
    client: clientGraphql,
    variables: {
      without_clinic_id: clinicId,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const { data: dataNotificationSettings } = useQuery(GET_NOTIFICATIONS_QUERY, {
    client: clientGraphql,
  });

  const [
    save_user_clinic,
    { data: saveUserClinicData, loading: saveUserClinicLoading, error: saveUserClinicError },
  ] = useMutation(SAVE_USER_CLINIC_MUTATION, {
    client: clientGraphql,
  });

  const {
    control,
    setValue,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const onSubmit = useCallback(() => {
    const userArr = dataUsers.rows.filter(user => user.email === getValues('userId'));
    var user = !isEmpty(userArr) ? JSON.parse(JSON.stringify(userArr[0])) : null;
    if (!isEmpty(user)) {

      var permisionsBinary = '100';
      var notificationsBinary = '1';

      user.permissions = user.permissions.map((perm,index) => {
        perm.value = parseInt(permisionsBinary[index]);
        return perm;
      });
      user.notifications = user.notifications.map((notif,index) => {
        notif.value = parseInt(notificationsBinary[index]);
        return notif;
      });
      var users = {rows: existingUsers.rows.slice(), count: existingUsers.rows};
      users['rows'].push({...user, 'userObj': new User({...user})});
      users['count'] = users.rows.length;
      console.log(user);
      appendUser({
        id: user.id,
        // id: '12',
        role_id: parseInt(user.role.id),
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        phone: user.phone,
        provider_ids: [],
        permission: parseInt('100', 2),
        notification: parseInt('1', 2),
      });

      setExistingUsers(users);
      setValue('userId', '');
      trigger('userId');
    }
  }, [dataUsers, existingUsers, setExistingUsers, getValues, setValue, trigger, appendUser]);

  useEffect(() => {
    if (!isEmpty(saveUserClinicError)) {
      const errors = !isEmpty(saveUserClinicError.graphQLErrors)
        ? saveUserClinicError.graphQLErrors[0]
        : {};
      if (
        !isEmpty(errors.extensions) &&
        !isEmpty(errors.extensions.validation) &&
        JSON.stringify(errors.extensions.validation).includes('user')
      ) {
        Swal.fire({
          icon: 'error',
          text: JSON.stringify(errors.extensions.validation),
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Error Message: ' + saveUserClinicError.message,
          showConfirmButton: true,
        });
      }
    }
    if (!isEmpty(saveUserClinicData)) {
      Swal.fire({
        icon: 'success',
        text: 'Successfully added a user',
        showConfirmButton: true,
      }).then(() => {
        reset();
      });
    }
  }, [saveUserClinicData, saveUserClinicError, reset]);

  useEffect(() => {
    if (!isEmpty(dataNotificationSettings)) {
      const newData = dataNotificationSettings.user_notification_settings.map(notification => {
        return { value: true, label: notification };
      });
      setParsedNotificationData(newData);
    }
  }, [dataNotificationSettings, setParsedNotificationData]);

  return (
    <>
      <Grid container direction="column" spacing={gridSpacing}>
        <Grid item xs={12}>
          <MyTypography variant="h3">Add User From Other Clinic</MyTypography>
        </Grid>
        <Grid item xs={12}>
          <MyTypography variant="subtitle">
            Search the registered user using the email used to register.
          </MyTypography>
        </Grid>
        <Grid item xs={9}>
          {!isEmpty(dataUsers) ? (
            <RegisterExistingUserForm
              control={control}
              errors={errors}
              dataUsers={dataUsers}
            />
          ) : null}
        </Grid>
        <Grid item xs={3}>
          <MyButton
            type="button"
            variant="contained"
            fullWidth={false}
            // style={{ marginBottom: '24px' }}
            onClick={() => {
              onSubmit();
            }}
          >
            Import User
          </MyButton>
        </Grid>
      </Grid>

      {/* <ManageClinicUsersTable
        data={existingUsers}
        showToolbar={false}
      /> */}
    </>
  );
}

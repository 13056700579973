import React from 'react';
import {MyDataGrid, MyIconButton} from '../../common/components';
import { IconPencil, IconTrash } from '@tabler/icons';
import {
    Chip,
    Stack,
} from '@mui/material';
import { capitalize } from 'lodash';

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        width: 180,
        valueGetter: (params) => {
            return params.row.userObj.getFullName();
        },
    },
    {
        field: 'email',
        headerName: 'Email',
        sortable: true,
        width: 300,
    },
    {
        field: 'role',
        headerName: 'Role',
        sortable: true,
        width: 150,
        renderCell: (params) => {
            const components = [
                <Chip size="small" label={params.row.userObj.getRoleName()} color="secondary" key={params.id} />
            ];

            return (
                <Stack direction="row" spacing={0.5}>
                    {components}
                </Stack>
            );
        },
    },
    {
        field: 'activated_at',
        headerName: 'Activated',
        sortable: true,
        width: 150,
        renderCell: (params) => {
            const value = params.row.userObj.getActivationStatus();
            const color = params.row.userObj.isActivated() ? 'success' : 'error';
            return <Chip size="small" label={value} color={color} sx={{textTransform: 'capitalize'}} />;
        },
    },
    {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        width: 150,
        renderCell: (params) => {
            return  (
                <Stack direction="row" spacing={1}>
                    {
                        (
                            params.row.actions.onEdit
                            && typeof params.row.actions.onEdit === 'function'
                        ) &&
                        <MyIconButton
                            label="Edit user"
                            onClick={(e) => {
                                e.stopPropagation();
                                params.row.actions.onEdit(params.row.uuid);
                            }}>
                            <IconPencil />
                        </MyIconButton>
                    }
                    {
                        (
                            params.row.actions.onDelete
                            && typeof params.row.actions.onDelete === 'function'
                        ) &&
                        <MyIconButton
                            label="Delete user"
                            onClick={(e) => {
                                e.stopPropagation();
                                params.row.actions.onDelete(params.row.clinicId, params.row.id, params.row.is_primary);
                            }}>
                            <IconTrash />
                        </MyIconButton>
                    }
                    {
                        (
                            params.row.actions.onEditUser
                            && typeof params.row.actions.onEditUser === 'function'
                        ) &&
                        <MyIconButton
                            label="Edit user"
                            onClick={(e) => {
                                e.stopPropagation();
                                params.row.actions.onEditUser(params.row.uuid, params);
                            }}>
                            <IconPencil />
                        </MyIconButton>
                    }
                </Stack>
            );
        }
    },
    {
        field: 'permission',
        headerName: 'Permissions',
        sortable: false,
        width: 800,
        valueGetter: params => {
          return "";
        },
        renderCell: params => {
          return (
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} style={{width: params.row.permissions.length * 120 }}>
              {params.row.permissions.filter(e => e.value === 1 ).map(item => (
                <Chip
                  color="primary"
                  size="small"
                  label={capitalize(item.label)}
                  key={item.key}
                />
              ))}
            </Stack>
          );
        },
      },
];

export default function ManageUsersTable({data, loading=false, onAdd=null, showToolbar=true}) {
    return (
        <MyDataGrid
            onAdd={onAdd}
            loading={loading}
            data={data}
            columns={columns}
            showToolbar={showToolbar}
            getRowId={(row) => {
                return row.id;
            }}
            height={100}
        />
    );
}
import React, { useCallback, useEffect, useState } from 'react';
import { gridSpacing } from '../../common/themes/constants';
import { Grid, Card, CardContent, CardHeader } from '@mui/material';
import { HookFormUtils, MyTypography } from '../common/components';
import { capitalize, debounce, isEmpty } from 'lodash';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetPatient } from '../referral/redux/getPatient';
import Swal from 'sweetalert2';
import Patient from '../referral/models/Patient';
import BackendErrorHandler from '../common/components/hook-form/BackendErrorHandler';
import { useSaveReferralAsDraft } from '../referral/redux/saveReferralAsDraft';
import Error from '../common/Error';
import PatientDemographicsForm from './PatientDemographicsForm';
import { useLazyQuery } from '@apollo/client';
import clientGraphql from '../../common/apollo-graphql';
import { GET_REFERRAL_BY_UUID, LOAD_PATIENT_BY_SSN } from '../referral/gql/Query';

String.prototype.splice = function(idx, rem, str) {
  return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};

export default function PatientDemographics({
  referralUuid,
  onSetReferralId,
  parsedData,
  prefix,
  onDisableNext = null,
  isSavedDraft = false,
  isSameOrg = false,
  patientSchema = {},
  referralSchema = {},
  requiredCustom = [],
  initialValues = {},
  isFax = false,
  lastRequiredIndex = -1,
  providers=[]
}) {
  const gqlParams = {
    client: clientGraphql,
    variables: {
      ssn: '',
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
  };
  const [referral, setReferral] = useState({});
  const [loadPatientBySsn] = useLazyQuery(LOAD_PATIENT_BY_SSN, gqlParams);
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    reset,
    setError,
    clearErrors,
    formState,
    watch,
  } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(
      yup.object().shape({
        referral: yup.object().shape(referralSchema),
        patient: yup.object().shape(patientSchema),
      }),
    ),
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const [details, setDetails] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [loadedSSN] = useState({});
  const {
    saveReferralAsDraft,
    saveReferralAsDraftPending,
    saveReferralAsDraftError,
    dismissSaveReferralAsDraftError,
  } = useSaveReferralAsDraft();

  const handleRetrieve = useCallback(
    res => {
      const feature = res.features[0];
      setValue('patient.address_line1', feature.properties.address_line1);
      setValue('patient.address_city', feature.properties.place);
      setValue('patient.address_state', feature.properties.region);
      setValue('patient.address_zip', feature.properties.postcode);
      trigger('patient.address_line1');
      trigger('patient.address_city');
      trigger('patient.address_state');
      trigger('patient.address_zip');
    },
    [setValue, trigger],
  );

  const debouncedSave = useCallback(
    debounce(async (referralUUID, args) => {
      setIsSaving(true);
      dismissSaveReferralAsDraftError();
      await saveReferralAsDraft(referralUUID, args)
        .then(data => {
          const { referral_uuid } = data;
          setIsSaving(false);
          if (isEmpty(referralUUID)) {
            onSetReferralId(referral_uuid);
          }
          reset({}, { keepValues: true, keepIsValid: false });
        })
        .catch(err => {
          setIsSaving(false);
          onDisableNext(true);
          reset({}, { keepValues: true, keepIsValid: false });
        });
    }, 500),
    [],
  );
  const hasAddressDirtyField = useCallback(() => {
    if ('patient' in formState.dirtyFields && 'address_line1' in formState.dirtyFields.patient) {
      return true;
    }
    if ('patient' in formState.dirtyFields && 'address_city' in formState.dirtyFields.patient) {
      return true;
    }
    if ('patient' in formState.dirtyFields && 'address_state' in formState.dirtyFields.patient) {
      return true;
    }
    if ('patient' in formState.dirtyFields && 'address_zip' in formState.dirtyFields.patient) {
      return true;
    }
    return false;
  }, [formState]);

  const params = {
    variables: {
      uuid: referralUuid,
    },
    client: clientGraphql,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
  };

  const [getReferralByUuid] = useLazyQuery(GET_REFERRAL_BY_UUID, params);

  const { getPatient, dismissGetPatientError } = useGetPatient();

  const onGetPatient = useCallback(() => {
    
    dismissGetPatientError();
    getPatient(referralUuid)
      .then(data => {
        getReferralByUuid(params).then(res=>{
          setReferral(res.data);
        });
        setDetails({
          ...data.data,
          patient: new Patient(data.data),
        });
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: err.data,
          showConfirmButton: true,
        });
      });
  }, [getPatient, dismissGetPatientError, referralUuid, getReferralByUuid, params, setReferral]);

  const updatedValues = useCallback(() => {
    if (!isEmpty(referralUuid) && !formState.isValidating && !hasAddressDirtyField()) {
      return HookFormUtils.dirtyValues(formState.dirtyFields, getValues());
    } else if (!isEmpty(referralUuid) && !formState.isValidating && hasAddressDirtyField()) {
      return {
        patient: {
          address_line1: getValues('patient.address_line1'),
          address_city: getValues('patient.address_city'),
          address_state: getValues('patient.address_state'),
          address_zip: getValues('patient.address_zip'),
        },
      };
    } else if (isEmpty(referralUuid) && !formState.isValidating) {
      return getValues();
    }
  }, [formState, getValues, hasAddressDirtyField, referralUuid]);

  useEffect(() => {
    if (!isEmpty(details) && !isEmpty(referralUuid)) {
      let patient = details.patient.patient;
      setValue('patient.ssn', patient.ssn);
      setValue('patient.firstname', patient.firstname);
      setValue('patient.lastname', patient.lastname);
      setValue('patient.birthdate', !isEmpty(patient.birthdate) ? patient.birthdate : '');
      setValue(
        'patient.phone',
        !isEmpty(patient.phone)
          ? patient.phone.splice(6, 0, '-').splice(3, 0, '-')
          : patient.phone != null
          ? patient.phone
          : '',
      );
      setValue(
        'patient.insurance_provider',
        !isEmpty(patient.insurance_provider) ? patient.insurance_provider : '',
      );
      setValue(
        'patient.policy_number',
        !isEmpty(patient.policy_number) ? patient.policy_number : '',
      );
      setValue('patient.address_line1', !isEmpty(patient.line1) ? patient.line1 : '');
      setValue('patient.address_city', !isEmpty(patient.city) ? patient.city : '');
      setValue('patient.address_state', !isEmpty(patient.state) ? patient.state : '');
      setValue('patient.address_zip', !isEmpty(patient.zip) ? patient.zip : '');
      console.log(details, referral);
      setValue('referral.diagnosis', !isEmpty(details.diagnosis) ? details.diagnosis : '');
      setValue('referral.comment', !isEmpty(details.comment) ? details.comment : '');
      setValue('referral.is_fax', !isEmpty(details.is_fax) ? details.is_fax : false);
      setValue('referral.referrer_provider_id', !isEmpty(patient.referrer_provider_id) ? patient.referrer_provider_id:'');
      if(!isEmpty(referral) && !isEmpty(referral.referral.extra_info)){
        var extra_info = JSON.parse(referral.referral.extra_info);
        console.log(extra_info);
        if (!isEmpty(extra_info)) {
          for (const [key, value] of Object.entries(extra_info)) {
            setValue('referral.' + key, value);
          }
        }
      }
      trigger('patient');
      trigger('referral');
    }
  }, [trigger, setValue, isSavedDraft, details, referralUuid, referral]);

  useEffect(() => {
    if (!isEmpty(providers) && providers.length === 1 && isEmpty(getValues('referral.referrer_provider_id'))) {
      setValue('referral.referrer_provider_id', providers[0].id);
      trigger('referral.referrer_provider_id');
    }
  }, [setValue, trigger, getValues, providers]);

  useEffect(() => {
    if (!isEmpty(parsedData)) {
      setValue('referral.clinic_receiver_id', parsedData.clinic.id);
      setValue('referral.provider_id', parsedData.clinic.provider_id);
      setValue('referral.is_fax', parsedData.clinic.isFax);
      trigger('referral.clinic_receiver_id');
      trigger('referral.provider_id');
      trigger('referral.is_fax');
    }
  }, [trigger, setValue, parsedData, reset, getValues]);

  useEffect(() => {
    if (!isEmpty(referralUuid) && isEmpty(details)) {
      onGetPatient();
    }
  }, [referralUuid, onGetPatient, details]);

  useEffect(() => {
    if (
      !isEmpty(getValues('patient.phone')) &&
      getValues('patient.phone').length === 10 &&
      !getValues('patient.phone').includes('-')
    ) {
      setValue(
        'patient.phone',
        getValues('patient.phone')
          .splice(6, 0, '-')
          .splice(3, 0, '-'),
      );
      trigger('patient.phone');
    }
    if (
      !isEmpty(getValues('patient.ssn')) &&
      getValues('patient.ssn').length === 9 &&
      !getValues('patient.ssn').includes('-')
    ) {
      setValue(
        'patient.ssn',
        getValues('patient.ssn')
          .splice(5, 0, '-')
          .splice(3, 0, '-'),
      );
      trigger('patient.ssn');
    }
    if (formState.isValid && !formState.isValidating && !isEmpty(formState.dirtyFields)) {
      const val = JSON.parse(JSON.stringify(updatedValues()));
      if (val.hasOwnProperty('patient') && val['patient'].hasOwnProperty('ssn')) {
        val.patient.ssn = val.patient.ssn.replace(/-/g, '');
      }
      if (val.hasOwnProperty('patient') && val.patient.hasOwnProperty('phone')) {
        val.patient.phone = val.patient.phone.replace(/-/g, '');
      }
      if (!isSaving) {
        if (!isEmpty(val) && !isEmpty(val.referral)) {
          val.referral.is_fax = parsedData.clinic.isFax;
        }
        handleSubmit(debouncedSave(referralUuid, val));
      }
    }

    onDisableNext(!formState.isValid);
  }, [
    parsedData,
    formState,
    referralUuid,
    handleSubmit,
    debouncedSave,
    updatedValues,
    getValues,
    setValue,
    trigger,
    onDisableNext,
    isSaving,
    isFax,
  ]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        !isEmpty(value.patient.ssn) &&
        name === 'patient.ssn' &&
        value.patient.ssn.length === 11 &&
        isEmpty(referralUuid)
      ) {
        loadPatientBySsn({
          client: clientGraphql,
          variables: { ssn: value.patient.ssn.replaceAll('-', '') },
        }).then(result => {
          if (
            result.hasOwnProperty('data') &&
            !isEmpty(result.data) &&
            result.data.hasOwnProperty('patient_by_ssn')
          ) {
            const { patient_by_ssn } = result.data;
            if (!isEmpty(patient_by_ssn)) {
              setValue('patient.firstname', patient_by_ssn.firstname);
              setValue('patient.lastname', patient_by_ssn.lastname);
              setValue(
                'patient.birthdate',
                !isEmpty(patient_by_ssn.birthdate) ? patient_by_ssn.birthdate : '',
              );
              setValue('patient.phone', !isEmpty(patient_by_ssn.phone) ? patient_by_ssn.phone : '');
              setValue(
                'patient.insurance_provider',
                !isEmpty(patient_by_ssn.insurance_provider)
                  ? patient_by_ssn.insurance_provider
                  : '',
              );
              setValue(
                'patient.policy_number',
                !isEmpty(patient_by_ssn.policy_number) ? patient_by_ssn.policy_number : '',
              );
              if (patient_by_ssn.address != null) {
                setValue('patient.address_line1', patient_by_ssn.address.line1);
                setValue('patient.address_city', patient_by_ssn.address.city);
                setValue('patient.address_state', patient_by_ssn.address.state);
                setValue('patient.address_zip', patient_by_ssn.address.zip);
              }
              trigger('patient');
              trigger('referral');
            }
          }
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, referralUuid, loadPatientBySsn, loadedSSN, setValue, trigger]);

  const handleProviderChange = useCallback(
    value => {
      setValue('referral.referrer_provider_id', value.id);
      trigger('referral.referrer_provider_id');
    },
    [setValue, trigger],
  );

  return (
    <Card>
      <CardHeader
        title={parsedData.clinic.provider_name + ' - ' + capitalize(parsedData.specialty.name)}
        subheader={parsedData.clinic.name + ' - ' + parsedData.clinic.address}
      />
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item sm={12} xs={12}>
            <MyTypography>
              STATUS:{' '}
              {!isEmpty(referralUuid)
                ? 'Saved as Draft'
                : saveReferralAsDraftPending
                ? 'Submitting...'
                : 'Unsaved'}
            </MyTypography>
          </Grid>
          <Grid item sm={12} xs={12}>
            {saveReferralAsDraftError && (
              <Grid item md={12} xs={12} sx={{ pb: 2 }}>
                <BackendErrorHandler
                  error={saveReferralAsDraftError}
                  setError={setError}
                  clearErrors={clearErrors}
                />
                <Error error={saveReferralAsDraftError} />
              </Grid>
            )}
            <PatientDemographicsForm
              control={control}
              prefix={prefix}
              errors={formState.errors}
              handleRetrieve={handleRetrieve}
              requiredCustom={requiredCustom}
              isSameOrg={isSameOrg}
              lastRequiredIndex={lastRequiredIndex}
              providers={providers}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

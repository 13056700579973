import { Checkbox, FormControlLabel, Grid, MenuItem, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import { MyIconButton, MyTypography } from '../../../common/components';
import { IconArrowDown, IconArrowUp, IconX } from '@tabler/icons';
import MyTextField from '../../../common/components/MyTextField';
import ChoicesOptions from '../ChoicesOptions';
import { DEFAULT_ORIGINAL_FIELDS, FIELD_TYPES } from '../../../common/services/DefaultValuesUtils';

function ViewFieldData({
  referralSetting,
  index,
  referralSettings,
  handleChange,
  handleSortChange,
  removeField,
  type,
}) {
  return (
    <>
      <Grid item xs={0.8} style={{ padding: 8 }}>
        {index > 0 && (
          <MyIconButton
            color="text.secondary"
            label="Up"
            style={{ fontSize: '14px', padding: 0 }}
            onClick={e => {
              e.stopPropagation();
              handleSortChange(index, referralSettings, 'up', type);
            }}
          >
            <IconArrowUp style={{ height: '16px', width: '16px' }} />
          </MyIconButton>
        )}
        {index < referralSettings.length - 1 && (
          <MyIconButton
            color="text.secondary"
            label="Down"
            style={{ fontSize: '14px', padding: 0 }}
            onClick={e => {
              e.stopPropagation();
              handleSortChange(index, referralSettings, 'down', type);
            }}
          >
            <IconArrowDown style={{ height: '16px', width: '16px' }} />
          </MyIconButton>
        )}
      </Grid>
      <Grid item xs={0.8} style={{ padding: 8 }}>
        <FormControlLabel
          key={`control-required.${index}`}
          control={
            <Checkbox
              checked={referralSetting.required}
              key={`switch.${index}`}
              onChange={event =>
                handleChange(index, event.target.checked, 'required', referralSettings, type)
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={''}
        />
      </Grid>
      {!DEFAULT_ORIGINAL_FIELDS.includes(referralSetting.field) ? (
        <Grid item xs={0.8} style={{ padding: 8 }}>
          <MyIconButton
            color="error"
            label="Remove Field"
            style={{ fontSize: '14px', padding: 0 }}
            onClick={e => {
              e.stopPropagation();
              removeField(referralSetting, index, type);
            }}
          >
            <IconX style={{ height: 16, width: 16, marginTop: 20 }} />
          </MyIconButton>
        </Grid>
      ) : (
        <Grid item xs={0.8} style={{ padding: 8 }}>
          <FormControlLabel
            key={`control-hidden.${index}`}
            control={
              <Checkbox
                checked={referralSetting.hidden}
                key={`switch.${index}`}
                onChange={event =>
                  handleChange(index, event.target.checked, 'hidden', referralSettings, type)
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={''}
            sx={{ height: 55, minWidth: 90, maxWidth: 90, marginRight: 0 }}
          />
        </Grid>
      )}

      <Grid item xs={1} style={{ padding: 8 }}>
        <MyTextField
          key={`text-size-.${index}`}
          value={referralSetting.size}
          type="number"
          InputProps={{ inputProps: { min: 1, max: 12 } }}
          onChange={e => {
            e.stopPropagation();
            handleChange(index, e.target.value, 'size', referralSettings, type);
          }}
          placeholder="Enter Size"
          label="Size"
          error={referralSetting.size === ''}
          helperText={referralSetting.size === '' ? 'Empty size!' : ' '}
          fullWidth
        />
      </Grid>
      <Grid item xs={1.7} style={{ padding: 8 }}>
        <MyTextField
          id={`text-field-type-.${index}`}
          select
          value={referralSetting.kind}
          onChange={e => {
            e.stopPropagation();
            handleChange(index, e.target.value, 'kind', referralSettings, type);
          }}
          fullWidth
        >
          {FIELD_TYPES.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </MyTextField>
      </Grid>
      <Grid item xs={1.7} style={{ padding: 8 }}>
        <MyTextField
          key={`text-field-.${index}`}
          value={referralSetting.field}
          onChange={e => {
            e.stopPropagation();
            handleChange(index, e.target.value, 'field', referralSettings, type);
          }}
          placeholder="Enter Field Name"
          label="Field Name"
          error={referralSetting.field === ''}
          helperText={referralSetting.field === '' ? 'Empty field name!' : ' '}
          fullWidth
        />
      </Grid>
      <Grid item xs={1.7} style={{ padding: 8 }}>
        <MyTextField
          key={`text-label-.${index}`}
          value={referralSetting.label}
          onChange={e => {
            e.stopPropagation();
            handleChange(index, e.target.value, 'label', referralSettings, type);
          }}
          placeholder="Enter Label"
          label="Label"
          error={referralSetting.label === ''}
          helperText={referralSetting.label === '' ? 'Empty label!' : ' '}
          fullWidth
        />
      </Grid>

      <Grid item xs={3.3} style={{ padding: 8 }}>
        {(referralSetting.kind === 'single_choice' ||
          referralSetting.kind === 'multiple_choice') && (
          <ChoicesOptions
            value={isEmpty(referralSetting.choices) ? [] : referralSetting.choices}
            name={'choices'}
            onChange={value => {
              handleChange(index, value, 'choices', referralSettings, type);
            }}
          />
        )}
      </Grid>
    </>
  );
}

export default function ReferralConfigurableForm({
  referralSettings = [],
  handleChange,
  removeField,
  handleSortChange,
  type = '',
}) {
  return (
    <Grid item container key={`grid.parent.1`} sm={12} xs={12}>
      <Grid item xs={0.8}>
        <MyTypography variant="h6">Sort</MyTypography>
      </Grid>
      <Grid item xs={0.8}>
        <MyTypography variant="h6">Required</MyTypography>
      </Grid>
      <Grid item xs={0.8}>
        <MyTypography variant="h6">Hidden / Remove</MyTypography>
      </Grid>
      <Grid item xs={1}>
        <MyTypography variant="h6">Size</MyTypography>
      </Grid>
      <Grid item xs={1.7}>
        <MyTypography variant="h5">Field Type</MyTypography>
      </Grid>
      <Grid item xs={1.7}>
        <MyTypography variant="h5">Field Name</MyTypography>
      </Grid>
      <Grid item xs={1.7}>
        <MyTypography variant="h5">Label</MyTypography>
      </Grid>
      <Grid item xs={3.3}>
        <MyTypography variant="h5">Choices</MyTypography>
      </Grid>

      {!isEmpty(referralSettings) &&
        referralSettings.map((referralSetting, index) => {
          return (
            <ViewFieldData
              referralSettings={referralSettings}
              referralSetting={referralSetting}
              index={index}
              handleChange={handleChange}
              handleSortChange={handleSortChange}
              removeField={removeField}
              type={type}
            />
          );
        })}
    </Grid>
  );
}

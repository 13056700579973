import React, { useState, useEffect } from 'react';

import { isEmpty } from 'lodash';
import { BarChart } from '@mui/x-charts/BarChart';
import clientGraphql from '../../common/apollo-graphql';
import { GET_TOTAL_REFERRAL_BY_DATES_WAITING } from './gql/Query';
import { useQuery } from '@apollo/client';

const chartSetting = {
    height: 500,
};
  
const valueFormatter = (value) => `${value} referrals`;

export default function TotalWaitingTimeDashboard() {
    const [waitingTimeParsed, setWaitingTimeParsed] = useState([]);
    const { loading, data, error, refetch } = useQuery(
        GET_TOTAL_REFERRAL_BY_DATES_WAITING,
        {
          client: clientGraphql,
          variables: {
            daysInterval: 5,
          },
          fetchPolicy: 'network-only',
          nextFetchPolicy: 'network-only',
        },
    );

    useEffect(() => {
        if(!isEmpty(data)){
            var interval = JSON.parse(data.wait_total_by_days_interval);
            var waiting = [];
            for (let key in interval) {
                waiting.push({
                    totalReferrals: interval[key],
                    dayWaiting: key + ' days',
                });
            }
            setWaitingTimeParsed(waiting);
        }
    }, [data, setWaitingTimeParsed]);    

    return (
        <>
        
        {!isEmpty(waitingTimeParsed) ? (<BarChart
            dataset={waitingTimeParsed}
            xAxis={[{ scaleType: 'band', dataKey: 'dayWaiting', label: 'Total Referrals' }]}
            series={[{ dataKey: 'totalReferrals', label: 'Total Referrals by Days Waiting', valueFormatter }]}
            {...chartSetting}
            />):null}
        </>
    );
};
import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
// project imports
import MainCard from '../common/ui-component/cards/MainCard';
import TotalIncomeCard from '../common/ui-component/cards/Skeleton/TotalIncomeCard';
import moment from 'moment';

// assets
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { GET_ADMIN_REFERRAL_COUNTS } from '../referrals/gql';
import { useQuery } from '@apollo/client';
import clientGraphql from '../../common/apollo-graphql';
import { isEmpty } from 'lodash';

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.warning.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.warning.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

// ==============================|| DASHBOARD - TOTAL INCOME LIGHT CARD ||============================== //

const TotalReferralPerMonth = ({ isLoading, month, year }) => {
    const theme = useTheme();

    const { loading, error, data, refetch } = useQuery(GET_ADMIN_REFERRAL_COUNTS, {
        client: clientGraphql,
        variables: {
            month: month,
            year: year

        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
      });

    return (
        <>
            {isLoading ? (
                <TotalIncomeCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 2 }}>
                        <List sx={{ py: 0 }}>
                            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                                <ListItemAvatar>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            backgroundColor: theme.palette.warning.light,
                                            color: theme.palette.warning.dark
                                        }}
                                    >
                                        <TableChartOutlinedIcon fontSize="inherit" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{
                                        py: 0,
                                        mt: 0.45,
                                        mb: 0.45
                                    }}
                                    primary={<Typography variant="h4">{!isEmpty(data) && !isEmpty(data.admin_referral_counts) ? data.admin_referral_counts[0].count:0}</Typography>}
                                    secondary={
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                color: theme.palette.grey[500],
                                                mt: 0.5
                                            }}
                                        >
                                            Total Referrals for Current Month ({moment().month(parseInt(month) - 1).format("MMMM")})
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TotalReferralPerMonth.propTypes = {
    isLoading: PropTypes.bool,
    month: PropTypes.string
};

export default TotalReferralPerMonth;

export default class StringUtils {

    static toTitleCase(str) {
        if (!str) return str;

        var result = str.replaceAll('_', ' ').toLowerCase().replace(/\b[a-z]/g, function(char) {
            return char.toUpperCase();
        });

        return result.includes(' Id') || result.includes(' Ids') ? result.replace(' Ids','').replace(' Id',''):result;
    }

    static toTitleCase2(str) {
        if (!str) return str;

        var result = str.replaceAll('_', ' ').toLowerCase().replace(/\b[a-z]/g, function(char) {
            return char.toUpperCase();
        });

        return result;
    }

    static validateEmail(email) {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          );
    }

    static getParameterByName(name, url) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    static getFileExtension(name, url) {
        var valueUrl = StringUtils.getParameterByName(name, url);
        return valueUrl.substr(valueUrl.lastIndexOf('.'));
    }

    static getFileNameExtension(fileName) {
        return fileName.substr(fileName.lastIndexOf('.') + 1);
    }

    static isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}
import { useQuery, useLazyQuery } from '@apollo/client';
import { Button, Divider, Grid, Stack } from '@mui/material';
import { capitalize, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import clientGraphql from '../../../common/apollo-graphql';
import { ReviewItem } from '../../clinics/Management/EnrollPsr/ReviewInformation';
import { REFERRAL_PATIENT_DETAIL, REFERRAL_PATIENT_MINI_DETAIL } from '../gql/Referral/Query';
import { MyTypography } from '../../common/components';
import StringUtils from '../../common/services/StringUtils';

function PatientDetails({ referralId, isDraft, parsedData={} }) {
    const [details, setDetails] = useState({});
    const [isShow, setIsShow] = useState(false);

    const gqlParams = {
        client: clientGraphql,
        variables: {
            uuid: referralId
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        refetchOnWindowFocus: false,
    };
    const [loadPatientFullDetail, { data: patientFullDetail }] = useLazyQuery(REFERRAL_PATIENT_DETAIL, gqlParams);
    const { data: patientMiniDetail } = useQuery(REFERRAL_PATIENT_MINI_DETAIL, gqlParams);

    const showPatientDetailHandler = (e) => {
        e.stopPropagation();
        if (!isShow && isEmpty(patientFullDetail)) {
            loadPatientFullDetail()
        }
        setIsShow(!isShow);
    };

    useEffect(() => {
        if (!isEmpty(patientMiniDetail)) {
            setDetails({...patientMiniDetail.patient});
        }
    }, [patientMiniDetail]);

    useEffect(() => {
        if (!isEmpty(patientFullDetail)) {
            setDetails({...patientFullDetail.patient});
        }
    }, [patientFullDetail]);

    useEffect(() => {
        if(isDraft && isEmpty(patientFullDetail)) {
            loadPatientFullDetail();
        }
    }, [isDraft, loadPatientFullDetail, patientFullDetail]);

    return (
        <>
            {!isEmpty(details) && (
                <Grid item container xs={12}>
                    {!isEmpty(details.full_name) ? (<ReviewItem label="Full Name" value={details.full_name} />):null}
                    {!isEmpty(details.phone) ? (<ReviewItem label="Phone" value={details.phone} />):null}

                    {(isDraft || isShow) && (
                        <>
                            {!isEmpty(details.ssn) ? (<ReviewItem label="SSN" value={details.ssn} />):null}
                            {!isEmpty(details.birthdate) ? (<ReviewItem label="Birthdate" value={details.birthdate} />):null}
                            {!isEmpty(details.full_address) ? (<ReviewItem label="Address" value={details.full_address} />):null}
                            {!isEmpty(details.insurance_provider) ? (<ReviewItem label="Insurance Provider" value={details.insurance_provider}/>):null}
                            {!isEmpty(details.policy_number) ? (<ReviewItem label="Policy Number" value={details.policy_number} />):null}
                        </>
                    )}
                    {
                        !isDraft &&
                        (
                            <Grid item xs={12}>
                                <Button
                                    color="secondary"
                                    style={{ padding: '0' }}
                                    onClick={showPatientDetailHandler}
                                >
                                    {!isShow ? "Show More" : "Show Less"}
                                </Button>
                            </Grid>
                        )
                    }
                </Grid>
            )}
            <Grid item md={12} xs={12}>
                <Divider sx={{ my: 1.5 }} />
            </Grid>
            <Grid item md={12} xs={12}>
              <Stack direction="column">
                <MyTypography variant="h4">Referral Details</MyTypography>
              </Stack>
          </Grid>

          <Grid item container md={12} xs={12}>
                {!isEmpty(details) && !isEmpty(details.diagnosis) ? (<ReviewItem label="Diagnosis" value={details.diagnosis} />):null}
                {!isEmpty(parsedData) ? (<ReviewItem label="Comment" value={parsedData.referral?.getComment()} />):null}
                {!isEmpty(parsedData) && !isEmpty(parsedData.referral) ? (
                        Object.keys(parsedData.referral?.getExtraInfo()).map((label, index) => {
                            return (<ReviewItem label={StringUtils.toTitleCase(label.replace('custom_',''))} value={parsedData.referral?.getExtraInfo()[label]} />);
                        })
                ):null}
                </Grid>
        </>
    );
}

export default React.memo(PatientDetails);

import React, { useCallback, useEffect } from 'react';
import { gridSpacing } from '../../common/themes/constants';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DropzoneAreaBase } from 'react-mui-dropzone';
import { IconDownload } from '@tabler/icons';
import { capitalize, isEmpty } from 'lodash';
import { MyButton, MyTypography } from '../common/components';
import { makeStyles } from '@mui/styles';
import ApiService from '../common/services/ApiService';
import StringUtils from '../common/services/StringUtils';
import { handlePreviewIcon } from '../patient/styles/documentPreviewIcon';
import FileUtils from '../common/services/FileUtils';

const useStyles = makeStyles(() => ({
  expanded: {},
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
}));

export default function PublicReferralAdditionalDocuments({
  parsedData,
  fileNames,
  setFileNames,
  fileObjects,
  setFileObjects,
  fileObjectsRequired,
  setFileObjectsRequired,
  onFileObjectChanged,
  uploadedFileObjects = {},
  data_referral_answers={}
}) {
  const classes = useStyles();

  const referralAnswers = useCallback(
    (question, name) => {
      var result = 'No';
      if (!isEmpty(data_referral_answers) && !isEmpty(data_referral_answers.answers)) {
        data_referral_answers.answers.map(ref => {
          if (ref.question.toLowerCase() === question.toLowerCase() || name === ref.name) {
            result = ref.answer;
            return;
          }
        });
      }

      return result;
    },
    [data_referral_answers],
  );

  useEffect(() => {
    if (!isEmpty(parsedData.clinic)) {
      var fileNameList = fileNames;
      var objectList = fileObjects;
      var objestListRequired = fileObjectsRequired;
      parsedData.clinic.documents.map(doc => {
        objectList[doc.document.id] =
          doc.document.id in objectList && !isEmpty(objectList[doc.document.id])
            ? objectList[doc.document.id]
            : [];
        fileNameList[doc.document.id] = !isEmpty(fileNameList[doc.document.id])
          ? fileNameList[doc.document.id]
          : doc.document.name;
        objestListRequired[doc.document.id] = true;
      });
      parsedData.clinic.optionalDocuments.map(doc => {
        objectList[doc.document.id] =
          doc.document.id in objectList && !isEmpty(objectList[doc.document.id])
            ? objectList[doc.document.id]
            : [];
        fileNameList[doc.document.id] = !isEmpty(fileNameList[doc.document.id])
          ? fileNameList[doc.document.id]
          : doc.document.name;
        objestListRequired[doc.document.id] = false;
      });

      setFileObjects(objectList);
      setFileNames(fileNameList);
      setFileObjectsRequired(objestListRequired);
    }
  }, [
    parsedData,
    setFileObjects,
    setFileObjectsRequired,
    fileObjects,
    fileObjectsRequired,
    setFileNames,
    fileNames,
  ]);

  return (
    <>
      <Grid container spacing={gridSpacing} key={'public-referral-' + Math.random()}>
        {!isEmpty(uploadedFileObjects) ? (
          <Grid item sm={12} xs={12}>
            <h3 style={{ margin: '0' }}>Uploaded Documents</h3>
          </Grid>
        ) : null}
        {!isEmpty(uploadedFileObjects) && !isEmpty(data_referral_answers) ? (
                  Object.keys(uploadedFileObjects).map(key => {
                    return (
                      <Grid key={'grid-file-' + Math.random()} item sm={6} xs={12}>
                        {uploadedFileObjects['' + key].map((uploadedDoc, index) => {
                          return (
                            <>
                              {!isEmpty(uploadedDoc.questions) &&
                              !isEmpty(data_referral_answers) &&
                              !isEmpty(data_referral_answers.answers) &&
                              data_referral_answers.answers.filter(
                                ref =>
                                  ref.question.toLowerCase() ===
                                  uploadedDoc.questions[0].question.toLowerCase(),
                              ).length > 0 &&
                              uploadedDoc.questions[0].question !== '?' &&
                              index == 0 ? (
                                <MyTypography
                                  variant="h5"
                                  key={'question-question-' + Math.random()}
                                >
                                  {uploadedDoc.questions[0].question +
                                    '  ' +
                                    referralAnswers(
                                      uploadedDoc.questions[0].question,
                                      uploadedDoc.name,
                                    )}
                                </MyTypography>
                              ) : (
                                index == 0 && (
                                  <MyTypography variant="h5" key={'question-name-' + Math.random()}>
                                    {capitalize(uploadedDoc.name)}
                                  </MyTypography>
                                )
                              )}
                              <MyButton
                                style={{ justifyContent: 'left' }}
                                key={'file' + Math.random()}
                                onClick={() => FileUtils.downloadFile(uploadedDoc)}
                                color="primary"
                                variant="text"
                                startIcon={
                                  <IconDownload style={{ height: '16px', width: '16px' }} />
                                }
                              >
                                {(!isEmpty(uploadedDoc.referral_document_name)
                                  ? uploadedDoc.referral_document_name
                                  : uploadedDoc.name + (index > 0 ? '-' + index : '')) +
                                  StringUtils.getFileExtension('path', uploadedDoc.url)}
                              </MyButton>
                            </>
                          );
                        })}
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item md={12} xs={12}>
                    <MyTypography variant="h5" style={{ textAlign: 'center' }}>
                      No Uploaded Documents.
                    </MyTypography>
                  </Grid>
                )}
        {!isEmpty(parsedData) &&
        !isEmpty(parsedData.clinic) &&
        !isEmpty(parsedData.clinic.optionalDocuments) ? (
          <>
            <Grid item sm={12} xs={12} style={{ paddingTop: 0 }}>
              {!isEmpty(parsedData.clinic.documents) &&
                parsedData.clinic.documents.map(doc => {
                  return (
                    <Accordion key={'upload-' + Math.random()} defaultExpanded={true}>
                      <AccordionSummary
                        key={'upload-summary-' + Math.random()}
                        expandIcon={<ExpandMoreIcon />}
                        style={{ minHeight: '32px', margin: 0 }}
                        classes={{ content: classes.content, expanded: classes.expanded }}
                      >
                        <h4>
                          {!isEmpty(doc.document.name) ? doc.document.name.toUpperCase() : ''} -
                          {isEmpty(fileObjects[doc.document.id]) ? (
                            <span style={{ color: 'red' }}> Required *</span>
                          ) : (
                            <span style={{ color: 'green' }}> Uploaded</span>
                          )}
                        </h4>
                      </AccordionSummary>
                      <AccordionDetails key={'upload-details-' + Math.random()}>
                        <DropzoneAreaBase
                          acceptedFiles={['.pdf', '.png', '.jpeg', '.zip', '.tif', '.tiff']}
                          fileObjects={fileObjects[doc.document.id]}
                          showPreviews={false}
                          showFileNamesInPreview={false}
                          showPreviewsInDropzone={false}
                          filesLimit={10}
                          maxFileSize={50000000}
                          onAdd={newFileObjs => {
                            var temp = fileObjects;
                            temp[doc.document.id].push(...newFileObjs);
                            onFileObjectChanged(temp, doc.document.name, true);
                          }}
                          onDelete={deleteFileObj => {
                            const index = fileObjects[doc.document.id].indexOf(deleteFileObj);
                            fileObjects[doc.document.id].splice(index, 1);
                            onFileObjectChanged(fileObjects, doc.document.name, false);
                          }}
                          getPreviewIcon={handlePreviewIcon}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </Grid>

            <Grid item sm={12} xs={12} style={{ paddingTop: 0 }}>
              {!isEmpty(parsedData.clinic.optionalDocuments) &&
                parsedData.clinic.optionalDocuments.map(doc => {
                  if (doc.document.name === 'misc') {
                    return (
                      <Accordion key={'upload-' + Math.random()} defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Stack direction="column">
                            <h4>
                              {!isEmpty(doc.document.name) ? doc.document.name.toUpperCase() : ''} -
                              Optional
                            </h4>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={gridSpacing}>
                            <Grid
                              key={'grid-note-' + Math.random()}
                              item
                              sm={12}
                              style={{ paddingTop: '0' }}
                            >
                              <MyTypography variant="h5" component="h5" color="text.primary">
                                Use this section to upload documents that could be useful for the
                                receiving provider that are not listed as an option above.
                              </MyTypography>
                            </Grid>
                          </Grid>
                          <DropzoneAreaBase
                            acceptedFiles={['.pdf', '.png', '.jpeg', '.zip', '.tif', '.tiff']}
                            fileObjects={fileObjects[doc.document.id]}
                            showPreviews={false}
                            showFileNamesInPreview={false}
                            showPreviewsInDropzone={false}
                            filesLimit={10}
                            maxFileSize={50000000}
                            onAdd={newFileObjs => {
                              var temp = fileObjects;
                              temp[doc.document.id].push(...newFileObjs);
                              onFileObjectChanged(temp, doc.document.name);
                            }}
                            onDelete={deleteFileObj => {
                              const index = fileObjects[doc.document.id].indexOf(deleteFileObj);
                              fileObjects[doc.document.id].splice(index, 1);
                              onFileObjectChanged(fileObjects, doc.document.name);
                            }}
                            getPreviewIcon={handlePreviewIcon}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  } else {
                    return null;
                  }
                })}
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
}

import { capitalize } from 'lodash';
import {ROLE_PSR, ROLE_NURSE} from '../constants/Role';

export default class User {

    constructor(user) {
        this.user = {...user};
    }

    getFullName() {
        return `${capitalize(this.user.firstname)} ${capitalize(this.user.lastname)}`;
    }

    isRolePsr() {
        return this.user.role.name === ROLE_PSR.value;
    }

    isNurse() {
        return this.user.role.toLowerCase() === ROLE_NURSE.value.toLowerCase();
    }

    getRoleName(uppercase=true) {
        const name = this.user.role.name;

        return uppercase
               ? name.toUpperCase()
               : name;
    }

    isActivated() {
        return !!this.user.activated_at;
    }

    getActivationStatus() {
        return this.isActivated()
               ? 'activated'
               : 'pending';
    }
}
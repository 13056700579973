import momentTz from 'moment-timezone';
import moment from 'moment';
import CONFIG from './../../../config';
import { isEmpty } from 'lodash';

const TIMEZONE = CONFIG.TIMEZONE || 'US/Central';

export default class DateTimeUtils {

    static now() {
        return momentTz().tz(TIMEZONE);
    }

    static parse(datetimeStr) {
        return momentTz(datetimeStr).tz(TIMEZONE);
    }

    static parseUTC(datetimeStr) {
        return momentTz(moment.utc(datetimeStr).local().format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss', 'UTC').tz(TIMEZONE);
    }

    static dateSortComparator(v1, v2) {
        if (isEmpty(v1) && isEmpty(v2)) {
            return 0; // Equal if both are empty
        }
        
        if (isEmpty(v1)) {
            return 1; // v2 comes first if v1 is empty
        }
        
        if (isEmpty(v2)) {
            return -1; // v1 comes first if v2 is empty
        }
        
        const date1 = moment(v1, 'DD MMM YYYY - HH:mm:ss').toDate();
        const date2 = moment(v2, 'DD MMM YYYY - HH:mm:ss').toDate();
        
        return date1.getTime() - date2.getTime();
    }
    
}
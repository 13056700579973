import axios from 'axios';
import CONFIG from './../../../config';
import FileSaver from 'file-saver';

const axiosInstance = axios.create({
    baseURL: `${CONFIG.API_PROTOCOL}://${CONFIG.API_BASE_URL}:${CONFIG.API_PORT}`,
    withCredentials: true,
    timeout: '0',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    }
});

export default class ApiService {

    static rejectError(error, reject) {

        if (error && error.hasOwnProperty('response')) {
            reject(error.response);
        } else {
            reject(error);
        }
    }

    static csrf() {
        return axiosInstance.get('sanctum/csrf-cookie');
    }

    static post(url, args={}, headers={}) {
        return new Promise((resolve, reject) => {
            ApiService.csrf().then(() => {
                axiosInstance.post(url, args, headers).then(res => {
                    resolve(res.data);
                }, error => {
                    ApiService.rejectError(error, reject);
                });
            }).catch(err => {
                ApiService.rejectError(err, reject);
            });
        });
    }

    static delete(url, args={}, headers={}) {
        return new Promise((resolve, reject) => {
            ApiService.csrf().then(() => {
                axiosInstance.delete(url, { data: args, headers: headers }).then(res => {
                    resolve(res.data);
                }, error => {
                    ApiService.rejectError(error, reject);
                });
            });
        });
    }

    static put(url, args={}, headers={}) {
        return new Promise((resolve, reject) => {
            ApiService.csrf().then(() => {
                axiosInstance.put(url, args, headers).then(res => {
                    resolve(res.data);
                }, error => {
                    ApiService.rejectError(error, reject);
                });
            });
        });
    }

    static get(url, args={}, headers={}) {
        return new Promise((resolve, reject) => {
            ApiService.csrf().then(() => {
                axiosInstance.get(url, args, headers).then(res => {
                    resolve(res.data);
                }, error => {
                    ApiService.rejectError(error, reject);
                    //reject(error);
                });
            });
        });
    }

    static getFile(url, name, args={responseType: 'blob'}, headers={}) {
        return new Promise((resolve, reject) => {
            ApiService.csrf().then(() => {
                axiosInstance.get(url, args, headers).then(res => {
                    const file = new Blob([res.data], { type: res.data.type});
                    if(res.data.type === 'application/zip'){
                        FileSaver.saveAs(res.data, name);
                    } else {
                        const fileURL = URL.createObjectURL(file);
                        const pdfWindow = window.open();
                        pdfWindow.location.href = fileURL;
                    }
                }, error => {
                    ApiService.rejectError(error, reject);
                });
            });
        });
    };

    static getFileUrl(url, name, args={responseType: 'blob'}, headers={}) {
        return new Promise((resolve, reject) => {
            ApiService.csrf().then(() => {
                axiosInstance.get(url, args, headers).then(res => {
                    const file = new Blob([res.data], { type: res.data.type});
                    if(res.data.type === 'application/zip'){
                        FileSaver.saveAs(res.data, name);
                    } else {
                        const fileURL = URL.createObjectURL(file);
                        resolve(file);
                    }
                }, error => {
                    ApiService.rejectError(error, reject);
                });
            });
        });
    };

    static getFileDownload(url, name, args={responseType: 'blob'}, headers={}) {
        return new Promise((resolve, reject) => {
            ApiService.csrf().then(() => {
                axiosInstance.get(url, args, headers).then(res => {
                    FileSaver.saveAs(res.data, name);
                }, error => {
                    ApiService.rejectError(error, reject);
                });
            });
        });
    }

    static getPDFFileAsBlob(url, args={responseType: 'blob'}, headers={}) {
        return new Promise((resolve, reject) => {
            ApiService.csrf().then(() => {
                axiosInstance.get(url, args, headers).then(res => {
                    resolve(res);
                }, error => {
                    ApiService.rejectError(error, reject);
                });
            });
        });
    }

    static apiUrl(path) {
        return `${CONFIG.API_INDEX}/${path}`;
    }

    /*
     * Authentication Routes
     */

    static checkAuth() {
        return this.post(this.apiUrl('auth/check'));
    }

    static getCurrentUser() {
        return this.post(this.apiUrl('u'));
    }

    static logIn(args) {
        return this.post('login', args);
    }

    static forgotPassword(args) {
        return this.post('forgot-password', args);
    }

    static resetPassword(args) {
        return this.post('reset-password', args);
    }

    static logout() {
        return this.post('logout');
    }

    static activateAccount(args) {
        return this.post(
            this.apiUrl('account/activate'),
            args
        );
    }

    static checkAccountActivated(args) {
        return this.post(
            this.apiUrl('account/activate/check'),
            args
        );
    }

    static verifyTwoFactor(args) {
        return this.post(
            'auth/2fa/verify',
            args
        );
    }

    static resendTwoFactor() {
        return this.post('auth/2fa/resend');
    }

    static readNotification(uuid) {
        return this.put(
            this.apiUrl(`notifications/${uuid}`)
        );
    }

    static enrollPsrAndClinic(args) {
        return this.post(
            this.apiUrl('clinics/enroll'),
            args
        );
    }

    static deleteClinic(id, args) {
        return this.delete(
            this.apiUrl(`clinics/${id}`),
            args
        );
    }

    static updateClinic(id, args) {
        return this.put(
            this.apiUrl(`clinics/${id}`),
            args
        );
    }

    static addClinicUser(id, args) {
        return this.post(
            this.apiUrl(`clinics/${id}/users`),
            args
        );
    }

    static deleteClinicUser(clinicId, userId) {
        return this.delete(
            this.apiUrl(`clinics/${clinicId}/users/${userId}`)
        );
    }

    static saveReferralAsDraft(args) {
        return this.post(
            this.apiUrl(`referrals/`),
            args
        );
    }

    static saveReferralAsDraftByUUID(referralUUID, args) {
        return this.put(
            this.apiUrl(`referrals/${referralUUID}`),
            args
        );
    }

    static sendReferral(referralUUID) {
        return this.post(
            this.apiUrl(`referrals/${referralUUID}/send`)
        );
    }

    static uploadReferralDocuments(referralUUID, args){
        const config = {
                'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        return this.post(
            this.apiUrl(`referrals/${referralUUID}/documents`),
            args,
            config
        );
    }
    static uploadReferralMultipleDocuments(referralUUID, args){
        const config = {
                'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        return this.post(
            this.apiUrl(`referrals/${referralUUID}/multiple_type/documents`),
            args,
            config
        );
    }

    static createReferralNote(referralUUID, args) {
        return this.post(
            this.apiUrl(`referrals/${referralUUID}/notes`),
            args
        );
    }

    static getReferral(referralUUID) {
        return this.get(
            this.apiUrl(`referrals/${referralUUID}/documents`)
        );
    }

    static getReferralDocuments(referralUUID) {
        return this.get(
            this.apiUrl(`referrals/${referralUUID}/documents`)
        );
    }

    static promoteReferral(referralUUID, status) {
        return this.put(
            this.apiUrl(`referrals/${referralUUID}/promote/${status}`)
        );
    }

    static declineReferral(referralUUID, args) {
        return this.put(
            this.apiUrl(`referrals/${referralUUID}/decline`),
            args
        );
    }

    static setAppointmentReferral(referralUUID, args) {
        return this.post(
            this.apiUrl(`referrals/${referralUUID}/appointments`),
            args
        );
    }

    static getPatient(referralUUID) {
        return this.post(
            this.apiUrl(`referrals/${referralUUID}/patient`),
        );
    }

    static faxSend(args){
        const config = {
                'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        return this.post(
            this.apiUrl(`fax/send`),
            args,
            config
        );
    }
}

import React from 'react';
import IconButton from '@mui/material/IconButton';
import MyTooltip from './MyTooltip';

export default function MyIconButton({
    children,
    label,
    ...otherProps
}) {
    return (
        <MyTooltip title={label}>
            <IconButton {...otherProps}>
                {children}
            </IconButton>
        </MyTooltip>
    );
}
import React from 'react';
import {
    Stack,
} from '@mui/material';
import MainCard from '../../ui-component/cards/MainCard';
import {gridSpacing} from '../../../../common/themes/constants';
import Breadcrumbs from '../Breadcrumbs';
import {MyTypography} from '../../components';

export default function PageContent({children, title, contentClass=null, contentSX={}, isBreadcrumbs=true}) {
    return (
        <Stack direction="column"
               spacing={gridSpacing}>
            <Stack direction="column"
                   spacing={1}>
                <MyTypography variant="h4" component="h1" color="text.primary">{title}</MyTypography>
                {isBreadcrumbs ? (<Breadcrumbs />) :null} 
            </Stack>
            <MainCard contentClass={contentClass}
                      sx={contentSX}>{children}</MainCard>
        </Stack>
    );
}

import React from 'react';
import { MyDataGrid, MyTypography } from '../../common/components';
import { isEmpty } from 'lodash';
import ReferralUtils from '../../common/services/ReferralUtils';
import { Stack } from '@mui/material';
import { IconDeviceLandlinePhone } from '@tabler/icons';
import moment from 'moment';
import DateTimeUtils from '../../common/services/DateTimeUtils';

const columns = [
  {
    field: 'provider',
    headerName: 'Provider',
    sortable: true,
    editable: false,
    filterable: true,
    width: 250,
    valueGetter: params => {
      return params.row.provider.display_name;
    },
  },
  {
    field: 'sender',
    headerName: 'Clinic Sender',
    sortable: true,
    editable: false,
    filterable: true,
    width: 250,
    valueGetter: params => {
      return params.row.referrer.name;
    },
  },
  {
    field: 'receiver',
    headerName: 'Clinic Receiver',
    sortable: true,
    editable: false,
    filterable: true,
    width: 250,
    renderCell: params => {
      return (
        <Stack direction="row" spacing={1}>
          {params.row.is_fax && <IconDeviceLandlinePhone />}
          <MyTypography variant="subtitle1">{params.row.receiver.name}</MyTypography>
        </Stack>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    editable: false,
    filterable: true,
    width: 130,
    valueGetter: params => {
      return ReferralUtils.getStatus(params.row.status, params.row.reason);
      // return params.row.status.toUpperCase();
    },
  },
  {
    field: 'sent_at',
    headerName: 'Sent At',
    sortable: true,
    editable: false,
    filterable: true,
    width: 250,
    valueGetter: params => {
      return params.row.sent_at.format('DD MMM YYYY - HH:mm:ss');
    },
    sortComparator: DateTimeUtils.dateSortComparator,
    // sortComparator: (v1, v2) => {
    //   // var date1 = moment(v1, 'DD MMM YYYY - HH:mm:ss').local().valueOf() || (new Date()).valueOf();
    //   // var date2 = moment(v2, 'DD MMM YYYY - HH:mm:ss').local().valueOf() || (new Date()).valueOf();
    //   // return date1 - date2;

    //   return DateTimeUtils.dateSortComparator;
    // },
  },
  {
    field: 'updated_by',
    headerName: 'Last Updated By',
    sortable: true,
    editable: false,
    filterable: true,
    width: 250,
    valueGetter: params => {
      return !isEmpty(params.row.updated_by) ? params.row.updated_by.fullname : '';
    },
  },
  {
    field: 'updated_at',
    headerName: 'Last Updated At',
    sortable: true,
    editable: false,
    filterable: true,
    width: 250,
    valueGetter: params => {
      return params.row.updated_at.format('DD MMM YYYY - HH:mm:ss');
    },
    sortComparator: DateTimeUtils.dateSortComparator,
    // sortComparator: (v1, v2) => {
    //   // var date1 = moment(v1, 'DD MMM YYYY - HH:mm:ss').local().valueOf() || (new Date()).valueOf();
    //   // var date2 = moment(v2, 'DD MMM YYYY - HH:mm:ss').local().valueOf() || (new Date()).valueOf();
    //   // return date1 - date2;
    //   return DateTimeUtils.dateSortComparator;
    // },
  },
  {
    field: 'aging',
    headerName: 'Processing',
    sortable: true,
    editable: false,
    filterable: true,
    width: 180,
    valueGetter: params => {
      return ((params.row.updated_at - params.row.sent_at) /(1000 * 60 * 60 * 24)).toFixed(1);
    },
    // sortComparator: (v1, v2) => {
    //   // var date1 = moment(v1, 'DD MMM YYYY - HH:mm:ss').local().valueOf() || (new Date()).valueOf();
    //   // var date2 = moment(v2, 'DD MMM YYYY - HH:mm:ss').local().valueOf() || (new Date()).valueOf();
    //   // return date1 - date2;
    //   return DateTimeUtils.dateSortComparator;
    // },
  },
  {
    field: 'aging_2',
    headerName: 'Aging',
    sortable: true,
    editable: false,
    filterable: true,
    width: 180,
    valueGetter: params => {
      return (((new Date() ) - params.row.updated_at ) /(1000 * 60 * 60 * 24)).toFixed(1);
    },
    // sortComparator: (v1, v2) => {
    //   // var date1 = moment(v1, 'DD MMM YYYY - HH:mm:ss').local().valueOf() || (new Date()).valueOf();
    //   // var date2 = moment(v2, 'DD MMM YYYY - HH:mm:ss').local().valueOf() || (new Date()).valueOf();
    //   // return date1 - date2;
    //   return DateTimeUtils.dateSortComparator;
    // },
  },
];

export default function ReferralsTable({
  data,
  onFilterChanged,
  onFilterFaxChanged,
  loading,
  onAdd,
  showToolbar = true,
}) {
  return (
    <MyDataGrid
      onAdd={onAdd}
      loading={loading}
      data={data}
      columns={columns}
      showToolbar={showToolbar}
      getRowId={row => {
        return row.id;
      }}
      minTableHeight={'calc(100vh - 282px)'}
      onFilterChanged={onFilterChanged}
      onFilterFaxChanged={onFilterFaxChanged}
    />
  );
}

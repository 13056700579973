import React, { useCallback, useState } from 'react';
import { Stack, Dialog, DialogActions, DialogContent, Grid, Alert } from '@mui/material';
import { DropzoneAreaBase } from 'react-mui-dropzone';
import { isEmpty } from 'lodash';
import { useUploadReferralDocuments } from '../referral/redux/uploadReferralDocuments';
import { gridSpacing } from '../../common/themes/constants';
import { MyButton, MyTypography } from '../common/components';
import { handlePreviewIcon } from './styles/documentPreviewIcon';

export default function UploadDocumentsDialog({
  open,
  onClose,
  document,
  referralUuid,
}) {
  const [fileObjects, setFileObjects] = useState([]);
  const { uploadReferralDocuments, uploadReferralDocumentsPending } = useUploadReferralDocuments();
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);

  const onFileObjectChanged = useCallback(
    (fileList, name) => {
      if (!isEmpty(fileList) && !isEmpty(referralUuid)) {
        const formData = new FormData();
        fileList.map(file => {
          formData.append('files[]', file.file);
        });
        formData.append('document_id', Number(document.doc_id));
        formData.append('type', 'internal');

        uploadReferralDocuments(referralUuid, formData)
          .then(data => {
            setShowAlert(true);
            setShowError(false);
            setFileObjects([]);
          })
          .catch(err => {
            setShowAlert(false);
            setShowError(true);
          });
      }
    },
    [referralUuid, uploadReferralDocuments, document],
  );

  return (
    <Dialog open={open} onClose={onClose} disablePortal={true}>
      <DialogContent>
        <Grid container direction="column" spacing={gridSpacing}>
          <Grid item sm={10} xs={12}>
            {showAlert ? (
              <Alert
                color="info"
                style={{ position: 'fixed', bottom: '10px', right: '10px', zIndex: '100' }}
                onClose={() => {
                  setShowAlert(false);
                }}
              >
                Successfully Uploaded Files
              </Alert>
            ) : null}
            {showError ? (
              <Alert
                color="error"
                style={{ position: 'fixed', bottom: '10px', right: '10px', zIndex: '100' }}
                onClose={() => {
                  setShowError(false);
                }}
              >
                Failed to upload files
              </Alert>
            ) : null}
          </Grid>
          <Grid item xs={10}>
            <MyTypography variant="h3">Upload Document - {!isEmpty(document.name) ? document.name.toUpperCase():''}</MyTypography>
          </Grid>
          <Grid item xs={10}>
            <DropzoneAreaBase
              acceptedFiles={['.pdf', '.png', '.jpeg', '.zip', '.tif', '.tiff']}
              fileObjects={fileObjects}
              showPreviews={true}
              showFileNamesInPreview={true}
              showPreviewsInDropzone={false}
              filesLimit={10}
              maxFileSize={50000000}
              onAdd={newFileObjs => {
                var temp = fileObjects;
                temp.push(...newFileObjs);
                onFileObjectChanged(temp, document.name);
              }}
              onDelete={deleteFileObj => {
                const index = fileObjects.indexOf(deleteFileObj);
                fileObjects.splice(index, 1);
                onFileObjectChanged(fileObjects, document.name);
              }}
              getPreviewIcon={handlePreviewIcon}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ px: 4, pb: 3 }}>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <MyButton onClick={onClose} disabled={uploadReferralDocumentsPending} fullWidth={false}>
            Close
          </MyButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

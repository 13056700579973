// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { Dashboard } from './';
import {Authenticated} from '../auth';

export default {
  path: 'dashboard',
  component: Authenticated,
  childRoutes: [
    { path: '', component: Dashboard },
  ],
};

import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';
import { capitalize } from 'lodash';
import ReferralDocuments from './ReferralDocuments';

export default function PatientReferralDocuments({
  isSameOrg=false,
  parsedData,
  referralUuid,
  onDisableSendReferral=null,
  handleFileRequiredChange=null,
  handleSetQualifyingQuestions=null,
  qualifyingQuestions,
  setParsedDocumentData,
  parsedDocumentData
}) {
  return (
    <Card>
      <CardHeader
        title={parsedData.clinic.provider_name + ' - ' + capitalize(parsedData.specialty.name)}
        subheader={parsedData.clinic.name + ' - '  + parsedData.clinic.address}
      />
      <CardContent>
        <ReferralDocuments isSameOrg={isSameOrg} parsedDocumentData={parsedDocumentData} setParsedDocumentData={setParsedDocumentData} 
        qualifyingQuestions={qualifyingQuestions} handleSetQualifyingQuestions={handleSetQualifyingQuestions} parsedData={parsedData} referralUuid={referralUuid} onDisableSendReferral={onDisableSendReferral} handleFileRequiredChange={handleFileRequiredChange}/>
         
      </CardContent>
    </Card>
  );
}

import React, { useState, useEffect, useCallback } from 'react';

import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { referralInitialValues, referralValidationSchema } from './ReferralForm';
import { GET_CLINIC_BY_CODE } from '../clinic/gql/Query';
import { useLazyQuery, useQuery } from '@apollo/client';
import clientGraphqlPublic from '../../common/apollo-graphql-public';
import {
  initialValues as patientInitialValues,
  createPublicPatientValidationSchema,
} from '../patient/PublicPatientReferralForm';
import * as yup from 'yup';

import { GET_PUBLIC_CLINIC_REFERRAL_FORM_ATTRIBUTES } from '../clinics/gql/Query';
import PublicCreateReferral from './PublicCreateReferral';
import { DEFAULT_REFERRAL_FORM_FIELD } from '../common/services/DefaultValuesUtils';

var initialValues = {
  patient: {
    ...patientInitialValues,
  },
  referral: {
    ...referralInitialValues,
  },
};

export default function WidgetCreateReferral({ history }) {
  let { clinic_code } = useParams();
  const [clinicCode, setClinicCode] = useState(null);
  const [referralSchema, setReferralSchema] = useState({});
  const [patientSchema, setPatientSchema] = useState({});
  const [requiredCustom, setRequiredCustom] = useState(null);
  const [initialReferralValues, setInitialReferralValues] = useState({ ...initialValues });
  const [parsedData, setParsedData] = useState({});

  if (!isEmpty(clinic_code) && isEmpty(clinicCode)) {
    setClinicCode(clinic_code);
  }

  const { loading: clinicLoading, data: clinicData } = useQuery(GET_CLINIC_BY_CODE, {
    client: clientGraphqlPublic,
    variables: { code: clinicCode },
  });

  const [getClinicReferralFormSettings] = useLazyQuery(GET_PUBLIC_CLINIC_REFERRAL_FORM_ATTRIBUTES, {
    client: clientGraphqlPublic,
    variables: { id: -1, formType: 'external' },
  });

  useEffect(() => {
    if (!isEmpty(clinicData)) {
      var extra_info = {};
      if (!isEmpty(clinicData.clinic_by_code) && !isEmpty(clinicData.clinic_by_code.extra_info)) {
        extra_info = JSON.parse(JSON.parse(clinicData.clinic_by_code.extra_info));
      }
      setParsedData({
        ...clinicData.clinic_by_code,
        office_phone: 'office_phone' in extra_info ? extra_info['office_phone'] : '',
        fax: 'fax' in extra_info ? extra_info['fax'] : '',
      });
      getClinicReferralFormSettings({
        client: clientGraphqlPublic,
        variables: { id: clinicData.clinic_by_code.id, formType: 'external' },
      }).then(res => {
        if (!isEmpty(res.data) && !isEmpty(res.data.public_clinic_custom_form_attributes)) {
          var form_attributes = res.data.public_clinic_custom_form_attributes.filter(attr => !attr.hidden);
          handleCustomFormAttributes(form_attributes);
        } else {
          handleCustomFormAttributes(DEFAULT_REFERRAL_FORM_FIELD.slice());
        }
      });
    }
  }, [clinicData, handleCustomFormAttributes, getClinicReferralFormSettings, setParsedData]);

  const handleCustomFormAttributes = useCallback(formAttrs => {
    var initialVals = { ...initialValues };
    var refSchema = { ...referralValidationSchema };
    var patSchema = {};
    var form_attributes = formAttrs;
    form_attributes.map(custom_field => {
      if (custom_field.field in createPublicPatientValidationSchema && custom_field.required) {
        patSchema[custom_field.field] = createPublicPatientValidationSchema[custom_field.field];
      }
      if (
        (custom_field.field === 'diagnosis' || custom_field.field === 'comment') &&
        custom_field.required
      ) {
        refSchema[custom_field.field] = yup.string().required('');
      }
      if (custom_field.field.includes('custom_') && custom_field.kind !== 'multiple_choice') {
        initialVals.referral[custom_field.field] = '';
      } else if (
        custom_field.field.includes('custom_') &&
        custom_field.kind === 'multiple_choice'
      ) {
        initialVals.referral[custom_field.field] = [];
      }
      if (
        custom_field.field.includes('custom_') &&
        custom_field.required &&
        custom_field.kind === 'multiple_choice'
      ) {
        refSchema[custom_field.field] = yup.array().min(1, custom_field.label + ' is required');
      } else if (
        custom_field.field.includes('custom_') &&
        custom_field.required &&
        custom_field.kind !== 'multiple_choice'
      ) {
        refSchema[custom_field.field] = yup.string().required(custom_field.label + ' is required');
      }
    });
    setPatientSchema(patSchema);
    setReferralSchema(refSchema);
    setInitialReferralValues(initialVals);
    setRequiredCustom(form_attributes.slice());
  }, []);

  return (
    <>
      {!isEmpty(requiredCustom) && (
        <PublicCreateReferral
          requiredCustom={requiredCustom}
          clinicData={parsedData}
          clinicLoading={clinicLoading}
          initialValues={initialReferralValues}
          history={history}
          referralSchema={referralSchema}
          patientSchema={patientSchema}
          clinic_code={clinic_code}
        />
      )}
    </>
  );
}

import React from 'react';
import {
    Box,
    CircularProgress,
} from '@mui/material';

export default function MyProgress({inline='true', ...otherProps}) {
    return (
        <Box sx={{ display: 'flex' }}>
            <CircularProgress {...otherProps} />
        </Box>
    );
}
import React from 'react';
import Image from 'mui-image';
import referAllLogo from '../../../images/ziprefer-transparent.png';
import referBlackLogo from '../../../images/black-logo.png';
import { Box } from '@mui/material';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

function Logo() {
    return (
        <Box 
        style={{backgroundColor: '#0B0633', padding: '12px'}}>
            <Image src={referAllLogo}
               height={32}
               width="auto"
               duration={0}></Image>
        </Box>
        
    );
}

export default Logo;

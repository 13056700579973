import React from 'react';
import { Link } from 'react-router-dom';
// material-ui
import { ButtonBase } from '@mui/material';
// project imports
import Logo from '../../ui-component/Logo';
import {themeConfig} from '../../../../common/themes/config';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={themeConfig.defaultPath}>
        <Logo />
    </ButtonBase>
);

export default LogoSection;

import React, { useCallback, useEffect, useState } from 'react';
import QualifyingQuestions from '../auth/RegisterUser/QualifyingQuestions';
import { Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import UploadDocumentsDialog from '../patient/UploadDocumentsDialog';
// import PropTypes from 'prop-types';

export default function ReferralQualifyingQuestions({ referralUuid=null, parsedData, reloadReferralDocumentList, handleQuestionChange }) {
  const [openUploadDocument, setOpenUploadDocument] = useState(false);
  const [document, setDocument] = useState({});
  const handleOpenUploadDocumentClose = useCallback(() => {
    setOpenUploadDocument(false);
    reloadReferralDocumentList();
}, [reloadReferralDocumentList]);

  const handleUploadDocument = useCallback(
    (question) => {
      setDocument(question);
      setOpenUploadDocument(true);
    },[setDocument, setOpenUploadDocument],
  );


  return (
    <>
    <Grid container key={"referral-qualifying-questions" + Math.random()} style={{paddingTop: 0}}>
      {!isEmpty(parsedData) ? 
        (<>
      <QualifyingQuestions
            key={"qualifying-questions" + Math.random()}
            questions={parsedData}
            referralUuid={referralUuid}
            handleChange={handleQuestionChange}
            handleUploadDocument={handleUploadDocument}
          />
        </>):null
    }
    </Grid>

    <UploadDocumentsDialog document={document} open={openUploadDocument} referralUuid={referralUuid} onClose={handleOpenUploadDocumentClose}/>
    </>
  );
}

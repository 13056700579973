import { Box, Tab, Tabs } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { TabPanel } from '../common';
import ReferralInternalNotes from './ReferralInternalNotes';
import ReferralExternalNotes from './ReferralExternalNotes';
import ReferralSystemLogs from './ReferralSystemLogs';

export default function ReviewReferralNotes({ referralUUID, parsedData, isReferrer, patientDetails=null }) {
  const [value, setValue] = useState(0);
  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
    },
    [setValue],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="active"
          centered
          textColor="secondary"
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'self-start',
            '&& .MuiTab-root': {
              fontSize: '1.0rem',
              border: '1px solid',
              fontWeight: 'normal',
              justifyContent: 'flex-start',
              alignItems: 'self-start',
            },
            '&& .MuiTabs-centered': {
              alignItems: 'self-start',
              justifyContent: 'flex-start',
            },
          }}
        >
          <Tab sx={{ alignItems: 'start' }} label="External Messages" />
          {!isReferrer && <Tab sx={{ alignItems: 'start' }} label="Internal Notes" />}
          <Tab sx={{ alignItems: 'start' }} label="System Log" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ReferralExternalNotes referralUUID={referralUUID} isReferrer={isReferrer} name={parsedData.referrer.name} isFax={parsedData.is_fax} referralDetails={parsedData} patientDetails={patientDetails}/>
      </TabPanel>

      {!isReferrer && (
        <TabPanel
          value={value}
          index={1}
        >
          <ReferralInternalNotes referralUUID={referralUUID} isReferrer={isReferrer} />
        </TabPanel>
      )}

      <TabPanel value={value} index={!isReferrer ? 2 : 1}>
        <ReferralSystemLogs referralUUID={referralUUID} />
      </TabPanel>
    </Box>
  );
}

import {
  gql
} from '@apollo/client';

const REFERRALS_DASHBOARD_BY_MONTH_AND_YEAR = gql `
  fragment ReferrralDashboardFields on ReferrralDashboard {
    referrals
    months
  }
`;

const REFERRING_DOCTORS_BY_MONTH_AND_YEAR = gql `
  fragment ReferrringDoctorsDashboardFields on ReferrringDoctorsDashboard {
    doctors
    month
  }
`;


const REFERRALS_BY_DAYS_WAITING = gql `
  fragment ReferralsByDaysDashboardFields on ReferralsByDaysDashboard {
    totalReferrals
    dayWaiting
  }
`;

export {
  REFERRALS_DASHBOARD_BY_MONTH_AND_YEAR,
  REFERRING_DOCTORS_BY_MONTH_AND_YEAR,
  REFERRALS_BY_DAYS_WAITING
};
import { gql } from '@apollo/client';
import { GET_REFERRAL_NOTES_FIELDS } from '../../referral/gql/Fragment';

const GET_PUBLIC_REFERRER = gql`
    query GetPublicReferrer($email: String!) {
    public_referrer(referrer_email: $email) {
      id
      uuid
      referrer_name
      referrer_email
      metadata {
        clinic_receiver_id
        clinic_name
        physician
        contact
        fax
        office_phone
        contact_email
        provider_uuid
        address
      }
    }
  }
`;

const REFERRAL_ANSWERS_PUBLIC_QUERY = gql`
  query ReferralAnswers($uuid: String!) {
    answers: public_referral_answers(referral_uuid: $uuid) {
      name
      question
      answer
    }
  }
`;

const GET_PUBLIC_REFERRAL_NOTES_BY_UUID = gql`
  ${GET_REFERRAL_NOTES_FIELDS}
  subscription getReferralNotes($uuid: uuid!) {
    rows: referral_notes(
      order_by: { created_at: desc }
      where: {
        referral: { uuid: { _eq: $uuid } }
      }
    ) {
      ...ReferralNotesFields
    }
  }
`;

export {
    GET_PUBLIC_REFERRER,
    REFERRAL_ANSWERS_PUBLIC_QUERY,
    GET_PUBLIC_REFERRAL_NOTES_BY_UUID
  };
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ApiService from '../../common/services/ApiService';
import {
  GET_REFERRAL_PATIENT_BEGIN,
  GET_REFERRAL_PATIENT_SUCCESS,
  GET_REFERRAL_PATIENT_FAILURE,
  GET_REFERRAL_PATIENT_DISMISS_ERROR,
} from './constants';

export function getPatient(referralUUID) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: GET_REFERRAL_PATIENT_BEGIN,
    });
    
    const promise = new Promise((resolve, reject) => {
        
        ApiService.getPatient(referralUUID).then(
          (res) => {
            dispatch({
              type: GET_REFERRAL_PATIENT_SUCCESS,
              data: res,
            });
            resolve(res);
          },
          // Use rejectHandler as the second argument so that render errors won't be caught.
          (err) => {
            dispatch({
              type: GET_REFERRAL_PATIENT_FAILURE,
              data: { error: err },
            });
            reject(err);
          },
        );
      
    });

    return promise;
  };
}

export function dismissGetPatientError() {
  return {
    type: GET_REFERRAL_PATIENT_DISMISS_ERROR,
  };
}

export function useGetPatient() {
  const dispatch = useDispatch();

  const { getPatientPending, getPatientError } = useSelector(
    state => ({
      getPatientPending: state.referral.getPatientPending,
      getPatientError: state.referral.getPatientError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((referralUUID, args) => {
    return dispatch(getPatient(referralUUID, args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetPatientError());
  }, [dispatch]);

  return {
    getPatient: boundAction,
    getPatientPending,
    getPatientError,
    dismissGetPatientError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case GET_REFERRAL_PATIENT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getPatientPending: true,
        getPatientError: null,
      };

    case GET_REFERRAL_PATIENT_SUCCESS:
      // The request is success
      return {
        ...state,
        getPatientPending: false,
        getPatientError: null,
      };

    case GET_REFERRAL_PATIENT_FAILURE:
      // The request is failed
      return {
        ...state,
        getPatientPending: false,
        getPatientError: action.data.error,
      };

    case GET_REFERRAL_PATIENT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getPatientError: null,
      };

    default:
      return state;
  }
}

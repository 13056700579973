import { Button, Stack } from '@mui/material';
import React from 'react';
import { MyDataGrid, MyTypography } from '../../common/components';
import DateTimeUtils from '../../common/services/DateTimeUtils';
import { IconDeviceLandlinePhone } from '@tabler/icons';

const columns = [
  {
    field: 'patient',
    headerName: 'Patient Name',
    width: 180,
    valueGetter: params => {
      return params.row.referral.getFullName();
    },
  },
  {
    field: 'receiver',
    headerName: 'Clinic Receiver',
    width: 250,
    // valueGetter: params => {
    //   const name = params.row.receiver.name;
    //   return name;
    // },
    renderCell: params => {
      return (
        <Stack direction="row" spacing={1}>
          {params.row.is_fax && <IconDeviceLandlinePhone />}
          <MyTypography variant="subtitle1">{params.row.receiver.name}</MyTypography>
        </Stack>
      );
    },
  },
  {
    field: 'provider',
    headerName: 'Provider',
    width: 160,
    valueGetter: params => {
      return params.row.provider.display_name;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    valueGetter: params => {
      return params.row.referral.getReferrerStatus();
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 272,
    headerAlign: 'center',
    sortable: false,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: params => {
      return (
        <Stack direction="row" spacing={1}>
          {params.row.user.referral_permissions.filter(e => e.key === 'SEND_REFERRAL' && e.value)
            .length > 0 && params.row.status.toLowerCase() === 'draft' ? (
            <>
              <Button
                onClick={e => {
                  e.stopPropagation();
                  params.row.actions.onUpdate(params.row.id);
                }}
              >
                Update Draft
              </Button>
              <Button
                onClick={e => {
                  e.stopPropagation();
                  params.row.actions.onDelete(params.row.id);
                }}
              >
                Delete
              </Button>
            </>
          ) : (
            <Button
              onClick={e => {
                e.stopPropagation();
                params.row.actions.onViewReferral(params.row.id);
              }}
            >
              View
            </Button>
          )}

          <Button
            onClick={e => {
              e.stopPropagation();
              params.row.actions.onViewNotes(params.row.id);
            }}
          >
            View Log
          </Button>
          {params.row.status === 'declined' ||
          params.row.status === 'scheduled' ||
          (params.row.is_fax &&
            (params.row.status === 'accepted' || params.row.status === 'faxed_successfully' ||
              params.row.status === 'sent')) ||
          params.row.status === 'rec_completed' ? (
            <Button
              onClick={e => {
                e.stopPropagation();
                params.row.actions.onComplete(params.row.id);
              }}
            >
              Complete
            </Button>
          ) : null}
        </Stack>
      );
    },
  },
  {
    field: 'appointments',
    headerName: 'Appointment Date or Decline Reason',
    width: 300,
    valueGetter: params => {
      return params.row.referral.getDateOrReason();
    },
  },
  {
    field: 'sent_at',
    headerName: 'Date Sent',
    width: 250,
    valueGetter: params => {
      return params.row.referral.sentFormattedDate();
    },
    sortComparator: DateTimeUtils.dateSortComparator,
  },
  {
    field: 'updated_by',
    headerName: 'Last Updated By',
    width: 250,
    valueGetter: params => {
      return params.row.referral.getLastUpdatedByFullName();
    },
  },
  {
    field: 'updated_at',
    headerName: 'Last Updated At',
    width: 250,
    valueGetter: params => {
      return params.row.referral.formattedDate();
    },
    sortComparator: DateTimeUtils.dateSortComparator,
  },
];

export default function ActiveSentReferralsTable({
  onFilterChanged,
  onFilterFaxChanged,
  onChangeDropdown,
  onSelectedRowsChanged,
  loading,
  data,
  onClick,
  onViewNotes,
}) {
  return (
    <MyDataGrid
      onFilterChanged={onFilterChanged}
      onFilterFaxChanged={onFilterFaxChanged}
      onChangeDropdown={onChangeDropdown}
      onSelectedRowsChanged={onSelectedRowsChanged}
      loading={loading}
      data={data}
      columns={columns}
      onClick={onClick}
      onViewNotes={onViewNotes}
      showToolbar={true}
      selections={['By Name', 'By Birthdate']}
      minTableHeight={'calc(100vh - 192px)'}
      getRowId={row => {
        return row.id;
      }}
    />
  );
}

import React, { useState, useCallback, useEffect } from 'react';
import { Box, Grid, Stack, Card, CardContent, Divider } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MyButton, MyTypography } from '../../common/components';
import { MyStepperHeader } from '../../common/components/MyStepper';
import RegisterUserForm, {
  registerUserValidationSchema,
  initialValues as registerUserInitialValues,
} from '../../auth/RegisterUser/RegisterUserForm';
import CreateClinicForm, {
  createClinicValidationSchema,
  initialValues as createClinicInitialValues,
} from './CreateClinic/CreateClinicForm';
import CreateProviderForm, {
  createProviderValidationSchema,
  initialValues as createProviderInitialValues,
} from './CreateProvider/CreateProviderForm';
import { gridSpacing } from '../../../common/themes/constants';
import Swal from 'sweetalert2';
import { capitalize, isEmpty, omit } from 'lodash';
import PageContent from '../../common/MainLayout/Page';
import BackendErrorHandler from '../../common/components/hook-form/BackendErrorHandler';
import Error from '../../common/Error';
import clientGraphql from '../../../common/apollo-graphql';
import { GET_PERMISSIONS_QUERY, GET_NOTIFICATIONS_QUERY } from '../gql/Query';
import { useMutation, useQuery } from '@apollo/client';
import { SAVE_CLINIC_MUTATION, SAVE_USER_CLINIC_MUTATION } from '../gql/Mutation';

import EnrollPsrReviewInfo from './EnrollPsrReviewInfo';
import AddExistingClinicUser from './ClinicDetails/AddExistingClinicUser';

const validationSchema = yup.object().shape({
  clinic: createClinicValidationSchema,
  providers: yup.array().of(createProviderValidationSchema),
  users: yup.array().of(registerUserValidationSchema),
});

const initialValues = {
  clinic: {
    ...createClinicInitialValues,
  },
  providers: [],
  users: [],
};

const stepsIndex = {
  clinic: 0,
  provider: 1,
  user: 2,
  review: 3,
};

const steps = [
  {
    label: 'Add Clinic',
  },
  {
    label: 'Add Providers',
  },
  {
    label: 'Add Users',
  },
  {
    label: 'Review',
  },
];

export default function EnrollPsr({ history }) {
  const [activeStep, setActiveStep] = useState(stepsIndex.clinic);
  const [existingUsers, setExistingUsers] = useState({ rows: [], count: 0 });
  const [parsedNotificationData, setParsedNotificationData] = useState({});

  const [addUserFromOtherClinicDialogVisible, setAddUserFromOtherClinicDialogVisible] = useState(
    false,
  );
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isValid, dirtyFields },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    shouldFocusError: true,
    mode: 'all',
    reValidateMode: 'onSubmit',
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'providers',
  });

  const { fields: fieldsUser, append: appendUser, remove: removeUser } = useFieldArray({
    control,
    name: 'users',
  });

  const handleNext = () => setActiveStep(prevActiveStep => prevActiveStep + 1);
  const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1);

  const handleOnEditUser = useCallback(() => {
    setActiveStep(stepsIndex.user);
  }, []);

  const handleOnEditClinic = useCallback(() => {
    setActiveStep(stepsIndex.clinic);
  }, []);

  const handleOnEditProvider = useCallback(() => {
    setActiveStep(stepsIndex.provider);
  }, []);

  const { loading, data } = useQuery(GET_PERMISSIONS_QUERY, {
    client: clientGraphql,
  });

  const { data: dataNotificationSettings } = useQuery(GET_NOTIFICATIONS_QUERY, {
    client: clientGraphql,
  });

  const [
    save_clinic,
    { data: saveClinicData, loading: saveClinicLoading, error: saveClinicError },
  ] = useMutation(SAVE_CLINIC_MUTATION, {
    client: clientGraphql,
  });

  const [
    save_user_clinic,
    { data: saveUserClinicData, loading: saveUserClinicLoading, error: saveUserClinicError },
  ] = useMutation(SAVE_USER_CLINIC_MUTATION, {
    client: clientGraphql,
  });

  const hasEmptyUserField = useCallback(
    (_dirty, _values) => {
      var users = getValues('users');
      var hasEmpty = false;
      users.map(user => {
        if (
          user.role_id === -1 ||
          isEmpty(user.email) ||
          isEmpty(user.firstname) ||
          isEmpty(user.lastname)
        ) {
          hasEmpty = true;
        }
      });
      return activeStep === stepsIndex.user && hasEmpty;
    },
    [getValues, activeStep],
  );

  const hasEmptyProviderField = useCallback(
    (_dirty, _values) => {
      var providers = getValues('providers');
      var hasEmpty = false;
      providers.map(provider => {
        if (
          isEmpty(provider.title) ||
          isEmpty(provider.firstname) ||
          isEmpty(provider.lastname) ||
          isEmpty(provider.specializations)
        ) {
          hasEmpty = true;
        }
      });
      return activeStep === stepsIndex.provider && hasEmpty;
    },
    [getValues, activeStep],
  );

  const hasEmptyClinicField = useCallback(
    (_dirty, _values) => {
      return (
        (activeStep === stepsIndex.clinic &&
          (isEmpty(getValues('clinic.name')) ||
            isEmpty(getValues('clinic.address_line1')) ||
            isEmpty(getValues('clinic.address_city')) ||
            isEmpty(getValues('clinic.address_state')))) ||
        isEmpty(getValues('clinic.address_zip'))
      );
    },
    [getValues, activeStep],
  );

  const onSubmit = data => {
    var newData = {
      clinic: JSON.parse(JSON.stringify(data.clinic)),
    };
    var newUsers = data.users.filter(user => {return !('id' in user);});
    var oldUsers = data.users.filter(user => {return ('id' in user);});
    console.log(oldUsers);
    if(isEmpty(newUsers)){
      newUsers = [];
    }
    if(isEmpty(oldUsers)){
      oldUsers = [];
    }
    newData.clinic['users'] = JSON.parse(JSON.stringify(newUsers));

    newUsers.map((user, index) => {
      newData.clinic.users[index]['provider_ids'] = [];
      user.provider_ids.map(prov_name => {
        const prov_index = data.providers.findIndex(
          provider =>
            capitalize(provider.firstname) + ' ' + capitalize(provider.lastname) === prov_name,
        );
        newData.clinic.users[index]['provider_ids'].push(prov_index);
      });
    });
    newData.clinic['providers'] = JSON.parse(JSON.stringify(data.providers));
    newData.clinic['extra_info'] = JSON.stringify({
      office_phone: newData.clinic['office_phone'],
      fax: newData.clinic['fax'],
    });
    data.providers.map((provider, index) => {
      if (
        provider.optional_documents.length === 0 ||
        provider.optional_documents.filter(e => e.document === 'misc').length <= 0
      ) {
        newData.clinic['providers'][index]['optional_documents'].push({
          document: 'misc',
          question: '',
        });
      }
    });

    newData.clinic = omit(newData.clinic, ['office_phone', 'fax']);

    save_clinic({
      client: clientGraphql,
      variables: newData,
    }).then(res => {
      console.log(res.data.save_clinic, oldUsers);
      oldUsers.map(user => {
        var existingUser = {};
        existingUser['userId'] = user.id;
        existingUser['clinicId'] = parseInt(res.data.save_clinic.id);
        existingUser['permission'] = user.permission;
        existingUser['notification'] = user.notification;
        save_user_clinic({
          client: clientGraphql,
          variables: { ...existingUser },
        });
      });
  
      

    });
  };

  useEffect(() => {
    if (!isEmpty(saveClinicError)) {
      const errors = !isEmpty(saveClinicError.graphQLErrors)
        ? saveClinicError.graphQLErrors[0]
        : {};
      if (!isEmpty(errors.user)) {
        handleOnEditUser();
      } else if (!isEmpty(errors.clinic)) {
        handleOnEditClinic();
      } else if (!isEmpty(errors.provider)) {
        handleOnEditProvider();
      } else if (
        !isEmpty(errors.extensions) &&
        !isEmpty(errors.extensions.validation) &&
        JSON.stringify(errors.extensions.validation).includes('clinic.users')
      ) {
        Swal.fire({
          icon: 'error',
          text: JSON.stringify(errors.extensions.validation),
          showConfirmButton: true,
        }).then(() => {
          handleOnEditUser();
        });
      } else if (
        !isEmpty(errors.extensions) &&
        !isEmpty(errors.extensions.validation) &&
        JSON.stringify(errors.extensions.validation).includes('clinic.provider')
      ) {
        Swal.fire({
          icon: 'error',
          text: JSON.stringify(errors.extensions.validation),
          showConfirmButton: true,
        }).then(() => {
          handleOnEditProvider();
        });
      } else if (
        !isEmpty(errors.extensions) &&
        !isEmpty(errors.extensions.validation) &&
        JSON.stringify(errors.extensions.validation).includes('clinic.')
      ) {
        Swal.fire({
          icon: 'error',
          text: JSON.stringify(errors.extensions.validation),
          showConfirmButton: true,
        }).then(() => {
          handleOnEditClinic();
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Error Message: ' + saveClinicError.message,
          showConfirmButton: true,
        });
      }
    }
    if (!isEmpty(saveClinicData)) {
      Swal.fire({
        icon: 'success',
        text: 'Successfully created a clinic',
        showConfirmButton: true,
      }).then(() => {
        history.push('/manage/clinics');
      });
    }
  }, [
    ,
    handleOnEditUser,
    handleOnEditClinic,
    handleOnEditProvider,
    saveClinicError,
    history,
    saveClinicData,
  ]);

  const handleRetrieve = useCallback(
    res => {
      const feature = res.features[0];
      setValue('clinic.address_line1', feature.properties.address_line1);
      setValue('clinic.address_city', feature.properties.place);
      setValue('clinic.address_state', feature.properties.region);
      setValue('clinic.address_zip', feature.properties.postcode);
      trigger('clinic.address_line1');
      trigger('clinic.address_city');
      trigger('clinic.address_state');
      trigger('clinic.address_zip');
    },
    [setValue, trigger],
  );

  useEffect(() => {
    if (!isEmpty(dataNotificationSettings)) {
      const newData = dataNotificationSettings.user_notification_settings.map(notification => {
        return { value: true, label: notification, name: notification };
      });
      setParsedNotificationData(newData);
    }
  }, [dataNotificationSettings, setParsedNotificationData]);

  return (
    <PageContent contentClass="clinics-enroll-psr" title="Register Clinic and Main User">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', mt: 3 }}>
          <MyStepperHeader activeStep={activeStep} steps={steps} />
        </Box>

        <BackendErrorHandler
          error={
            !isEmpty(saveClinicError) && !isEmpty(saveClinicError.graphQLErrors)
              ? saveClinicError.graphQLErrors[0]
              : null
          }
          setError={setError}
          clearErrors={clearErrors}
        />

        <Stack direction="column">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={gridSpacing}
            sx={{ px: 2, mt: 3, py: 2 }}
          >
            {saveClinicError && (
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                md={12}
                xs={12}
                sx={{ pb: 2 }}
              >
                <Error
                  error={
                    !isEmpty(saveClinicError) && !isEmpty(saveClinicError.graphQLErrors)
                      ? saveClinicError.graphQLErrors[0]
                      : null
                  }
                />
              </Grid>
            )}
            {activeStep === stepsIndex.user && activeStep === stepsIndex.user && (
              <Grid item xs={10}>
                <MyTypography key="user-notes" variant="subtitle">
                  {' '}
                  You can add new users or you can import existing users into this clinic
                </MyTypography>
              </Grid>
            )}
            <Grid item xs={10}>
              {activeStep === stepsIndex.user && activeStep === stepsIndex.user && (
                <Stack direction="column">
                  <Stack direction="row">
                    <MyButton
                      type="button"
                      variant="contained"
                      style={{ width: '100px', marginRight: '20px' }}
                      onClick={() => {
                        appendUser({ ...registerUserInitialValues });
                      }}
                      fullWidth={false}
                    >
                      Add User
                    </MyButton>
                    <MyButton
                      type="button"
                      variant="contained"
                      style={{ width: '160px' }}
                      onClick={setAddUserFromOtherClinicDialogVisible.bind(this, true)}
                      fullWidth={false}
                    >
                      Import Existing User
                    </MyButton>
                  </Stack>

                  <Divider sx={{ my: 1.5 }} />
                  {addUserFromOtherClinicDialogVisible ? (
                    <AddExistingClinicUser
                      existingUsers={existingUsers}
                      setExistingUsers={setExistingUsers}
                      appendUser={appendUser}
                    />
                  ) : null}
                  {fieldsUser.map((_item, index) => (
                    <Box key={`box-users-${index}`}>
                      <Stack
                        key={`stack-users-${index}`}
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ pt: 2 }}
                        style={{ marginBottom: '15px' }}
                      >
                        <MyTypography key={`typography.users.${index}`} variant="h4">
                          User Information - {index + 1}
                        </MyTypography>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {index > 0 && (
                          <MyButton
                            key={`button.users.${index}`}
                            type="button"
                            variant="contained"
                            onClick={() => {
                              removeUser(index);
                            }}
                            fullWidth={false}
                          >
                            Remove
                          </MyButton>
                        )}
                      </Stack>

                      <RegisterUserForm
                        key={`users.${index}`}
                        name={`users.${index}`}
                        prefix={`users.${index}`}
                        control={control}
                        errors={errors}
                        disabled={!isEmpty(getValues(`users.${index}.id`))}
                        setValue={setValue}
                        trigger={trigger}
                        providers={getValues('providers')}
                        permissions={[]}
                      />
                      <Divider key={`divider.users.${index}`} sx={{ my: 1.5 }} />
                    </Box>
                  ))}
                </Stack>
              )}
              {activeStep === stepsIndex.clinic && (
                <CreateClinicForm
                  prefix="clinic"
                  control={control}
                  errors={errors}
                  handleRetrieve={handleRetrieve}
                />
              )}
              {activeStep === stepsIndex.provider && (
                <Stack direction="column">
                  <MyTypography
                    variant="h5"
                    component="h5"
                    color="text.primary"
                    style={{ marginBottom: '20px' }}
                  >
                    Note: You can click skip this process if clinic has no provider.
                  </MyTypography>
                  <MyButton
                    type="button"
                    variant="contained"
                    style={{ width: '150px' }}
                    onClick={() => {
                      append({ ...createProviderInitialValues });
                    }}
                    fullWidth={false}
                  >
                    Add Provider
                  </MyButton>
                  <Divider sx={{ my: 1.5 }} />
                  {fields.map((_item, index) => (
                    <Box key={`box-providers-${index}`}>
                      <Stack
                        key={`stack-providers-${index}`}
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        style={{ marginBottom: '15px' }}
                      >
                        <MyTypography variant="h4">Provider Information - {index + 1}</MyTypography>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <MyButton
                          key={`button.${index}`}
                          type="button"
                          variant="contained"
                          onClick={() => {
                            remove(index);
                          }}
                          fullWidth={false}
                        >
                          Remove
                        </MyButton>
                      </Stack>

                      <CreateProviderForm
                        key={`providers.${index}`}
                        name={`providers.${index}`}
                        prefix={`providers.${index}`}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                      />
                      <Divider key={`divider.providers.${index}`} sx={{ my: 1.5 }} />
                    </Box>
                  ))}
                </Stack>
              )}
              {activeStep === stepsIndex.review && (
                <EnrollPsrReviewInfo
                  onEditClinic={handleOnEditClinic}
                  onEditProvider={handleOnEditProvider}
                  onEditUser={handleOnEditUser}
                  data={getValues()}
                  permissions={!isEmpty(data) ? data.user_permission_settings : []}
                  notificationSettings={
                    !isEmpty(parsedNotificationData) ? parsedNotificationData : []
                  }
                />
              )}
            </Grid>
          </Grid>

          <Stack direction="row" spacing={1} sx={{ pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <MyButton
              color="primary"
              variant="outlined"
              disabled={activeStep === 0 || saveClinicLoading}
              onClick={handleBack}
              fullWidth={false}
              sx={{ mr: 1 }}
            >
              Back
            </MyButton>
            {activeStep < steps.length - 1 && (
              <MyButton
                onClick={handleNext}
                disabled={
                  saveClinicLoading ||
                  hasEmptyClinicField(dirtyFields, getValues()) ||
                  (!isEmpty(getValues('users')) && hasEmptyUserField(dirtyFields, getValues())) ||
                  (!isEmpty(getValues('providers')) && hasEmptyProviderField(dirtyFields, getValues())) ||
                  (!isEmpty(errors) && console.log(errors))
                }
                variant="contained"
                fullWidth={false}
              >
                Next
              </MyButton>
            )}
            {isValid &&
              ((!isEmpty(getValues('users')) && !hasEmptyUserField(dirtyFields, getValues())) ||
                !isEmpty(existingUsers.rows)) &&
              activeStep < steps.length - 1 && (
                <MyButton
                  onClick={() => setActiveStep(stepsIndex.review)}
                  disabled={saveClinicLoading}
                  variant="contained"
                  color="secondary"
                  fullWidth={false}
                >
                  Review
                </MyButton>
              )}
            {activeStep === steps.length - 1 && (
              <MyButton
                type="submit"
                variant="contained"
                loading={saveClinicLoading}
                fullWidth={false}
              >
                Submit
              </MyButton>
            )}
          </Stack>
        </Stack>
      </form>
    </PageContent>
  );
}

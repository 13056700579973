// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { Authenticated } from '../auth';
import ManageNonAffiliatedClinicDetails from '../clinics/Management/ManageNonAffiliatedClinicDetails';
import ManageReferralFormClinicDetails from '../clinics/Management/ManageReferralFormClinicDetails';
import { ViewClinic } from './';

export default {
  path: 'clinic',
  name: 'Clinic',
  component: Authenticated,
  childRoutes: [
    {
      path: '/clinic',
      childRoutes: [
        { path: '', component: ViewClinic },
        {
          path: ':id',
          childRoutes: [
            {
              path: '',
              component: ManageNonAffiliatedClinicDetails,
              props: { title: 'Clinics Details', subtitle: 'Manage clinics details and users' },
            },
            {
              path: 'settings',
              component: ManageReferralFormClinicDetails,
              props: {
                title: 'Referral Form Clinics Details',
                subtitle: 'Manage Referral Form clinics details and users',
              },
            },
          ],
        },
      ],
    },
  ],
};

import { gql } from '@apollo/client';

const NOTIFICATION_MIN_FIELDS = gql`
    fragment NotificationMinimumFields on notifications {
        uuid:id
        data
        severity
        url
        notified_at:created_at
    }
`;

const NOTIFICATION_FIELDS = gql`
    fragment NotificationFields on notifications {
        uuid:id
        data
        severity
        url
        read_at
        notified_at:created_at
    }
`;

export {
    NOTIFICATION_MIN_FIELDS,
    NOTIFICATION_FIELDS,
};
import React from 'react';
import * as yup from 'yup';
import {gridSpacing} from '../../../../common/themes/constants';
import {
    Grid,
} from '@mui/material';
import {HookFormTextField} from '../../../common/components';

export const initialValues = {
    referral_network: ''
};

export const createReferralGroupValidationSchema = yup.object().shape({
    referral_network: yup.string().required('Group name is required'),
});

export default function CreateReferralGroupForm({control, errors, prefix=null}) {
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item
                  sm={8}
                  xs={12}>
                <HookFormTextField
                    id="referral_network"
                    name="referral_network"
                    placeholder="Enter Group name"
                    label="Group name *"
                    schema="referral_network"
                    prefix={prefix}
                    control={control}
                    errors={errors}
                    autoFocus
                    fullWidth
                />
            </Grid>
        
        </Grid>
    );
}
import {gql} from '@apollo/client';
import {NOTIFICATION_FIELDS} from './Fragment';

const allNotificationsQuery = gql`
    ${NOTIFICATION_FIELDS}
    query allNotificationsQuery($filter: notifications_bool_exp!) {
      items:notifications(order_by: {read_at: desc_nulls_first, created_at: desc}, where: $filter) {
        ...NotificationFields
      }
    }
`;

export {
    allNotificationsQuery,
};
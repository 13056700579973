import { capitalize, isEmpty } from 'lodash';
import moment from 'moment';
import StringUtils from '../../common/services/StringUtils';

export default class Referral {

    constructor(referral) {
        this.referral = {...referral};
    }

    getFullName() {
        if(!this.referral.patient) return '';
        return this.referral.patient.full_name;
    }

    getPhone() {
        if(!this.referral.patient) return '';
        if(!Array.isArray(this.referral.patient.details)){
            return `${this.referral.patient.details.phone}`;
        }
        return `${this.referral.patient.details[0].phone}`;
    }

    isUserReferrer(){
        let usersUUIDArr = this.referral.referrer.clinic_users.map(item => item.user != null ? item.user.uuid:-1);
        return usersUUIDArr.indexOf(this.referral.user_uuid) >= 0;
    }

    getClinic(currentUser){
        return !isEmpty(this.referral.referrer.clinic_users) && this.isUserReferrer() && !isEmpty(currentUser.clinics) 
        && this.referral.referrer.name.toUpperCase() === currentUser.clinics[0].name.toUpperCase() ?  this.getReceiverClinic():this.getReferrerClinic();
    }

    getClinicAddress(currentUser){
        return !isEmpty(this.referral.referrer.clinic_users) && this.isUserReferrer() && !isEmpty(currentUser.clinics) 
        && this.referral.referrer.name.toUpperCase() === currentUser.clinics[0].name.toUpperCase() ? this.getReceiverClinicAddress():this.getReferrerClinicAddress();
    }

    getClinicOfficePhone(currentUser){
        return !isEmpty(this.referral.referrer.clinic_users) && this.isUserReferrer() && !isEmpty(currentUser.clinics) 
        && this.referral.referrer.name.toUpperCase() === currentUser.clinics[0].name.toUpperCase() ? this.getReceiverClinicOfficePhone():this.getReferrerClinicOfficePhone();
    }

    getClinicFax(currentUser){
        return !isEmpty(this.referral.referrer.clinic_users) && this.isUserReferrer() && !isEmpty(currentUser.clinics) 
        && this.referral.referrer.name.toUpperCase() === currentUser.clinics[0].name.toUpperCase() ? this.getReceiverClinicFax():this.getReferrerClinicFax();
    }

    getReferralStatus(currentUser){
        return !isEmpty(this.referral.referrer.clinic_users) && this.isUserReferrer() && !isEmpty(currentUser.clinics) 
        && this.referral.referrer.name.toUpperCase() === currentUser.clinics[0].name.toUpperCase() ? this.getReferrerStatus():this.getReceiverStatus();
    }

    isDraft(){
        return this.getReferrerStatus() === 'Draft';
    }

    getReceiverClinic() {
        return `${'Dr. ' + this.referral.provider.display_name + ' - ' + (this.referral.provider.specializations.map(item => item.name)).join(', ')}`;
    }

    getReceiverClinicAddress() {
        return `${this.referral.receiver.name + ' - ' + this.referral.receiver.full_address}`;
    }

    getReferrerClinic() {
        return `${this.referral.referrer.name }`;
    }

    getProviderName() {
        return `${this.referral.provider.display_name }`;
    }

    getReferrerClinicAddress() {
        return `${!isEmpty(this.referral.referrer.full_address) ? 'ADDRESS: ':''}${this.referral.referrer.full_address}`;
    }

    getLastUpdatedByFullName() {
        return !isEmpty(this.referral.updated_by)
        // ? `${capitalize(this.referral.updated_by.firstname)} ${capitalize(this.referral.updated_by.lastname)}`
        ? `${StringUtils.toTitleCase(this.referral.updated_by.fullname)}`
        : '';
    }

    getComment() {
        return !isEmpty(this.referral.comment) ? this.referral.comment:'';
    }

    getExtraInfo() {
        return !isEmpty(this.referral.extra_info) ? JSON.parse(this.referral.extra_info):{};
    }

    // getLastUpdatedByName() {
    //     return !isEmpty(this.referral.updated_by)
    //     ? `${capitalize(this.referral.updated_by.firstname)} ${capitalize(this.referral.updated_by.lastname)}`
    //     : '';
    // }

    getReferrerStatus(){
        var status = this.referral.status;
        if(status === 'rec_completed' && !isEmpty(this.referral.reason)){
            status = 'Declined';
        } else if(status === 'ref_completed' && !isEmpty(this.referral.reason)){
            status = 'Completed';
        } else if(status === 'rec_completed' && isEmpty(this.referral.reason)){
            status = "Scheduled";
        } else if(status === 'ref_completed' && isEmpty(this.referral.reason)){
            status = "Completed";
        }
        return StringUtils.toTitleCase2(status);
    }

    getReceiverStatus(){
        var status = this.referral.status;
        if(status === 'sent'){
            status = 'Received';
        } else if(status === 'ref_completed' && !isEmpty(this.referral.reason)){
            status = 'Declined';
        } else if(status === 'rec_completed' && !isEmpty(this.referral.reason)){
            status = 'Completed';
        } else if(status === 'ref_completed' && isEmpty(this.referral.reason)){
            status = "Scheduled";
        } else if(status === 'rec_completed' && isEmpty(this.referral.reason)){
            status = "Completed";
        }
        return capitalize((status.replace('_', ' ')));
    }

    getDateOrReason(){
        return !isEmpty(this.referral.appointment)
        ? moment(this.referral.appointment.appointment_date.replace('T', ' ')).format('DD MMM YYYY - HH:mm:ss')
        : !isEmpty(this.referral.reason)
        ? this.referral.reason
        : '';
    }

    formattedDate() {
        return moment(this.referral.updated_at, "YYYY-MM-DD HH:mm:ss").local().format('DD MMM YYYY - HH:mm:ss') ;
    }

    sentFormattedDate() {
        return !isEmpty(this.referral.sent_at) ? 
            moment(this.referral.sent_at, "YYYY-MM-DD HH:mm:ss").local().format('DD MMM YYYY - HH:mm:ss'):"";
    }

    receivedFormattedDate() {
        return this.sentFormattedDate();
    }

    getReceiverClinicOfficePhone() {
        var office_phone = '';
        if('extra_info' in this.referral.receiver && !isEmpty(this.referral.receiver.extra_info)){
            var extra_info = JSON.parse(JSON.parse(this.referral.receiver.extra_info));
            office_phone = 'office_phone' in extra_info ? extra_info['office_phone']:'';
        }
        return office_phone;
    }
    getReceiverClinicFax() {
        var fax = '';
        if('extra_info' in this.referral.receiver && !isEmpty(this.referral.receiver.extra_info)){
            var extra_info = JSON.parse(JSON.parse(this.referral.receiver.extra_info));
            fax = 'fax' in extra_info ? extra_info['fax']:'';
        }
        return fax;
    }

    getReferrerClinicOfficePhone() {
        var office_phone = '';
        if('extra_info' in this.referral.referrer && !isEmpty(this.referral.referrer.extra_info)){
            var extra_info = JSON.parse(JSON.parse(this.referral.referrer.extra_info));
            office_phone = 'office_phone' in extra_info ? extra_info['office_phone']:'';
        }
        return office_phone;
    }
    getReferrerClinicFax() {
        var fax = '';
        if('extra_info' in this.referral.referrer && !isEmpty(this.referral.referrer.extra_info)){
            var extra_info = JSON.parse(JSON.parse(this.referral.referrer.extra_info));
            fax = 'fax' in extra_info ? extra_info['fax']:'';
        }
        return fax;
    }
}
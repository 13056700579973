import moment from 'moment';
import DateTimeUtils from '../../common/services/DateTimeUtils';

export default class MyNotification {
    constructor(data) {
        this.item = {...data};

        const {content, title} = this.parseContent(data);
        this.item.content = content;
        this.item.title = title;
    }

    parseContent(notification) {
        const {type, data} = notification;
        try {
            const content = JSON.parse(data);

            return {
                title: content.title,
                content: content.content,
            };
        } catch(err) {
            switch(type) {
                case 'App\\Notifications\\Referral\\ReferralSentNotification':
                default:
                    return {
                        title: 'Referral Sent',
                        content: data,
                    };
            }
        }
    }

    getTitle() {
        return this.item.title;
    }

    getContent() {
        return this.item.content;
    }

    getUuid() {
        return this.item.uuid;
    }

    getUrl() {
        return this.item.url;
    }

    getOnReadParams() {
        return {
            uuid: this.getUuid(),
            url: this.getUrl(),
        };
    }

    getCreatedAt(asTimeObject=false) {
        if (!this.item.notified_at) return null;

        if (asTimeObject) {
            return DateTimeUtils.parseUTC(this.item.notified_at.replace('T', ' '));
        }

        return this.item.notified_at;
    }

    isRead() {
        return !!this.item.read_at;
    }
}
import {gql} from '@apollo/client';
import {NOTIFICATION_FIELDS, NOTIFICATION_MIN_FIELDS} from './Fragment';

const currentNotificationsSubscription = gql`
    ${NOTIFICATION_FIELDS}
    subscription unreadNotificationsSubscription($limit: Int!, $filter: notifications_bool_exp!) {
      items:notifications(limit: $limit, order_by: {read_at: desc_nulls_first, created_at: desc}, where: $filter) {
        ...NotificationFields
      }
    }
`;

const unreadNotificationsAggregateSubscription = gql`
    subscription unreadNotificationsAggregateSubscription($filter: notifications_bool_exp!) {
      aggregate:notifications_aggregate(where: $filter) {
        aggregate {
          count
        }
      }
    }
`;

const latestNotificationsSubscription = gql`
    ${NOTIFICATION_MIN_FIELDS}
    subscription latestNotificationsSubscription($limit: Int!, $filter: notifications_bool_exp!) {
      items:notifications(limit: $limit, order_by: {created_at: desc}, where: $filter) {
        ...NotificationMinimumFields
      }
    }
`;

export {
    currentNotificationsSubscription,
    unreadNotificationsAggregateSubscription,
    latestNotificationsSubscription,
};
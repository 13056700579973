import React, { useCallback, useEffect, useState } from 'react';
import QualifyingQuestions from '../auth/RegisterUser/QualifyingQuestions';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DropzoneAreaBase } from 'react-mui-dropzone';
import { handlePreviewIcon } from '../patient/styles/documentPreviewIcon';
// import PropTypes from 'prop-types';

export default function PublicReferralQualifyingQuestions({ parsedData, setParsedData, documents, onFileObjectChanged, fileObjects, handleFileRequiredChange, classes }) {
  
  const [openUploadDocument, setOpenUploadDocument] = useState(false);

  const handleQuestionChange = useCallback(
    (value, question, index) => {
      var temp = JSON.parse(JSON.stringify(parsedData));
      temp[index].value = value;
      setParsedData(temp);
      handleFileRequiredChange(temp[index].doc_id, value);
    },[parsedData, setParsedData, handleFileRequiredChange],
  );

  const handleUploadDocument = useCallback(
    (question) => {
      setOpenUploadDocument(true);
    },[ setOpenUploadDocument],
  );


  useEffect(() => {
    if (!isEmpty(documents) && documents.filter(doc => doc.question != null && doc.document.name != 'misc').length > 0 && isEmpty(parsedData)) {
        const newData = [];
        documents.map(doc => {
          if(doc.question != null && doc.document.name != 'misc'){
            newData.push({ doc_id: doc.document.id,
              value: doc.document.id in fileObjects && !isEmpty(fileObjects[doc.document.id]),
              name: doc.document.name,
              ...doc.question});
          }
        });
        setParsedData(newData);
    }
  }, [documents, setParsedData, parsedData, fileObjects]);


  return (
    <>
    <Grid container key={"referral-qualifying-questions" + Math.random()} style={{paddingTop: 0}}>
      {!isEmpty(parsedData) ? 
        (<>
      <QualifyingQuestions
            key={"qualifying-questions" + Math.random()}
            questions={parsedData}
            handleChange={handleQuestionChange}
            handleUploadDocument={handleUploadDocument}
            isPublic={true}
          />
        </>):null
     }
     <Grid item sm={12} xs={12} style={{ paddingTop: 0 }} key={"referral-qualifying-upload" + Math.random()}>
                {!isEmpty(parsedData) &&
                  parsedData.map(doc => {
                    return doc.value ? (
                      <Accordion key={'upload-question-' + Math.random()} defaultExpanded={true}>
                        <AccordionSummary
                          key={'upload-accord-summary-' + Math.random()}
                          expandIcon={<ExpandMoreIcon />}
                          style={{ minHeight: '32px', margin: 0 }}
                          classes={{ content: classes.content, expanded: classes.expanded }}
                        >
                          <h4>
                            {!isEmpty(doc.name) ? doc.name.toUpperCase() : ''} - 
                            {isEmpty(fileObjects[parseInt(doc.doc_id)]) ? (<span style={{'color':'red'}}> Required *</span>):(<span style={{'color':'green'}}> Uploaded</span>)}
                          </h4>
                        </AccordionSummary>
                        <AccordionDetails key={'upload-accord-details-' + Math.random()} >
                        <DropzoneAreaBase
                          key={'drop-zone-' + Math.random()}
                              acceptedFiles={['.pdf', '.png', '.jpeg', '.zip', '.tif', '.tiff']}
                              fileObjects={fileObjects[parseInt(doc.doc_id)]}
                              showPreviews={false}
                              showFileNamesInPreview={false}
                              showPreviewsInDropzone={false}
                              filesLimit={10}
                              maxFileSize={50000000}
                              onAdd={newFileObjs => {
                                var temp = fileObjects;
                                temp[parseInt(doc.doc_id)].push(...newFileObjs);
                                onFileObjectChanged(temp, doc.name);
                              }}
                              onDelete={deleteFileObj => {
                                const index = fileObjects[parseInt(doc.doc_id)].indexOf(deleteFileObj);
                                fileObjects[doc.doc_id].splice(index, 1);
                                onFileObjectChanged(fileObjects, doc.name);
                              }}
                              getPreviewIcon={handlePreviewIcon}
                            />
                        </AccordionDetails>
                      </Accordion>
                    ):null;
                  })}
              </Grid>
      
    </Grid>
</>
  );
}

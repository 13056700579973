import React, {useState, useEffect} from 'react';
import PageContent from '../../common/MainLayout/Page';
import { useQuery } from '@apollo/client';
import {MANAGE_CLINIC_DETAILS_QUERY} from '../gql';
import { isEmpty, omit } from 'lodash';
import {
    Stack,
} from '@mui/material';
import User from '../../auth/models/User';
import {default as useQueryParams} from '../../common/hooks/useQuery';
import EditClinic from './EditClinic/EditClinic';
import ClinicUsersTable from './ClinicDetails/ClinicUsersTable';
import ClinicProvidersTable from './ClinicDetails/ClinicProvidersTable';
import ClinicDetails from './ClinicDetails/ClinicDetails';
import {ClinicProvider} from '../context/ClinicContext';
import clientGraphql from '../../../common/apollo-graphql';

function ViewClinicDetails({formattedData, userData, providerData, loading}) {
    return (
        <Stack direction="column"
               spacing={1}>
            <ClinicDetails data={formattedData} />
            <ClinicUsersTable data={userData}
                            providers={!isEmpty(providerData) ? providerData.rows:[]}
                              loading={loading} />
            <ClinicProvidersTable data={providerData}
                              loading={loading} />
        </Stack>
    );
}

export default function ManageClinicDetails({match}) {
    const {edit} = useQueryParams(true);

    const [formattedData, setFormattedData] = useState({});
    const [userData, setUserData] = useState({rows: [], count: 0});
    const [providerData, setProviderData] = useState({rows: [], count: 0});

    const {id} = match.params;
    const { loading, data, refetch } = useQuery(MANAGE_CLINIC_DETAILS_QUERY, {client: clientGraphql, variables: {id: id}});

    useEffect(() => {
        if (data) {
            const {clinic} = data;
            var newData = omit(clinic, ['id', '__typename', 'aggr_users', 'address_json', 'full_address', 'documents', 'specializations', 'users', 'required_documents', 'optional_documents','providers']);
            newData['office_phone'] = '';
            newData['fax'] = '';
            
            if('extra_info' in newData && !isEmpty(newData['extra_info'])){
                var extra_info = JSON.parse(JSON.parse(newData['extra_info']));
                newData['office_phone'] = 'office_phone' in extra_info ? extra_info['office_phone']:'';
                newData['fax'] = 'fax' in extra_info ? extra_info['fax']:'';
            }
            if('extra_info' in newData){
                newData = omit(newData, ['extra_info']);
            }
            setFormattedData({
                ...newData,
                address: clinic.full_address
            });
            var rows = [];
            data.clinic.users.map(item => {
                if(item.user != null){
                    rows.push({
                        is_primary: item.is_primary,
                        ...item.user,
                        userObj: new User({...item.user, is_primary: item.is_primary}),
                    });
                }
            })
            
            setUserData({
                rows: rows,
                count: rows.length,
            });
            setProviderData({
                rows: data.clinic.providers.map(item => {
                    return {
                        ...item
                    };
                }),
                count: data.clinic.providers.count,
            });
        }

        return () => {
            setFormattedData({});
        };
    }, [data]);

    return (
        <PageContent contentClass="management-manage-clinic-details"
                     loading={loading}>
            <ClinicProvider
                value={{
                    id,
                    refetch
                }}>
            {
                edit == 1
                ? <EditClinic loading={loading}
                              data={data} />
                : <ViewClinicDetails loading={loading}
                                     formattedData={formattedData}
                                     userData={userData}
                                     providerData={providerData} />
            }
            </ClinicProvider>
        </PageContent>
    );
};

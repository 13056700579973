import { gql } from '@apollo/client';
import { REFERRALS_BY_DAYS_WAITING, REFERRALS_DASHBOARD_BY_MONTH_AND_YEAR, REFERRING_DOCTORS_BY_MONTH_AND_YEAR } from './Fragment';

// doctors and months are array of object
// [
// { doctors: [1, 5, 2], month: 'September 2023' },
// { doctors: [11, 6, 9], month: 'October 2023'},
// ]
const GET_REFERRING_DOCTORS_BY_MONTH_AND_YEAR = gql`
${REFERRING_DOCTORS_BY_MONTH_AND_YEAR }
  query getReferringDoctorsByMonthAndYear($clinicId: ID){
    referrring_doctors_by_month_and_year(clinic_id: $clinicId) {
      ...ReferrringDoctorsDashboardFields
    }
  }
`;

// referrals and months are array
// ['October 2023', 'November 2023', 'December 2023', 'January 2024', 'February 2024', 'March 2024']
// [0, 0, 1, 8, 4, 1]
const GET_REFERRALS_RECEIVED_BY_MONTH_AND_YEAR = gql`
  query getSentTotalByMonthAndYearWithoutClinic{
    sent_total_by_month_year_without_clinic
  }
`;

// referrals and months are array
// ['October 2023', 'November 2023', 'December 2023', 'January 2024', 'February 2024', 'March 2024']
// [0, 0, 1, 8, 4, 1]
const GET_REFERRALS_REJECTED_BY_MONTH_AND_YEAR = gql`
  query getRejectedTotalByMonthAndYearWithoutClinic{
    rejected_total_by_month_year_without_clinic
  }
`;

// referrals and months are array
// ['October 2023', 'November 2023', 'December 2023', 'January 2024', 'February 2024', 'March 2024']
// [0, 0, 1, 8, 4, 1]
const GET_SENT_TOTAL_BY_MONTH_YEAR = gql`
  query getSentTotalByMonthYear{
    sent_total_by_month_year
  }
`;



// array of object {totalReferrals, daysWaiting}
// [
//   {
//     totalReferrals: 54,
//     dayWaiting: '7 days',
//   },
//   {
//     totalReferrals: 21,
//     dayWaiting: '14 days',
//   }
//   ...
// ]
const GET_TOTAL_REFERRAL_BY_DATES_WAITING = gql`
query getTotalReferralsByDatesWaiting($daysInterval: Int) {
  wait_total_by_days_interval(days_interval:$daysInterval)
}
`;


export {
  GET_REFERRING_DOCTORS_BY_MONTH_AND_YEAR,
  GET_REFERRALS_REJECTED_BY_MONTH_AND_YEAR,
  GET_REFERRALS_RECEIVED_BY_MONTH_AND_YEAR,
  GET_TOTAL_REFERRAL_BY_DATES_WAITING,
  GET_SENT_TOTAL_BY_MONTH_YEAR
};

import { Box, Tab, Tabs } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { TabPanel } from '../common';
import PublicReferralExternalNotes from './PublicReferralExternalNotes';
import PublicReferralSystemLogs from './PublicReferralSystemLogs';

export default function PublicReviewReferralNotes({ referralUUID, email, code, name }) {
  const [value, setValue] = useState(0);

  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
    },
    [setValue],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="active"
          centered
          textColor="secondary"
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'self-start',
            '&& .MuiTab-root': {
              fontSize: '1.0rem',
              border: '1px solid',
              fontWeight: 'normal',
              justifyContent: 'flex-start',
              alignItems: 'self-start',
            },
            '&& .MuiTabs-centered': {
              alignItems: 'self-start',
              justifyContent: 'flex-start',
            },
          }}
        >
          <Tab sx={{ alignItems: 'start' }} label="External Messages" />
          <Tab sx={{ alignItems: 'start' }} label="System Log" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <PublicReferralExternalNotes referralUUID={referralUUID} isReferrer={true} 
                        email={email}
                        code={code}
                        name={name}
                        isFax={false}/>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <PublicReferralSystemLogs referralUUID={referralUUID} />
      </TabPanel>
    </Box>
  );
}

import React, { useEffect } from "react";
import config from "../../../config";

function HotjarTracking() {
  useEffect(() => {
    // Hotjar Tracking Code
    const hotjar_id = config.HOTJAR_ID || 3841386; // this ID is a trial account of sid.carredo@bdlabs.ventures
    const hotjar_version = config.HOTJAR_VERSION || 6;
    
    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments); };
      h._hjSettings = { hjid: hotjar_id, hjsv: hotjar_version };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  }, []);

  return null;
}

export default HotjarTracking;

import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import { GET_ADMIN_REFERRAL_COUNTS } from '../referrals/gql';
import { useQuery } from '@apollo/client';
import clientGraphql from '../../common/apollo-graphql';
import { isEmpty } from 'lodash';

export default function TotalReferralsByClinic({ clinicId, month, year, clinicName }) {
  const theme = useTheme();
  
  const { data } = useQuery(GET_ADMIN_REFERRAL_COUNTS, {
    client: clientGraphql,
    variables: {
      clinicReferrerId: clinicId,
        month: month,
        year: year,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const { data : dataReceived} = useQuery(GET_ADMIN_REFERRAL_COUNTS, {
    client: clientGraphql,
    variables: {
      clinicReceiverId: clinicId,
        month: month,
        year: year,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  return (
    <>
              <Grid item xs={12}>
                <Grid container direction="column">
                  <Grid item>
                    <Grid container alignItems="center" justifyContent="space-between">
                      <Grid item>
                        <Typography variant="subtitle1" color="inherit">
                          {clinicName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="center" justifyContent="space-between">
                          <Grid item>
                            <Typography variant="subtitle1" color="inherit">
                            SENT: {!isEmpty(data) && !isEmpty(data.admin_referral_counts) ? data.admin_referral_counts[0].count:'0'} : RECEIVED: {!isEmpty(dataReceived) && !isEmpty(dataReceived.admin_referral_counts) ? dataReceived.admin_referral_counts[0].count:'0'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 1.5 }} />
              </Grid>
    </>
  );
}

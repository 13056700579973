import React, { useCallback, useEffect, useState } from 'react';
import {  Grid } from '@mui/material';
import { HookFormTextField } from '../../common/components';
import * as yup from 'yup';
import { gridSpacing } from '../../../common/themes/constants';
export const initialValues = {
  question: '',
};


export const addDocumentQuestionValidationSchema = yup.object().shape({
  question: yup.string().required('Question is required'),
});

export default function DocumentQuestionForm({
  control,
  errors,
  prefix = null,
  setValue,
  trigger,
}) {


  return (
    <Grid container spacing={gridSpacing}>

      <Grid item sm={12} xs={12}>
        <HookFormTextField
          id="question"
          name="question"
          placeholder="Enter Question"
          label="Question *"
          autoComplete="question"
          schema="question"
          prefix={prefix}
          control={control}
          errors={errors}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

import React from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { useSubscription } from '@apollo/client';
import { GET_DOCUMENTS_QUERY } from '../../clinics/gql/Query';
import { capitalize } from 'lodash';
import HookFormAutocompleteSingle from '../../common/components/hook-form/HookFormAutocompleteSingle';
import { gridSpacing } from '../../../common/themes/constants';
import { HookFormTextField } from '../../common/components';

export default function DocumentOptionalOptions({ prefix, control, errors, schema, placeholder, label, skip='' }) {
    const { loading, data } = useSubscription(GET_DOCUMENTS_QUERY, { variables: { limit: 100 } });
  
    if (loading) return <CircularProgress />;
  
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={4}>
            <HookFormAutocompleteSingle
            id="document"
            name="document"
            placeholder="Enter the optional document below"
            label="Optional Document *"
            schema="document"
            prefix={prefix}
            control={control}
            errors={errors}
            autocompleteProps={{
            freeSolo: true,
            fullWidth: true
            }}
            options={
            data && data.hasOwnProperty('rows')
                ? data.rows.filter(i => { return i.name !== skip}).map(i => ({ value: i.name, label: capitalize(i.name) }))
                : []
            }
            getOptionLabel={option => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
                return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
                return option.inputValue;
            }
            // Regular option
            return option.label;
            }}
            renderOption={(props, option) => (
            <li key={option.value} {...props}>
                {capitalize(option.label)}
            </li>
            )}
        />
        </Grid>
        <Grid item xs={7}>
            <HookFormTextField
            id="question"
            name="question"
            placeholder="Enter Question"
            label="Question *"
            autoComplete="question"
            schema="question"
            prefix={prefix}
            control={control}
            errors={errors}
            fullWidth
            />
        </Grid>
      </Grid>
    );
  }
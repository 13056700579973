import React from 'react';
import { Controller } from 'react-hook-form';
import HookFormUtils from './HookFormUtils';
import MyTextField from '../MyTextField';
import { isEmpty } from 'lodash';

export default function HookFormRedSelect({
    control,
    schema,
    variant,
    loading,
    errors,
    label,
    prefix=null,
    children,
    ...others
}) {
    const error = HookFormUtils.getFieldError(errors, schema, prefix);
    const _schema = HookFormUtils.getSchemaName(schema, prefix);

    return (
        <Controller
            control={control}
            name={_schema}
            render={({ field: { onChange, value } }) => (
                <MyTextField onChange={onChange}
                             name={_schema}
                             value={value}
                             select
                             variant={variant || 'outlined'}
                             label={label}
                             error={error ? true : false}
                             helperText={error && error.message}
                             defaultValue={""}
                             InputLabelProps={isEmpty(value) ? { style: { color: 'red' } }:{}}
                             {...others}>
                    {children}
                </MyTextField>
            )}
        />
    );
}
import { isEmpty } from "lodash";

export default class HelperUtils {
  static isInt = value => {
    return !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
  };

  static getFaxNumber(referral) {
    var fax = '';
    if(!isEmpty(referral) && !isEmpty(referral.referrer ) && 'extra_info' in referral.receiver && !isEmpty(referral.receiver.extra_info)){
        var extra_info = JSON.parse(JSON.parse(referral.receiver.extra_info));
        fax = 'fax' in extra_info ? extra_info['fax']:'';
    }
    return fax;
  }

  static getReferrerFaxNumber(referral) {
    var fax = '';
    if(!isEmpty(referral) && 'extra_info' in referral.referrer && !isEmpty(referral.referrer.extra_info)){
        var extra_info = JSON.parse(JSON.parse(referral.referrer.extra_info));
        fax = 'fax' in extra_info ? extra_info['fax']:'';
    }
    return fax;
  }

  static getReferrerPhone(referral) {
    var phone = '';
    if(!isEmpty(referral) && 'extra_info' in referral.referrer && !isEmpty(referral.referrer.extra_info)){
        var extra_info = JSON.parse(JSON.parse(referral.referrer.extra_info));
        phone = 'office_phone' in extra_info ? extra_info['office_phone']:'';
    }
    return phone;
  }

  static dataURItoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
  }

  static renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}
}

import { App } from '../features/home';
import { PageNotFound } from '../features/common';
import homeRoute from '../features/home/route';
import _ from 'lodash';
import authRoute from '../features/auth/route';
import dashboardRoute from '../features/dashboard/route';
import clinicsRoute from '../features/clinics/route';
import referralRoute from '../features/referral/route';
import managementRoute from '../features/management/route';
import referralsRoute from '../features/referrals/route';
import patientRoute from '../features/patient/route';
import notificationsRoute from '../features/notifications/route';
import userRoute from '../features/user/route';
import clinicRoute from '../features/clinic/route';
import widgetRoute from '../features/widget/route';

// NOTE: DO NOT CHANGE the 'childRoutes' name and the declaration pattern.
// This is used for Rekit cmds to register routes config for new features, and remove config when remove features, etc.
export const childRoutes = [
  homeRoute,
  authRoute,
  dashboardRoute,
  clinicsRoute,
  referralRoute,
  managementRoute,
  referralsRoute,
  patientRoute,
  notificationsRoute,
  userRoute,
  clinicRoute,
  widgetRoute,
];

const routes = [{
  path: '/',
  component: App,
  childRoutes: [
    ...childRoutes,
    { path: '*', name: 'Page not found', component: PageNotFound },
  ].filter(r => r.component || (r.childRoutes && r.childRoutes.length > 0)),
}];

// Handle isIndex property of route config:
//  Dupicate it and put it as the first route rule.
function handleIndexRoute(route) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }

  const indexRoute = _.find(route.childRoutes, (child => child.isIndex));
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = '';
    first.exact = true;
    first.autoIndexRoute = true; // mark it so that the simple nav won't show it.
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}

routes.forEach(handleIndexRoute);
export default routes;

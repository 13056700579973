import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Badge,
  Button,
  ButtonBase,
  CardActions,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from '../../common/ui-component/cards/MainCard';
import Transitions from '../../common/ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';
import CONFIG from '../../../config';
import NotificationsContext from '../context/NotificationsProvider';
import AuthContext from '../../auth/context/AuthContext';
import { isEmpty } from 'lodash';
import * as yup from 'yup';
import ClinicOptions from '../../auth/AddUser/ClinicOptions';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { setUser } from '../../auth/hooks/useAuth';
import { MAKE_PRIMARY_CLINIC_MUTATION } from '../../clinics/gql/Mutation';
import { useMutation } from '@apollo/client';
import clientGraphql from '../../../common/apollo-graphql';
import ApiService from '../../common/services/ApiService';
import MyModal from '../../common/MyModal';
import NavigationManager from '../../common/services/NavigationManager';

export const initialValues = {
  clinic_id: ''
};

export const validationSchema = yup.object().shape({
  clinic_id: yup
    .number()
});


const NOTIFICATION_LIMIT = CONFIG.NOTIFICATION_LIMIT || 10;

const NotificationSection = () => {
  const { notifications, count, onRead, onMarkAllReadNotification } = useContext(
    NotificationsContext,
  );
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();
  var currentUser = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = () => {
    setModalOpen(false);
  };

  const [
    make_primary_clinic,
  ] = useMutation(MAKE_PRIMARY_CLINIC_MUTATION, {
    client: clientGraphql,
  });

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleViewAllNotifications = () => {
    history.push('/notifications');
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
    }, [open]);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const userFullName = currentUser ? `${currentUser.firstname} ${currentUser.lastname}` : '';
  const userRole = currentUser ? currentUser.role : '';
  const userClinic =
    currentUser && !isEmpty(currentUser.clinics) ? currentUser.clinics[0].name : '';

  useEffect(()=>{
    if(!isEmpty(currentUser) && !isEmpty(currentUser.clinics)){
      setValue('clinic_id',currentUser.clinics[0].id);
      trigger('clinic_id');
    }

  },[setValue, trigger, getValues, currentUser]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if(name === 'clinic_id') {
        openModal();
        make_primary_clinic({
          client: clientGraphql,
          variables: {userUuid: currentUser.uuid, clinicId: value.clinic_id},
        }).then( res => {

          ApiService
            .getCurrentUser()
            .then((userData) => {
              setUser(userData);

              history.replace(NavigationManager.redirectTo(userData));

              window.location.reload();
            });
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, currentUser, make_primary_clinic, openModal, history]);

  return (
    <>
      { currentUser && !isEmpty(currentUser.clinics) ? (<Stack direction="row" spacing={2} alignItems="left" style={{ width: '50%', margin: '0 20px' }}>
        <ClinicOptions control={control} errors={errors}
          clinics={currentUser.clinics}
        />
      </Stack>):null
      }
    
      <Box sx={{ flex: '1 1 auto' }} />
      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down('md')]: {
            mr: 2,
          },
        }}
      >
        <Badge badgeContent={count} color="secondary" max={999} overlap="circular">
          <ButtonBase sx={{ borderRadius: '12px' }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background: 'none',
                '&[aria-controls="menu-list-grow"],&:hover': {
                  background: 'none',
                  color: theme.palette.secondary.light,
                },
              }}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              color="inherit"
            >
              <IconBell stroke={1.5} size="1.5rem" sx={{color: theme.palette.primaryLight}}/>
            </Avatar>
          </ButtonBase>
        </Badge>
      </Box>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ pt: 2, px: 2 }}
                      >
                        <Grid item xs={12}>
                          <Stack direction="row" spacing={2} sx={{ pb: 1.5 }}>
                            <Typography variant="subtitle1">All Notification</Typography>
                            <Chip
                              size="small"
                              label={count}
                              sx={{
                                color: theme.palette.background.default,
                                bgcolor: theme.palette.warning.dark,
                              }}
                            />
                            <Box sx={{ flex: '1 1 auto' }} />
                            {count > 0 && (
                              <Button
                                style={{ height: '20px' }}
                                onClick={e => {
                                  e.stopPropagation();
                                  if (!isEmpty(currentUser)) {
                                    onMarkAllReadNotification(currentUser.uuid);
                                  }
                                }}
                              >
                                Mark all read
                              </Button>
                            )}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className="notifications-section-content-list"
                      sx={{
                        py: '0 !important',
                        backgroundColor: theme.palette.grey['100'],
                      }}
                    >
                      <PerfectScrollbar
                        style={{
                          height: '100%',
                          maxHeight: 'calc(100vh - 205px)',
                          overflowX: 'hidden',
                        }}
                      >
                        <Grid container direction="column" spacing={2}>
                          <Grid item xs={12} p={0}>
                            <Divider sx={{ my: 0 }} />
                          </Grid>
                        </Grid>
                        <NotificationList notifications={notifications} onRead={onRead} />
                      </PerfectScrollbar>
                    </Grid>
                  </Grid>
                  {count > NOTIFICATION_LIMIT && (
                    <>
                      <Divider />
                      <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                        <Button size="small" disableElevation onClick={handleViewAllNotifications}>
                          View All
                        </Button>
                      </CardActions>
                    </>
                  )}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      <MyModal
        open={modalOpen}
        onClose={closeModal}
        title="Changing Clinic"
        content="Please wait."
      />
    </>
  );
};

export default NotificationSection;

// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { Authenticated } from '../auth';
import { CreateReferral, ReviewReferral } from '../referral/';
import { ActiveReferrals, DeclinedReferrals, CompletedReferrals, FaxedReferrals } from './';

export default {
  path: 'referrals',
  component: Authenticated,
  childRoutes: [
    { path: 'active', component: ActiveReferrals },
    { path: 'faxed', component: FaxedReferrals },
    { path: 'declined', component: DeclinedReferrals },
    { path: 'completed', component: CompletedReferrals },
    { path: ':id/review', component: ReviewReferral },
    { path: ':id/notes', component: ReviewReferral },
    { path: 'send', component: CreateReferral },
    { path: ':id', component: CreateReferral },
  ],
};

import { FormControlLabel, Grid, Switch } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import { MyButton } from '../../common/components';

export default function QualifyingQuestions({
  isPublic=false,
  questions = [],
  handleChange,
  handleUploadDocument,
}) {
  return (
    <>
      {!isEmpty(questions) &&
        questions.map((question, index) => {
          return (
            <Grid container key={`container-question.${index}`}>
              {!isEmpty(question) && question.name != 'misc' ? (
                <Grid item key={`grid.${question.uuid}`} sm={10} xs={12} style={{ paddingTop: 10 }}>
                  <FormControlLabel
                    key={`control.${question.question}`}
                    control={
                      <Switch
                        checked={question.value}
                        key={`switch.${question.question}`}
                        onChange={event => handleChange(event.target.checked, question, index)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={question.question}
                  />
                </Grid>
              ) : !isPublic && !isEmpty(question) && question.name != 'misc' ? (
                <Grid item key={`grid.uuid.${index}`} sm={2} xs={12} style={{ paddingTop: 10 }}>
                  <MyButton
                    type="button"
                    variant="contained"
                    style={{ width: '100%' }}
                    onClick={() => {
                      handleUploadDocument(question);
                    }}
                    fullWidth={false}
                  >
                    Upload {' ' + (!isEmpty(question) ? question.name.toUpperCase() : '')}
                  </MyButton>
                </Grid>
              ) : null}
              {!isPublic && !isEmpty(question) && question.value ? (
                <Grid item key={`grid-upload.${index}`} sm={2} xs={12} style={{ paddingTop: 10 }}>
                  <MyButton
                    type="button"
                    variant="contained"
                    style={{ width: '100%' }}
                    onClick={() => {
                      handleUploadDocument(question);
                    }}
                    fullWidth={false}
                  >
                    Upload
                    {' ' + question.name.toUpperCase()}
                  </MyButton>
                </Grid>
              ) : null}
            </Grid>
          );
        })}
    </>
  );
}

import {
  Alert,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useMutation, useQuery, useSubscription, useLazyQuery } from '@apollo/client';
import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import { isEmpty } from 'lodash';
import { GET_ACTIVE_SENT_REFERRALS } from '../gql';
import ActiveSentReferralsTable from '../tables/ActiveSentReferralsTable';
import ReferralNotes from '../../referral/ReferralNotes';
import { MyButton } from '../../common/components';
import MyPaperComponent from '../../common/components/MyPaperComponent';
import { usePromoteReferral } from '../../referral/redux/promoteReferral';
import Swal from 'sweetalert2';
import Referral from '../../referral/models/Referral';
import clientGraphql from '../../../common/apollo-graphql';
import AuthContext from '../../auth/context/AuthContext';
import { DELETE_REFERRAL_MUTATION } from '../../referral/gql/Mutation';
import { REFERRAL_REFRESH_SUBSCRIPTION } from '../../referral/gql/Subscription';

export default function ActiveSentReferrals({ history, uuid }) {
  const [openNotes, setOpenNotes] = useState(false);
  const [closeError, setCloseError] = useState(false);
  const [type, setType] = useState("Name"); 
  const [patientName, setPatientName] = useState("");
  const [filterFax, setFilterFax] = useState('all');
  const [birthdate, setBirthdate] = useState("");
  const [parsedData, setParsedData] = useState({});
  const [referralUuid, setreferralUuid] = useState('');
  const currentUser = useContext(AuthContext);
  const handleClose = () => setOpenNotes(false);
  const [shouldSubscribe, setShouldSubscribe] = useState(true);
  const referralsSubscribeRef = useRef([]);

  const [
    delete_referral,
  ] = useMutation(DELETE_REFERRAL_MUTATION, {
    client: clientGraphql,
  });

  const gqlParams = {
    client: clientGraphql,
    variables: {
      patientName: patientName,
      birthdate: birthdate,
      uuid: uuid,
      isFax: null,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
 };

  const [getReferralActiveSent] = useLazyQuery(GET_ACTIVE_SENT_REFERRALS,gqlParams);

  const { loading, error, data } = useQuery(GET_ACTIVE_SENT_REFERRALS, {
    client: clientGraphql,
    variables: {
      patientName: patientName,
      birthdate: birthdate,
      uuid: uuid,
      isFax: null
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const { data: referrals } = useSubscription(REFERRAL_REFRESH_SUBSCRIPTION, {
    variables: {
      clinic_id: currentUser.clinics[0].id
    },
    skip: !shouldSubscribe,
    lazy: true,
  });
  const handleRefetch = useCallback(() => {
    var variables = {
      patientName: patientName,
      birthdate: birthdate,
      uuid: uuid,
      isFax: null
    };
    if(filterFax === 'fax'){
      variables.isFax = true;
    } else if(filterFax === 'none'){
      variables.isFax = false;
    }
    getReferralActiveSent({
      client: clientGraphql,
      variables: variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
   }).then(res => {
    const {data} = res;
    if (!isEmpty(data) && !isEmpty(data.rows)) {
      setParsedData({
        rows: data.rows.map(item => ({
          ...item,
          user: currentUser,
          referral: new Referral(item),
          actions: {
            onViewNotes: handleOnViewNotes,
            onUpdate: handleOnUpdate,
            onViewReferral: handleOnViewReferral,
            onComplete: handleOnComplete,
            onDelete: handleOnDelete
          },
        })),
        count: data.rows.length,
      });
    }
   });
  }, [getReferralActiveSent, uuid, birthdate, patientName, filterFax, handleOnViewNotes, handleOnUpdate, handleOnComplete, currentUser, handleOnViewReferral, handleOnDelete]);

  useEffect(() => {
    if (!isEmpty(referrals)) {
      if (referralsSubscribeRef.current.length === 0) {
        referralsSubscribeRef.current = referrals;
      } else {
        handleRefetch();
      }
    }
  }, [referrals, handleRefetch]);

  

  const handleOnSelectedRowsChanged = useCallback(selectedRows => {
  }, []);

  const handleFilterChanged = useCallback(e => {
    if(type === 'Name'){
      setPatientName(e.target.value);
    } else {
      setBirthdate(e.target.value);
    }
  }, [setPatientName, setBirthdate, type]);

  const handleFilterFaxChanged = useCallback(e => {
    console.log(e.target.value);
    setFilterFax(e.target.value);
  }, [setFilterFax]);

  const handleChangeDropdown= useCallback(e => {
    setType(e.target.value);
    setBirthdate('');
    setPatientName('');
  }, [setType, setBirthdate, setPatientName]);


  const handleOnUpdate = useCallback(
    rowId => {
      history.push(`/referrals/${rowId}?edit=1`);
    },
    [history],
  );

  const handleOnDelete = useCallback(
    rowId => {
      Swal.fire({
        title: "Are you sure?" ,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          delete_referral({
            client: clientGraphql,
            variables: { referralUuid: rowId },
          }).then(() => {
            handleRefetch();
          });
        }
      });
      
    },
    [delete_referral, handleRefetch],
  );

  const handleOnViewReferral = useCallback(
    rowId => {
      history.push(`/referrals/${rowId}/review`);
    },
    [history],
  );

  const handleOnViewNotes = useCallback(
    rowId => {
      setreferralUuid(rowId);
      setOpenNotes(true);
    },
    [setOpenNotes, setreferralUuid],
  );

  const {
    promoteReferral,
  } = usePromoteReferral();

  const onPromoteReferral = useCallback(
    (id, status, message) => {
      promoteReferral(id, status).then(data => {
        Swal.fire({
          icon: 'success',
          text: message,
          showConfirmButton: true,
        }).then(() => {
          handleRefetch();
        });
      });
    },
    [promoteReferral, handleRefetch],
  );

  const handleOnComplete = useCallback(
    rowId => {
      onPromoteReferral(rowId, 'ref_completed', 'Referral Completed');
    },
    [onPromoteReferral],
  );

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(data.rows)) {
      setParsedData({
        rows: data.rows.map(item => ({
          ...item,
          user: currentUser,
          referral: new Referral(item),
          actions: {
            onViewNotes: handleOnViewNotes,
            onUpdate: handleOnUpdate,
            onViewReferral: handleOnViewReferral,
            onComplete: handleOnComplete,
            onDelete: handleOnDelete
          },
        })),
        count: data.rows.length,
      });
    }else {
      setParsedData({});
    }
  }, [data, handleOnViewNotes, handleOnUpdate, handleOnComplete, currentUser, handleOnViewReferral, handleOnDelete]);

  return (
    <>
      {error && !closeError ? (
        <Alert
          variant="filled"
          severity="error"
          onClose={() => {
            setCloseError(true);
          }}
        >
          We are sorry, but we are having difficulty with our system. We are unable to load data at
          the moment. Please reach out to us if you continue to experience this issue.
        </Alert>
      ) : null}
      <ActiveSentReferralsTable
        onFilterChanged={handleFilterChanged}
        onFilterFaxChanged={handleFilterFaxChanged}
        onChangeDropdown={handleChangeDropdown}
        onSelectedRowsChanged={handleOnSelectedRowsChanged}
        loading={loading}
        onViewNotes={handleOnViewNotes}
        data={
          !isEmpty(parsedData) && !loading
            ? { rows: parsedData.rows, count: parsedData.count }
            : { rows: [], count: 0 }
        }
      />
      <Dialog
        open={openNotes}
        onClose={handleClose}
        PaperComponent={MyPaperComponent}
        aria-labelledby="draggable-dialog-title"
        style={{ minWidth: '800px' }}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Referral Log
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Card style={{ padding: '0' }}>
              <CardContent>
                <ReferralNotes uuid={!isEmpty(referralUuid) && referralUuid} isExternal={true} />
              </CardContent>
            </Card>
          </Box>
        </DialogContent>
        <DialogActions>
          <MyButton autoFocus onClick={handleClose} fullWidth={false}>
            Close
          </MyButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React, { useEffect } from 'react';
import {
    MenuItem,
} from '@mui/material';
import { isEmpty, startCase, upperCase} from 'lodash';
import { HookFormRedSelect } from '../common/components';

export default function ProviderOptions({prefix=null, control, errors, providers, onProviderChange, schema=''}) {

    useEffect(()=>{
        if(!isEmpty(providers) && onProviderChange != null && providers.length === 1) {
            onProviderChange(providers[0]);
        }
    },[onProviderChange, providers]);
    

    return (
        <HookFormRedSelect
            id={!isEmpty(schema) ? schema:"provider_uuid"}
            type="select"
            select
            name={!isEmpty(schema) ? schema:"provider_uuid"}
            placeholder={ !isEmpty(schema) ? "Select Referrer Provider *":"Select Provider *"}
            label="Referrer Provider *"
            schema={!isEmpty(schema) ? schema:"provider_uuid"}
            prefix={prefix}
            control={control}
            errors={errors}
            fullWidth
        >
            {
                providers &&
                providers.map((option) => (
                    <MenuItem key={option.display_name} value={!isEmpty(schema) ? option.id:option.uuid} onClick={() => onProviderChange(option)}>
                        {(!isEmpty(option.title) ? upperCase(option.title) + ". ":"DR. ") +  startCase(option.display_name)}
                    </MenuItem>
                ))
            }
        </HookFormRedSelect>
    );
}
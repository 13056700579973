import { Alert } from '@mui/material';
import { useQuery, useSubscription } from '@apollo/client';
import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { isEmpty } from 'lodash';
import { GET_DECLINED_RECEIVED_REFERRALS } from '../gql';
import DeclinedReceivedReferralsTable from '../tables/DeclinedReceivedReferralsTable';
import Referral from '../../referral/models/Referral';
import clientGraphql from '../../../common/apollo-graphql';
import { REFERRAL_RECEIVER_SUBSCRIPTION } from '../../referral/gql/Subscription';
import AuthContext from '../../auth/context/AuthContext';

export default function DeclinedReceivedReferrals({ uuid, onViewLogs, onView }) {
  const [parsedData, setParsedData] = useState({});
  const [closeError, setCloseError] = useState(false);
  const currentUser = useContext(AuthContext);

  const { loading, data, error, refetch } = useQuery(GET_DECLINED_RECEIVED_REFERRALS, {
    client: clientGraphql,
    variables: {
      uuid: uuid,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const [shouldSubscribe, setShouldSubscribe] = useState(true);
  const referralsSubscribeRef = useRef([]);

  const { data: referrals } = useSubscription(REFERRAL_RECEIVER_SUBSCRIPTION, {
    variables: {
      clinic_id: !isEmpty(currentUser) && !isEmpty(currentUser.clinics) ? currentUser.clinics[0].id:-1
    },
    skip: !shouldSubscribe,
    lazy: true,
  });

  useEffect(() => {
    if (!isEmpty(referrals)) {
      if (referralsSubscribeRef.current.length === 0) {
        referralsSubscribeRef.current = referrals;
      } else {
        refetch();
      }
    }
  }, [referrals, refetch]);

  const handleFilterChanged = useCallback(e => {
  }, []);

  useEffect(() => {
    if (!isEmpty(data)) {
      setParsedData({
        rows: data.rows.map(item => ({
          ...item,
          referral: new Referral(item),
          actions: {
            onViewNotes: onViewLogs,
            onView: onView,
          },
        })),
        count: data.rows.length,
      });
    } else {
      setParsedData({});
    }
  }, [data, onViewLogs, onView]);

  return (
    <>
      {error && !closeError ? (
        <Alert
          variant="filled"
          severity="error"
          onClose={() => {
            setCloseError(true);
          }}
        >
          We are sorry, but we are having difficulty with our system. We are unable to load data at
          the moment. Please reach out to us if you continue to experience this issue.
        </Alert>
      ) : null}
      <DeclinedReceivedReferralsTable
        onFilterChanged={handleFilterChanged}
        loading={loading}
        data={
          !isEmpty(parsedData) && !loading
            ? { rows: parsedData.rows, count: parsedData.count }
            : { rows: [], count: 0 }
        }
      />
    </>
  );
}

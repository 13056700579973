import React, {useCallback, useMemo, useState} from 'react';
import { useQuery } from '@apollo/client';
import PageContent from '../../common/MainLayout/Page';
import {MANAGE_CLINICS_QUERY} from '../gql';
import {useDeleteClinic} from './redux/hooks';
import Swal from 'sweetalert2';
import ClinicsTable from './ManageClinics/ClinicsTable';
import useParsedTableData from '../../common/hooks/useParsedTableData';
import clientGraphql from '../../../common/apollo-graphql';

export default function ManageClinics({history}) {
  const {deleteClinic} = useDeleteClinic();

  const [keyword, setKeyword] = useState("");
  const { loading, data, refetch } = useQuery(MANAGE_CLINICS_QUERY, {
    client: clientGraphql,
    variables: {
      keyword: keyword 
    },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
  });

  const handleOnAdd = useCallback(() => {
    history.push('/clinics/enroll');
  }, [history]);

  const handleFilterChanged = useCallback(e => {
    setKeyword(e.target.value);
  }, []);

  const handleOnDelete = useCallback((rowId) => {
    Swal.fire({
        html: "You are about to archive this clinic record." ,
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: false,
        confirmButtonText: 'Archive',
        denyButtonText: 'Delete',
    }).then((result) => {
        let forceDelete;
        let type;

        if (result.isConfirmed) {
            forceDelete = false;
            type = 'archived';
        } else if (result.isDenied) {
            forceDelete = true;
            type = 'deleted';
        }

        if(type) {
          deleteClinic(rowId, { 'forceDelete': forceDelete }).then(res => {
            refetch();
            Swal.fire(
                'Clinic ' + type,
                '',
                'success'
            );
          });
        }
    });
  }, [deleteClinic, refetch]);

  const handleOnEdit = useCallback((rowId) => {
    history.push(`/manage/clinics/${rowId}?edit=1`);
  }, [history]);

  const handleOnView = useCallback((rowId) => {
      history.push(`/manage/clinics/${rowId}`);
  }, [history]);

  const handleOnReferralSettings = useCallback((rowId) => {
    history.push(`/manage/clinics/${rowId}/settings`);
}, [history]);

  const handleOnSelectedRowsChanged = useCallback((rowId) => {
  }, []);

  const actions = useMemo(() => {
      return {onView: handleOnView, onEdit: handleOnEdit, onDelete: handleOnDelete, onReferralSettings: handleOnReferralSettings};
  }, [handleOnView, handleOnEdit, handleOnDelete, handleOnReferralSettings]);

  const {parsedData} = useParsedTableData(data, actions);

  return (
    <PageContent contentClass="clinics-manage-clinics">
      <ClinicsTable onFilterChanged={handleFilterChanged}
                    onSelectedRowsChanged={handleOnSelectedRowsChanged}
                    onAdd={handleOnAdd}
                    onDelete={handleOnDelete}
                    onEdit={handleOnEdit}
                    loading={loading}
                    data={parsedData}
      />
    </PageContent>
  );
};

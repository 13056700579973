import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ApiService from '../../common/services/ApiService';
import {
  FAX_SEND_BEGIN,
  FAX_SEND_SUCCESS,
  FAX_SEND_FAILURE,
  FAX_SEND_DISMISS_ERROR,
} from './constants';

export function faxSend(args) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: FAX_SEND_BEGIN,
    });
    
    const promise = new Promise((resolve, reject) => {
        
        ApiService.faxSend(args).then(
          (res) => {
            dispatch({
              type: FAX_SEND_SUCCESS,
              data: res,
            });
            resolve(res);
          },
          // Use rejectHandler as the second argument so that render errors won't be caught.
          (err) => {
            dispatch({
              type: FAX_SEND_FAILURE,
              data: { error: err },
            });
            reject(err);
          },
        );
      
    });

    return promise;
  };
}

export function dismissFaxSendError() {
  return {
    type: FAX_SEND_DISMISS_ERROR,
  };
}

export function useFaxSend() {
  const dispatch = useDispatch();

  const { faxSendPending, faxSendError } = useSelector(
    state => ({
      faxSendPending: state.referral.faxSendPending,
      faxSendError: state.referral.faxSendError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((args) => {
    return dispatch(faxSend(args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFaxSendError());
  }, [dispatch]);

  return {
    faxSend: boundAction,
    faxSendPending,
    faxSendError,
    dismissFaxSendError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FAX_SEND_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        faxSendPending: true,
        faxSendError: null,
      };

    case FAX_SEND_SUCCESS:
      // The request is success
      return {
        ...state,
        faxSendPending: false,
        faxSendError: null,
      };

    case FAX_SEND_FAILURE:
      // The request is failed
      return {
        ...state,
        faxSendPending: false,
        faxSendError: action.data.error,
      };

    case FAX_SEND_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        faxSendError: null,
      };

    default:
      return state;
  }
}

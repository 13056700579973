import React, { useState, useEffect } from 'react';

import { isEmpty } from 'lodash';
import clientGraphql from '../../common/apollo-graphql';
import { GET_REFERRALS_RECEIVED_BY_MONTH_AND_YEAR } from './gql/Query';
import { useQuery } from '@apollo/client';
import { LineChart } from '@mui/x-charts/LineChart';

const chartSetting = {
    height: 500,
};
  
const valueFormatter = (value) => `${value} referrals`;

export default function ReferralReceivedByMonthAndYear() {
    const [parsedData, setParsedData] = useState({});
    const { loading, data, error, refetch } = useQuery(
        GET_REFERRALS_RECEIVED_BY_MONTH_AND_YEAR,
        {
          client: clientGraphql,
          variables: {
            daysInterval: 5,
          },
          fetchPolicy: 'network-only',
          nextFetchPolicy: 'network-only',
        },
    );

    useEffect(() => {
        if(!isEmpty(data)){
            var interval = JSON.parse(data.sent_total_by_month_year_without_clinic);
            var dataVal = {months: [''], data: [0]};
            for (let key in interval) {
                dataVal.months.push(key);
                dataVal.data.push(interval[key]);
            }
            setParsedData(dataVal);
        }
    }, [data, setParsedData]);    

    return (
        <>
        
        {!isEmpty(parsedData) ? (
            <LineChart
            xAxis={[{ scaleType: 'point', data: parsedData.months}]}
            series={[
                {
                    curve: "linear",
                    data: parsedData.data,
                    label: 'Referrals Received by Month and Year',
                },
            ]}
            {...chartSetting}
        />
        ):null}
        </>
    );
};
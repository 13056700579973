import React from 'react';
import { Link } from 'react-router-dom';
import {
    Grid,
    Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MainCard from '../common/ui-component/cards/MainCard';
import Logo from '../common/ui-component/Logo';

const AuthWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    minHeight: '100vh'
}));

const AuthCardWrapper = ({ children, ...other }) => (
    <MainCard
        sx={{
            maxWidth: { xs: 400, lg: 475 },
            margin: { xs: 2.5, md: 3 },
            '& > *': {
                flexGrow: 1,
                flexBasis: '50%'
            }
        }}
        content={false}
        {...other}
    >
        <Box sx={{ p: { xs: 2, sm: 3, xl: 5 }}}>{children}</Box>
    </MainCard>
);

export default function AuthPageContainer({children}) {
    return (
        <AuthWrapper>
            <Grid container
                  direction="column"
                  justifyContent="flex-end"
                  sx={{ height: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 4, mt: 2 }}>
                                        <Link to="#">
                                            <Logo />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {children}
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AuthWrapper>
    );
}
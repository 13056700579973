import React, { useCallback, useEffect, useState } from 'react';
import PageContent from '../common/MainLayout/Page';
import { Stack } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import clientGraphql from '../../common/apollo-graphql';
import { FaxProvider } from '../referral/context/FaxContext';
import { isEmpty } from 'lodash';
import { GET_FAX } from '../referrals/gql/Query';
import ManageFaxTable from './table/ManageFaxTable';
import moment from 'moment';

function ViewManageFaxTable({ faxData, loading }) {
  return (
    <Stack direction="column" spacing={1}>
      <ManageFaxTable data={faxData} loading={loading} />
    </Stack>
  );
}

export default function ManageFax() {
  const [faxData, setFaxData] = useState({});

  const gqlParams = {
    client: clientGraphql,
    variables: {},
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  };

  const [getFax, { loading }] = useLazyQuery(GET_FAX, gqlParams);

  const handleRefetch = useCallback(() => {
    var variables = {};
    getFax({
      client: clientGraphql,
      variables: variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }).then(res => {
      const { data } = res;
      if (!isEmpty(data) && !isEmpty(data.faxes)) {
        setFaxData({
          rows: data.faxes.map(item => {
            return {
              ...item,
              completionTime: moment(item.completionTime, 'YYYY-MM-DDTHH:mm:ss').local(),
              submitTime: moment(item.submitTime, 'YYYY-MM-DDTHH:mm:ss').local(),
            };
          }),
          count: data.faxes.count,
        });
      }
    });
  }, [getFax]);

  useEffect(() => {
    if (isEmpty(faxData)) {
      setFaxData({ rows: [], count: 0 });
      handleRefetch();
    }
  }, [faxData, handleRefetch, setFaxData]);

  return (
    <PageContent contentClass="manage-users">
      <FaxProvider
        value={{
          handleRefetch,
        }}
      >
        {<ViewManageFaxTable loading={loading} faxData={faxData} />}
      </FaxProvider>
    </PageContent>
  );
}

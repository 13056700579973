import React, { useEffect } from 'react';
import {
    MenuItem,
} from '@mui/material';
import { isEmpty, startCase, upperCase} from 'lodash';
import { HookFormSelect } from '../common/components';

export default function ReferralProviderOptions({prefix=null, control, errors, providers, schema}) {

    // useEffect(()=>{
    //     if(!isEmpty(providers) && onProviderChange != null && providers.length === 1) {
    //         onProviderChange(providers[0]);
    //     }
    // },[onProviderChange, providers]);
    

    return (
        <HookFormSelect
            id={schema}
            type="select"
            select
            name={schema}
            placeholder={schema}
            label="Referrer Provider *"
            schema={schema}
            prefix={prefix}
            control={control}
            errors={errors}
            fullWidth
        >
            {
                providers &&
                providers.map((option) => (
                    <MenuItem key={option.display_name} value={option.id}>
                        {(!isEmpty(option.title) ? upperCase(option.title) + ". ":"DR. ") +  startCase(option.display_name)}
                    </MenuItem>
                ))
            }
        </HookFormSelect>
    );
}
import { capitalize } from 'lodash';

export default class Patient {

    constructor(details) {
        this.patient = {...details.patient};
        this.diagnosis = {...details.diagnosis};
    }
    getSSN() {
        return `${capitalize(this.patient.ssn )}`;
    }

    getBirthdate() {
        return `${capitalize(this.patient.birthdate )}`;
    }

    getFullName() {
        return `${capitalize(this.patient.firstname )} ${capitalize(this.patient.lastname)}`;
    }

    getAddress(){
        return `${capitalize(this.patient.line1 )}, ${capitalize(this.patient.city)}, ${capitalize(this.patient.state)}, ${capitalize(this.patient.zip)}`;
    }

    getInsuranceProvider(){
        return `${capitalize(this.patient.insurance_provider)}`;
    }

    getPolicyNumber(){
        return `${capitalize(this.patient.policy_number)}`;
    }

    getPhone(){
        return `${capitalize(this.patient.phone)}`;
    }

    getClinic(){
        return true ? this.getReceiverClinic():this.getReferrerClinic();
    }
}
import React from 'react';
import Alert from '@mui/material/Alert';

export default function MyAlert({
    children,
    ...otherProps
}) {
    return (
        <Alert {...otherProps}>{children}</Alert>
    );
}
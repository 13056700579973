import React, {useMemo} from 'react';
import {
    Breadcrumbs as MuiBreadcrumbs,
    Typography,
} from '@mui/material';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import {childRoutes} from '../../../../common/routeConfig';
import MyLinkRouter from '../../components/MyLinkRouter';
import {findIndex} from 'lodash';
import UrlUtils from '../../services/UrlUtils';

function getTreeRoute(routes, path='', routeList=[]) {
    if (Array.isArray(routes)) {
        routes.forEach(item => {
            const parentPath = path ? path : '';
            const _path = `${parentPath}/${item.path}`;
            if (item.hasOwnProperty('childRoutes') && item.childRoutes.length > 0) {
                return getTreeRoute(item.childRoutes, _path, routeList);
            } else {
                return getTreeRoute(item, _path, routeList);
            }
        });
    } else {
        if (routes.hasOwnProperty('childRoutes') && routes.childRoutes.length > 0) {
            routes.forEach(item => {
                const _path = [path, item.path].filter(val => val).join('/');
                if (item.hasOwnProperty('childRoutes') && item.childRoutes.length > 0) {
                    return getTreeRoute(item.childRoutes, _path, routeList);
                } else {
                    return getTreeRoute(item, _path, routeList);
                }
            });
        } else {
            const props = routes.props ? routes.props : {title: 'No title', subtitle: 'No subtitle'};
            routeList.push({
                path: UrlUtils.sanitizePath(path),
                name: routes.name,
                ...props,
            });
        }
    }
}

function getPathNames(pathname, routes) {
    const paths = pathname.split('/').map(v => (v ? v : '/'));
    const pathsMatched = [];

    paths.forEach((path, index) => {
        let currentPath = path;

        if (index > 0) {
            currentPath = paths.slice(0, index+1).filter(v => v).join('/');
        }

        const isLast = index === (paths.length - 1);
        currentPath = UrlUtils.sanitizePath(currentPath);

        routes.forEach(route => {
            if (
                matchPath(currentPath, {
                    path: route.path,
                    exact: true,
                    strict: false,
                })
                &&
                route.path
            ) {
                pathsMatched.push({
                    ...route,
                    isLast
                });
            }
        });
    });

    return pathsMatched;
}

export default function Breadcrumbs() {
    const location = useLocation();
    const routes = useMemo(() => {
        const routes = [];
        getTreeRoute(childRoutes, '', routes);
        return routes;
    }, []);

    const pathname = location.pathname;
    const pathnames = getPathNames(pathname, routes);

    return (
        <MuiBreadcrumbs aria-label="breadcrumbs">
            {
                !pathname.includes('dashboard') ? (
                <MyLinkRouter to="/dashboard">Dashboard</MyLinkRouter>
                ) : (
                    <Typography>Dashboard</Typography>
                )
            }

            {
                !pathname.includes('dashboard') &&
                pathnames.map((item, index) => {
                    return item.isLast ? (
                        <Typography key={index}>{item.title}</Typography>
                    ) : (
                        <MyLinkRouter key={index} to={item.path}>
                            {item.title}
                        </MyLinkRouter>
                    );
                })
            }
        </MuiBreadcrumbs>
    );
}
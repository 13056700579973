import React from 'react';
import Cleave from 'cleave.js/dist/cleave-react';

export default React.forwardRef(function AddressZipMask(props, ref) {
    const { inputRef, ...other } = props;

    return (
        <Cleave
            {...other}
            ref={(ref) => {
                if (inputRef) {
                    inputRef(ref ? ref.inputElement : null);
                }
            }}
            options={{
                blocks: [5],
                numericOnly: true,
                swapHiddenInput: true,
            }}
        />
    );
});

import { gql } from '@apollo/client';

const MANAGE_USER_DETAILS_FIELDS = gql`
    fragment ManageUserDetailsFields on User {
      id
      uuid
      firstname
      lastname
      email
      phone
      activated_at
      role {
        id
        name
      }
      permissions {
        key
        value
        label
      }
      notifications {
        key
        value
        label
      }
    }
`;

export {
    MANAGE_USER_DETAILS_FIELDS,
};
import React, { useState, useEffect } from 'react';

import { isEmpty } from 'lodash';
import clientGraphql from '../../common/apollo-graphql';
import { GET_SENT_TOTAL_BY_MONTH_YEAR } from './gql/Query';
import { useQuery } from '@apollo/client';
import { BarChart } from '@mui/x-charts/BarChart';

export default function TotalReceivedPerClinicByMonthAndYear() {
  const [parsedData, setParsedData] = useState({});
  const { data } = useQuery(GET_SENT_TOTAL_BY_MONTH_YEAR, {
    client: clientGraphql,
    variables: {
      daysInterval: 5,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!isEmpty(data)) {
      var interval = JSON.parse(data.sent_total_by_month_year);
      var dataVal = { months: [], series: [] };
      var clinicData = {};
      var count = 0;
      for (let key in interval) {
        dataVal.months.push(key);
        var clinicValue = interval[key];
        for (let clinickey in clinicValue) {
          if (clinickey in clinicData) {
            clinicData[clinickey].data.push(clinicValue[clinickey]);
          } else {
            clinicData[clinickey] = { data: [] };
            for (var index = 0; index < count; index++) {
              clinicData[clinickey].data.push(0);
            }
            clinicData[clinickey].data.push(clinicValue[clinickey]);
          }
        }
        count++;
      }
      var maxLength = dataVal.months.length;
      for (let clinicName in clinicData) {
        if (clinicData[clinicName].data.length < dataVal.months.length) {
          var i = clinicData[clinicName].data.length;
          for (; i < maxLength; i++) {
            clinicData[clinicName].data.push(0);
          }
        }
      }
      var indexClinicData = 0;
      for (let clinicName in clinicData) {
        dataVal.series.push({
          data: clinicData[clinicName].data,
          stack:
            indexClinicData % 4 === 0
              ? 'A'
              : indexClinicData % 4 === 1
              ? 'B'
              : indexClinicData % 4 === 2
              ? 'C'
              : 'D',
          label: clinicName,
        });
        indexClinicData++;
      }
      setParsedData(dataVal);
    }
  }, [data, setParsedData]);

  return (
    <>
      {!isEmpty(parsedData) ? (
        <BarChart
          series={parsedData.series}
          height={1000}
          margin={{ top: 5 }}
          xAxis={[
            {
              scaleType: 'band',
              data: parsedData.months,
              categoryGapRatio: 0.3,
              barGapRatio: 0.1,
            },
          ]}
        />
      ) : null}
    </>
  );
}

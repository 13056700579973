import React, {useState, useCallback, useMemo, useContext} from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Box,
} from '@mui/material';
import {IconFile} from '@tabler/icons';

import useParsedTableData from '../../common/hooks/useParsedTableData';
import { gridSpacing } from '../../../common/themes/constants';
import UpdateClinicUserDialog from '../../clinics/Management/ClinicDetails/UpdateClinicUserDialog';
import ManageDocumentsTable from './ManageDocumentsTable';
import DocumentContext from '../context/DocumentContext';
import Swal from 'sweetalert2';

export default function DocumetsTable({history, data, loading}) {
    const {id, refetch} = useContext(DocumentContext);
    const [updateUserDialogVisible, setUpdateUserDialogVisible] = useState(false);

    const handleUpdateUserDialogClose = useCallback((isSuccess=false) => {
        setUpdateUserDialogVisible(false);
        if (isSuccess) {
            refetch();
        }
    }, [refetch]);

    const handleOnDelete = useCallback((rowId) => {
        Swal.fire({
            html: "You are about to archive this document." ,
            icon: 'warning',
            showCancelButton: true,
            showDenyButton: false,
            confirmButtonText: 'Archive',
            denyButtonText: 'Delete',
        }).then((result) => {
            // delete document
            refetch();
        });
      }, [refetch]);
    
      const handleOnEdit = useCallback((rowId) => {
        history.push(`/manage/documents/${rowId}`);
      }, [history]);

    const actions = useMemo(() => {
        return {onEdit: handleOnEdit, onDelete: handleOnDelete};
    }, [handleOnDelete, handleOnEdit]);

    const {parsedData} = useParsedTableData(data, actions);

    return (<>
        <Card>
            <CardHeader
                avatar={<IconFile />}
                title="Documents"
            />
            <CardContent>
                <Box sx={{m: -3}}
                      direction="column"
                      spacing={gridSpacing}>
                    <ManageDocumentsTable
                        loading={loading}
                        data={parsedData}
                        showToolbar={false}
                    />
                </Box>
            </CardContent>
        </Card>
        <UpdateClinicUserDialog open={updateUserDialogVisible}
                                    onClose={handleUpdateUserDialogClose} />
    </>);
}
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ApiService from '../../common/services/ApiService';
import {
  UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_BEGIN,
  UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_SUCCESS,
  UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_FAILURE,
  UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_DISMISS_ERROR,
} from './constants';

export function uploadReferralMultipleDocuments(referralUUID, args) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.uploadReferralMultipleDocuments(referralUUID, args).then(
        (res) => {
          dispatch({
            type: UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissUploadReferralMultipleDocumentsError() {
  return {
    type: UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_DISMISS_ERROR,
  };
}

export function useUploadReferralMultipleDocuments() {
  const dispatch = useDispatch();

  const { uploadReferralMultipleDocumentsPending, uploadReferralMultipleDocumentsError } = useSelector(
    state => ({
      uploadReferralMultipleDocumentsPending: state.referral.uploadReferralMultipleDocumentsPending,
      uploadReferralMultipleDocumentsError: state.referral.uploadReferralMultipleDocumentsError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((referralUUID, args) => {
    return dispatch(uploadReferralMultipleDocuments(referralUUID, args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissUploadReferralMultipleDocumentsError());
  }, [dispatch]);

  return {
    uploadReferralMultipleDocuments: boundAction,
    uploadReferralMultipleDocumentsPending,
    uploadReferralMultipleDocumentsError,
    dismissUploadReferralMultipleDocumentsError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        uploadReferralMultipleDocumentsPending: true,
        uploadReferralMultipleDocumentsError: null,
      };

    case UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_SUCCESS:
      // The request is success
      return {
        ...state,
        uploadReferralMultipleDocumentsPending: false,
        uploadReferralMultipleDocumentsError: null,
      };

    case UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_FAILURE:
      // The request is failed
      return {
        ...state,
        uploadReferralMultipleDocumentsPending: false,
        uploadReferralMultipleDocumentsError: action.data.error,
      };

    case UPLOAD_REFERRAL_MULTIPLE_DOCUMENTS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        uploadReferralMultipleDocumentsError: null,
      };

    default:
      return state;
  }
}

import { gql } from '@apollo/client';

const SPECIALIZATION_FIELDS = gql`
    fragment SpecializationFields on specializations {
        id
        name
    }
`;

const DOCUMENT_FIELDS = gql`
    fragment DocumentFields on documents {
        id
        name
    }
`;

const CLINIC_FIELDS = gql`
    fragment ClinicFields on Clinic {
      id
      code
      name
      extra_info
      referral_network {
        id
        uuid
        name
      }
      full_address
      address {
        line1
        city
        state
        zip
      }
      specializations: clinic_specializations {
        id
        name
      }
      providers {
        id
        display_name
        specializations {
          id
          name
        }
        documents {
          id 
          required
          document {
            id
            name
          }
          question {
            id
            uuid
            question
          }
        }
      }
      organization {
        id
        name
      }
    }
`;

export {
    SPECIALIZATION_FIELDS,
    DOCUMENT_FIELDS,
    CLINIC_FIELDS
};
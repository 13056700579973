import { combineReducers } from 'redux';
// import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router'
import history from './history';
import homeReducer from '../features/home/redux/reducer';
import commonReducer from '../features/common/redux/reducer';
import authReducer from '../features/auth/redux/reducer';
import dashboardReducer from '../features/dashboard/redux/reducer';
import clinicsReducer from '../features/clinics/redux/reducer';
import referralReducer from '../features/referral/redux/reducer';
import managementReducer from '../features/management/redux/reducer';
import referralsReducer from '../features/referrals/redux/reducer';
import patientReducer from '../features/patient/redux/reducer';
import notificationsReducer from '../features/notifications/redux/reducer';
import userReducer from '../features/user/redux/reducer';
import clinicReducer from '../features/clinic/redux/reducer';
import widgetReducer from '../features/widget/redux/reducer';

// NOTE 1: DO NOT CHANGE the 'reducerMap' name and the declaration pattern.
// This is used for Rekit cmds to register new features, remove features, etc.
// NOTE 2: always use the camel case of the feature folder name as the store branch name
// So that it's easy for others to understand it and Rekit could manage them.

const reducerMap = {
  router: connectRouter(history),
  home: homeReducer,
  common: commonReducer,
  auth: authReducer,
  dashboard: dashboardReducer,
  clinics: clinicsReducer,
  referral: referralReducer,
  management: managementReducer,
  referrals: referralsReducer,
  patient: patientReducer,
  notifications: notificationsReducer,
  user: userReducer,
  clinic: clinicReducer,
  widget: widgetReducer,
};

export default combineReducers(reducerMap);

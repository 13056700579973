import React, {useState, useEffect} from 'react';
import { useQuery } from '@apollo/client';
import { isEmpty, omit } from 'lodash';
import {
    Stack,
} from '@mui/material';
import {default as useQueryParams} from '../common/hooks/useQuery';
import DocumentQuestionsTable from './table/DocumentQuestionsTable';
import DocumentDetails from './details/DocumentDetails';
import PageContent from '../common/MainLayout/Page';
import { DocumentProvider } from './context/DocumentContext';
import { GET_DOCUMENT_QUERY } from './gql/Query';
import clientGraphql from '../../common/apollo-graphql';

function ViewDocumentDetails({formattedData, documentData, loading}) {
    return (
        <Stack direction="column"
               spacing={1}>
            <DocumentDetails data={formattedData} />
            <DocumentQuestionsTable data={documentData}
                              loading={loading} />
        </Stack>
    );
}

export default function ManageDocumentQuestions({match}) {
    const {edit} = useQueryParams(true);

    const [formattedData, setFormattedData] = useState({});
    const [documentData, setDocumentData] = useState({rows: [], count: 0});

    const {id} = match.params;
    const { loading, data, refetch } = useQuery(GET_DOCUMENT_QUERY, {client: clientGraphql, variables: {document_id: id}});

    useEffect(() => {
        if (data) {
            const {document} = data;
            const newData = omit(document, ['id', '__typename', 'question']);

            setFormattedData({
                ...newData,
            });
            if(!isEmpty(data.document.question)){
                // setDocumentData({
                //     rows: data.document.question?.map(item => {
                //         return {
                //             ...item
                //         };
                //     }),
                //     count: data.document.question?.count,
                // });
                setDocumentData({
                    rows: [{...data.document.question}],
                    count: 1,
                });
            } else {
                setDocumentData({rows: [], count: 0});
            }
            
        }

        return () => {
            setFormattedData({});
        };
    }, [data]);

    return (
        <PageContent contentClass="management-manage-clinic-details"
                     loading={loading}>
            <DocumentProvider
                value={{
                    id,
                    refetch
                }}>
            {
                edit == 1
                ? null
                // <EditClinic loading={loading}
                //               data={data} />
                : <ViewDocumentDetails loading={loading}
                                     formattedData={formattedData}
                                     documentData={documentData} />
            }
            </DocumentProvider>
        </PageContent>
    );
};

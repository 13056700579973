import React, { useCallback, useEffect, useState } from 'react';
import PageContent from '../common/MainLayout/Page';
import { Stack } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import clientGraphql from '../../common/apollo-graphql';
import { ReferralProvider } from '../referral/context/ReferralContext';
import ManageReferralsTable from './table/ManageReferralsTable';
import { GET_REFERRALS } from '../referrals/gql';
import { isEmpty } from 'lodash';
import moment from 'moment';

function ViewManageReferralsTable({
  referralsData,
  loading,
  handleFilterFaxChanged,
  handleFilterChanged,
}) {
  return (
    <Stack direction="column" spacing={1}>
      <ManageReferralsTable
        data={referralsData}
        loading={loading}
        handleFilterFaxChanged={handleFilterFaxChanged}
        handleFilterChanged={handleFilterChanged}
      />
    </Stack>
  );
}

export default function ManageReferrals({ history }) {
  const { id } = 1;

  const [filterFax, setFilterFax] = useState('all');
  const [referralsData, setReferralsData] = useState({});
  const [patientName, setPatientName] = useState('');
  const [type, setType] = useState('Name');
  const [birthdate, setBirthdate] = useState('');

  const gqlParams = {
    client: clientGraphql,
    variables: {
      patientName: patientName,
      birthdate: birthdate,
      isFax: null,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  };

  const [getReferrals, { loading }] = useLazyQuery(GET_REFERRALS, gqlParams);

  const handleFilterFaxChanged = useCallback(
    e => {
      console.log(e.target.value);
      setFilterFax(e.target.value);
      if (e.target.value !== filterFax) {
        handleRefetch(e.target.value, patientName);
      }
    },
    [setFilterFax, handleRefetch, filterFax, patientName],
  );

  const handleFilterChanged = useCallback(
    e => {
      if (type === 'Name') {
        setPatientName(e.target.value);
        if (e.target.value !== filterFax) {
          handleRefetch(filterFax, e.target.value);
        }
      } else {
        setBirthdate(e.target.value);
      }
    },
    [setPatientName, setBirthdate, type, filterFax, handleRefetch],
  );

  const handleRefetch = useCallback(
    (fax, name) => {
      console.log('handleRefetch');
      var variables = {
        patientName: !isEmpty(name) ? name : patientName,
        birthdate: birthdate,
        isFax: null,
      };
      if (!isEmpty(fax) && fax == 'fax') {
        variables.isFax = true;
      } else if (!isEmpty(fax) && fax == 'none') {
        variables.isFax = false;
      } else if (!isEmpty(fax) && fax == 'all') {
        variables.isFax = null;
      } else if (filterFax === 'fax') {
        variables.isFax = true;
      } else if (filterFax === 'none') {
        variables.isFax = false;
      }
      getReferrals({
        client: clientGraphql,
        variables: variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
      }).then(res => {
        const { data } = res;
        if (!isEmpty(data) && !isEmpty(data.rows)) {
          setReferralsData({
            rows: data.rows.filter(item => !item.status.includes('completed')).map(item => {
              return {
                ...item,
                sent_at: moment(item.sent_at, 'YYYY-MM-DD HH:mm:ss').local(),
                updated_at: moment(item.updated_at, 'YYYY-MM-DD HH:mm:ss').local(),
              };
            }),
            count: data.rows.count,
          });
        }
      });
    },
    [getReferrals, birthdate, patientName, filterFax],
  );

  useEffect(() => {
    if (isEmpty(referralsData)) {
      setReferralsData({ rows: [], count: 0 });
      handleRefetch();
    }
  }, [referralsData, handleRefetch, setReferralsData]);

  return (
    <PageContent contentClass="manage-users">
      <ReferralProvider
        value={{
          id,
          handleRefetch,
        }}
      >
        {
          <ViewManageReferralsTable
            history={history}
            loading={loading}
            referralsData={referralsData}
            handleFilterFaxChanged={handleFilterFaxChanged}
            // handleFilterChanged={handleFilterChanged}
          />
        }
      </ReferralProvider>
    </PageContent>
  );
}

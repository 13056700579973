import React from 'react';
import { IconPencil, IconTrash} from '@tabler/icons';
import {
    Stack,
} from '@mui/material';
import { MyDataGrid, MyIconButton } from '../../common/components';

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        width: 250,
        valueGetter: (params) => {
            return params.row.name;
        },
    },
    {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        width: 200,
        renderCell: (params) => {
            return  (
                <Stack direction="row" spacing={1}>
                    {
                        (
                            params.row.actions.onEdit
                            && typeof params.row.actions.onEdit === 'function'
                        ) &&
                        <MyIconButton
                            label="Edit document"
                            onClick={(e) => {
                                e.stopPropagation();
                                params.row.actions.onEdit(params.row.id);
                            }}>
                            <IconPencil />
                        </MyIconButton>
                    }
                    {
                        (
                            params.row.actions.onDelete
                            && typeof params.row.actions.onDelete === 'function'
                        ) &&
                        <MyIconButton
                            label="Delete document"
                            onClick={(e) => {
                                e.stopPropagation();
                                params.row.actions.onDelete(params.row.id);
                            }}>
                            <IconTrash />
                        </MyIconButton>
                    }
                </Stack>
            );
        }
    },
];

export default function ManageDocumentsTable({data, loading, onAdd, showToolbar=true}) {
    return (
        <MyDataGrid
            onAdd={onAdd}
            loading={loading}
            data={data}
            columns={columns}
            showToolbar={showToolbar}
            getRowId={(row) => {
                return row.id;
            }}
            minTableHeight={600}
        />
    );
}
import React from 'react';
import {
    Grid,
} from '@mui/material';
import {gridSpacing} from '../../../common/themes/constants';
import {HookFormPasswordField} from '../../common/components';
import * as yup from 'yup';

export const initialValues = {
    password: '',
    password_confirmation: '',
};

export const validationSchema = yup.object({
    password: yup
        .string('Enter your password')
        .min(10, 'Password should be of minimum 10 characters length')
        .required('Password is required'),
    password_confirmation: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
});

export default function ActivateUserForm({control, errors}) {
    return (
        <Grid item
              container
              direction="column"
              md={8}
              xs={12}
              spacing={gridSpacing}>
            <Grid item
                  xs={12}>
                <HookFormPasswordField
                    id="password"
                    name="password"
                    placeholder="Enter password"
                    label="Password *"
                    schema="password"
                    control={control}
                    errors={errors}
                    autoFocus
                    fullWidth
                />
            </Grid>

            <Grid item
                  xs={12}>
                <HookFormPasswordField
                    id="password_confirmation"
                    name="password_confirmation"
                    placeholder="Enter confirm your password"
                    label="Password Confirmation *"
                    schema="password_confirmation"
                    control={control}
                    errors={errors}
                    fullWidth
                />
            </Grid>
        </Grid>
    );
}
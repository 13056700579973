import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

export default function MyButton({
    children,
    type='button',
    loading=false,
    variant='outlined',
    color='primary',
    fullWidth=true,
    size='medium',
    ...otherProps
}) {
    return (
        <LoadingButton type={type}
                       loading={loading}
                       variant={variant}
                       color={color}
                       size={size}
                       disableElevation
                       {...otherProps}
                       fullWidth={fullWidth}>{children}</LoadingButton>
    );
}
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ApiService from '../../common/services/ApiService';
import {
  UPLOAD_REFERRAL_DOCUMENTS_BEGIN,
  UPLOAD_REFERRAL_DOCUMENTS_SUCCESS,
  UPLOAD_REFERRAL_DOCUMENTS_FAILURE,
  UPLOAD_REFERRAL_DOCUMENTS_DISMISS_ERROR,
} from './constants';

export function uploadReferralDocuments(referralUUID, args) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: UPLOAD_REFERRAL_DOCUMENTS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.uploadReferralDocuments(referralUUID, args).then(
        (res) => {
          dispatch({
            type: UPLOAD_REFERRAL_DOCUMENTS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: UPLOAD_REFERRAL_DOCUMENTS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissUploadReferralDocumentsError() {
  return {
    type: UPLOAD_REFERRAL_DOCUMENTS_DISMISS_ERROR,
  };
}

export function useUploadReferralDocuments() {
  const dispatch = useDispatch();

  const { uploadReferralDocumentsPending, uploadReferralDocumentsError } = useSelector(
    state => ({
      uploadReferralDocumentsPending: state.referral.uploadReferralDocumentsPending,
      uploadReferralDocumentsError: state.referral.uploadReferralDocumentsError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((referralUUID, args) => {
    return dispatch(uploadReferralDocuments(referralUUID, args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissUploadReferralDocumentsError());
  }, [dispatch]);

  return {
    uploadReferralDocuments: boundAction,
    uploadReferralDocumentsPending,
    uploadReferralDocumentsError,
    dismissUploadReferralDocumentsError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case UPLOAD_REFERRAL_DOCUMENTS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        uploadReferralDocumentsPending: true,
        uploadReferralDocumentsError: null,
      };

    case UPLOAD_REFERRAL_DOCUMENTS_SUCCESS:
      // The request is success
      return {
        ...state,
        uploadReferralDocumentsPending: false,
        uploadReferralDocumentsError: null,
      };

    case UPLOAD_REFERRAL_DOCUMENTS_FAILURE:
      // The request is failed
      return {
        ...state,
        uploadReferralDocumentsPending: false,
        uploadReferralDocumentsError: action.data.error,
      };

    case UPLOAD_REFERRAL_DOCUMENTS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        uploadReferralDocumentsError: null,
      };

    default:
      return state;
  }
}

import React, {useCallback, useMemo, useState} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PageContent from '../../common/MainLayout/Page';
import Swal from 'sweetalert2';
import useParsedTableData from '../../common/hooks/useParsedTableData';
import clientGraphql from '../../../common/apollo-graphql';
import ReferralGroupsTable from './ReferralGroups/ReferralGroupsTable';
import { GET_GROUPS_QUERY } from '../gql/Query';
import { DELETE_REFERRAL_GROUP_MUTATION } from '../gql/Mutation';

export default function ManageReferralGroups({history}) {
  const [
    delete_referral_network,
  ] = useMutation(DELETE_REFERRAL_GROUP_MUTATION, {
    client: clientGraphql,
  });
  const [keyword, setKeyword] = useState("");
  const { loading, data, refetch } = useQuery(GET_GROUPS_QUERY, {
    client: clientGraphql,
    variables: {
      keyword: keyword 
    },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
  });

  const handleOnAdd = useCallback(() => {
    history.push('/manage/referral_network/enroll');
  }, [history]);

  const handleFilterChanged = useCallback(e => {
    setKeyword(e.target.value);
  }, []);

  const handleOnDelete = useCallback((rowId) => {
    Swal.fire({
        html: "You are about to delete the referral group." ,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed){
        delete_referral_network({
          client: clientGraphql,
          variables: {uuid: rowId},
        }).then( res => {
          refetch();
          Swal.fire(
            'Referral Group is Deleted',
            '',
            'success'
        );
        });
      }
      
    });
  }, [delete_referral_network, refetch]);

  const handleOnEdit = useCallback((rowId) => {
    history.push(`/manage/referral_networks/${rowId}?edit=1`);
  }, [history]);

  const handleOnView = useCallback((rowId) => {
      history.push(`/manage/referral_networks/${rowId}`);
  }, [history]);

  const handleOnSelectedRowsChanged = useCallback((rowId) => {
  }, []);

  const actions = useMemo(() => {
      return {onView: handleOnView, onEdit: handleOnEdit, onDelete: handleOnDelete};
  }, [handleOnView, handleOnEdit, handleOnDelete]);

  const {parsedData} = useParsedTableData(data, actions);

  return (
    <PageContent contentClass="clinics-manage-referral-groups">
      <ReferralGroupsTable onFilterChanged={handleFilterChanged}
                    onSelectedRowsChanged={handleOnSelectedRowsChanged}
                    onAdd={handleOnAdd}
                    onDelete={handleOnDelete}
                    onEdit={handleOnEdit}
                    loading={loading}
                    data={parsedData}
                    history={history}
      />
    </PageContent>
  );
};

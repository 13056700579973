import React, { useCallback, useState } from 'react';
import {
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Card,
  CardContent,
} from '@mui/material';
import { MyButton } from '../common/components';
import MyPaperComponent from '../common/components/MyPaperComponent';
import { isEmpty } from 'lodash';
import MyTextField from '../common/components/MyTextField';
import { gridSpacing } from '../../common/themes/constants';

export default function RenameDocDialog({
  openRenameFile,
  handleClose,
  renameDoc,
  setRenameDoc,
  setIsSaveMisc,
}) {
  const [indexFocus, setIndexFocus] = useState(0);

  const updateName = useCallback(() => {
    setIsSaveMisc(true);
    handleClose();
  }, [handleClose, setIsSaveMisc]);

  const setName = useCallback(
    (index, name, event) => {
      var files = [...renameDoc];
      files[index] = {
        ...files[index],
        referral_document_name: name,
      };
      setRenameDoc(files);
      setIndexFocus(index);
    },
    [setRenameDoc, renameDoc, setIndexFocus],
  );

  return (
    <Dialog
      open={openRenameFile}
      onClose={() => {
        console.log('do nothing');
      }}
      PaperComponent={MyPaperComponent}
      aria-labelledby="draggable-dialog-title"
      style={{ minWidth: '800px' }}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Rename File
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Card style={{ padding: '0' }}>
            <CardContent>
              {!isEmpty(renameDoc)
                ? renameDoc.map((uploadedDoc, index) => {
                    return (
                      !('done' in uploadedDoc) && (
                        <Stack direction="row" key={'stack-' + Math.random()} spacing={gridSpacing}>
                          <MyButton
                            style={{ justifyContent: 'left' }}
                            key={'file-down' + index}
                            color="primary"
                            variant="text"
                          >
                            {!isEmpty(uploadedDoc.referral_document_name)
                              ? uploadedDoc.referral_document_name +
                                '.' +
                                uploadedDoc.file.path.split('.').pop()
                              : uploadedDoc.file.path}
                          </MyButton>
                          <MyTextField
                            key={'text-' + index}
                            value={uploadedDoc.referral_document_name}
                            onChange={e => {
                              setName(index, e.target.value, e);
                              e.stopPropagation();
                            }}
                            placeholder="Enter filename"
                            label="Filename *"
                            style={{ minWidth: '600px', marginTop: 20 }}
                            autoFocus={indexFocus === index}
                          />
                        </Stack>
                      )
                    );
                  })
                : null}
            </CardContent>
          </Card>
        </Box>
      </DialogContent>
      <DialogActions>
        <MyButton color="success" variant="contained" onClick={updateName} fullWidth={false}>
          Save
        </MyButton>
      </DialogActions>
    </Dialog>
  );
}

import React, {useState, useEffect} from 'react';
import PageContent from '../../common/MainLayout/Page';
import { useQuery } from '@apollo/client';
import { isEmpty, omit } from 'lodash';
import {
    Stack,
} from '@mui/material';
import User from '../../auth/models/User';
import {default as useQueryParams} from '../../common/hooks/useQuery';
import ClinicDetails from './ClinicDetails/ClinicDetails';
import {ClinicProvider} from '../context/ClinicContext';
import clientGraphql from '../../../common/apollo-graphql';
import EditReferralGroup from './ReferralGroups/EditReferralGroup';
import { GET_REFERRAL_GROUP_QUERY } from '../gql/Query';
import ReferralGroupClinicsTable from './ReferralGroups/ReferralGroupClinicsTable';
import ReferralGroupDetails from './ReferralGroups/ReferralGroupDetails';

function ViewReferralGroupDetails({history, formattedData, clinicData, loading}) {
    return (
        <Stack direction="column"
               spacing={1}>
            <ReferralGroupDetails data={formattedData} />
            <ReferralGroupClinicsTable data={clinicData}
                              loading={loading} history={history} />
        </Stack>
    );
}

export default function ManageReferralGroupDetails({history, match}) {
    const {edit} = useQueryParams(true);

    const [formattedData, setFormattedData] = useState({});
    const [clinicData, setClinicData] = useState({rows: [], count: 0});

    const {id} = match.params;
    const { loading, data, refetch } = useQuery(GET_REFERRAL_GROUP_QUERY, {client: clientGraphql, variables: {referralNetworkUuid: id}});

    useEffect(() => {
        if (data) {
            const {referral_network} = data;
            const newData = omit(referral_network, ['id', '__typename', 'members', 'uuid']);

            setFormattedData({
                ...newData,
            });
            var rows = [];
            referral_network.members.map(item => {
                if(item.member != null){
                    rows.push(item.member);
                }
            })
            
            setClinicData({
                rows: rows,
                count: rows.length,
            });
        }

        return () => {
            setFormattedData({});
        };
    }, [data]);

    return (
        <PageContent contentClass="management-manage-clinic-details"
                     loading={loading}>
            <ClinicProvider
                value={{
                    id,
                    refetch
                }}>
            {
                edit == 1
                ? <EditReferralGroup loading={loading}
                              data={data} />
                : <ViewReferralGroupDetails loading={loading}
                                     formattedData={formattedData}
                                     clinicData={clinicData}
                                     history={history} />
            }
            </ClinicProvider>
        </PageContent>
    );
};

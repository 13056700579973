import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import {
    Box,
    Stack,
    Paper,
    InputBase,
    IconButton,
    LinearProgress
} from '@mui/material';
import MyButton from './MyButton';
import { IconPlus, IconPencil, IconTrash, IconSearch } from '@tabler/icons';
import ReferralTypeOptions from '../../referral/tables/ReferralTypeOptions';

const PAGE_SIZE_OPTIONS = [15, 25, 50];

function CustomizedInputBase({onChange, onChangeDropdown, selections}) {
    return (
        <Paper
            component="form"
            elevation={1}
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
        >
            {/* {
                (onChangeDropdown && typeof onChangeDropdown === 'function') &&
                <FormControl style={{width: '130px'}}>
                    <InputLabel id="demo-simple-select-label">Search By</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={'Name'}
                        label="Search"
                        onChange={onChangeDropdown}
                    >
                        <MenuItem value={'Name'}>Name</MenuItem>
                        <MenuItem value={'Birthdate'}>Birthdate</MenuItem>
                    </Select>
                </FormControl>
            } */}
            
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                onChange={onChange}
                placeholder={ (onChangeDropdown && typeof onChangeDropdown === 'function') ? "Search by Name":"Search..."}
                inputProps={{ 'aria-label': 'search' }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <IconSearch />
            </IconButton>
        </Paper>
    );
}

function EditToolbar(props) {
    const { onDelete, onEdit } = props;

    return (
            <Stack direction="row"
                   justifyContent="flex-end"
                   alignItems="center"
                   spacing={1}
                   sx={{width: '100%'}}>
                {
                    (onDelete && typeof onDelete === 'function') &&
                    <MyButton color="error"
                              variant="contained"
                              startIcon={<IconTrash />}
                              onClick={onDelete}
                              fullWidth={false}>
                        Delete
                    </MyButton>
                }
                {
                    (onEdit && typeof onEdit === 'function') &&
                    <MyButton variant="contained"
                              startIcon={<IconPencil/>}
                              onClick={onEdit}
                              fullWidth={false}>
                        Edit
                    </MyButton>
                }
            </Stack>
    );
}

function MainToolbar(props) {
    const { onAdd, addButtonText, onFilterChanged, onFilterFaxChanged, selections, onChangeDropdown } = props;

    return (
        <Stack direction="row"
               justifyContent="space-between"
               alignItems="center"
               spacing={1}
               sx={{width: '100%'}}>
            {
                (onFilterChanged && typeof onFilterChanged === 'function') &&
                <CustomizedInputBase onChange={onFilterChanged} onChangeDropdown={onChangeDropdown} selections={selections} />
            }

            {
                (onFilterFaxChanged && typeof onFilterFaxChanged === 'function') &&
                <ReferralTypeOptions onChange={onFilterFaxChanged}/>
                // <FormControlLabel
                //     key={`control.fax.filter`}
                //     control={
                //       <Switch
                //         key={`switch.fax.filter`}
                //         onChange={onFilterFaxChanged}
                //         inputProps={{ 'aria-label': 'controlled' }}
                //       />
                //     }
                //     label={"View FAX only"}
                //   />
            }

            
            {
                (onAdd && typeof onAdd === 'function') &&
                <MyButton color="primary"
                          variant="contained"
                          startIcon={<IconPlus/>}
                          onClick={onAdd}
                          fullWidth={false}>
                    {addButtonText}
                </MyButton>
            }
        </Stack>
    );
}

function TableToolbarContainer({selectionMode, ...otherProps}) {
    return (
        <GridToolbarContainer
            sx={{
                bgcolor: selectionMode ? 'grey.500' : '#fff',
                width: '100%',
                p: 2,
            }}>
            {
                selectionMode
                ?
                  <EditToolbar {...otherProps} />
                :
                  <MainToolbar {...otherProps} />
            }
        </GridToolbarContainer>
    );
}

export default function MyDataGrid({
    loading,
    data,
    columns,
    selections=[],
    onChangeDropdown=null,
    onFilterChanged=null,
    onFilterFaxChanged=null,
    onAdd=null,
    onDelete=null,
    onEdit=null,
    onSelectedRowsChanged=null,
    showToolbar=true,
    hideFooter=false,
    hideFooterPagination=false,
    minTableHeight=400,
    onClick=null,
    height='100%',
    addButtonText='New',
    ...otherProps
}) {
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectionMode, setSelectionMode] = useState(false);
    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(0);

    const {rows, count} = data;

    useEffect(() => {
        if (onSelectedRowsChanged) {
            onSelectedRowsChanged(selectionModel);
            setSelectionMode(selectionModel.length > 0);
        }
    }, [selectionModel, onSelectedRowsChanged]);

    const _onEdit =  useCallback(() => {
        onEdit(selectionModel)
    }, [onEdit, selectionModel]);

    const _onDelete = useCallback(() => {
        onDelete(selectionModel)
    }, [onDelete, selectionModel]);

    return (
        <Box style={{ display: 'flex', flex: 1, flexDirection: 'column', height: height, minHeight: minTableHeight, width: '100%' }}>
            <DataGrid

                sx={{
                    '& .MuiDataGrid-cellContent': {
                    padding: '16px 8px',
                    }
                }}

                {...otherProps}

                // autoPageSize
                hideFooter={hideFooter}
                hideFooterPagination={hideFooterPagination}
                initialState={{
                    pinnedColumns: { right: ['actions'] },
                    columnMenu: {
                        onAdd,
                        onFilterChanged,
                        onFilterFaxChanged,
                        onDelete: onDelete ? _onDelete : onDelete,
                        onEdit: onEdit ? _onEdit : onEdit,
                    }
                }}
                checkboxSelection={false}
                disableSelectionOnClick

                showCellRightBorder={false}
                showCellLeftBorder={false}
                loading={loading}

                rowCount={count}
                rows={rows}
                columns={columns}
                onRowClick={onClick}
                page={page}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onPageChange={(newPage) => setPage(newPage)}
                rowsPerPageOptions={PAGE_SIZE_OPTIONS}
                pagination

                components={{
                    LoadingOverlay: LinearProgress,
                    Toolbar: showToolbar ? TableToolbarContainer : null,
                    ColumnResizeIcon: () => null,
                }}
                componentsProps={{
                    toolbar: {
                        selectionMode: selectionMode,
                        setSelectionMode: setSelectionMode,

                        onAdd,
                        addButtonText,
                        onChangeDropdown,
                        onFilterChanged,
                        onFilterFaxChanged
                        // onDelete: onDelete ? _onDelete : onDelete,
                        // onEdit: onEdit ? _onEdit : onEdit,
                    },
                }}
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                getRowHeight={() => 'auto'}
            />
        </Box>
    );
}
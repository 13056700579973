import { Box, Card, CardContent, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { MyButton, MyTypography } from '../common/components';
import { isEmpty } from 'lodash';
import PublicReferralAdditionalDocuments from './PublicReferralAdditionalDocuments';
import { useUploadReferralDocuments } from '../referral/redux/uploadReferralDocuments';
import Swal from 'sweetalert2';
import { gridSpacing } from '../../common/themes/constants';
export default function PublicUploadReferralDocuments({ uuid, parsedData, setOpenDialog, setIsRefresh, uploadedFileObjects={}, data_referral_answers }) {
  const handleClose = useCallback(() => {
    setOpenDialog(false);
    setIsRefresh(true);
}, [setIsRefresh, setOpenDialog]);
  const [fileNames, setFileNames] = useState({});
  const [documentParsedData, setDocumentParsedData] = useState({});
  const [fileObjects, setFileObjects] = useState({});
  const [fileObjectsRequired, setFileObjectsRequired] = useState({});
  const { uploadReferralDocuments, uploadReferralDocumentsPending } = useUploadReferralDocuments();

  const uploadFiles = useCallback( (uuid) => {
    var keys = Object.keys(fileObjects);
    var docIdList = [];
    var miscDocId = -1;
    documentParsedData.clinic.documents.map(doc => {
      docIdList.push(doc.document.id);
    });
    documentParsedData.clinic.optionalDocuments.map(doc => {
      if (doc.document.name === 'misc') {
        miscDocId = doc.document.id;
      }
    });

    for (let key in fileObjects) {
      if (fileObjects.hasOwnProperty(key)) {
        if (!isEmpty(fileObjects[key])) {
          const formData = new FormData();
          fileObjects[key].map(file => {
            formData.append('files[]', file.file);
          });
          formData.append(
            'document_id',
            docIdList.includes(key) ? Number(key) : Number(miscDocId),
          );
          formData.append('type', 'external');
          uploadReferralDocuments(uuid, formData)
            .then(data => {
                Swal.fire({
                  icon: 'success',
                  text: 'Successfully uploaded files.',
                  showConfirmButton: true,
                }).then(() => {
                    var temp = fileObjects;
                    temp[key] = [];
                    setFileObjects(temp);
                    setIsRefresh(true);
                });
            })
            .catch(err => {
              Swal.fire({
                icon: 'error',
                text: 'Failed to submit referral documents.',
                showConfirmButton: true,
              });
            });
        }
      }
    }
  },[uploadReferralDocuments, documentParsedData , fileObjects, setIsRefresh]);

  useEffect(() => {
    if (isEmpty(documentParsedData) && !isEmpty(parsedData)) {
      setDocumentParsedData({
        clinic: {
          provider_id: parsedData.provider.id,
          provider_name: parsedData.provider.display_name,
          name: parsedData.receiver.name,
          id: parsedData.receiver.id,
          address: parsedData.receiver.full_address,
          documents: parsedData.provider.documents.filter(doc => {
            return doc.required;
          }),
          optionalDocuments: parsedData.provider.documents.filter(doc => {
            return !doc.required;
          }),
        },
      });
    }
  }, [setDocumentParsedData, documentParsedData, parsedData]);

  const onFileObjectChanged = useCallback(
    (fileList, name, showAlert) => {
      var temp = fileObjects;
      temp = { ...fileList };
      setFileObjects(temp);
      uploadFiles(uuid);
    },
    [setFileObjects, fileObjects, uploadFiles, uuid],
  );

  return (
    <>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Upload Documents
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Card style={{ padding: '0' }}>
            <CardContent>
            {uploadReferralDocumentsPending ? (
            <Grid container spacing={gridSpacing} key={'public-referral--uploading' + Math.random()}>
              <Grid item sm={3} xs={12}>
                <MyTypography variant="h4">Uploading Files</MyTypography>
              </Grid>
              <Grid item sm={12} xs={12}>
                <LinearProgress />
              </Grid>
            </Grid>
          ) : null}
              {!isEmpty(documentParsedData) ? (
                <PublicReferralAdditionalDocuments
                  parsedData={documentParsedData}
                  fileNames={fileNames}
                  setFileNames={setFileNames}
                  fileObjects={fileObjects}
                  setFileObjects={setFileObjects}
                  fileObjectsRequired={fileObjectsRequired}
                  setFileObjectsRequired={setFileObjectsRequired}
                  onFileObjectChanged={onFileObjectChanged}
                  uploadedFileObjects={uploadedFileObjects}
                  data_referral_answers={data_referral_answers}
                />
              ) : null}
            </CardContent>
          </Card>
        </Box>
      </DialogContent>
      <DialogActions>
        <MyButton color="primary" variant="outlined" onClick={handleClose} fullWidth={false}>
          Done
        </MyButton>
      </DialogActions>
    </>
  );
}

import React from 'react';
import { Box } from '@mui/material';


const iframeUrl = 'https://ben4950.wixsite.com/ziprefer';

export default function WelcomePage() {
  return (
      <Box className="container"
           id="iframe-container">
        <iframe frameBorder="0"
                src={iframeUrl}></iframe>
      </Box>
  );
}

import React, {useState, useEffect, useCallback, useContext} from 'react';
import {useMutation, useSubscription} from '@apollo/client';
import ApiService from '../../common/services/ApiService';
import {useHistory} from 'react-router-dom';
import CONFIG from '../../../config';
import {unreadNotificationsFilter, usersNotificationsFilter} from '../gql/Filter';
import {
    unreadNotificationsAggregateSubscription,
    currentNotificationsSubscription
} from '../gql/Subscription';
import MyNotification from '../models/Notification';
import clientGraphql from '../../../common/apollo-graphql';
import { MARK_ALL_AS_READ_NOTIFICATIONS } from '../../management/gql/Query';
import AuthContext from '../../auth/context/AuthContext';
import { isEmpty } from 'lodash';

const NOTIFICATION_LIMIT = CONFIG.NOTIFICATION_LIMIT || 100;

export default function useGetNotifications(user) {
    const [count, setCount] = useState(0);
    const [notifications, setNotifications] = useState([]);

    const history = useHistory();
    const {id} = user;
    const [markAllAsReadNotifications] = useMutation(
        MARK_ALL_AS_READ_NOTIFICATIONS,{
            client: clientGraphql
        }
    );

    // get the current user's notification items
    const { data, loading } = useSubscription(currentNotificationsSubscription, {variables: {limit: parseInt(NOTIFICATION_LIMIT), filter: usersNotificationsFilter(id)}});
    // get the current user's total count of unread notifications
    const { data: dataAggr } = useSubscription(unreadNotificationsAggregateSubscription, {variables: {filter: unreadNotificationsFilter(id)}});

    const handleNotificationOnRead = useCallback((notification) => {
        ApiService.readNotification(notification.uuid)
            .finally(() => {
                if (notification.url.includes('http')) {
                    window.location.href = notification.url;
                } else {
                    history.push(notification.url);
                }
            });
    }, [history]);

    const handleMarkAllReadNotification = useCallback((user_uuid) => {
        markAllAsReadNotifications({
            client: clientGraphql,
            variables: {
                uuid: user_uuid
            }
        });
        
    }, [markAllAsReadNotifications]);

    useEffect(() => {
        if (data && data.items) {
            var notifications = [];
            var items = data.items.filter(item => {
                const contentData = JSON.parse(item.data);
                const {title, clinic_receiver_id} = contentData;
                return item.read_at == null && (contentData.clinic_receiver_id === null 
                        || (!isEmpty(user) 
                                && !isEmpty(user.clinics)
                                && user.clinics[0].id === clinic_receiver_id));
            });
            items.map(item => {
                notifications.push({
                    model: new MyNotification(item),
                });
            });
            setNotifications(notifications);
        } else {
            setNotifications([]);
        }
    }, [data, user]);

    useEffect(() => {
        if (dataAggr && dataAggr.aggregate) {
            var count = 0;
            if(!isEmpty(notifications)) {
                notifications.map(item => {
                    if(item.model.item.read_at == null){
                        count++;
                    }

                });
            }
            setCount(count);
        } else {
            setCount(0);
        }
    }, [dataAggr, notifications]);

    return {
        notifications,
        count,
        loading,
        onRead: handleNotificationOnRead,
        onMarkAllReadNotification: handleMarkAllReadNotification,
    };
}
import React from 'react';
import { isEmpty } from 'lodash';
import SpecializationList from './SpecializationList';
import { IconClock, IconStar } from '@tabler/icons';
import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';

const columns = [
  {
    field: 'provider',
    headerName: 'Provider',
    width: 200,
    valueGetter: params => {
      return params.row.display_name;
    },
  },
  {
    field: 'name',
    headerName: 'Clinic Name',
    width: 300,
    valueGetter: params => {
      const clinicName = !isEmpty(params.row.clinic) ? params.row.clinic.name : '';
      return clinicName;
    },
  },
  {
    field: 'full_address',
    headerName: 'Address',
    width: 300,
    valueGetter: params => {
      return params.row.clinic.full_address;
    },
  },
  {
    field: 'specialization',
    headerName: 'Specialization',
    sortable: false,
    width: 160,
    valueGetter: params => {
      const specializations = params.row.specializations.map(item => item.name);
      return specializations.join(', ');
    },
    renderCell: params => {
      return <SpecializationList params={params} />;
    },
  },
];

export default function FindClinicFaveRecentTable({ label, data, onClick }) {
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography gutterBottom variant="h4">
          {label}:
        </Typography>
        <List>
          {data.rows.map(provider => {
            return (
              <>
                <ListItem
                  key={provider.id}
                  onClick={() => {
                    onClick({ row: provider });
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#6dd2bf',
                      color: 'white',
                      '& .MuiListItemIcon-root': {
                        color: 'white',
                      },
                    },
                  }}
                >
                  <ListItemAvatar>
                    <Avatar style={{ width: 24, height: 24 }} sx={{ width: 24, height: 24 }}>
                      {label === 'Favorites' ? (
                        <IconStar
                          style={{ width: 16, height: 16 }}
                          sx={{ width: 16, height: 16 }}
                        />
                      ) : (
                        <IconClock
                          style={{ width: 16, height: 16 }}
                          sx={{ width: 16, height: 16 }}
                        />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={provider.display_name + ' - ' + provider.clinic.name} />
                </ListItem>
                <Divider />
              </>
            );
          })}
          {isEmpty(data.rows) && (
            <ListItem key={'empty'}>
              <ListItemText primary={'Empty ' + label} />
            </ListItem>
          )}
        </List>
      </Grid>
    </Grid>
  );
}

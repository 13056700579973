import React from 'react';
import { MenuItem } from '@mui/material';
import { capitalize } from 'lodash';
import HookFormAutocompleteSingle from '../../common/components/hook-form/HookFormAutocompleteSingle';

export default function UserOptions({ prefix, control, errors, schema, placeholder, label, data=null }) {
    return (
      
      <HookFormAutocompleteSingle
        id={schema}
        name={schema}
        placeholder={placeholder}
        label={label}
        schema={schema}
        prefix={prefix}
        control={control}
        errors={errors}
        autocompleteProps={{
          freeSolo: true,
          fullWidth: true
        }}
        options={
          data && data.hasOwnProperty('rows')
            ? data.rows.map(i => ({ value:  i.email, label: capitalize(i.firstname + " " + i.lastname + " - " + i.email) }))
            : []
        }
        getOptionLabel={option => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.value) {
            return option.label;
          }
          // Regular option
          return option.label;
        }}
        renderOption={(props, option) => (
          <MenuItem key={option.value} {...props}>
            {capitalize(option.label)}
          </MenuItem>
        )}
      />
    );
  }
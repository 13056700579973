import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    useMediaQuery,
    Stack,
    Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    HookFormTextField,
    MyButton,
    MyTypography,
    MyAlert,
} from '../common/components';
import { useHistory } from 'react-router-dom';
import AuthPageContainer from './AuthPageContainer';
import { useForgotPassword } from './redux/forgotPassword';
import Swal from 'sweetalert2';

const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required')
});

export default function ForgotPassword() {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();

  const {forgotPassword, forgotPasswordError, dismissForgotPasswordError, forgotPasswordPending} = useForgotPassword();

  const cancel = () => {
    window.location.href = '/login';
};

  const { handleSubmit, control, setValue, formState: {errors}, reset } = useForm({
      defaultValues: {
          email: ''
      },
      resolver: yupResolver(validationSchema),
      shouldFocusError: true,
  });

  const onSubmit = (data) => {
    dismissForgotPasswordError();
    forgotPassword(data).then((response)=>{
      Swal.fire({
        icon: 'success',
        text: response.status,
        showConfirmButton: true,
      }).then(() => {
        window.location.href = '/login';
      });
    });
  };

  return (
    <AuthPageContainer>
        <Stack
            direction={matchDownSM ? 'column-reverse' : 'row'}
            alignItems="center"
            justifyContent="center"
        >
            <Box>
                    <Stack alignItems="center" justifyContent="center" spacing={1}>
                        <MyTypography color={theme.palette.secondary.main}
                                      gutterBottom
                                      variant={matchDownSM ? 'h3' : 'h2'}>
                            Forgot Password
                        </MyTypography>
                        <MyTypography
                            variant="caption"
                            fontSize="14px"
                            textAlign={matchDownSM ? 'center' : 'inherit'}
                        >
                            Enter the email address associated with your account and we will send you a link to reset your password.
                        </MyTypography>
                    </Stack>
                </Box>
        </Stack>

        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack className="forgot-password"
                   direction="column"
                   spacing={2}
                   sx={{
                       px: matchDownSM ? 1 : 3,
                       py: 4,
                   }}>
                {
                    forgotPasswordError &&
                    <MyAlert severity="error"
                             sx={{mb: 2}}>{forgotPasswordError.message}</MyAlert>
                }

                <HookFormTextField
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter email address"
                    label="Email address *"
                    autoComplete="email"
                    schema="email"
                    control={control}
                    errors={errors}
                    autoFocus
                    fullWidth
                />

                <MyButton type="submit"
                          variant="contained"
                          size="large"
                          loading={forgotPasswordPending}
                          disableElevation>Submit</MyButton>
                <MyButton variant="standard"
                              color="secondary"
                              size="large"
                              onClick={cancel}
                              disableElevation>Back to Login</MyButton>
            </Stack>
        </form>
    </AuthPageContainer>
  );
};
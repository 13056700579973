import ls from 'localstorage-slim';

const DEFAULT_SESSION_STORAGE_TTL_IN_SECONDS = 60 * 60 * 24;

ls.config.encrypt = true;
// ls.config.secret = 'my-scret-key';

export default class SessionStorage {

    static get(key) {
        return ls.get(key);
    }

    static set(key, value, options={ttl: DEFAULT_SESSION_STORAGE_TTL_IN_SECONDS}) {
        return ls.set(key, value, options);
    }

    static remove(key) {
        return ls.remove(key);
    }

    static flush(force=false) {
        return ls.flush(force);
    }

    static clear() {
        return ls.clear();
    }
}

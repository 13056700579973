import React, { useCallback, useState } from 'react';
import {
    Stack,
    Grid,
    Chip,
    IconButton,
    Box,
} from '@mui/material';
import {MyTypography} from '../../../common/components';
import StringUtils from '../../../common/services/StringUtils';
import {IconCopy, IconPencil} from '@tabler/icons';
import {capitalize, isEmpty, isObject} from 'lodash';

export function ReviewItem({label, value}) {
    const [ishover, setIsHover] = useState(false);
    const copyToClipboard =  useCallback(
        (event, copyMe) => {
            var TempText = document.createElement("input");
            TempText.value = copyMe;
            document.body.appendChild(TempText);
            TempText.select();
            
            document.execCommand("copy");
            document.body.removeChild(TempText);
        },
        [],
      );
    return (
        <Grid item
              md={label === 'Permission' || (label === 'Comment' && !isEmpty(value) && value.length > 40) ? 12:6}
              xs={12}>
            <Stack direction="column" sx={{py: 1}}>
                <MyTypography variant="caption" sx={{fontWeight: 300}}>{label}</MyTypography>
                <Stack spacing={1}
                               direction="row"
                               key={"row-item" + label}
                               sx={{py: 1}}>
                {
                    Array.isArray(value)
                    ?
                        
                                value.map((val,index) => {
                                    return (
                                        <Chip label={capitalize(isObject(val) && val.hasOwnProperty('name') ? val.name : (isObject(val) && val.hasOwnProperty('document') ? val.document:val))} size="small" color="secondary" variant="outlined" key={"row-chip-item-" + label + "-" + index}/>
                                    );
                                })
                            
                    :   (
                            isObject(value)
                            ?
                                (<><MyTypography variant="subtitle1" style={{width: '250px'}}>{value.name}</MyTypography>
                                <IconCopy key={"copy"+Math.random()} style={{ cursor: 'pointer', height: '20px', width: '20px', color: ishover ? "#FF494999":'#001100' }} 
                                onMouseLeave={(e) => {setIsHover(false);}} onMouseEnter={(e) => {setIsHover(true);}}
                            onClick={(e) => {
                                copyToClipboard(e, value.name);
                             }} />
                                </>)
                            :
                            (<><MyTypography variant="subtitle1" 
                            style={(label === 'Comment' && !isEmpty(value) && value.length > 40) ? {width: 'calc(100% - 50px)'}:{width: '250px'}}
                            >{value}</MyTypography>
                            <IconCopy key={"copy"+Math.random()} style={{ cursor: 'pointer', height: '20px', width: '20px', color: ishover ? "#FF494999":'#001100' }} 
                                onMouseLeave={(e) => {setIsHover(false);}} onMouseEnter={(e) => {setIsHover(true);}}
                            onClick={(e) => {
                                copyToClipboard(e, value);
                             }} /></>)
                        )
                }
                
                
                
                </Stack>
            </Stack>
        </Grid>
    );
}

export default function ReviewCardGroup({title, data, onEditGroup=null}) {
    return (
        <Grid item
              direction="column"
              container>
            <Grid item
                  md={6}
                  xs={12}>
                <Stack direction="row">
                    <MyTypography variant="h4">{title}</MyTypography>
                    {
                        (onEditGroup && typeof onEditGroup === 'function') &&
                        <>
                            <Box sx={{flexGrow: 1}} />
                            <IconButton color="primary" onClick={onEditGroup}>
                                <IconPencil />
                            </IconButton>
                        </>
                    }
                </Stack>
            </Grid>
            <Grid item
                  container
                  xs={12}>
                {
                   !isEmpty(data) && Object.entries(data).map((obj) => {
                        const [key, value] = obj;
                        return StringUtils.isJsonString(value) && !isEmpty(JSON.parse(JSON.parse(value))) ? Object.entries(JSON.parse(JSON.parse(value))).map((obj_2) => {
                            const [key, value] = obj_2;
                            return (
                                <ReviewItem label={StringUtils.toTitleCase(key)} value={value} key={key}/>
                            );
                        }):
                        (
                            <ReviewItem label={StringUtils.toTitleCase(key)} value={value} key={key}/>
                        );
                    })
                }
            </Grid>
        </Grid>
    );
}

import React, { useCallback } from 'react';
import * as yup from 'yup';
import { gridSpacing } from '../../../../common/themes/constants';
import { Box, Divider, FormControlLabel, Grid, IconButton, Stack, Switch } from '@mui/material';
import { HookFormTextField, MyButton } from '../../../common/components';
import SpecializationOptions from '../../../auth/RegisterUser/SpecializationOptions';
import DocumentOptions from '../../../auth/RegisterUser/DocumentOptions';
import { useFieldArray } from 'react-hook-form';
import CreateOptionalDocumentForm, {
  initialValues as createOptionalDocumentInitialValues,
  createOptionalDocumentSchema,
} from './CreateOptionalDocumentForm';
import { IconX } from '@tabler/icons';
import { isEmpty } from 'lodash';
import ProviderTitleOptions from '../../../auth/AddUser/ProviderTitleOptions';

export const initialValues = {
  title: 'dr',
  firstname: '',
  lastname: '',
  can_receive: true,
  specializations: [],
  required_documents: [],
  optional_documents: [],
};

export const createProviderValidationSchema = yup.object().shape({
  title: yup.string().required('Title'),
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  can_receive: yup.boolean(),
  specializations: yup
    .array()
    .min(1, 'add atleast 1 specialization')
    .of(yup.string())
    .required('Specializations is required'),
  required_documents: yup.array().of(yup.string()),
  optional_documents: yup.array().of(createOptionalDocumentSchema),
});

export default function CreateProviderForm({ control, errors, prefix = null, setValue, trigger, getValues }) {
  const {
    fields: fieldsOptionalDocument,
    append: appendOptionalDocument,
    remove: removeOptionalDocument,
  } = useFieldArray({
    control,
    name: !isEmpty(prefix) ? prefix + '.optional_documents' : 'optional_documents',
  });

  const handleChange = useCallback(
    (value) => {
      setValue((!isEmpty(prefix) ? (prefix + '.'):'')+ 'can_receive',value);
      trigger((!isEmpty(prefix) ? (prefix + '.'):'')+ 'can_receive');
    },
    [setValue, trigger, prefix],
  );

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item sm={12} xs={12}>
        <ProviderTitleOptions prefix={prefix} control={control} errors={errors} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <HookFormTextField
          id="firstname"
          name="firstname"
          placeholder="Enter First name"
          label="First name *"
          schema="firstname"
          prefix={prefix}
          control={control}
          errors={errors}
          autoFocus
          fullWidth
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <HookFormTextField
          id="lastname"
          name="lastname"
          placeholder="Enter Last name"
          label="Last name *"
          schema="lastname"
          prefix={prefix}
          control={control}
          errors={errors}
          fullWidth
        />
      </Grid>

      <Grid item sm={12} xs={12}>
        <FormControlLabel
          key={`control.can_receive`}
          control={
            <Switch
              checked={(getValues((!isEmpty(prefix) ? (prefix + '.'):'')+ 'can_receive')) }
              key={`switch.can_receive`}
              onChange={event => handleChange(event.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={"Can Receive Referral"}
        />
      </Grid>

      <Grid item sm={12} xs={12}>
        <SpecializationOptions prefix={prefix} control={control} errors={errors} />
      </Grid>

      <Grid item xs={12}>
        <DocumentOptions
          prefix={prefix}
          control={control}
          errors={errors}
          skip="misc"
          schema="required_documents"
          placeholder="Enter the required referral documents below"
          label="Required Documents"
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column">
          {fieldsOptionalDocument.map((_item, index) => (
            <Box key={`box-doc-${index}`}>
              <Stack
                key={`stack-users-${index}`}
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ pt: 2 }}
                style={{ marginBottom: '15px' }}
              >
                <CreateOptionalDocumentForm
                  key={`optional_documents.${index}`}
                  name={`optional_documents.${index}`}
                  prefix={
                    !isEmpty(prefix)
                      ? `${prefix}.optional_documents.${index}`
                      : `optional_documents.${index}`
                  }
                  control={control}
                  errors={errors}
                />
                <IconButton
                  color="gray"
                  onClick={() => {
                    removeOptionalDocument(index);
                  }}
                >
                  <IconX />
                </IconButton>
              </Stack>
              <Divider key={`divider.optional_documents.${index}`} sx={{ my: 1.5 }} />
            </Box>
          ))}
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <MyButton
          type="button"
          variant="contained"
          style={{ width: '200px' }}
          onClick={() => {
            appendOptionalDocument({ ...createOptionalDocumentInitialValues });
          }}
          fullWidth={false}
        >
          Add Optional Document
        </MyButton>
      </Grid>
    </Grid>
  );
}

import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Box, Grid, Stack, Divider } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MyButton, MyTypography } from '../../common/components';
import { MyStepperHeader } from '../../common/components/MyStepper';
import CreateNonAffiliatedClinicForm, {
  createClinicValidationSchema,
  initialValues as createClinicInitialValues,
} from './CreateClinic/CreateNonAffiliatedClinicForm';
import CreateProviderForm, {
  createProviderValidationSchema,
  initialValues as createProviderInitialValues,
} from './CreateProvider/CreateProviderForm';
import { gridSpacing } from '../../../common/themes/constants';
import Swal from 'sweetalert2';
import { isEmpty, omit } from 'lodash';
import PageContent from '../../common/MainLayout/Page';
import BackendErrorHandler from '../../common/components/hook-form/BackendErrorHandler';
import Error from '../../common/Error';
import clientGraphql from '../../../common/apollo-graphql';
import { useMutation } from '@apollo/client';
import { SAVE_NON_AFFILIATED_CLINIC_MUTATION } from '../gql/Mutation';

import EnrollPsrReviewInfo from './EnrollPsrReviewInfo';
import AuthContext from '../../auth/context/AuthContext';

const validationSchema = yup.object().shape({
  clinic: createClinicValidationSchema,
  providers: yup.array().of(createProviderValidationSchema),
});

const initialValues = {
  clinic: {
    ...createClinicInitialValues,
  },
  providers: [],
};

const stepsIndex = {
  clinic: 0,
  provider: 1,
  review: 2,
};

const steps = [
  {
    label: 'Add Clinic',
  },
  {
    label: 'Add Providers',
  },
  {
    label: 'Review',
  },
];

export default function EnrollNonAffiliateClinic({ history }) {
  const [activeStep, setActiveStep] = useState(stepsIndex.clinic);

  const currentUser = useContext(AuthContext);
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isValid, dirtyFields },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    shouldFocusError: true,
    mode: 'all',
    reValidateMode: 'onSubmit',
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'providers',
  });

  const handleNext = () => setActiveStep(prevActiveStep => prevActiveStep + 1);
  const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1);

  const handleOnEditClinic = useCallback(() => {
    setActiveStep(stepsIndex.clinic);
  }, []);

  const handleOnEditProvider = useCallback(() => {
    setActiveStep(stepsIndex.provider);
  }, []);

  const [
    save_clinic,
    { data: saveClinicData, loading: saveClinicLoading, error: saveClinicError },
  ] = useMutation(SAVE_NON_AFFILIATED_CLINIC_MUTATION, {
    client: clientGraphql,
  });

  const hasEmptyProviderField = useCallback(
    (_dirty, _values) => {
      var providers = getValues('providers');
      var hasEmpty = false;
      providers.map(provider => {
        if (
          isEmpty(provider.title) ||
          isEmpty(provider.firstname) ||
          isEmpty(provider.lastname) ||
          isEmpty(provider.specializations)
        ) {
          hasEmpty = true;
        }
      });
      return activeStep === stepsIndex.provider && hasEmpty;
    },
    [getValues, activeStep],
  );

  const hasEmptyClinicField = useCallback(
    (_dirty, _values) => {
      return (
        (activeStep === stepsIndex.clinic &&
          (isEmpty(getValues('clinic.name')) ||
            isEmpty(getValues('clinic.office_phone')) ||
            isEmpty(getValues('clinic.fax')) ||
            isEmpty(getValues('clinic.address_line1')) ||
            isEmpty(getValues('clinic.address_city')) ||
            isEmpty(getValues('clinic.address_state')))) ||
        isEmpty(getValues('clinic.address_zip'))
      );
    },
    [getValues, activeStep],
  );

  const onSubmit = data => {
    var newData = {
      clinic: JSON.parse(JSON.stringify(data.clinic))
    };

    newData.clinic['providers'] = JSON.parse(JSON.stringify(data.providers));
    newData.clinic['extra_info'] = JSON.stringify({
      office_phone: newData.clinic['office_phone'],
      fax: newData.clinic['fax'],
    });
    newData.clinic['creator_id'] = currentUser.id;
    newData.clinic['creator_clinic_id'] = currentUser.clinics[0].id;
    newData.clinic['users'] = [];
    data.providers.map((provider, index) => {
      if (
        provider.optional_documents.length === 0 ||
        provider.optional_documents.filter(e => e.document === 'misc').length <= 0
      ) {
        newData.clinic['providers'][index]['optional_documents'].push({
          document: 'misc',
          question: '',
        });
      }
    });

    newData.clinic = omit(newData.clinic, ['office_phone', 'fax']);
    save_clinic({
      client: clientGraphql,
      variables: newData,
    }).then(res => {});
  };

  useEffect(() => {
    if (isEmpty(getValues('providers'))) {
      append({ ...createProviderInitialValues });
    }
  }, [append, getValues]);

  useEffect(() => {
    if (!isEmpty(saveClinicError)) {
      const errors = !isEmpty(saveClinicError.graphQLErrors)
        ? saveClinicError.graphQLErrors[0]
        : {};
      if (!isEmpty(errors.clinic)) {
        handleOnEditClinic();
      } else if (!isEmpty(errors.provider)) {
        handleOnEditProvider();
      } else if (
        !isEmpty(errors.extensions) &&
        !isEmpty(errors.extensions.validation) &&
        JSON.stringify(errors.extensions.validation).includes('clinic.provider')
      ) {
        Swal.fire({
          icon: 'error',
          text: JSON.stringify(errors.extensions.validation),
          showConfirmButton: true,
        }).then(() => {
          handleOnEditProvider();
        });
      } else if (
        !isEmpty(errors.extensions) &&
        !isEmpty(errors.extensions.validation) &&
        JSON.stringify(errors.extensions.validation).includes('clinic.')
      ) {
        Swal.fire({
          icon: 'error',
          text: JSON.stringify(errors.extensions.validation),
          showConfirmButton: true,
        }).then(() => {
          handleOnEditClinic();
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Error Message: ' + saveClinicError.message,
          showConfirmButton: true,
        });
      }
    }
    if (!isEmpty(saveClinicData)) {
      Swal.fire({
        icon: 'success',
        text: 'Successfully created a clinic',
        showConfirmButton: true,
      }).then(() => {
        history.push('/clinic');
      });
    }
  }, [, handleOnEditClinic, handleOnEditProvider, saveClinicError, history, saveClinicData]);

  const handleRetrieve = useCallback(
    res => {
      const feature = res.features[0];
      setValue('clinic.address_line1', feature.properties.address_line1);
      setValue('clinic.address_city', feature.properties.place);
      setValue('clinic.address_state', feature.properties.region);
      setValue('clinic.address_zip', feature.properties.postcode);
      trigger('clinic.address_line1');
      trigger('clinic.address_city');
      trigger('clinic.address_state');
      trigger('clinic.address_zip');
    },
    [setValue, trigger],
  );

  return (
    <PageContent contentClass="clinics-enroll-psr" title="Register Clinic" isBreadcrumbs={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', mt: 3 }}>
          <MyStepperHeader activeStep={activeStep} steps={steps} />
        </Box>

        <BackendErrorHandler
          error={
            !isEmpty(saveClinicError) && !isEmpty(saveClinicError.graphQLErrors)
              ? saveClinicError.graphQLErrors[0]
              : null
          }
          setError={setError}
          clearErrors={clearErrors}
        />

        <Stack direction="column">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={gridSpacing}
            sx={{ px: 2, mt: 3, py: 2 }}
          >
            {saveClinicError && (
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                md={12}
                xs={12}
                sx={{ pb: 2 }}
              >
                <Error
                  error={
                    !isEmpty(saveClinicError) && !isEmpty(saveClinicError.graphQLErrors)
                      ? saveClinicError.graphQLErrors[0]
                      : null
                  }
                />
              </Grid>
            )}
            <Grid item xs={10}>
              {activeStep === stepsIndex.clinic && (
                <CreateNonAffiliatedClinicForm
                  prefix="clinic"
                  control={control}
                  errors={errors}
                  handleRetrieve={handleRetrieve}
                />
              )}
              {activeStep === stepsIndex.provider && (
                <Stack direction="column">
                  <MyButton
                    type="button"
                    variant="contained"
                    style={{ width: '150px' }}
                    onClick={() => {
                      append({ ...createProviderInitialValues });
                    }}
                    fullWidth={false}
                  >
                    Add Provider
                  </MyButton>
                  <Divider sx={{ my: 1.5 }} />
                  {fields.map((_item, index) => (
                    <Box key={`box-providers-${index}`}>
                      <Stack
                        key={`stack-providers-${index}`}
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        style={{ marginBottom: '15px' }}
                      >
                        <MyTypography variant="h4">Provider Information - {index + 1}</MyTypography>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <MyButton
                          key={`button.${index}`}
                          type="button"
                          variant="contained"
                          onClick={() => {
                            remove(index);
                          }}
                          fullWidth={false}
                        >
                          Remove
                        </MyButton>
                      </Stack>

                      <CreateProviderForm
                        key={`providers.${index}`}
                        name={`providers.${index}`}
                        prefix={`providers.${index}`}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                      />
                      <Divider key={`divider.providers.${index}`} sx={{ my: 1.5 }} />
                    </Box>
                  ))}
                </Stack>
              )}
              {activeStep === stepsIndex.review && (
                <EnrollPsrReviewInfo
                  onEditClinic={handleOnEditClinic}
                  onEditProvider={handleOnEditProvider}
                  data={getValues()}
                />
              )}
            </Grid>
          </Grid>

          <Stack direction="row" spacing={1} sx={{ pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <MyButton
              color="primary"
              variant="outlined"
              disabled={activeStep === 0 || saveClinicLoading}
              onClick={handleBack}
              fullWidth={false}
              sx={{ mr: 1 }}
            >
              Back
            </MyButton>
            {activeStep < steps.length - 1 && (
              <MyButton
                onClick={handleNext}
                disabled={
                  saveClinicLoading ||
                  (activeStep === stepsIndex.clinic &&
                    hasEmptyClinicField(dirtyFields, getValues())) ||
                  (activeStep === stepsIndex.provider &&
                    (isEmpty(getValues('providers')) ||
                      (!isEmpty(getValues('providers')) &&
                        hasEmptyProviderField(dirtyFields, getValues())))) ||
                  !isEmpty(errors)
                }
                variant="contained"
                fullWidth={false}
              >
                Next
              </MyButton>
            )}
            {isValid &&
              !isEmpty(getValues('providers')) &&
              !hasEmptyProviderField(dirtyFields, getValues()) &&
              activeStep < steps.length - 1 && (
                <MyButton
                  onClick={() => setActiveStep(stepsIndex.review)}
                  disabled={saveClinicLoading}
                  variant="contained"
                  color="secondary"
                  fullWidth={false}
                >
                  Review
                </MyButton>
              )}
            {activeStep === steps.length - 1 && (
              <MyButton
                type="submit"
                variant="contained"
                loading={saveClinicLoading}
                fullWidth={false}
              >
                Submit
              </MyButton>
            )}
          </Stack>
        </Stack>
      </form>
    </PageContent>
  );
}

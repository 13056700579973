// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import {EnrollPsr} from './Management';
import {Authenticated} from '../auth';
import EnrollNonAffiliateClinic from './Management/EnrollNonAffiliateClinic';

export default {
  path: 'clinics',
  name: 'Clinics',
  component: Authenticated,
  childRoutes: [
    { path: 'enroll', component: EnrollPsr, props: {title: 'Register Clinic and Main User', subtitle: 'Enroll PSR user first, then add clinic details'} },
    { path: 'non-affiliate', component: EnrollNonAffiliateClinic, props: {title: 'Register Clinic and Main User', subtitle: 'Enroll PSR user first, then add clinic details'} },
  ],
};

import React, { useEffect } from 'react';
import { Stack, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { MyButton, MyTypography } from '../../../common/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { gridSpacing } from '../../../../common/themes/constants';
import Swal from 'sweetalert2';
import BackendErrorHandler from '../../../common/components/hook-form/BackendErrorHandler';
import { UPDATE_CLINIC_PROVIDER_MUTATION } from '../../gql/Mutation';
import clientGraphql from '../../../../common/apollo-graphql';
import { useMutation } from '@apollo/client';
import { isEmpty } from 'lodash';
import UpdateProviderForm, {
  initialValues as createProviderInitialValues,
  createProviderValidationSchema,
} from '../../../auth/RegisterUser/UpdateProviderForm';

const validationSchema = createProviderValidationSchema;

export default function UpdateClinicProviderDialog({
  open,
  onClose,
  clinicId,
  providerData = null,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...createProviderInitialValues,
    },
    resolver: yupResolver(validationSchema),
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const [
    update_clinic_provider,
    {
      data: saveClinicProviderData,
      loading: saveClinicProviderLoading,
      error: saveClinicProviderError,
    },
  ] = useMutation(UPDATE_CLINIC_PROVIDER_MUTATION, {
    client: clientGraphql,
  });

  const onSubmit = data => {
    console.log(data);
    var newData = JSON.parse(JSON.stringify(data));
    if (newData.optional_documents.length === 0 || newData.optional_documents.indexOf('misc') < 0) {
      newData.optional_documents.push({ document: 'misc', question: '' });
    }
    newData['uuid'] = providerData.uuid;
    update_clinic_provider({
      client: clientGraphql,
      variables: { provider: newData },
    });
  };

  useEffect(() => {
    if (!isEmpty(saveClinicProviderError)) {
      const errors = !isEmpty(saveClinicProviderError.graphQLErrors)
        ? saveClinicProviderError.graphQLErrors[0]
        : {};
      if (
        !isEmpty(errors.extensions) &&
        !isEmpty(errors.extensions.validation) &&
        JSON.stringify(errors.extensions.validation).includes('provider')
      ) {
        Swal.fire({
          icon: 'error',
          text: JSON.stringify(errors.extensions.validation),
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Error Message: ' + saveClinicProviderError.message,
          showConfirmButton: true,
        });
      }
    }
    if (!isEmpty(saveClinicProviderData)) {
      Swal.fire({
        icon: 'success',
        text: 'Successfully updated the provider',
        showConfirmButton: true,
      }).then(() => {
        reset();
        onClose(true);
      });
    }
  }, [saveClinicProviderData, saveClinicProviderError, onClose, reset]);

  useEffect(() => {
    if (!isEmpty(providerData)) {
      const optional = providerData.documents
        .filter(doc => {
          return !doc.required && doc.document.name !== 'misc';
        })
        .map(doc => {
          return {
            document: doc.document.name,
            question: !isEmpty(doc.question) ? doc.question.question : '',
          };
        });
      setValue('title', !isEmpty(providerData.title)? providerData.title.toLowerCase():'');
      setValue('firstname', providerData.firstname);
      setValue('lastname', providerData.lastname);
      setValue('can_receive', providerData.can_receive);
      setValue(
        'specializations',
        providerData.specializations.map(specialization => {
          return specialization.name;
        }),
      );
      setValue(
        'required_documents',
        providerData.documents
          .filter(doc => {
            return doc.required;
          })
          .map(doc => {
            return doc.document.name;
          }),
      );
      setValue('optional_documents', optional);
      trigger();
    }
  }, [providerData, setValue, trigger]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackendErrorHandler
        error={
          !isEmpty(saveClinicProviderError)
            ? { errors: saveClinicProviderError.graphQLErrors[0] }
            : null
        }
        setError={setError}
        clearErrors={clearErrors}
      />
      <Dialog open={open} onClose={onClose} disablePortal={true}>
        <DialogContent>
          <Grid container direction="column" spacing={gridSpacing}>
            <Grid item xs={10}>
              <MyTypography variant="h3">Update Provider</MyTypography>
            </Grid>
            <Grid item xs={10}>
              <UpdateProviderForm control={control} errors={errors} setValue={setValue} trigger={trigger} getValues={getValues}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 3 }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <MyButton onClick={onClose} disabled={saveClinicProviderLoading} fullWidth={false}>
              Cancel
            </MyButton>
            <MyButton
              type="submit"
              variant="contained"
              loading={saveClinicProviderLoading}
              fullWidth={false}
            >
              Update Provider
            </MyButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </form>
  );
}

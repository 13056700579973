import React from 'react';
import {  Grid } from '@mui/material';
import * as yup from 'yup';
import 'yup-phone';
import { gridSpacing } from '../../../common/themes/constants';
import UserOptions from './UserOptions';

export const initialValues = {
  userId: ''
}

export const registerUserValidationSchema = yup.object().shape({
  userId: yup
    .string()
});

export default function RegisterExistingUserForm({
  control,
  errors,
  prefix = null,
  dataUsers=null
}) {

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item sm={12} xs={12}>
        <UserOptions schema="userId"
          placeholder="Enter the User"
          label="User"
          data={dataUsers}
                    prefix={prefix}
                    control={control}
                    errors={errors}/>
      </Grid>
    </Grid>
  );
}

import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  AUTH_ACTIVATE_ACCOUNT_BEGIN,
  AUTH_ACTIVATE_ACCOUNT_SUCCESS,
  AUTH_ACTIVATE_ACCOUNT_FAILURE,
  AUTH_ACTIVATE_ACCOUNT_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';
import { useHistory } from 'react-router-dom';

export function activateAccount(args) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: AUTH_ACTIVATE_ACCOUNT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.activateAccount(args).then(
        (res) => {
          dispatch({
            type: AUTH_ACTIVATE_ACCOUNT_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: AUTH_ACTIVATE_ACCOUNT_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

// TODO: add a function to check if the user is activated, then if activated redirect to dashboard
function checkAccountActivated(args) {
  return new Promise((resolve, reject) => {
    ApiService.checkAccountActivated(args).then(
        (res) => {
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          reject(err);
        },
    );
  });
}

export function dismissActivateAccountError() {
  return {
    type: AUTH_ACTIVATE_ACCOUNT_DISMISS_ERROR,
  };
}

export function useActivateAccount(queryParams) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { activateAccountPending, activateAccountError } = useSelector(
    state => ({
      activateAccountPending: state.auth.activateAccountPending,
      activateAccountError: state.auth.activateAccountError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((args) => {
    return dispatch(activateAccount(args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissActivateAccountError());
  }, [dispatch]);

  useEffect(() => {
    checkAccountActivated(queryParams).then(res => res)
        .catch(err => {
          history.replace('/login');
        });
  }, [queryParams, history]);

  return {
    activateAccount: boundAction,
    activateAccountPending,
    activateAccountError,
    dismissActivateAccountError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_ACTIVATE_ACCOUNT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        activateAccountPending: true,
        activateAccountError: null,
      };

    case AUTH_ACTIVATE_ACCOUNT_SUCCESS:
      // The request is success
      return {
        ...state,
        activateAccountPending: false,
        activateAccountError: null,
      };

    case AUTH_ACTIVATE_ACCOUNT_FAILURE:
      // The request is failed
      return {
        ...state,
        activateAccountPending: false,
        activateAccountError: action.data.error,
      };

    case AUTH_ACTIVATE_ACCOUNT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        activateAccountError: null,
      };

    default:
      return state;
  }
}

import { gql } from '@apollo/client';

export const REFERRAL_UPDATES_SUBSCRIPTION = gql`
    subscription ReferralUpdates($uuids: [uuid!]) {
        referrals(where: {uuid: {_in: $uuids}, status: {_in: ["draft", "sent", "under_reviewed", "accepted", "declined"]}}) {
            clinic_referrer_id
            uuid
            status
        }
    }
`;

export const REFERRAL_REFRESH_SUBSCRIPTION = gql`
    subscription ReferralRefresh($clinic_id: bigint!) {
        referrals(where: {clinic_referrer_id: {_eq: $clinic_id}}) {
            uuid
            status
        }
    }
`;

export const REFERRAL_RECEIVER_SUBSCRIPTION = gql`
    subscription ReferralReceiverRefresh($clinic_id: bigint!) {
        referrals(where: {clinic_receiver_id: {_eq: $clinic_id}}) {
            uuid
            status
        }
    }
`;
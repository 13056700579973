import {gql} from '@apollo/client';
import { USER_TABLE_FIELDS } from './Fragment';

const GET_USER = gql`
${USER_TABLE_FIELDS}
query getUser($uuid: String!) {
  user(user_uuid: $uuid){
    ...UserFields
  }
}
`;

const GET_USERS = gql`
  ${USER_TABLE_FIELDS}
  query getUsers($keyword: String, $without_clinic_id: ID) {
    rows: users(keyword: $keyword, without_clinic_id: $without_clinic_id) {
      ...UserFields
    }
  }
  
`;


export {
    GET_USER,
    GET_USERS
};
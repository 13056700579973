import { gql } from '@apollo/client';

const SAVE_CLINIC_MUTATION = gql `
  mutation CreateClinic($clinic: ClinicAttributes!) {
    save_clinic(clinic: $clinic) {
        id
        name
      }
    }
`;

const SAVE_NON_AFFILIATED_CLINIC_MUTATION = gql `
  mutation CreateNonAffiliatedClinic($clinic: ClinicAttributes!) {
    save_non_affiliated_clinic(clinic: $clinic) {
        id
        name
      }
    }
`;

const SAVE_CLINIC_USER_MUTATION = gql `
  mutation CreateClinicUser($clinic_id: ID!, $user: UserAttributes!) {
    save_clinic_user(clinic_id: $clinic_id, user: $user) {
        firstname
      }
    }
`;

const UPDATE_CLINIC_USER_MUTATION = gql `
  mutation SaveUser($userUuid: String!, $user: UserSaveAttributes!, $clinicId: ID) {
    save_user(user_uuid: $userUuid, user: $user, clinic_id: $clinicId) {
        firstname
        lastname
      }
    }
`;

const SAVE_CLINIC_PROVIDER_MUTATION = gql `
  mutation CreateClinicProvider($clinic_id: ID!, $provider: ProviderAttributes!) {
    save_clinic_provider(clinic_id: $clinic_id, provider: $provider) {
        display_name
      }
    }
`;

const DELETE_PROVIDER_MUTATION = gql `
  mutation DeleteProvider($uuid: String!) {
    delete_provider(uuid: $uuid)
  }
`;

const UPDATE_CLINIC_PROVIDER_MUTATION = gql `
  mutation UpdateClinicProvider($provider: ProviderAttributes!) {
    save_provider(provider: $provider) {
        id
        display_name
      }
    }
`;

const SAVE_CLINIC_ORGANIZATION_MUTATION = gql `
  mutation SaveClinicOrganization($organization: OrganizationAttributes!) {
    save_organization(organization: $organization) {
        name
        clinics {
          name
        }
      }
    }
`;

const SAVE_USER_CLINIC_MUTATION = gql `
  mutation SaveUserClinic($userId: ID!, $clinicId: ID!, $permission: Int, $notification: Int) {
    save_user_clinic(user_id: $userId, clinic_id: $clinicId, permission: $permission, notification: $notification) {
      id
      uuid
      clinics {
        id
       name
      }
    }
  }
`;

const MAKE_PRIMARY_CLINIC_MUTATION = gql `
  mutation MakePrimaryClinic($userUuid: String!, $clinicId: ID!) {
    make_primary_clinic(user_uuid: $userUuid, clinic_id: $clinicId) {
      id
      uuid
    }
  }
`;

const DELETE_USER_CLINIC_MUTATION = gql `
  mutation DeleteUserClinic($userId: ID!, $clinicId: ID!) {
    delete_user_clinic(user_id: $userId, clinic_id: $clinicId) {
      id
      uuid
    }
  }
`;

const DELETE_REFERRAL_GROUP_MUTATION = gql `
  mutation DeleteReferralNetwork($uuid: String!) {
    delete_referral_network(uuid: $uuid)
  }
`;

const SAVE_REFERRAL_GROUP_MUTATION = gql `
  mutation SaveReferralNetwork($referralNetwork: ReferralNetworkAttributes!) {
    save_referral_network(referral_network: $referralNetwork) {
      id
      name
      members {
        id
        uuid
        member {
          ... on Clinic {
            id
            name
            code
          }
        }
      }
    }
  }
`;

const SAVE_CLINIC_REFERRAL_FORM_ATTRIBUTES = gql `
mutation SaveClinicFormAttributes($id: ID!, $formType: String!, $clinicForm: [ClinicFormAttributes!]) {
  save_clinic_custom_form_attributes(clinic_id: $id, form_type: $formType, custom_form_attributes: $clinicForm)
}
`;

const SAVE_USER_NOTIFICATION_MUTATION = gql `
mutation SaveUserNotification($clinicId: ID!, $notificationSettings: Int!) {
  save_user_notification_settings(clinic_id: $clinicId, notification_settings: $notificationSettings)
}
`;





export {
  SAVE_CLINIC_MUTATION,
  SAVE_NON_AFFILIATED_CLINIC_MUTATION,
  SAVE_CLINIC_USER_MUTATION,
  SAVE_CLINIC_PROVIDER_MUTATION,
  UPDATE_CLINIC_USER_MUTATION,
  DELETE_PROVIDER_MUTATION,
  UPDATE_CLINIC_PROVIDER_MUTATION,
  SAVE_CLINIC_ORGANIZATION_MUTATION,
  SAVE_USER_CLINIC_MUTATION,
  MAKE_PRIMARY_CLINIC_MUTATION,
  DELETE_USER_CLINIC_MUTATION,
  DELETE_REFERRAL_GROUP_MUTATION,
  SAVE_REFERRAL_GROUP_MUTATION,
  SAVE_CLINIC_REFERRAL_FORM_ATTRIBUTES,
  SAVE_USER_NOTIFICATION_MUTATION
};

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  expanded: {},
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  alert: {
    '& .MuiAlert-icon': {
      fontSize: 40,
      margin: '0',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      transform: 'translateY(-50%)',
    },
    minHeight: '200px',
    minWidth: '500px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '100',
  },
  typography: {
    margin: '0 60px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    transform: 'translateY(-50%)',
  },
}));

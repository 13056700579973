import React, { useEffect, useState } from 'react';

// material-ui
import {
  CardActions,
  CardContent,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';

// project imports
import MainCard from '../common/ui-component/cards/MainCard';
import SkeletonPopularCard from '../common/ui-component/cards/Skeleton/PopularCard';

// assets
import { gridSpacing } from '../../common/themes/constants';
import MyTextField from '../common/components/MyTextField';
import TotalReferralsByClinic from './TotalReferralByClinics';
import { useLazyQuery, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import clientGraphql from '../../common/apollo-graphql';
import { GET_ADMIN_REFERRAL_COUNTS } from '../referrals/gql';
import { MANAGE_CLINICS_QUERY } from '../clinics/gql';

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const months = [
  {
    value: 0,
    label: 'January',
  },
  {
    value: 1,
    label: 'February',
  },
  {
    value: 2,
    label: 'March',
  },
  {
    value: 3,
    label: 'April',
  },
  {
    value: 4,
    label: 'May',
  },
  {
    value: 5,
    label: 'June',
  },
  {
    value: 6,
    label: 'July',
  },
  {
    value: 7,
    label: 'August',
  },
  {
    value: 8,
    label: 'September',
  },
  {
    value: 9,
    label: 'October',
  },
  {
    value: 10,
    label: 'November',
  },
  {
    value: 11,
    label: 'December',
  },
];

const years = [
  {
    value: (new Date()).getFullYear(),
    label: "" + ((new Date()).getFullYear()),
  },
  {
    value: (new Date()).getFullYear() - 1,
    label: "" + ((new Date()).getFullYear() - 1),
  },
  {
    value: (new Date()).getFullYear() - 2,
    label: "" + ((new Date()).getFullYear() - 2),
  }
];


export default function TotalReferralsByMonth({ isLoading }) {
  const [value, setValue] = useState((new Date()).getMonth());
  const [valueYear, setValueYear] = useState((new Date()).getFullYear());

  const gqlParams = {
    client: clientGraphql,
    variables: {
        value: "" + value + ""
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
};
const [GetAdminReferralCounts, {data}] = useLazyQuery(GET_ADMIN_REFERRAL_COUNTS, gqlParams);

const { data: dataClinics } = useQuery(MANAGE_CLINICS_QUERY, {
  client: clientGraphql,
  variables: {
    keyword: "" 
  },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
});

useEffect(() => {
    if(value === (new Date().getMonth())){
      GetAdminReferralCounts({
        client: clientGraphql,
        variables: {month: "" + ((value) + 1) + "", year: "" + ((new Date()).getFullYear())},
      });
    }
}, [value, GetAdminReferralCounts]);

  return (
    <>
      {isLoading ? (
        <SkeletonPopularCard />
      ) : (
        <MainCard content={false}>
          <CardContent>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <Grid container alignContent="center" justifyContent="space-between">
                  <Grid item>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Typography variant="subtitle2">Total Referrals</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h3">{!isEmpty(data) && !isEmpty(data.admin_referral_counts) ? data.admin_referral_counts[0].count:'0'}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <MyTextField
                      id="standard-select-currency"
                      select
                      value={value}
                      onChange={e => {
                        setValue(e.target.value);
                        GetAdminReferralCounts({
                          client: clientGraphql,
                          variables: {month: "" + (e.target.value + 1) + "" , year: "" + valueYear + ""},
                        });
                      }
                    }
                    >
                      {months.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </MyTextField>
                    <MyTextField
                      id="standard-select-year"
                      select
                      value={valueYear}
                      onChange={e => {
                        setValueYear(e.target.value);
                        GetAdminReferralCounts({
                          client: clientGraphql,
                          variables: {month: "" + (value + 1) + "", year: "" + e.target.value + ""},
                        });
                      }
                    }
                    >
                      {years.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </MyTextField>
                  </Grid>
                </Grid>
              </Grid>
              { !isEmpty(dataClinics) && !isEmpty(dataClinics.rows) ? (
                dataClinics.rows.map(clinic => {
                  return (<TotalReferralsByClinic clinicId={clinic.id} key={clinic.id} clinicName={clinic.name} year={"" + valueYear} month={"" + (value + 1) + ""}></TotalReferralsByClinic>);
                })
              ):null

              }
            </Grid>
          </CardContent>
          <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
            
          </CardActions>
        </MainCard>
      )}
    </>
  );
}

import { gql } from '@apollo/client';


const SAVE_QUESTION_MUTATION = gql `
  mutation saveQuestion($documentId: ID!, $question: String!) {
    save_document_question(document_id: $documentId, question: $question) {
        id
        uuid
        question
      }
    }
`;

const DELETE_QUESTION_MUTATION = gql `
  mutation deleteQuestion($uuid: String!) {
    delete_document_question(uuid: $uuid) 
  }
`;


export {
    SAVE_QUESTION_MUTATION,
    DELETE_QUESTION_MUTATION
};

import React, { useState, useEffect, useCallback } from 'react';
import PageContent from '../../common/MainLayout/Page';
import { useMutation, useQuery } from '@apollo/client';
import { MANAGE_CLINIC_DETAILS_QUERY } from '../gql';
import { isEmpty, omit } from 'lodash';
import { Box, Checkbox, FormControlLabel, Grid, MenuItem, Stack } from '@mui/material';
import User from '../../auth/models/User';
import ClinicDetails from './ClinicDetails/ClinicDetails';
import { ClinicProvider } from '../context/ClinicContext';
import clientGraphql from '../../../common/apollo-graphql';
import ReferralConfigurableForm from './CreateClinic/ReferralConfigurableForm';
import { gridSpacing } from '../../../common/themes/constants';
import { MyButton, MyTypography } from '../../common/components';
import { GET_CLINIC_REFERRAL_FORM_ATTRIBUTES } from '../gql/Query';
import Swal from 'sweetalert2';
import { SAVE_CLINIC_REFERRAL_FORM_ATTRIBUTES } from '../gql/Mutation';
import MyTextField from '../../common/components/MyTextField';
import ChoicesOptions from './ChoicesOptions';
import {
  DEFAULT_INTERNAL_REFERRAL_FORM_FIELD,
  DEFAULT_ORIGINAL_FIELDS,
  DEFAULT_REFERRAL_FORM_FIELD,
  FIELD_TYPES,
} from '../../common/services/DefaultValuesUtils';

Array.prototype.swapItems = function(a, b) {
  this[a] = this.splice(b, 1, this[a])[0];
  return this;
};

export default function ManageReferralFormClinicDetails({ match }) {
  const [formattedData, setFormattedData] = useState({});
  const [referralSettings, setReferralSettings] = useState([]);
  const [required, setRequired] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [choices, setChoices] = useState([]);
  const [fieldType, setFieldType] = useState('text');
  const [field, setField] = useState('');
  const [size, setSize] = useState(12);
  const [label, setLabel] = useState('');
  const [internalReferralSettings, setInternalReferralSettings] = useState([]);

  const { id } = match.params;
  const { loading, data, refetch } = useQuery(MANAGE_CLINIC_DETAILS_QUERY, {
    client: clientGraphql,
    variables: { id: id },
  });
  const { loading: referralFormLoading, data: referralFormData } = useQuery(
    GET_CLINIC_REFERRAL_FORM_ATTRIBUTES,
    {
      client: clientGraphql,
      variables: { id: id, formType: 'external' },
    },
  );
  const { data: internalReferralFormData } = useQuery(GET_CLINIC_REFERRAL_FORM_ATTRIBUTES, {
    client: clientGraphql,
    variables: { id: id, formType: 'internal' },
  });

  const [SAVE_CLINIC_REFERRAL_FORM, { loading: saveLoading }] = useMutation(
    SAVE_CLINIC_REFERRAL_FORM_ATTRIBUTES,
    {
      client: clientGraphql,
    },
  );

  const existFieldLabel = useCallback((settings, fieldname, fieldLabel) => {
    return (
      !isEmpty(fieldname) &&
      !isEmpty(fieldLabel) &&
      settings.filter(cf => {
        return cf.field === fieldname && cf.label === fieldLabel;
      }).length > 0
    );
  }, []);

  const existField = useCallback((settings, fieldname) => {
    return (
      !isEmpty(fieldname) &&
      settings.filter(cf => {
        return cf.field === fieldname;
      }).length > 0
    );
  }, []);

  const existLabel = useCallback((settings, fieldLabel) => {
    return (
      !isEmpty(fieldLabel) &&
      settings.filter(cf => {
        return cf.label === fieldLabel;
      }).length > 0
    );
  }, []);

  const onChange = useCallback(value => {
    setChoices(value);
  }, []);

  const handleAddNewField = useCallback(
    group => {
      if (
        group === '' &&
        (existFieldLabel(internalReferralSettings, field, label) ||
          existFieldLabel(referralSettings, field, label))
      ) {
        Swal.fire({
          icon: 'error',
          text: 'The field name and label already exist in Internal or External Form.',
          showConfirmButton: true,
        }).then(() => {});
        return;
      }
      if (group === 'internal' && existFieldLabel(internalReferralSettings, field, label)) {
        Swal.fire({
          icon: 'error',
          text: 'The field name and label already exist in Internal Form.',
          showConfirmButton: true,
        }).then(() => {});
        return;
      }
      if (group === 'external' && existFieldLabel(referralSettings, field, label)) {
        Swal.fire({
          icon: 'error',
          text: 'The field name and label already exist in External Form.',
          showConfirmButton: true,
        }).then(() => {});
        return;
      }
      if (
        group === '' &&
        (existField(internalReferralSettings, field) || existField(referralSettings, field))
      ) {
        Swal.fire({
          icon: 'error',
          text: 'The field name already exist in Internal or External Form.',
          showConfirmButton: true,
        }).then(() => {});
        return;
      }
      if (group === 'internal' && existField(internalReferralSettings, field)) {
        Swal.fire({
          icon: 'error',
          text: 'The field name already exist in Internal Form.',
          showConfirmButton: true,
        }).then(() => {});
        return;
      }
      if (group === 'external' && existField(referralSettings, field)) {
        Swal.fire({
          icon: 'error',
          text: 'The field name already exist in External Form.',
          showConfirmButton: true,
        }).then(() => {});
        return;
      }
      if (
        group === '' &&
        (existLabel(internalReferralSettings, label) || existLabel(referralSettings, label))
      ) {
        Swal.fire({
          icon: 'error',
          text: 'The field label already exist in Internal or External Form.',
          showConfirmButton: true,
        }).then(() => {});
        return;
      }
      if (group === 'internal' && existLabel(internalReferralSettings, label)) {
        Swal.fire({
          icon: 'error',
          text: 'The field label already exist in Internal Form.',
          showConfirmButton: true,
        }).then(() => {});
        return;
      }
      if (group === 'external' && existLabel(referralSettings, label)) {
        Swal.fire({
          icon: 'error',
          text: 'The field label already exist in External Form.',
          showConfirmButton: true,
        }).then(() => {});
        return;
      }
      if ((group === '' || group === 'internal') && !isEmpty(field) && !isEmpty(label)) {
        var settings = [...internalReferralSettings];
        settings.push({
          field: field,
          label: label,
          size: size,
          required: required,
          kind: fieldType,
          hidden: hidden,
          choices: choices,
        });
        setInternalReferralSettings(settings);
      }
      if ((group === '' || group === 'external') && !isEmpty(field) && !isEmpty(label)) {
        var settings = [...referralSettings];
        settings.push({
          field: field,
          label: label,
          size: size,
          required: required,
          kind: fieldType,
          hidden: hidden,
          choices: choices,
        });
        setReferralSettings(settings);
      }
      if (isEmpty(field) || isEmpty(label)) {
        Swal.fire({
          icon: 'error',
          text: 'The field name and label are required.',
          showConfirmButton: true,
        }).then(() => {});
      } else if (
        isEmpty(choices) &&
        (fieldType === 'single_choice' || fieldType === 'multiple_choice')
      ) {
        Swal.fire({
          icon: 'error',
          text: 'The choices is required for Checkbox and Radio Button.',
          showConfirmButton: true,
        }).then(() => {});
      } else {
        setField('');
        setLabel('');
        setSize(12);
        setFieldType('text');
        setRequired(false);
        setHidden(false);
        setChoices([]);
      }
    },
    [
      referralSettings,
      internalReferralSettings,
      field,
      label,
      size,
      required,
      fieldType,
      hidden,
      choices,
      existField,
      existLabel,
      existFieldLabel,
    ],
  );

  const handleRemoveField = useCallback(
    (value, index, type) => {
      Swal.fire({
        text: 'Are you sure you want to remove ' + value.label + ' field ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          if (type === 'internal') {
            var settings = [...internalReferralSettings];
            settings.splice(index, 1);
            setInternalReferralSettings(settings);
          } else {
            var settings = [...referralSettings];
            settings.splice(index, 1);
            setReferralSettings(settings);
          }
        }
      });
    },
    [referralSettings, internalReferralSettings],
  );

  const handleChange = useCallback((index, value, varName, formSettings, type) => {
    var settings = [...formSettings];
    settings[index][varName] = value;
    if (type === 'internal') {
      setInternalReferralSettings(settings);
    } else if (type === 'external') {
      setReferralSettings(settings);
    }
  }, []);

  const handleSortChange = useCallback((index, formSettings, direction, type) => {
    var settings = [...formSettings];
    if (direction === 'up') {
      settings = settings.swapItems(index, index - 1);
    } else if (direction === 'down') {
      settings = settings.swapItems(index, index + 1);
    }
    if (type === 'internal') {
      setInternalReferralSettings(settings);
    } else if (type === 'external') {
      setReferralSettings(settings);
    }
  }, []);

  const handleSetFieldChange = useCallback(
    value => {
      if (isEmpty(value)) {
        setField('');
        return;
      }
      if (value.includes('custom_')) {
        var temp = value.replace('custom_', '');
        if (DEFAULT_ORIGINAL_FIELDS.includes(temp.toLowerCase())) {
          setField(temp.toLowerCase());
        } else if (isEmpty(temp)) {
          setField('');
        } else {
          setField(value.toLowerCase());
        }
        return;
      } else if (DEFAULT_ORIGINAL_FIELDS.includes(value.toLowerCase())) {
        setField(value.toLowerCase());
        return;
      } else {
        setField('custom_' + value.toLowerCase());
        return;
      }
    },
    [setField],
  );

  const handleSubmit = useCallback(() => {
    var clinicFormArr = internalReferralSettings.map(setting => {
      var temp = omit(setting, ['__typename']);
      if (temp.required === null) {
        temp.required = false;
      }
      if (temp.hidden === null) {
        temp.hidden = false;
      }
      if (isEmpty(temp.size)) {
        temp.size = '6';
      }
      temp.size = '' + temp.size;
      return temp;
    });
    var newData = { id: id, clinicForm: clinicFormArr, formType: 'internal' };
    SAVE_CLINIC_REFERRAL_FORM({
      client: clientGraphql,
      variables: newData,
    })
      .then(res => {
        submitExternal(referralSettings);
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Cannot save clinic referral form settings.',
          text:
            'data' in error && !isEmpty(error.data) && !isEmpty(error.data.message)
              ? error.data.message
              : JSON.stringify(error),
          showConfirmButton: true,
        });
      });
  }, [internalReferralSettings, referralSettings, submitExternal, SAVE_CLINIC_REFERRAL_FORM, id]);

  const submitExternal = useCallback((settings) => {
    var clinicFormArr = settings.map(setting => {
      var temp = omit(setting, ['__typename']);
      if (temp.required === null) {
        temp.required = false;
      }
      if (temp.hidden === null) {
        temp.hidden = false;
      }
      if (isEmpty(temp.size)) {
        temp.size = '6';
      }
      temp.size = '' + temp.size;
      return temp;
    });
    var newData = { id: id, clinicForm: clinicFormArr, formType: 'external' };
    SAVE_CLINIC_REFERRAL_FORM({
      client: clientGraphql,
      variables: newData,
    })
      .then(res => {
        Swal.fire({
          icon: 'success',
          text: 'Successfully saved clinic referral form settings.',
          showConfirmButton: true,
        });
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Cannot save clinic referral form settings.',
          text:
            'data' in error && !isEmpty(error.data) && !isEmpty(error.data.message)
              ? error.data.message
              : JSON.stringify(error),
          showConfirmButton: true,
        });
      });
  }, [SAVE_CLINIC_REFERRAL_FORM, id]);

  useEffect(() => {
    if (data) {
      const { clinic } = data;
      const newData = omit(clinic, [
        'id',
        '__typename',
        'aggr_users',
        'address_json',
        'full_address',
        'documents',
        'specializations',
        'users',
        'required_documents',
        'optional_documents',
        'providers',
      ]);

      setFormattedData({
        ...newData,
        address: clinic.full_address,
      });
      var rows = [];
      data.clinic.users.map(item => {
        if (item.user != null) {
          rows.push({
            is_primary: item.is_primary,
            ...item.user,
            userObj: new User({ ...item.user, is_primary: item.is_primary }),
          });
        }
      });
    }

    return () => {
      setFormattedData({});
    };
  }, [data]);

  useEffect(() => {
    if (
      !isEmpty(referralFormData) &&
      !isEmpty(referralFormData.clinic_custom_form_attributes)&&
      isEmpty(referralSettings)
    ) {
      setReferralSettings(referralFormData.clinic_custom_form_attributes.slice());
    } else if (
      !isEmpty(referralFormData) &&
      isEmpty(referralFormData.clinic_custom_form_attributes) &&
      isEmpty(referralSettings)
    ) {
      setReferralSettings(DEFAULT_REFERRAL_FORM_FIELD.slice());
    }
  }, [referralSettings, referralFormData]);

  useEffect(() => {
    if (
      !isEmpty(internalReferralFormData) &&
      !isEmpty(internalReferralFormData.clinic_custom_form_attributes) &&
      isEmpty(internalReferralSettings)
    ) {
      setInternalReferralSettings(internalReferralFormData.clinic_custom_form_attributes.slice());
    } else if (
      !isEmpty(internalReferralFormData) &&
      isEmpty(internalReferralFormData.clinic_custom_form_attributes) &&
      isEmpty(internalReferralSettings)
    ) {
      setInternalReferralSettings(DEFAULT_INTERNAL_REFERRAL_FORM_FIELD.slice());
    }
  }, [internalReferralSettings, internalReferralFormData]);

  return (
    <PageContent contentClass="management-manage-clinic-details" loading={loading}>
      <ClinicProvider
        value={{
          id,
          refetch,
        }}
      >
        <Stack direction="column" spacing={1}>
          <ClinicDetails data={formattedData} />
          <Grid container spacing={gridSpacing}>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <MyTypography variant="h4" component="h4" color="text.primary">
                  Public Form
                </MyTypography>
              </Grid>

              <Grid item xs={12}>
                <ReferralConfigurableForm
                  placeholder="Referral Form"
                  label="Referral Form"
                  referralSettings={referralSettings}
                  handleChange={handleChange}
                  handleSortChange={handleSortChange}
                  removeField={handleRemoveField}
                  type={'external'}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12}>
              <Grid item xs={12}>
                <MyTypography variant="h4" component="h4" color="text.primary">
                  Intra Organizational Form
                </MyTypography>
              </Grid>

              <Grid item xs={12}>
                <ReferralConfigurableForm
                  placeholder="Referral Form"
                  label="Referral Form"
                  referralSettings={internalReferralSettings}
                  handleChange={handleChange}
                  handleSortChange={handleSortChange}
                  removeField={handleRemoveField}
                  type={'internal'}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MyTypography variant="h4" component="h4" color="text.primary">
                New Custom Field
              </MyTypography>
            </Grid>
            <Grid item key={`grid.parent.2`} sm={4} xs={12}>
              <Stack direction="row" sx={{ margin: '8px 0 ' }}>
                <MyTypography sx={{ minWidth: 80, maxWidth: 80 }} variant="h5">
                  Required
                </MyTypography>
                <MyTypography sx={{ minWidth: 80, maxWidth: 80 }} variant="h5">
                  Hidden
                </MyTypography>
                <MyTypography sx={{ minWidth: 80, maxWidth: 80 }} variant="h5">
                  Size
                </MyTypography>
                <MyTypography sx={{ minWidth: 150, maxWidth: 150 }} variant="h5">
                  Field Type
                </MyTypography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row">
                <FormControlLabel
                  key={`custom-control-required`}
                  control={
                    <Checkbox
                      checked={required}
                      key={`switch.required`}
                      onChange={e => {
                        e.stopPropagation();
                        setRequired(e.target.checked);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={''}
                  sx={{ height: 55, minWidth: 90, maxWidth: 90, marginRight: 0 }}
                />
                <FormControlLabel
                  key={`custom-control-hidden`}
                  control={
                    <Checkbox
                      checked={hidden}
                      key={`switch.hidden`}
                      onChange={e => {
                        e.stopPropagation();
                        setHidden(e.target.checked);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={''}
                  sx={{ height: 55, minWidth: 90, maxWidth: 90, marginRight: 0 }}
                />
                <MyTextField
                  key={'text-size'}
                  type="number"
                  InputProps={{ inputProps: { min: 1, max: 12 } }}
                  value={size}
                  onChange={e => {
                    e.stopPropagation();
                    setSize(e.target.value);
                  }}
                  placeholder="Enter Size"
                  label="Size"
                  style={{ minWidth: 80, maxWidth: 80, marginRight: 16 }}
                />
                <MyTextField
                  id="standard-select-field-type"
                  select
                  value={fieldType}
                  onChange={e => {
                    e.stopPropagation();
                    setFieldType(e.target.value);
                  }}
                  style={{ minWidth: 150, maxWidth: 150, marginRight: 16 }}
                >
                  {FIELD_TYPES.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </MyTextField>
                <MyTextField
                  key={'text-field'}
                  value={field}
                  onChange={e => {
                    e.stopPropagation();
                    handleSetFieldChange(e.target.value);
                  }}
                  placeholder="Enter Field Name"
                  label="Field Name"
                  style={{ minWidth: 200, maxWidth: 200, marginRight: 16 }}
                />
                <MyTextField
                  key={'text-label'}
                  value={label}
                  onChange={e => {
                    e.stopPropagation();
                    setLabel(e.target.value);
                  }}
                  placeholder="Enter Label"
                  label="Label"
                  style={{ minWidth: 200, maxWidth: 200, marginRight: 16 }}
                />
                {(fieldType === 'single_choice' || fieldType === 'multiple_choice') && (
                  <ChoicesOptions value={choices} name={'choices'} onChange={onChange} />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row">
                <MyButton
                  variant="contained"
                  size="large"
                  loading={referralFormLoading}
                  fullWidth={false}
                  style={{ width: 150, marginRight: 16 }}
                  onClick={() => handleAddNewField('')}
                >
                  Add Both
                </MyButton>
                <MyButton
                  variant="contained"
                  size="large"
                  loading={referralFormLoading}
                  fullWidth={false}
                  style={{ width: 150, marginRight: 16 }}
                  onClick={() => handleAddNewField('external')}
                >
                  Public Form
                </MyButton>
                <MyButton
                  variant="contained"
                  size="large"
                  loading={referralFormLoading}
                  fullWidth={false}
                  style={{ width: 200 }}
                  onClick={() => handleAddNewField('internal')}
                >
                  Intra Organization Form
                </MyButton>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <MyButton
                  variant="contained"
                  size="large"
                  loading={saveLoading || referralFormLoading}
                  fullWidth={false}
                  style={{ width: 250 }}
                  onClick={() => handleSubmit()}
                  disabled={saveLoading}
                >
                  Save Referral Form
                </MyButton>
                <Box sx={{ flex: '1 1 auto' }} />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </ClinicProvider>
    </PageContent>
  );
}

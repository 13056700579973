import { gql } from '@apollo/client';

const CLINIC_SHORT_FIELDS = gql`
  fragment ClinicShortFields on Clinic {
    id
    code
    name
    full_address
    extra_info
  }
`;

export { CLINIC_SHORT_FIELDS };

import React, { useEffect, useState } from 'react';
import DocumentsTable from './table/DocumentsTable';
import PageContent from '../common/MainLayout/Page';
import { Stack } from '@mui/material';
import { useQuery } from '@apollo/client';
import clientGraphql from '../../common/apollo-graphql';
import { GET_DOCUMENTS_QUERY } from './gql/Query';
import { DocumentProvider } from './context/DocumentContext';

function ViewDocumentsTable({history, documentsData, loading}) {
  return (
      <Stack direction="column"
             spacing={1}>
          <DocumentsTable history={history} data={documentsData}
                            loading={loading} />
      </Stack>
  );
};

export default function ManageDocuments({history}) {
  const {id} = 1;
  const [documentsData, setDocumentsData] = useState({rows: [], count: 0});
  const { loading, data, refetch } = useQuery(GET_DOCUMENTS_QUERY, {
    client: clientGraphql,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
      if (data) {
          setDocumentsData({
              rows: data.documents.map(item => {
                  return {
                      ...item,
                  };
              }),
              count: data.documents.count,
          });
      } 
  }, [data]);

  return (
    <PageContent contentClass="manage-users">
      <DocumentProvider
                value={{
                    id,
                    refetch
                }}>
            {
                <ViewDocumentsTable history={history} loading={loading}
                documentsData={documentsData} />
            }
            </DocumentProvider>
      
    </PageContent>
  );
};
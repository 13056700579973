import { gql } from '@apollo/client';


const ENDORSE_NOTIFICATION_MUTATION = gql `
  mutation EndorseNotification($referralUuid: String!) {
    endorse_notification(referral_uuid: $referralUuid)
  }
`;

const DELETE_REFERRAL_MUTATION = gql `
  mutation DeleteReferral($referralUuid: String!) {
    delete_referral(uuid: $referralUuid)
  }
`;

const DELETE_REFERRAL_DOCUMENT = gql `
  mutation DeleteReferralDocument($referralUuid: String!, $referralDocumentUuid: String!) {
    delete_referral_document(referral_uuid: $referralUuid, referral_document_uuid: $referralDocumentUuid)
  }
`;

const UPDATE_REFERRAL_DOCUMENT = gql `
  mutation SaveReferralDocument($referralUuid: String!, $referralDocumentUuid: String!, $referralDocument:ReferralDocumentAttributes!) {
    save_referral_document(referral_uuid: $referralUuid, referral_document_uuid: $referralDocumentUuid, referral_document:$referralDocument){
      id
      uuid
      referral_document_name
    }
  }
`;

const SAVE_REFERRAL_QUESTIONS_MUTATION = gql `
  mutation SaveReferralAnswers($uuid: String!, $answers: [ReferralAnswerAttributes!]) {
    save_referral_answers(referral_uuid: $uuid, answers: $answers)
  }
`;

const RESEND_FAX = gql `mutation ResendFax($faxId: ID!) {
  resend_fax(fax_id: $faxId) {
    id
  }
} 

`;




export {
    ENDORSE_NOTIFICATION_MUTATION,
    DELETE_REFERRAL_MUTATION,
    DELETE_REFERRAL_DOCUMENT,
    UPDATE_REFERRAL_DOCUMENT,
    SAVE_REFERRAL_QUESTIONS_MUTATION,
    RESEND_FAX
};

import { gql } from '@apollo/client';
import { 
  REFERRAL_PATIENT_DETAIL_FRAGMENT, 
  REFERRAL_PATIENT_MINI_DETAIL_FRAGMENT 
} from './Fragment';

export const REFERRAL_PATIENT_MINI_DETAIL = gql`
  ${REFERRAL_PATIENT_MINI_DETAIL_FRAGMENT}
  query ReferralPatientMiniDetail($uuid: String!) {
    patient: referral_patient_mini_detail(referral_uuid: $uuid) {
      ...ReferralPatientMiniDetailsFragment
    }
  }  
`;

export const REFERRAL_PATIENT_DETAIL = gql`
  ${REFERRAL_PATIENT_DETAIL_FRAGMENT}
  query ReferralPatientDetail($uuid: String!) {
    patient: referral_patient_detail(referral_uuid: $uuid) {
      ...ReferralPatientDetailsFragment
    }
  }  
`;
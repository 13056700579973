import React from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

export default function MyLinkRouter(props) {
    return (
        <Link underline="none"
              component={RouterLink}
              {...props} />
    );
}
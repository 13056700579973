import { Grid, TextareaAutosize } from '@mui/material';
import React, { useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import { gridSpacing } from '../../common/themes/constants';
import { MyButton, MyTypography } from '../common/components';
import { SEND_NOTE } from './gql/Mutation';
import clientGraphqlPublic from '../../common/apollo-graphql-public';
import { useMutation } from '@apollo/client';
// import PropTypes from 'prop-types';

export default function PublicCreateReferralNote({
  referralId,
  email,
  code,
  isFax=false,
}) {
  const [message, setMessage] = useState('');
  const [send_note, { data, loading }] = useMutation(SEND_NOTE, {
    client: clientGraphqlPublic,
  });

  const onCreateReferralNote = useCallback(
    group => {
      
      send_note({
        client: clientGraphqlPublic,
        variables: {
            referralUuid: referralId,
            email: email,
            code: code,
            note: message
            
        },
      }).then(data => {
          Swal.fire({
            icon: 'success',
            text: 'Created Referral Note',
            showConfirmButton: true,
          }).then(() => {
            setMessage('');
          });
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            text: err,
            showConfirmButton: true,
          });
        });
    },
    [
      referralId,
      message,
      email, code,
      send_note
    ],
  );

  const handleMessageChange = useCallback(event => {
    setMessage(event.target.value);
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item md={12} xs={12}>
        <MyTypography>Send Message to Receiving Clinic</MyTypography>
      </Grid>
      <Grid item md={10} xs={12}>
        <TextareaAutosize
          key={'note-note'}
          aria-label="note-textarea"
          placeholder="Enter your message here."
          minRows={3}
          value={message}
          style={{ width: '100%' }}
          onChange={e => handleMessageChange(e)}
        />
      </Grid>

      <Grid item md={2} xs={12}>
        <MyButton
          style={{ height: '100%' }}
          type="button"
          color="primary"
          variant="outlined"
          loading={loading}
          onClick={() => onCreateReferralNote('external')}
        >
          {isFax ? 'Send Message(via Fax)': 'Send Message'}
        </MyButton>
      </Grid>
    </Grid>
  );
}

import React from 'react';
// import PropTypes from 'prop-types';
import * as yup from 'yup';
import { gridSpacing } from '../../common/themes/constants';
import { Grid } from '@mui/material';
import { HookFormRedTextField, HookFormTextField } from '../common/components';
import MobileNumberMask from '../common/components/mask/MobileNumberMask';
import ProviderOptions from './ProviderOptions';

export const referralInitialValues = {
  contact_email: '',
  physician: '',
  clinic_name: '',
  contact: '',
  office_phone: '',
  fax: '',
  provider_uuid: '',
  address: '',
  diagnosis: ''
};

export const referralValidationSchema = {
  contact_email: yup
    .string()
    .email('Invalid email')
    .required(''),
  physician: yup.string().required(''),
  clinic_name: yup
      .string()
      .required(''),
  contact: yup
    .string()
    .required(''),
  office_phone: yup
    .string()
    .required('')
    .length(12, 'Office phone number must have 10 digits'),
  fax: yup
      .string()
      .matches(/.{12,}/, {
        excludeEmptyString: true,
        message: 'Fax must have 10 digits',
      }),
  provider_uuid: yup.string().required(''),
};

export default function ReferralForm({ control, errors, prefix = null, providers, onProviderChange }) {
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item sm={12} xs={12}>
        <h3 style={{ margin: '0' }}>Referrer Information</h3>
      </Grid>
      <Grid item sm={6} xs={12}>
        <HookFormRedTextField
          id="contact_email"
          name="contact_email"
          type="email"
          placeholder="Enter Referring Contact Email Address"
          label="Referring Contact Email address *"
          autoComplete="contact_email"
          schema="contact_email"
          prefix={prefix}
          control={control}
          errors={errors}
          autoFocus
          fullWidth
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <HookFormRedTextField
          id="physician"
          name="physician"
          placeholder="Enter Referring Physician's Name"
          label="Referring Physician's Name *"
          schema="physician"
          prefix={prefix}
          control={control}
          errors={errors}
          fullWidth
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <HookFormRedTextField
          id="clinic_name"
          name="clinic_name"
          placeholder="Enter Referring Clinic"
          label="Referring Clinic *"
          schema="clinic_name"
          prefix={prefix}
          control={control}
          errors={errors}
          fullWidth
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <HookFormRedTextField
          id="contact"
          name="contact"
          type="tel"
          placeholder="Enter Referring Contact Person"
          label="Referring Contact Person *"
          autoComplete="contact"
          schema="contact"
          prefix={prefix}
          control={control}
          errors={errors}
          fullWidth
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <HookFormRedTextField
          id="office_phone"
          name="office_phone"
          type="tel"
          placeholder="Enter Referring Office Phone"
          label="Referring Office Phone *"
          autoComplete="office_phone"
          schema="office_phone"
          prefix={prefix}
          control={control}
          errors={errors}
          InputProps={{
            inputComponent: MobileNumberMask,
          }}
          fullWidth
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <ProviderOptions prefix={prefix} control={control} errors={errors} providers={providers} onProviderChange={onProviderChange}/>
      </Grid>

      <Grid item sm={6} xs={12}>
        <HookFormTextField
          id="fax"
          name="fax"
          type="tel"
          placeholder="Enter Fax number"
          label="Referring Fax number"
          autoComplete="fax"
          schema="fax"
          prefix={prefix}
          control={control}
          errors={errors}
          InputProps={{
            inputComponent: MobileNumberMask,
          }}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

import React from 'react';
import { Controller } from 'react-hook-form';
import HookFormUtils from './HookFormUtils';
import { Checkbox, FormControlLabel, FormLabel } from '@mui/material';
import { isEmpty } from 'lodash';
import StringUtils from '../../services/StringUtils';

export default function HookFormCheckboxGroup({
  control,
  schema,
  variant,
  type = 'text',
  loading,
  errors,
  label,
  prefix = null,
  choices = [],
  ...others
}) {
  const error = HookFormUtils.getFieldError(errors, schema, prefix);
  const _schema = HookFormUtils.getSchemaName(schema, prefix);

  return (
    <>
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        control={control}
        name={_schema}
        render={({ field: { onChange, value } }) =>
          choices.map((item, index) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={event => {
                      if (event.target.checked) {
                        var val = isEmpty(value) ? [] : value;
                        val.push(item);
                        value = val;
                      } else {
                        const index = value.indexOf(item);
                        if (index > -1) {
                          value = value.splice(index, 1); 
                        }
                      }
                      onChange(value);
                    }}
                    value={value.includes(item)}
                    checked={value.includes(item)}
                  />
                }
                label={StringUtils.toTitleCase2(item)}
              />
            );
          })
        }
      />
      {error && (
        <FormLabel
          component="radio-error"
          color="error"
          style={{ margin: '3px 14px 0px', color: 'rgb(244, 67, 54)' , fontFamily: 'Roboto, sans-serif', fontSize: '0.75rem', fontWeight: '400', lineHeight: '1.66' }}
        >
          {error && error.message}
        </FormLabel>
      )}
    </>
  );
}

import React, {useState, useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import {MyAlert} from './components';

export default function Error({error}) {
    const [errorMessage, setErrorMessage] = useState('');
    const htmlElRef = useRef(null);

    useEffect(() => {
        if (!error) {
            setErrorMessage('');
            return;
        }

        if (error.message) {
            setErrorMessage(error.message);
        } else if(error.data && error.data.message) {
            setErrorMessage(error.data.message);
        }

        if (htmlElRef.current) {
            htmlElRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [error]);

    if (!errorMessage) return null;

    return (
        <MyAlert ref={htmlElRef} severity="error">
            {errorMessage}
        </MyAlert>
    );
}
import React, { useEffect } from 'react';

export default function BackendErrorHandler({error, setError, clearErrors}) {

    useEffect(() => {
        if (error && error.data && error.data.errors) {
            clearErrors();
            const errors = {...error.data.errors};

            for(let k in errors) {
                if (Array.isArray(errors[k])) {
                    errors[k].forEach(errMsg => {
                        setError(k, {type: 'custom', message: errMsg}, {shouldFocus: true});
                    });
                }
            }
        } else {
            clearErrors();
        }
    }, [error, setError, clearErrors]);

    return null;
}
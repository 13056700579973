import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ApiService from '../../common/services/ApiService';
import {
  CREATE_REFERRAL_NOTE_BEGIN,
  CREATE_REFERRAL_NOTE_SUCCESS,
  CREATE_REFERRAL_NOTE_FAILURE,
  CREATE_REFERRAL_NOTE_DISMISS_ERROR,
} from './constants';

export function createReferralNote(referralUUID, args) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: CREATE_REFERRAL_NOTE_BEGIN,
    });
    
    const promise = new Promise((resolve, reject) => {
        
        ApiService.createReferralNote(referralUUID, args).then(
          (res) => {
            dispatch({
              type: CREATE_REFERRAL_NOTE_SUCCESS,
              data: res,
            });
            resolve(res);
          },
          // Use rejectHandler as the second argument so that render errors won't be caught.
          (err) => {
            dispatch({
              type: CREATE_REFERRAL_NOTE_FAILURE,
              data: { error: err },
            });
            reject(err);
          },
        );
      
    });

    return promise;
  };
}

export function dismissCreateReferralNoteError() {
  return {
    type: CREATE_REFERRAL_NOTE_DISMISS_ERROR,
  };
}

export function useCreateReferralNote() {
  const dispatch = useDispatch();

  const { createReferralNotePending, createReferralNoteError } = useSelector(
    state => ({
      createReferralNotePending: state.referral.createReferralNotePending,
      createReferralNoteError: state.referral.createReferralNoteError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((referralUUID, args) => {
    return dispatch(createReferralNote(referralUUID, args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissCreateReferralNoteError());
  }, [dispatch]);

  return {
    createReferralNote: boundAction,
    createReferralNotePending,
    createReferralNoteError,
    dismissCreateReferralNoteError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CREATE_REFERRAL_NOTE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        createReferralNotePending: true,
        createReferralNoteError: null,
      };

    case CREATE_REFERRAL_NOTE_SUCCESS:
      // The request is success
      return {
        ...state,
        createReferralNotePending: false,
        createReferralNoteError: null,
      };

    case CREATE_REFERRAL_NOTE_FAILURE:
      // The request is failed
      return {
        ...state,
        createReferralNotePending: false,
        createReferralNoteError: action.data.error,
      };

    case CREATE_REFERRAL_NOTE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        createReferralNoteError: null,
      };

    default:
      return state;
  }
}

import React from 'react';
import { capitalize, isEmpty, isObject } from 'lodash';
import { Autocomplete, Chip } from '@mui/material';
import MyTextField from '../../common/components/MyTextField';
import StringUtils from '../../common/services/StringUtils';

export default function ChoicesOptions({ value, name, 
    variant='outlined', label='Choices',options=[],errorMessage='', error=false, onChange}) {
  const data = [];

  return (
    <Autocomplete
      {...{
        includeInputInList: true,
        multiple: true,
        freeSolo: true,
        fullWidth: true,
      }}
      fullWidth
      autoSelect
      options={options}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(props, option) => (
        <li key={option.value} {...props}>
          {capitalize(option.label)}
        </li>
      )}
      renderInput={params => {
        return (
          <MyTextField
            {...params}
            label={label}
            variant={variant}
            error={error ? true : false}
            helperText={errorMessage}
          />
        );
      }}
      value={value}
      name={name}
      onChange={(_, data) => {
        console.log(data);
        if (Array.isArray(data)) {
          const parsedData = data.map(_item => {
            return isObject(_item) && _item.hasOwnProperty('value') ? _item.value : _item;
          });
          onChange(parsedData);
        } else if (!isEmpty(data) && typeof data === 'object') {
          if (data.inputValue) {
            onChange(data.inputValue);
          } else if (data.value) {
            onChange(data.value);
          }
        }
      }}
      renderTags={(value, getTagProps) => {
        if (!value) {
          return value;
        }

        if (typeof value === 'string') {
          const index = options.length;
          return <Chip variant="outlined" label={value} {...getTagProps({ index })} />;
        }

        return value.map((option, index) => (
          <Chip variant="outlined" label={StringUtils.toTitleCase2(option)} {...getTagProps({ index })} />
        ));
      }}
      // onInputChange={(_, data) => {
      //     onChange(data);
      // }}

      isOptionEqualToValue={(option, value) => {
        if (!value) return false;

        return typeof option === 'object' ? option.value === value : option === value;
      }}
    />
    //   <HookFormAutocomplete
    //     id="specializations"
    //     name="specializations"
    //     placeholder="Enter specializations"
    //     label="Specializations *"
    //     schema="specializations"
    //     prefix={prefix}
    //     control={control}
    //     errors={errors}
    //     autocompleteProps={{
    //       includeInputInList: true,
    //       multiple: true,
    //       freeSolo: true,
    //       fullWidth: true,
    //     }}
    //     options={
    //       data && data.hasOwnProperty('rows')
    //         ? data.rows.map(i => ({ value: i.name, label: capitalize(i.name) }))
    //         : []
    //     }
    //     getOptionLabel={option => {
    //       // Value selected with enter, right from the input
    //       if (typeof option === 'string') {
    //         return option;
    //       }
    //       // Add "xxx" option created dynamically
    //       if (option.inputValue) {
    //         return option.inputValue;
    //       }
    //       // Regular option
    //       return option.label;
    //     }}
    //     renderOption={(props, option) => (
    //       <li key={option.value} {...props}>
    //         {capitalize(option.label)}
    //       </li>
    //     )}
    //   />
  );
}

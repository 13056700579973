import React from 'react';
import { IconFileDescription, IconFileImport } from "@tabler/icons";

export const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file;
    const iconProps = {
      className: classes.image,
    };

    switch (type) {
      case 'application/pdf':
        return <IconFileDescription {...iconProps} />;
      default:
        return <IconFileImport {...iconProps} />;
    }
  };
export const AUTH_LOG_IN_BEGIN = 'AUTH_LOG_IN_BEGIN';
export const AUTH_LOG_IN_SUCCESS = 'AUTH_LOG_IN_SUCCESS';
export const AUTH_LOG_IN_FAILURE = 'AUTH_LOG_IN_FAILURE';
export const AUTH_LOG_IN_DISMISS_ERROR = 'AUTH_LOG_IN_DISMISS_ERROR';
export const AUTH_ACTIVATE_ACCOUNT_BEGIN = 'AUTH_ACTIVATE_ACCOUNT_BEGIN';
export const AUTH_ACTIVATE_ACCOUNT_SUCCESS = 'AUTH_ACTIVATE_ACCOUNT_SUCCESS';
export const AUTH_ACTIVATE_ACCOUNT_FAILURE = 'AUTH_ACTIVATE_ACCOUNT_FAILURE';
export const AUTH_ACTIVATE_ACCOUNT_DISMISS_ERROR = 'AUTH_ACTIVATE_ACCOUNT_DISMISS_ERROR';
export const AUTH_VERIFY_TWO_FACTOR_BEGIN = 'AUTH_VERIFY_TWO_FACTOR_BEGIN';
export const AUTH_VERIFY_TWO_FACTOR_SUCCESS = 'AUTH_VERIFY_TWO_FACTOR_SUCCESS';
export const AUTH_VERIFY_TWO_FACTOR_FAILURE = 'AUTH_VERIFY_TWO_FACTOR_FAILURE';
export const AUTH_VERIFY_TWO_FACTOR_DISMISS_ERROR = 'AUTH_VERIFY_TWO_FACTOR_DISMISS_ERROR';
export const AUTH_RESET_PASSWORD_BEGIN = 'AUTH_RESET_PASSWORD_BEGIN';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_FAILURE = 'AUTH_RESET_PASSWORD_FAILURE';
export const AUTH_RESET_PASSWORD_DISMISS_ERROR = 'AUTH_RESET_PASSWORD_DISMISS_ERROR';
export const AUTH_FORGOT_PASSWORD_BEGIN = 'AUTH_FORGOT_PASSWORD_BEGIN';
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS';
export const AUTH_FORGOT_PASSWORD_FAILURE = 'AUTH_FORGOT_PASSWORD_FAILURE';
export const AUTH_FORGOT_PASSWORD_DISMISS_ERROR = 'AUTH_FORGOT_PASSWORD_DISMISS_ERROR';

import React, { useCallback, useContext, useEffect } from 'react';
import { Stack, CircularProgress } from '@mui/material';
import CreateClinicForm, {
  initialValues,
  createClinicValidationSchema,
} from '../CreateClinic/CreateClinicForm';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MyButton } from '../../../common/components';
import { isEmpty, isString, omit } from 'lodash';
import Swal from 'sweetalert2';
import { gridSpacing } from '../../../../common/themes/constants';
import { useHistory } from 'react-router-dom';
import BackendErrorHandler from '../../../common/components/hook-form/BackendErrorHandler';
import { mapKeys } from 'lodash';
import { useMutation } from '@apollo/client';
import clientGraphql from '../../../../common/apollo-graphql';
import ClinicContext from '../../context/ClinicContext';
import { SAVE_CLINIC_MUTATION } from '../../gql/Mutation';

export default function EditClinic({ data, loading }) {
  const { refetch } = useContext(ClinicContext);
  const [save_clinic, { loading: saveClinicLoading, error: saveClinicError }] = useMutation(
    SAVE_CLINIC_MUTATION,
    {
      client: clientGraphql,
    },
  );
  const history = useHistory();

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    reset,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(createClinicValidationSchema),
    shouldFocusError: true,
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  const handleOnDocumentsChanged = useCallback(
    docs => {
      setValue('required_documents', docs);
      trigger('required_documents');
    },
    [setValue, trigger],
  );

  const handleRetrieve = useCallback(
    (res) => {
      const feature = res.features[0];
      setValue('address_line1', feature.properties.address_line1);
      setValue('address_city', feature.properties.place);
      setValue('address_state', feature.properties.region);
      setValue('address_zip', feature.properties.postcode);
      trigger('address_line1');
      trigger('address_city');
      trigger('address_state');
      trigger('address_zip');
    },
    [setValue, trigger]
  );

  useEffect(() => {
    if (data && data.clinic) {
      const { clinic } = data;
      var newClinic = JSON.parse(JSON.stringify(clinic));
      let addressJson = {};
      newClinic.code = isEmpty(newClinic.code) ? '' : newClinic.code;
      newClinic.referral_network = !isEmpty(newClinic) && !isEmpty(newClinic.referral_network) && !isEmpty(newClinic.referral_network.name) ? newClinic.referral_network.name : '';
      newClinic.organization = !isEmpty(newClinic) && !isEmpty(newClinic.organization) && !isEmpty(newClinic.organization.name) ? newClinic.organization.name : '';
      if (!isEmpty(clinic.address)) {
        if (isString(clinic.address)) {
          addressJson = JSON.parse(clinic.address);
        } else {
          addressJson = mapKeys(clinic.address, (value, key) => {
            return 'address_' + key;
          });
        }
      }
      newClinic['office_phone'] = '';
      newClinic['fax'] = '';
            
      if('extra_info' in newClinic && !isEmpty(newClinic['extra_info'])){
          var extra_info = JSON.parse(JSON.parse(newClinic['extra_info']));
          newClinic['office_phone'] = 'office_phone' in extra_info ? extra_info['office_phone']:'';
          newClinic['fax'] = 'fax' in extra_info ? extra_info['fax']:'';
      }
      if('extra_info' in newClinic){
        newClinic = omit(newClinic, ['extra_info']);
      }

      const resetData = {
        ...newClinic,
        ...addressJson,
      };
      reset(resetData);
      trigger();
    }
  }, [data, reset, trigger, handleOnDocumentsChanged]);

  const onSubmit = data => {
    var newData = omit(data, [
      'address___typename',
      '__typename',
      'providers',
      'specializations',
      'users',
      'address',
      'full_address',
    ]);
    newData['providers'] = [];
    newData['users'] = [];
    newData['extra_info'] = JSON.stringify({'office_phone': newData['office_phone'], 'fax': newData['fax']});
    if(isEmpty(newData['referral_network'])){
      newData['referral_network'] = "";
    }
    newData = omit(newData, [
      'office_phone',
      'fax',
    ]);

    save_clinic({
      client: clientGraphql,
      variables: {
        clinic: {
          ...newData,
        },
      },
    }).then(res => {
      if (!isEmpty(res) && !isEmpty(res.data) && !isEmpty(res.data.save_clinic)) {
        Swal.fire({
          icon: 'success',
          text: 'Successfully updated a clinic',
          showConfirmButton: true,
        }).then(() => {
          refetch();
          history.push('/manage/clinics/');
        });
      }
    });
  };

  if (loading || !data || !data.clinic) return <CircularProgress />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackendErrorHandler error={saveClinicError} setError={setError} clearErrors={clearErrors} />

      <Stack direction="column" spacing={gridSpacing}>
        <CreateClinicForm
          documents={getValues('required_documents')}
          onDocumentsChanged={handleOnDocumentsChanged}
          control={control}
          prefix={null}
          errors={errors}
          handleRetrieve={handleRetrieve}
        />

        <Stack direction="row" justifyContent="flex-end">
          <MyButton
            type="submit"
            variant="contained"
            size="large"
            disabled={!isValid}
            loading={saveClinicLoading}
            fullWidth={false}
          >
            Update Clinic
          </MyButton>
        </Stack>
      </Stack>
    </form>
  );
}

// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { WidgetCreateReferral } from './';
import PublicClinicDirectory from './PublicClinicDirectory';
import PublicReviewReferral from './PublicReviewReferral';

export default {
  path: '',
  childRoutes: [
    { path: 'directory/:clinic_code', component: WidgetCreateReferral },
    { path: 'directory', component: PublicClinicDirectory },
    { path: 'public/:id/referral/review/:code', component: PublicReviewReferral },
    // { path: ':id', component: WidgetCreateReferral },
  ],
};

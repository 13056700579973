import { Button, Stack } from '@mui/material';
import React from 'react';
import { MyDataGrid, MyTypography } from '../../common/components';
import DateTimeUtils from '../../common/services/DateTimeUtils';

const columns = [
  {
    field: 'patient',
    headerName: 'Patient Name',
    width: 180,
    valueGetter: params => {
      return params.row.referral.getFullName();
    },
  },
  {
    field: 'referrer',
    headerName: 'Referral Source',
    width: 250,
    // valueGetter: params => {
    //   return params.row.referral.getReferrerClinic();
    // },
    renderCell: params => {
      return (
        <Stack direction="column" spacing={1}>
          <MyTypography variant="h5" component="h5" color="text.primary" style={{'margin': '0'}}>
            {params.row.referral.getReferrerClinic()}
          </MyTypography>
          {params.row.referral.getReferrerClinic().includes('★') ? (<MyTypography variant="h6" component="h6" color="text.primary" style={{'fontStyle': 'italic', 'margin': '0'}}>
            Submitted via Public Form
          </MyTypography>):null}
          
        </Stack>
      );
    },
  },
  {
    field: 'provider',
    headerName: 'Provider',
    width: 160,
    valueGetter: params => {
      return params.row.referral.getProviderName();
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    valueGetter: params => {
      return params.row.referral.getReceiverStatus();
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 252,
    headerAlign: 'center',
    sortable: false,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: params => {
      return (
        <Stack direction="row" spacing={1}>
          <Button
            color="secondary"
            onClick={e => {
              e.stopPropagation();
              params.row.actions.onViewNotes(params.row.id);
            }}
          >
            View Log
          </Button>
          <Button
            color="secondary"
            onClick={e => {
              e.stopPropagation();
              params.row.actions.onReview(params.row.id, params.row);
            }}
          >
            Review
          </Button>
          {(params.row.status === 'declined' ||
          params.row.status === 'scheduled' ||
          params.row.status === 'ref_completed') ? (
            <Button
              color="secondary"
              onClick={e => {
                e.stopPropagation();
                params.row.actions.onComplete(params.row.id);
              }}
            >
              Complete
            </Button>
          ) : null}
        </Stack>
      );
    },
  },
  {
    field: 'appointments',
    headerName: 'Appointment Date or Decline Reason',
    width: 300,
    valueGetter: params => {
      return params.row.referral.getDateOrReason();
    },
  },
  {
    field: 'sent_at',
    headerName: 'Date Received',
    width: 250,
    valueGetter: params => {
      return params.row.referral.receivedFormattedDate();
    },
    sortComparator: DateTimeUtils.dateSortComparator
  },
  {
    field: 'updated_by',
    headerName: 'Last Updated By',
    width: 250,
    valueGetter: params => {
      return params.row.referral.getLastUpdatedByFullName();
    },
  },
  {
    field: 'updated_at',
    headerName: 'Last Updated At',
    width: 250,
    valueGetter: params => {
      return params.row.referral.formattedDate();
    },
    sortComparator: DateTimeUtils.dateSortComparator
  },
];

export default function ActiveReceivedReferralsTable({
  onFilterChanged,
  onChangeDropdown,
  onSelectedRowsChanged,
  loading,
  data,
  onClick,
  onViewNotes,
}) {
  return (
    <MyDataGrid
      onFilterChanged={onFilterChanged}
      onChangeDropdown={onChangeDropdown}
      onSelectedRowsChanged={onSelectedRowsChanged}
      loading={loading}
      data={data}
      columns={columns}
      onClick={onClick}
      onViewNotes={onViewNotes}
      showToolbar={true}
      selections={['By Name', 'By Birthdate']}
      minTableHeight={'calc(100vh - 192px)'}
      getRowId={row => {
        return row.id;
      }}
    />
  );
}

import React from 'react';
import {Box} from '@mui/material';
import NavigationScroll from '../common/NavigationScroll';
import HotjarTracking from '../common/components/HotjarTracking';

export default function App({ children }) {
  return (
    <div>
      <HotjarTracking />
      
      <NavigationScroll>
        <Box className="home-app"
             sx={{height: '100vh'}}>
          <Box className="page-container"
               sx={{height: '100%'}}>{children}</Box>
        </Box>
      </NavigationScroll>
    </div>
  );
}

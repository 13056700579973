import { isEmpty } from 'lodash';

export default class HookFormUtils {

    static getSchemaName(schema, prefix=null) {
        return prefix
                ? `${prefix}.${schema}`
                : schema;
    }

    static getFieldError(errors, schema, prefix = null) {
        if (!errors || isEmpty(errors)) return null;

        if (!prefix && errors.hasOwnProperty(schema)) return errors[schema];

        return (errors.hasOwnProperty(prefix) && errors[prefix].hasOwnProperty(schema))
               ? errors[prefix][schema]
               : null;
    }

    static dirtyValues(dirtyFields, allValues) {
        // NOTE: Recursive function.

        // If *any* item in an array was modified, the entire array must be submitted, because there's no
        // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
        if (dirtyFields === true || Array.isArray(dirtyFields)) {
            return allValues;
        }

        // Here, we have an object.
        return Object.fromEntries(
            Object.keys(dirtyFields).map((key) => [
                key,
                this.dirtyValues(dirtyFields[key], allValues[key])
            ])
        );
    }
}
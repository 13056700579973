import { useSubscription } from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import AuthContext from '../auth/context/AuthContext';
import { GET_REFERRAL_NOTES_BY_UUID } from './gql';
import ReferralNotesTable from './tables/ReferralNotesTable';

export default function ReferralNotes({ uuid, refresh, isExternal }) {
  const [userUuid, setUserUuid] = useState({});
  const currentUser = useContext(AuthContext);
  useEffect(() => {
    if (!isEmpty(currentUser)) {
      setUserUuid(currentUser.uuid);
    }
  }, [currentUser]);

  const { loading, data } = useSubscription(GET_REFERRAL_NOTES_BY_UUID, {
    variables: {
      uuid: uuid,
      userUUID: userUuid,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const handleOnSelectedRowsChanged = useCallback(selectedRows => {
  }, []);

  const handleFilterChanged = useCallback(e => {
  }, []);

  return (
    <ReferralNotesTable
      onFilterChanged={handleFilterChanged}
      onSelectedRowsChanged={handleOnSelectedRowsChanged}
      loading={loading}
      isFromNotes={true}
      data={
        !isEmpty(data) && !loading
          ? { rows: data.rows, count: data.rows.count }
          : { rows: [], count: 0 }
      }
    />
  );
}

// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { LogIn, ActivateUserAccount, VerifyTwoFactor, ForgotPassword, ResetPassword } from './';

export default {
  path: '',
  name: 'Authentication',
  childRoutes: [
    { path: '/login', component: LogIn },
    { path: '/account/activate', component: ActivateUserAccount },
    { path: '/auth/2fa/verify', component: VerifyTwoFactor },
    { path: '/forgot-password', component: ForgotPassword },
    { path: '/password-reset/:token/', component: ResetPassword },
  ],
};

import CONFIG  from './../config';
import {
    ApolloClient,
    InMemoryCache,
    HttpLink,
    split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';

const GRAPHQL_URL = CONFIG.GRAPHQL_PORT !== '8080'
    ? `${CONFIG.GRAPHQL_HOST}/v1/graphql`
    : `${CONFIG.GRAPHQL_HOST}:${CONFIG.GRAPHQL_PORT}/v1/graphql`;
const WS_PROTOCOL = CONFIG.GRAPHQL_PROTOCOL  === 'https' ? 'wss' : 'ws';
const GRAPHQL_HEADERS = CONFIG.GRAPHQL_SECRET
    ? {'x-hasura-admin-secret': CONFIG.GRAPHQL_SECRET}
    : {};

// Create a WebSocket link
const wsLink = new WebSocketLink({
    uri: `${WS_PROTOCOL}://${GRAPHQL_URL}`,
    options: {
        reconnect: true,
        timeout: 30000,
        connectionParams: () => {
            return {
                // for admin authentication, not advisable
                // TODO: need to change this to web hook authentication
                headers: GRAPHQL_HEADERS,
            };
        },
    }
});

// Create an http link
const httpLink = new HttpLink({
    uri: `${CONFIG.GRAPHQL_PROTOCOL}://${GRAPHQL_URL}`,
    credentials: 'include', // this is needed for cookie authentication
    // headers: {
    //     'Accept-encoding': 'gzip',
    // }
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // TODO: Create Singleton Auth Class for token
    //  const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...GRAPHQL_HEADERS, // for admin authentication, not advisable
            ...headers,
        },
    };
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const splitLink = split(
    // split based on operation type
    ({query, setContext}) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink
);

const client = new ApolloClient({
    link: authLink.concat(splitLink),
    cache: new InMemoryCache(),
});

export default client;
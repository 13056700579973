import { MyDataGrid } from '../../common/components';
import React from 'react';
import { isEmpty } from 'lodash';
import SpecializationList from './SpecializationList';

const columns = [
  {
    field: 'provider',
    headerName: 'Provider',
    width: 200,
    valueGetter: params => {
      return params.row.display_name;
    },
  },
  {
    field: 'specialization',
    headerName: 'Specialization',
    sortable: false,
    width: 220,
    valueGetter: params => {
      const specializations = params.row.specializations.map(item => item.name);
      return specializations.join(', ');
    },
    renderCell: params => {
      return <SpecializationList params={params} />;
    },
  },
  {
    field: 'name',
    headerName: 'Clinic Name',
    width: 300,
    valueGetter: params => {
      const clinicName = !isEmpty(params.row.clinic) ? params.row.clinic.name : '';
      return clinicName;
    },
  },
  {
    field: 'full_address',
    headerName: 'Address',
    width: 300,
    valueGetter: params => {
      return params.row.clinic.full_address;
    },
  },
];

export default function FindClinicTable({
  onFilterChanged,
  onSelectedRowsChanged,
  loading,
  data,
  onClick,
}) {
  return (
    <MyDataGrid
      onFilterChanged={onFilterChanged}
      onSelectedRowsChanged={onSelectedRowsChanged}
      loading={loading}
      data={data}
      columns={columns}
      onClick={onClick}
      showToolbar={true}
      hideFooter={false}
      hideFooterPagination={false}
      getRowId={row => {
        return row.id;
      }}
      minTableHeight={'calc(100vh - 270px)'}
    />
  );
}

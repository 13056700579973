import React from 'react';
import {
    Stack,
    IconButton,
} from '@mui/material';
import { IconPencil, IconTrash, IconEye, IconSettings } from '@tabler/icons';
import MyDataGrid from '../../../common/components/MyDataGrid';
import { capitalize } from 'lodash';

const columns = [
    { field: 'name', headerName: 'Clinic Name', width: 200, flex: 1},
    { field: 'full_address', headerName: 'Address', width: 300, flex: 1 },
    {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        width: 210,
        headerAlign: 'center',
        renderCell: (params) => {
            return  (
                <Stack direction="row" spacing={1} sx={{m: "auto"}}>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            params.row.actions.onEdit(params.row.id);
                        }}>
                        <IconPencil />
                    </IconButton>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            params.row.actions.onDelete(params.row.id);
                        }}>
                        <IconTrash />
                    </IconButton>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            params.row.actions.onView(params.row.id);
                        }}>
                        <IconEye />
                    </IconButton>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            params.row.actions.onReferralSettings(params.row.id);
                        }}>
                        <IconSettings />
                    </IconButton>
                </Stack>
            );
        }
    },
];

export default function NonAffiliateClinicsTable({
    onFilterChanged,
    onAdd,
    onDelete,
    onEdit,
    onSelectedRowsChanged,
    loading,
    data,
}) {
    return (
        <MyDataGrid
            onFilterChanged={onFilterChanged}
            onAdd={onAdd}
            addButtonText="Enroll Clinic"
            onDelete={onDelete}
            onEdit={onEdit}
            onSelectedRowsChanged={onSelectedRowsChanged}
            loading={loading}
            data={data}
            columns={columns}
            getRowId={(row) => {
                return row.id;
            }}
            minTableHeight={'calc(100vh - 232px)'}
        />
    );
}

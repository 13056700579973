import React from 'react';
import { MyDataGrid, MyIconButton } from '../../common/components';
import { IconPencil, IconTrash } from '@tabler/icons';
import { Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import SpecializationList from '../../referral/tables/SpecializationList';
import DocumentList from '../../referral/tables/DocumentList';

const columns = [
  {
    field: 'display_name',
    headerName: 'Name',
    sortable: true,
    width: 180,
    valueGetter: params => {
      const name =
        (!isEmpty(params.row.title) ? params.row.title + '. ' : '') + params.row.display_name;
      return name;
    },
  },
  {
    field: 'specializations',
    headerName: 'Specialization',
    sortable: false,
    width: 160,
    valueGetter: params => {
      const specializations = params.row.specializations.map(item => item.name);
      return specializations.join(', ');
    },
    renderCell: params => {
      return <SpecializationList params={params} />;
    },
  },
  {
    field: 'documents',
    headerName: 'Documents Needed',
    sortable: false,
    width: 300,
    valueGetter: params => {
      const docs = params.row.documents.map(item => item.document.name);
      return docs.join(', ');
    },
    renderCell: params => {
      return <DocumentList params={params} />;
    },
  },
  {
    field: 'can_receive',
    headerName: 'Can Receive Referral',
    sortable: false,
    width: 220,
    valueGetter: params => {
      return params.row.can_receive ? 'Yes' : 'No';
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    maxWidth: 200,
    headerAlign: 'center',
    sortable: false,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: params => {
      return (
        <Stack direction="row" spacing={1}>
          {params.row.actions.onEditProvider &&
            typeof params.row.actions.onEditProvider === 'function' && (
              <MyIconButton
                label="Edit Provider"
                onClick={e => {
                  e.stopPropagation();
                  params.row.actions.onEditProvider(params.row.id, params);
                }}
              >
                <IconPencil />
              </MyIconButton>
            )}
          {params.row.actions.onDelete && typeof params.row.actions.onDelete === 'function' && (
            <MyIconButton
              label="Delete Provider"
              onClick={e => {
                e.stopPropagation();
                params.row.actions.onDelete(params.row.uuid);
              }}
            >
              <IconTrash />
            </MyIconButton>
          )}
        </Stack>
      );
    },
  },
];

export default function ManageProvidersTable({ data, loading, onAdd, showToolbar = true }) {
  return (
    <MyDataGrid
      onAdd={onAdd}
      loading={loading}
      data={data}
      columns={columns}
      showToolbar={showToolbar}
      getRowId={row => {
        return row.id;
      }}
    />
  );
}

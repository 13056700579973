import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ApiService from '../../common/services/ApiService';
import {
  SAVE_REFERRAL_AS_DRAFT_BEGIN,
  SAVE_REFERRAL_AS_DRAFT_SUCCESS,
  SAVE_REFERRAL_AS_DRAFT_FAILURE,
  SAVE_REFERRAL_AS_DRAFT_DISMISS_ERROR,
} from './constants';

export function saveReferralAsDraft(referralUUID, args) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: SAVE_REFERRAL_AS_DRAFT_BEGIN,
    });
    
    const promise = new Promise((resolve, reject) => {
      if(isEmpty(referralUUID)){
        ApiService.saveReferralAsDraft(args).then(
          (res) => {
            dispatch({
              type: SAVE_REFERRAL_AS_DRAFT_SUCCESS,
              data: res,
            });
            resolve(res);
          },
          // Use rejectHandler as the second argument so that render errors won't be caught.
          (err) => {
            dispatch({
              type: SAVE_REFERRAL_AS_DRAFT_FAILURE,
              data: { error: err },
            });
            reject(err);
          },
        );
      } else {
        ApiService.saveReferralAsDraftByUUID(referralUUID, args).then(
          (res) => {
            dispatch({
              type: SAVE_REFERRAL_AS_DRAFT_SUCCESS,
              data: res,
            });
            resolve(res);
          },
          // Use rejectHandler as the second argument so that render errors won't be caught.
          (err) => {
            dispatch({
              type: SAVE_REFERRAL_AS_DRAFT_FAILURE,
              data: { error: err },
            });
            reject(err);
          },
        );
      }
      
    });

    return promise;
  };
}

export function dismissSaveReferralAsDraftError() {
  return {
    type: SAVE_REFERRAL_AS_DRAFT_DISMISS_ERROR,
  };
}

export function useSaveReferralAsDraft() {
  const dispatch = useDispatch();

  const { saveReferralAsDraftPending, saveReferralAsDraftError } = useSelector(
    state => ({
      saveReferralAsDraftPending: state.referral.saveReferralAsDraftPending,
      saveReferralAsDraftError: state.referral.saveReferralAsDraftError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((referralUUID, args) => {
    return dispatch(saveReferralAsDraft(referralUUID, args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissSaveReferralAsDraftError());
  }, [dispatch]);

  return {
    saveReferralAsDraft: boundAction,
    saveReferralAsDraftPending,
    saveReferralAsDraftError,
    dismissSaveReferralAsDraftError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SAVE_REFERRAL_AS_DRAFT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        saveReferralAsDraftPending: true,
        saveReferralAsDraftError: null,
      };

    case SAVE_REFERRAL_AS_DRAFT_SUCCESS:
      // The request is success
      return {
        ...state,
        saveReferralAsDraftPending: false,
        saveReferralAsDraftError: null,
      };

    case SAVE_REFERRAL_AS_DRAFT_FAILURE:
      // The request is failed
      return {
        ...state,
        saveReferralAsDraftPending: false,
        saveReferralAsDraftError: action.data.error,
        
      };

    case SAVE_REFERRAL_AS_DRAFT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        saveReferralAsDraftError: null,
      };

    default:
      return state;
  }
}

import { FormControlLabel, Grid, Switch } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';

export default function NotificationOptions({ notificationSettings = [], handleChange, size=4 }) {
  return (
    <>
      {!isEmpty(notificationSettings) &&
        notificationSettings.map((notification, index) => {
          return (
            <Grid item key={`grid.${notification.label}`} sm={size} xs={12}>
              <FormControlLabel
                key={`control.${notification.label}`}
                control={
                  <Switch
                    checked={notification.value}
                    key={`switch.${notification.label}`}
                    onChange={event => handleChange(index, notification)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={notification.label}
              />
            </Grid>
          );
        })}
    </>
  );
}
// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { Authenticated } from '../auth';
import { CreateReferral, ReviewReferral } from './';

export default {
  path: 'referral',
  component: Authenticated,
  childRoutes: [
    { path: 'send', component: CreateReferral },
    { path: ':id', component: CreateReferral },
  ],
};

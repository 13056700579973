// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.
// Learn more from the introduction of this approach:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da.

import { reducer as enrollPsrAndClinicReducer } from './enrollPsrAndClinic';
import { reducer as deleteClinicReducer } from './deleteClinic';
import { reducer as addClinicUserReducer } from './addClinicUser';
import { reducer as deleteClinicUserReducer } from './deleteClinicUser';

const reducers = [
  enrollPsrAndClinicReducer,
  deleteClinicReducer,
  addClinicUserReducer,
  deleteClinicUserReducer,
];

export default reducers;


import React from 'react';
export default function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          style={{minHeight:"600px"}}
          {...other}
      >
          {
              value === index &&
              children
          }
      </div>
  );
}

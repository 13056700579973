import React, { useState, useCallback, useEffect } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Typography,
  CircularProgress,
  Alert,
  Fade,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { gridSpacing } from '../../common/themes/constants';
import { MyButton, MyTypography } from '../common/components';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ReferralForm from './ReferralForm';
import { useLazyQuery, useMutation } from '@apollo/client';
import clientGraphqlPublic from '../../common/apollo-graphql-public';
import {
  SAVE_PUBLIC_REFERRAL_MUTATION,
  SAVE_PUBLIC_REFERRAL_QUESTIONS_MUTATION,
} from './gql/Mutation';
import Swal from 'sweetalert2';
import PublicPatientDemographicsForm from '../patient/PublicPatientReferralForm';
import PublicReferralDocuments from './PublicReferralDocuments';
import StringUtils from '../common/services/StringUtils';

import { GET_PUBLIC_REFERRER } from './gql/Query';
import { useStyles } from '../patient/styles/documentStyle';
import RenameDocDialog from '../patient/RenameDocDialog';
import { useUploadReferralMultipleDocuments } from '../referral/redux/uploadReferralMultipleDocuments';

export default function PublicCreateReferral({
  history,
  clinicData,
  requiredCustom = {},
  patientSchema = {},
  referralSchema = {},
  clinicLoading = true,
  clinic_code,
  initialValues = {},
}) {
  const classes = useStyles();
  const [openRenameFile, setOpenRenameFile] = useState(false);
  const [isSaveMisc, setIsSaveMisc] = useState(false);
  const [renameDoc, setRenameDoc] = useState([]);

  const handleClose = useCallback(() => {
    setOpenRenameFile(false);
  }, []);

  const [parsedQuestionData, setParsedQuestionData] = useState({});
  const [parsedData, setParsedData] = useState({});
  const [clinicId, setClinicId] = useState(null);
  const [fileNames, setFileNames] = useState({});
  const [fileObjects, setFileObjects] = useState({});
  const [document, setDocument] = useState({});
  const [qualifyingQuestions, setQualifyingQuestions] = useState({});
  const [fileObjectsRequired, setFileObjectsRequired] = useState({});
  const [disableSend, setDisableSend] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const {
    uploadReferralMultipleDocuments,
    uploadReferralMultipleDocumentsPending,
  } = useUploadReferralMultipleDocuments();

  const { handleSubmit, setValue, trigger, getValues, watch, control, formState } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(
      yup.object().shape({
        patient: yup.object().shape(patientSchema),
        referral: yup.object().shape(referralSchema),
      }),
    ),
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const gqlParams = {
    client: clientGraphqlPublic,
    variables: {
      email: '',
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
  };

  const [loadReferrerByEmail] = useLazyQuery(GET_PUBLIC_REFERRER, gqlParams);

  const [save_public_referral, { loading: savePublicReferralLoading }] = useMutation(
    SAVE_PUBLIC_REFERRAL_MUTATION,
    {
      client: clientGraphqlPublic,
    },
  );

  const [save_public_referral_questions] = useMutation(SAVE_PUBLIC_REFERRAL_QUESTIONS_MUTATION, {
    client: clientGraphqlPublic,
  });

  const autofillFieldByEmail = useCallback(() => {
    const email = getValues('referral.contact_email');
    loadReferrerByEmail({
      client: clientGraphqlPublic,
      variables: { email: email },
    }).then(result => {
      if (!isEmpty(result.data) && !isEmpty(result.data.public_referrer)) {
        setValue('referral.physician', result.data.public_referrer.metadata.physician);
        setValue('referral.clinic_name', result.data.public_referrer.metadata.clinic_name);
        setValue('referral.contact', result.data.public_referrer.metadata.contact);
        setValue('referral.office_phone', result.data.public_referrer.metadata.office_phone);
        setValue('referral.address', result.data.public_referrer.metadata.address);
        if (!isEmpty(result.data.public_referrer.metadata.fax)) {
          setValue('referral.fax', result.data.public_referrer.metadata.fax);
          trigger('referral.fax');
        }
        trigger('referral.physician');
        trigger('referral.clinic_name');
        trigger('referral.contact');
        trigger('referral.office_phone');
        trigger('referral.address');
      }
    });
  }, [setValue, trigger, loadReferrerByEmail, getValues]);

  useEffect(() => {
    if (!isEmpty(clinicData)) {
      const subscription = watch((value, { name, type }) => {
        if (
          !isEmpty(value.referral.contact_email) &&
          name === 'referral.contact_email' &&
          StringUtils.validateEmail(value.referral.contact_email)
        ) {
          autofillFieldByEmail();
        }
      });
      return () => subscription.unsubscribe();
    }
  }, [clinicData, autofillFieldByEmail, watch, trigger]);

  useEffect(() => {
    if (!isEmpty(renameDoc) && isSaveMisc) {
      setIsSaveMisc(false);
      var temp = fileObjects;
      var tempDoc = renameDoc;
      tempDoc = renameDoc.map(doc => {
        return doc['done'] = true;
      });
      console.log(tempDoc);
      temp[parseInt(document.document.id)] = renameDoc.slice();
      setFileObjects(temp);
    }
  }, [renameDoc, isSaveMisc, setIsSaveMisc, document, setFileObjects, fileObjects]);

  const onFileObjectChanged = useCallback(
    (fileList, name, showAlert, doc) => {
      var temp = fileObjects;
      temp = { ...fileList };
      setFileObjects(temp);
      setDisableSend(false);
      setShowAlert(showAlert);
      if (name === 'misc' && !isEmpty(doc)) {
        setOpenRenameFile(true);
        setDocument({ ...doc });
        var temp2 = { ...fileList };
        var renameDocs = temp2[parseInt(doc.document.id)];
        renameDocs = renameDocs.map(item => {
          if(isEmpty(item.referral_document_name)){
            var filename = item.file.name;
            item.referral_document_name = filename.substring(0, filename.lastIndexOf('.')) || filename;
          }
          return item;
        });
        setRenameDoc(renameDocs);
      }
      Object.keys(temp).map(key => {
        if (isEmpty(temp[key]) && fileObjectsRequired[key]) {
          setDisableSend(true);
        }
      });
    },
    [setFileObjects, fileObjects, fileObjectsRequired, setOpenRenameFile, setDocument],
  );

  const handleProviderChange = useCallback(
    value => {
      const clinic = clinicData;
      const provider = clinic.providers.filter(prov => prov.uuid === value.uuid);
      if (
        !isEmpty(provider) &&
        provider.length === 1 &&
        !isEmpty(parsedData) &&
        provider[0].id === parsedData.clinic.provider_id
      ) {
        return;
      }
      if (!isEmpty(provider) && provider.length === 1) {
        setValue('referral.provider_uuid', value.uuid);
        trigger('referral.provider_uuid');
        setClinicId(clinic.id);
        setQualifyingQuestions({});
        setParsedQuestionData({});
        setParsedData({
          clinic: {
            provider_id: provider[0].id,
            provider_name: provider[0].display_name,
            name: clinic.name,
            id: clinic.id,
            address: clinic.full_address,
            documents: provider[0].documents.filter(doc => {
              return doc.required;
            }),
            optionalDocuments: provider[0].documents.filter(doc => {
              return !doc.required;
            }),
          },
        });
      } else {
        setParsedData({});
      }
    },
    [setValue, trigger, clinicData, parsedData],
  );

  const handleFileRequiredChange = useCallback(
    (key, value) => {
      var rtemp = fileObjectsRequired;
      var qtemp = qualifyingQuestions;
      rtemp[key] = value;
      qtemp[key].answer = value ? 'Yes' : 'No';
      setFileObjectsRequired(rtemp);
      setQualifyingQuestions(qtemp);
      setDisableSend(false);
      Object.keys(fileObjects).map(key => {
        if (isEmpty(fileObjects[key]) && fileObjectsRequired[key]) {
          setDisableSend(true);
        }
      });
    },
    [
      fileObjectsRequired,
      setFileObjectsRequired,
      fileObjects,
      setQualifyingQuestions,
      qualifyingQuestions,
    ],
  );

  const onSubmit = dataParams => {
    var newData = JSON.parse(JSON.stringify(dataParams));
    newData.referral['clinic_receiver_id'] = clinicId;
    newData['referral'] = JSON.stringify(newData.referral);
    if ('phone' in newData.patient) {
      newData.patient.phone = newData.patient.phone.replace(/-/g, '');
    }
    if ('ssn' in newData.patient) {
      newData.patient.ssn = newData.patient.ssn.replace(/-/g, '');
    }
    save_public_referral({
      client: clientGraphqlPublic,
      variables: newData,
    })
      .then(res => {
        if (!isEmpty(res.data) && res.data.save_public_referral) {
          uploadReferralQuestions(res.data.save_public_referral);
        } else {
          Swal.fire({
            icon: 'error',
            text: 'Failed to submit a referral.',
            showConfirmButton: true,
          }).then(() => {
            //
          });
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          text: 'Failed to submit a referral.',
          showConfirmButton: true,
        }).then(() => {
          //
        });
      });
  };

  const handleRetrieve = useCallback(
    res => {
      const feature = res.features[0];
      if ('address_line1' in initialValues.patient) {
        setValue('patient.address_line1', feature.properties.address_line1);
        trigger('patient.address_line1');
      }
      if ('address_city' in initialValues.patient) {
        setValue('patient.address_city', feature.properties.place);
        trigger('patient.address_city');
      }
      if ('address_state' in initialValues.patient) {
        setValue('patient.address_state', feature.properties.region);
        trigger('patient.address_state');
      }
      if ('address_zip' in initialValues.patient) {
        setValue('patient.address_zip', feature.properties.postcode);
        trigger('patient.address_zip');
      }
    },
    [setValue, trigger, initialValues],
  );

  const uploadFiles = uuid => {
    var keys = Object.keys(fileObjects);
    var docIdList = [];
    var miscDocId = -1;
    parsedData.clinic.documents.map(doc => {
      docIdList.push(doc.document.id);
    });
    parsedData.clinic.optionalDocuments.map(doc => {
      if (doc.document.name === 'misc') {
        miscDocId = doc.document.id;
      }
    });
    var keyCount = 0;
    if (isEmpty(keys)) {
      Swal.fire({
        icon: 'success',
        text: 'Successfully submitted a referral.',
        showConfirmButton: true,
      }).then(() => {
        history.go(0);
      });
      return;
    }
    for (let key in fileObjects) {
      if (fileObjects.hasOwnProperty(key)) {
        if (!isEmpty(fileObjects[key])) {
          const formData = new FormData();
          if (key === miscDocId) {
            formData.append('use_filename', true);
            fileObjects[key].map((file, index) => {
              console.log(file.referral_document_name + '.' + file.file.path.split('.').pop());
              formData.append('files[]', file.file, file.referral_document_name + '.' + file.file.path.split('.').pop());
            });
          } else {
            fileObjects[key].map(file => {
              formData.append('files[]', file.file, key);
            });
          }
          formData.append(
            'document_id',
            key in qualifyingQuestions || docIdList.includes(key) ? Number(key) : Number(miscDocId),
          );
          formData.append('type', 'external');
          uploadReferralMultipleDocuments(uuid, formData)
            .then(data => {
              keyCount++;
              if (keys.length == keyCount) {
                Swal.fire({
                  icon: 'success',
                  text: 'Successfully submitted a referral.',
                  showConfirmButton: true,
                }).then(() => {
                  history.go(0);
                });
              }
            })
            .catch(err => {
              Swal.fire({
                icon: 'error',
                text: 'Failed to submit referral documents.',
                showConfirmButton: true,
              }).then(() => {
                history.go(0);
              });
            });
        } else {
          keyCount++;
          if (keys.length == keyCount) {
            Swal.fire({
              icon: 'success',
              text: 'Successfully submitted a referral.',
              showConfirmButton: true,
            }).then(() => {
              history.go(0);
            });
          }
        }
      }
    }
  };

  const uploadReferralQuestions = uuid => {
    var keys = Object.keys(qualifyingQuestions);
    var answers = [];
    for (let key in qualifyingQuestions) {
      if ('name' in qualifyingQuestions[key] && qualifyingQuestions[key].name !== 'misc') {
        answers.push(qualifyingQuestions[key]);
      }
    }
    if (isEmpty(keys)) {
      uploadFiles(uuid);
      return;
    }
    var newData = { uuid: uuid, answers: answers };
    save_public_referral_questions({
      client: clientGraphqlPublic,
      variables: newData,
    }).then(res => {
      if (!isEmpty(res.data) && res.data.save_referral_answers) {
        uploadFiles(uuid);
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Failed to submit a referral.',
          showConfirmButton: true,
        }).then(() => {
          //
        });
      }
    });
  };

  return (
    <>
      <Card>
        {!isEmpty(clinicData) ? (
          <CardHeader
            title={clinicData.name}
            subheader={
              <ul style={{ marginTop: 0, marginBottom: 0 }}>
                {!isEmpty(clinicData.full_address) ? (
                  <li>{'ADDRESS: ' + clinicData.full_address}</li>
                ) : null}
                {!isEmpty(clinicData.office_phone) ? (
                  <li>{'PHONE #: ' + clinicData.office_phone}</li>
                ) : null}
                {!isEmpty(clinicData.fax) ? <li>{'FAX #: ' + clinicData.fax}</li> : null}
              </ul>
            }
          />
        ) : null}
        <CardContent>
          {!isEmpty(clinicData) ? (
            <>
              <Fade
                in={showAlert}
                timeout={{ enter: 1000, exit: 1000 }}
                addEndListener={() => {
                  setTimeout(() => {
                    setShowAlert(false);
                  }, 4000);
                }}
              >
                <Alert
                  severity="success"
                  className={classes.alert}
                  onClose={() => {
                    setShowAlert(false);
                  }}
                >
                  <MyTypography variant="h2" component="h2" className={classes.typography}>
                    Successfully Uploaded Files
                  </MyTypography>
                </Alert>
              </Fade>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={gridSpacing}>
                  {requiredCustom != null ? (
                    <Grid item sm={12} xs={12}>
                      <ReferralForm
                        control={control}
                        prefix="referral"
                        errors={formState.errors}
                        providers={clinicData.providers}
                        onProviderChange={handleProviderChange}
                        requiredCustom={requiredCustom}
                      />
                    </Grid>
                  ) : null}
                  {requiredCustom != null ? (
                    <Grid item sm={12} xs={12}>
                      <PublicPatientDemographicsForm
                        control={control}
                        prefix="patient"
                        errors={formState.errors}
                        handleRetrieve={handleRetrieve}
                        requiredCustom={requiredCustom}
                      />
                    </Grid>
                  ) : null}

                  {!isEmpty(parsedData) ? (
                    <Grid item sm={12} xs={12}>
                      <PublicReferralDocuments
                        parsedQuestionData={parsedQuestionData}
                        setParsedQuestionData={setParsedQuestionData}
                        parsedData={parsedData}
                        fileNames={fileNames}
                        setFileNames={setFileNames}
                        fileObjects={fileObjects}
                        setFileObjects={setFileObjects}
                        fileObjectsRequired={fileObjectsRequired}
                        setFileObjectsRequired={setFileObjectsRequired}
                        handleFileRequiredChange={handleFileRequiredChange}
                        onFileObjectChanged={onFileObjectChanged}
                        qualifyingQuestions={qualifyingQuestions}
                        setQualifyingQuestions={setQualifyingQuestions}
                        setDisableSend={setDisableSend}
                        openRenameFile={openRenameFile}
                        setOpenRenameFile={setOpenRenameFile}
                        setIsSaveMisc={setIsSaveMisc}
                        renameDoc={renameDoc}
                        setRenameDoc={setRenameDoc}
                        handleClose={handleClose}
                      />
                    </Grid>
                  ) : null}

                  <Grid item sm={12} xs={12}>
                    <MyButton
                      type="submit"
                      variant="contained"
                      size="large"
                      disabled={!formState.isValid || disableSend}
                      loading={savePublicReferralLoading || uploadReferralMultipleDocumentsPending}
                    >
                      {!formState.isValid || disableSend
                        ? 'Please complete all required items to enable submission'
                        : 'Submit Referral'}
                    </MyButton>
                  </Grid>
                </Grid>
              </form>
            </>
          ) : clinicLoading && isEmpty(clinicData) ? (
            <Stack direction="row">
              <Typography gutterBottom variant="h5">
                Loading clinic referral form for {clinic_code}
              </Typography>

              <CircularProgress />
            </Stack>
          ) : (
            <Stack direction="column">
              <Typography gutterBottom variant="h4">
                Cannot load clinic referral form for Clinic {clinic_code}
              </Typography>
              <Typography gutterBottom variant="h5">
                Please contact the system administrator
              </Typography>
            </Stack>
          )}
        </CardContent>
      </Card>

      <RenameDocDialog
        handleClose={handleClose}
        openRenameFile={openRenameFile}
        renameDoc={renameDoc}
        setRenameDoc={setRenameDoc}
        setIsSaveMisc={setIsSaveMisc}
      />
    </>
  );
}

import React from 'react';
import {
    MenuItem,
} from '@mui/material';
import {HookFormSelect} from '../../common/components';
import {isEmpty, startCase} from 'lodash';
import { useTheme } from '@mui/material/styles';

export default function ClinicOptions({prefix=null, control, errors, clinics=[]}) {
    const theme = useTheme();
    return (
        <HookFormSelect
            id="clinic_id"
            type="select"
            select
            name="clinic_id"
            placeholder="Select clinic"
            label="Clinic"
            schema="clinic_id"
            disabled={isEmpty(clinics) || (!isEmpty(clinics) && clinics.length <= 1)}
            prefix={prefix}
            control={control}
            errors={errors}
            fullWidth
            InputLabelProps={{
                style: { color: theme.palette.secondary.main, background: theme.palette.primary.light, paddingLeft: '10px', paddingRight: '10px'},
              }}
        >
            {
                clinics &&
                clinics.map((clinic) => (
                    <MenuItem key={clinic.name} value={parseInt(clinic.id,10)}>
                        {startCase(clinic.name)}
                    </MenuItem>
                ))
            }
        </HookFormSelect>
    );
}
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import {tooltipPlacement} from '../../../common/themes/constants';

export default function MyTooltip({
    children,
    title,
    placement=tooltipPlacement
}) {
    return (
        <Tooltip title={title}
                 placement={placement}>
            {children}
        </Tooltip>
    );
}
import { gql } from '@apollo/client';

const USER_TABLE_FIELDS = gql`
  fragment UserFields on User {
    id
    email
    firstname
    lastname
    phone
    role{
      id
      name
    }
    activated_at
    notifications {
      key
      value
      label
    }
    permissions {
        key
        value
        label
    }
    clinic{
      id
      name
      providers {
        display_name
      }
    }
    clinics{
      id
      name
    }
  }
`;

export { USER_TABLE_FIELDS };

import  { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  CLINICS_DELETE_CLINIC_USER_BEGIN,
  CLINICS_DELETE_CLINIC_USER_SUCCESS,
  CLINICS_DELETE_CLINIC_USER_FAILURE,
  CLINICS_DELETE_CLINIC_USER_DISMISS_ERROR,
} from './constants';
import Swal from 'sweetalert2';
import ApiService from '../../../common/services/ApiService';
import clientGraphql from '../../../../common/apollo-graphql';

export function onDeleteUser(clinicId, userId, args) {
  const {action, dismissError, refetch} = args;
  
  dismissError();

  Swal.fire({
    title: 'Are you sure?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Proceed',
  }).then((result) => {
    if (result.isConfirmed) {
      action(userId).then(() => {
        refetch();
      }, err => {
        Swal.fire({
          text: (err && err.data && err.data.message) ? err.data.message : 'Cannot process request.',
          icon: 'error',
          showConfirmButton: true,
        });
      });
    }
  });
}

export function onRemoveUserFromClinic(clinicId, userId, args) {
  const {action, dismissError, refetch, delete_user_clinic} = args;
  

  dismissError();

  Swal.fire({
    title: "Are you sure?",
    text: 'Click proceed if you want to remove this user from this clinic.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Proceed',
  }).then((result) => {
    if (result.isConfirmed) {
      delete_user_clinic({
        client: clientGraphql,
        variables: {userId: userId, clinicId: clinicId},
      }).then( res => {
        refetch();
      });
    }
  });
}

export function onDeleteClinicUser(clinicId, userId, args) {
  const {action, dismissError, refetch} = args;

  dismissError();

  Swal.fire({
    title: 'Are you sure?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Proceed',
  }).then((result) => {
    if (result.isConfirmed) {
      action(clinicId, userId).then(() => {
        refetch();
      }, err => {
        Swal.fire({
          text: (err && err.data && err.data.message) ? err.data.message : 'Cannot process request.',
          icon: 'error',
          showConfirmButton: true,
        });
      });
    }
  });
}

export function deleteClinicUser(clinicId, userId) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: CLINICS_DELETE_CLINIC_USER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.deleteClinicUser(clinicId, userId).then(
        (res) => {
          Swal.fire({
            icon: 'success',
            text: 'User is successfully deleted.',
            showConfirmButton: true,
          }).then(() => {
            dispatch({
              type: CLINICS_DELETE_CLINIC_USER_SUCCESS,
              data: res,
            });
            resolve(res);
          });
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: CLINICS_DELETE_CLINIC_USER_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissDeleteClinicUserError() {
  return {
    type: CLINICS_DELETE_CLINIC_USER_DISMISS_ERROR,
  };
}

export function useDeleteClinicUser(clinicId, refetch, delete_user_clinic) {
  const dispatch = useDispatch();

  const { deleteClinicUserPending, deleteClinicUserError } = useSelector(
    state => ({
      deleteClinicUserPending: state.clinics.deleteClinicUserPending,
      deleteClinicUserError: state.clinics.deleteClinicUserError,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (deleteClinicUserPending) {
      Swal.showLoading();
    } else {
      Swal.hideLoading();
    }
  }, [deleteClinicUserPending]);

  const boundAction = useCallback((userId) => {
    return dispatch(deleteClinicUser(clinicId, userId));
  }, [dispatch, clinicId]);

  const boundClinicUserAction = useCallback((clinicId, userId) => {
    return dispatch(deleteClinicUser(clinicId, userId));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissDeleteClinicUserError());
  }, [dispatch]);

  const onDeleteBoundAction = useCallback((clinicId, userId, is_primary) => {

      return onRemoveUserFromClinic(
        clinicId,
        userId,
        {
          action: boundAction,
          refetch,
          delete_user_clinic,
          loading: deleteClinicUserPending,
          dismissError: boundDismissError,
        }
    );
    
  }, [boundAction, deleteClinicUserPending, boundDismissError, refetch, delete_user_clinic]);

  const onDeleteUserBoundAction = useCallback((clinicId, userId) => {
    return onDeleteClinicUser(
        clinicId,
        userId,
        {
          action: boundClinicUserAction,
          refetch,
          loading: deleteClinicUserPending,
          dismissError: boundDismissError,
        }
    );
  }, [boundClinicUserAction, deleteClinicUserPending, boundDismissError, refetch]);

  return {
    handleOnDeleteClinicUser: onDeleteBoundAction,
    handleOnDeleteUser: onDeleteUserBoundAction,
    deleteClinicUser: boundAction,
    deleteClinicUserPending,
    deleteClinicUserError,
    dismissDeleteClinicUserError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CLINICS_DELETE_CLINIC_USER_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        deleteClinicUserPending: true,
        deleteClinicUserError: null,
      };

    case CLINICS_DELETE_CLINIC_USER_SUCCESS:
      // The request is success
      return {
        ...state,
        deleteClinicUserPending: false,
        deleteClinicUserError: null,
      };

    case CLINICS_DELETE_CLINIC_USER_FAILURE:
      // The request is failed
      return {
        ...state,
        deleteClinicUserPending: false,
        deleteClinicUserError: action.data.error,
      };

    case CLINICS_DELETE_CLINIC_USER_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        deleteClinicUserError: null,
      };

    default:
      return state;
  }
}

import React, {useState, useCallback, useMemo, useContext} from 'react';
import {
    Grid,
    Card,
    CardContent,
    CardHeader,
} from '@mui/material';
import {IconPlus, IconQuestionCircle} from '@tabler/icons';
import { isEmpty } from 'lodash';
// import UpdateClinicQuestionDialog from './UpdateClinicQuestionDialog';
import ManageQuestionsTable from './ManageQuestionsTable';
import { MyButton } from '../../common/components';
import DocumentContext from '../context/DocumentContext';
import Swal from 'sweetalert2';
import AddQuestionDialog from '../dialog/AddQuestionDialog';
import useParsedTableData from '../../common/hooks/useParsedTableData';
import { DELETE_QUESTION_MUTATION } from '../gql/Mutation';
import { useMutation } from '@apollo/client';
import clientGraphql from '../../../common/apollo-graphql';
import UpdateQuestionDialog from '../dialog/UpdateQuestionDialog';

export default function DocumentQuestionsTable({history, data, loading}) {
    const {id: documentId, refetch} = useContext(DocumentContext);
    const [question, setQuestion] = useState({});
    const [addQuestionDialogVisible, setAddQuestionDialogVisible] = useState(false);
    const [updateQuestionDialogVisible, setUpdateQuestionDialogVisible] = useState(false);
    const [
        delete_document_question,
        { data: deleteDocumentQuestionData, loading: deleteClinicProviderLoading, error: deleteClinicProviderError },
      ] = useMutation(DELETE_QUESTION_MUTATION, {
        client: clientGraphql,
      });

    const handleAddQuestionDialogClose = useCallback((isSuccess=false) => {
        setAddQuestionDialogVisible(false);
        if (isSuccess) {
            refetch();
        }
    }, [refetch]);

    const handleUpdateQuestionDialogClose = useCallback((isSuccess=false) => {
        setUpdateQuestionDialogVisible(false);
        if (isSuccess) {
            refetch();
        }
    }, [refetch]);

    const handleOnAdd = useCallback(() => {
    }, []);

    const handleOnDelete = useCallback((rowUuid) => {
        Swal.fire({
            html: "Are you sure?" ,
            icon: 'warning',
            showCancelButton: true,
            showDenyButton: false,
            confirmButtonText: 'Delete',
            denyButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                delete_document_question({
                    client: clientGraphql,
                    variables: { uuid: rowUuid },
                  }).then(() => {
                    refetch();
                  });
            }
        });
      }, [delete_document_question, refetch]);
    
      const handleOnEdit = useCallback((question) => {
        setQuestion(question);
        setUpdateQuestionDialogVisible(true);
      }, [setUpdateQuestionDialogVisible, setQuestion]);

    const actions = useMemo(() => {
        return {onEdit: handleOnEdit, onDelete: handleOnDelete};
    }, [handleOnDelete, handleOnEdit]);

    const {parsedData} = useParsedTableData(data, actions);

    return (<>
        <Card>
            <CardHeader
                avatar={<IconQuestionCircle />}
                action={
                    <MyButton startIcon={<IconPlus />}
                              onClick={setAddQuestionDialogVisible.bind(this, true)}
                              sx={{color: '#fff'}}>
                        Add Question
                    </MyButton>
                }
                title="Qualifying Questions"
            />
            <CardContent>
                <Grid sx={{m: -3}}>
                    <ManageQuestionsTable
                        onAdd={handleOnAdd}
                        loading={loading}
                        data={parsedData}
                        showToolbar={false}
                    />
                </Grid>
            </CardContent>
        </Card>
        <AddQuestionDialog documentId={documentId}
                             open={addQuestionDialogVisible}
                             onClose={handleAddQuestionDialogClose} />

        <UpdateQuestionDialog documentId={documentId}
            open={updateQuestionDialogVisible}
            onClose={handleUpdateQuestionDialogClose}
            question={isEmpty(question) ? "":question.question} />
    </>);
}
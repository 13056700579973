import {
  Alert,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useQuery, useSubscription } from '@apollo/client';
import React, { useEffect, useCallback, useState, useContext, useRef } from 'react';
import { isEmpty } from 'lodash';
import { GET_ACTIVE_RECEIVED_REFERRALS } from '../gql';
import ActiveReceivedReferralsTable from '../tables/ActiveReceivedReferralsTable';
import ReferralNotes from '../../referral/ReferralNotes';
import { MyButton } from '../../common/components';
import MyPaperComponent from '../../common/components/MyPaperComponent';
import { usePromoteReferral } from '../../referral/redux/promoteReferral';
import Swal from 'sweetalert2';
import Referral from '../../referral/models/Referral';
import clientGraphql from '../../../common/apollo-graphql';
import AuthContext from '../../auth/context/AuthContext';
import { REFERRAL_RECEIVER_SUBSCRIPTION } from '../../referral/gql/Subscription';

export default function ActiveReceivedReferrals({ history, uuid }) {
  const [parsedData, setParsedData] = useState({});
  const [closeError, setCloseError] = useState(false);
  const [type, setType] = useState("Name"); 
  const [patientName, setPatientName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [referralUuid, setreferralUuid] = useState('');
  const [openNotes, setOpenNotes] = useState(false);
  const handleClose = () => setOpenNotes(false);
  const currentUser = useContext(AuthContext);
  const { promoteReferral } = usePromoteReferral();
  const [shouldSubscribe, setShouldSubscribe] = useState(true);
  const referralsSubscribeRef = useRef([]);
  const gqlParams = {
    client: clientGraphql,
    variables: {
      patientName: '',
      birthdate: '',
      uuid: uuid,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
};
  // const [getReferralActiveReceived, { data:referralData }] = useLazyQuery(GET_ACTIVE_RECEIVED_REFERRALS, gqlParams);
  const { loading, data : receivedReferrals, error, refetch } = useQuery(
    GET_ACTIVE_RECEIVED_REFERRALS,
    {
      client: clientGraphql,
      variables: {
        patientName: patientName,
        birthdate: birthdate,
        uuid: uuid,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    },
  );
  const { data: referrals } = useSubscription(REFERRAL_RECEIVER_SUBSCRIPTION, {
    variables: {
      clinic_id: !isEmpty(currentUser) && !isEmpty(currentUser.clinics) ? currentUser.clinics[0].id : -1
    },
    skip: !shouldSubscribe,
    lazy: true,
  });

  // const handleRefetch = useCallback(() => {
  //   getReferralActiveReceived({
  //     client: clientGraphql,
  //     variables: {
  //       patientName: patientName,
  //       birthdate: '',
  //       uuid: uuid,
  //     },
  //   }).then(result => {
  //     setParsedData({
  //       rows: result.data.rows.map(item => ({
  //         ...item,
  //         user: currentUser,
  //         referral: new Referral(item),
  //         actions: {
  //           onViewNotes: handleOnViewNotes,
  //           onReview: handleOnReview,
  //           onComplete: handleOnComplete,
  //         },
  //       })),
  //       count: result.data.rows.length,
  //     })
  //   });
  // }, [getReferralActiveReceived,  uuid, currentUser, handleOnViewNotes, handleOnComplete, handleOnReview, patientName]);

  useEffect(() => {
    if (!isEmpty(referrals)) {
      if (referralsSubscribeRef.current.length === 0) {
        referralsSubscribeRef.current = referrals;
      } else {
        refetch();
      }
    }
  }, [referrals, refetch]);

  const handleOnSelectedRowsChanged = useCallback(selectedRows => {
  }, []);

  const handleFilterChanged = useCallback(e => {
    if(type === 'Name'){
      setPatientName(e.target.value);
    } else {
      setBirthdate(e.target.value);
    }
    // refetch();
  }, [setPatientName, setBirthdate, type]);

  const handleChangeDropdown= useCallback(e => {
    setType(e.target.value);
    setBirthdate('');
    setPatientName('');
  }, [setType, setBirthdate, setPatientName]);

  const handleOnReview = useCallback(
    (rowId, row) => {
      history.push(`/referrals/${row.id}/review`);
    },
    [history],
  );

  const isAutomaticUnderReview = useCallback((row) => {
    var isShown = false;
    if (!isEmpty(currentUser) && !isEmpty(row)) {
      currentUser.referral_permissions.map(item => {
        if (
          item.key === 'ACCEPT_REFERRAL' && item.value && row.status === 'sent'
        ) {
          isShown = true;
        }
        else if (
          item.key === 'SCHEDULE_REFERRAL' && item.value && row.status === 'sent'
        ) {
          isShown = true;
        }
      });
    }
    return isShown;
  }, [currentUser]);

  const autopromoteToUnderReview = useCallback((status, row) => {
    promoteReferral(row.id, status).then(response => {
      history.push(`/referrals/${row.id}/review`);
    });
    },[promoteReferral, history]
  );

  const onPromoteReferral = useCallback(
    (id, status, message) => {
      promoteReferral(id, status).then(data => {
        Swal.fire({
          icon: 'success',
          text: message,
          showConfirmButton: true,
        }).then(() => {
          refetch();
        });
      });
    },
    [promoteReferral, refetch],
  );

  const handleOnComplete = useCallback(
    rowId => {
      onPromoteReferral(rowId, 'rec_completed', 'Referral Completed');
    },
    [onPromoteReferral],
  );

  const handleOnViewNotes = useCallback(
    rowId => {
      setreferralUuid(rowId);
      setOpenNotes(true);
    },
    [setreferralUuid, setOpenNotes],
  );

  useEffect(() => {
    if (!isEmpty(receivedReferrals) && !isEmpty(receivedReferrals.rows)) {
      var referrals = {rows: [], count: receivedReferrals.rows.length};
      receivedReferrals.rows.map(item => {
        referrals.rows.push({
        ...item,
        user: currentUser,
        referral: new Referral(item),
        actions: {
          onViewNotes: handleOnViewNotes,
          onReview: handleOnReview,
          onComplete: handleOnComplete,
        },
      })});
      setParsedData(referrals);
    } else {
      setParsedData({});
    }
  }, [receivedReferrals, handleOnViewNotes, handleOnReview, handleOnComplete, currentUser]);

  return (
    <>
      {error && !closeError ? (
        <Alert
          variant="filled"
          severity="error"
          onClose={() => {
            setCloseError(true);
          }}
        >
          We are sorry, but we are having difficulty with our system. We are unable to load data at
          the moment. Please reach out to us if you continue to experience this issue.
        </Alert>
      ) : null}
      <ActiveReceivedReferralsTable
        onFilterChanged={handleFilterChanged}
        onChangeDropdown={handleChangeDropdown}
        onSelectedRowsChanged={handleOnSelectedRowsChanged}
        loading={loading}
        onViewNotes={handleOnViewNotes}
        data={
          !isEmpty(parsedData) && !loading
            ? { rows: parsedData.rows, count: parsedData.count }
            : { rows: [], count: 0 }
        }
      />
      <Dialog
        open={openNotes}
        onClose={handleClose}
        PaperComponent={MyPaperComponent}
        aria-labelledby="draggable-dialog-title"
        style={{ minWidth: '800px' }}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Referral Log
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Card style={{ padding: '0' }}>
              <CardContent>
                <ReferralNotes uuid={!isEmpty(referralUuid) && referralUuid} isExternal={false} />
              </CardContent>
            </Card>
          </Box>
        </DialogContent>
        <DialogActions>
          <MyButton autoFocus onClick={handleClose} fullWidth={false}>
            Close
          </MyButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

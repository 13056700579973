import React, { useCallback, useEffect } from 'react';
import { gridSpacing } from '../../common/themes/constants';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DropzoneAreaBase } from 'react-mui-dropzone';
import { IconX } from '@tabler/icons';
import { isEmpty } from 'lodash';
import { MyButton, MyIconButton, MyTypography } from '../common/components';
import { makeStyles } from '@mui/styles';
import PublicReferralQualifyingQuestions from './PublicReferralQualifyingQuestions';
import StringUtils from '../common/services/StringUtils';
import { handlePreviewIcon } from '../patient/styles/documentPreviewIcon';

const useStyles = makeStyles(() => ({
  expanded: {},
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
}));

export default function PublicReferralDocuments({
  parsedQuestionData,
  setParsedQuestionData,
  parsedData,
  fileNames,
  setFileNames,
  fileObjects,
  setFileObjects,
  fileObjectsRequired,
  setFileObjectsRequired,
  onFileObjectChanged,
  handleFileRequiredChange,
  qualifyingQuestions,
  setQualifyingQuestions,
  setDisableSend,
  openRenameFile,
  setOpenRenameFile,
  setIsSaveMisc,
  renameDoc, 
  setRenameDoc,
  handleClose
}) {
  const classes = useStyles();

  const deleteReferralDocument = useCallback(
    (key, index) => {
      fileObjects[key].splice(index, 1);
      onFileObjectChanged(fileObjects);
    },
    [fileObjects, onFileObjectChanged],
  );

  useEffect(() => {
    if (!isEmpty(parsedData.clinic) && isEmpty(qualifyingQuestions)) {
      var fileNameList = fileNames;
      var objectList = fileObjects;
      var objestListRequired = fileObjectsRequired;
      var objectQualifyingQuestions = {};
      parsedData.clinic.documents.map(doc => {
        objectList[doc.document.id] = doc.document.id in objectList && !isEmpty(objectList[doc.document.id]) ? objectList[doc.document.id]:[];
        fileNameList[doc.document.id] = !isEmpty(fileNameList[doc.document.id]) ? fileNameList[doc.document.id]:doc.document.name;
        objestListRequired[doc.document.id] = true;
      });
      parsedData.clinic.optionalDocuments.map(doc => {
        objectList[doc.document.id] = doc.document.id in objectList && !isEmpty(objectList[doc.document.id]) ? objectList[doc.document.id]:[];
        fileNameList[doc.document.id] = !isEmpty(fileNameList[doc.document.id]) ? fileNameList[doc.document.id]:doc.document.name;
        objestListRequired[doc.document.id] = false;
        if(!isEmpty(doc.question)){
          objectQualifyingQuestions[doc.document.id] = {name: doc.document.name, question: doc.question.question, answer: (doc.document.id in objectList && !isEmpty(objectList[doc.document.id])) ? 'Yes':'No'};
        }
      });
      setDisableSend(false);
      Object.keys(objectList).map(key => {
        if(objestListRequired[key] && isEmpty(objectList[key])){
          setDisableSend(true);
        }
      });
      setDisableSend(false);
      Object.keys(objectList).map(key => {
        if(objestListRequired[key] && isEmpty(objectList[key])){
          setDisableSend(true);
        }
      });
      setDisableSend(false);
      Object.keys(objectList).map(key => {
        if(objestListRequired[key] && isEmpty(objectList[key])){
          setDisableSend(true);
        }
      });
      setFileObjects(objectList);
      setFileNames(fileNameList);
      setFileObjectsRequired(objestListRequired);
      setQualifyingQuestions(objectQualifyingQuestions);
      setParsedQuestionData({});
    }
  }, [setDisableSend, parsedData, setFileObjects, setParsedQuestionData, setFileObjectsRequired, setQualifyingQuestions, fileObjects, fileObjectsRequired, qualifyingQuestions, setFileNames, fileNames]);

  return (
    <>
      <Grid container spacing={gridSpacing} key={'public-referral-'+Math.random()}>
        <Grid item sm={12} xs={12}>
          <h3 style={{ margin: '0' }}>Referral Documents</h3>
        </Grid>
        {!isEmpty(parsedData) &&
          !isEmpty(parsedData.clinic) &&
          !isEmpty(parsedData.clinic.optionalDocuments) ? (
            <>
              <Grid item sm={12} xs={12} style={{ paddingTop: 0 }}>
                <PublicReferralQualifyingQuestions
                  key={'qualifying-questions-' + Math.random()} defaultExpanded={true}
                  parsedData={parsedQuestionData}
                  setParsedData={setParsedQuestionData}
                  documents={parsedData.clinic.optionalDocuments}
                  onFileObjectChanged={onFileObjectChanged}
                  fileObjects={fileObjects}
                  setFileObjects={setFileObjects}
                  handleFileRequiredChange={handleFileRequiredChange}
                  handlePreviewIcon={handlePreviewIcon}
                  classes={classes}
                />
              </Grid>

              <Grid item sm={12} xs={12} style={{ paddingTop: 0 }}>
                {!isEmpty(parsedData.clinic.documents) &&
                  parsedData.clinic.documents.map(doc => {
                    return (
                      <Accordion key={'upload-' + Math.random()} defaultExpanded={true}>
                        <AccordionSummary
                          key={'upload-summary-' + Math.random()}
                          expandIcon={<ExpandMoreIcon />}
                          style={{ minHeight: '32px', margin: 0 }}
                          classes={{ content: classes.content, expanded: classes.expanded }}
                        >
                          <h4>
                            
                            {!isEmpty(doc.document.name) ? doc.document.name.toUpperCase() : ''} - 
                            {isEmpty(fileObjects[doc.document.id]) ? (<span style={{'color':'red'}}> Required *</span>):(<span style={{'color':'green'}}> Uploaded</span>)}
                            
                          </h4>
                        </AccordionSummary>
                        <AccordionDetails key={'upload-details-' + Math.random()} >
                          <DropzoneAreaBase
                            acceptedFiles={['.pdf', '.png', '.jpeg', '.zip', '.tif', '.tiff']}
                            fileObjects={fileObjects[doc.document.id]}
                            showPreviews={false}
                            showFileNamesInPreview={false}
                            showPreviewsInDropzone={false}
                            filesLimit={10}
                            maxFileSize={50000000}
                            onAdd={newFileObjs => {
                              var temp = fileObjects;
                              temp[doc.document.id].push(...newFileObjs);
                              onFileObjectChanged(temp, doc.document.name, true, doc);
                            }}
                            onDelete={deleteFileObj => {
                              const index = fileObjects[doc.document.id].indexOf(deleteFileObj);
                              fileObjects[doc.document.id].splice(index, 1);
                              onFileObjectChanged(fileObjects, doc.document.name, false, doc);
                            }}
                            getPreviewIcon={handlePreviewIcon}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </Grid>

              <Grid item sm={12} xs={12} style={{ paddingTop: 0 }}>
                {!isEmpty(parsedData.clinic.optionalDocuments) &&
                  parsedData.clinic.optionalDocuments.map(doc => {
                    if (doc.document.name === 'misc') {
                      return (
                        <Accordion key={'upload-' + Math.random()} defaultExpanded={true}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Stack direction="column">
                              <h4>
                                {!isEmpty(doc.document.name) ? doc.document.name.toUpperCase() : ''}{' '}
                                - Optional
                              </h4>
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={gridSpacing}>
                              <Grid
                                key={'grid-note-' + Math.random()}
                                item
                                sm={12}
                                style={{ paddingTop: '0' }}
                              >
                                <MyTypography variant="h5" component="h5" color="text.primary">
                                  Use this section to upload documents that could be useful for the
                                  receiving provider that are not listed as an option above.
                                </MyTypography>
                              </Grid>
                            </Grid>
                            <DropzoneAreaBase
                              acceptedFiles={['.pdf', '.png', '.jpeg', '.zip', '.tif', '.tiff']}
                              fileObjects={fileObjects[doc.document.id]}
                              showPreviews={false}
                              showFileNamesInPreview={false}
                              showPreviewsInDropzone={false}
                              filesLimit={10}
                              maxFileSize={50000000}
                              onAdd={newFileObjs => {
                                var temp = fileObjects;
                                temp[doc.document.id].push(...newFileObjs);
                                onFileObjectChanged(temp, doc.document.name, true, doc);
                              }}
                              onDelete={deleteFileObj => {
                                const index = fileObjects[doc.document.id].indexOf(deleteFileObj);
                                fileObjects[doc.document.id].splice(index, 1);
                                onFileObjectChanged(fileObjects, doc.document.name, false, doc);
                              }}
                              getPreviewIcon={handlePreviewIcon}
                            />
                          </AccordionDetails>
                        </Accordion>
                      );
                    } else {
                      return null;
                    }
                  })}
                  
              </Grid>
              <Grid item sm={12} xs={12}>
                <h3 style={{ margin: '0' }}>Referral Documents for Submission</h3>
              </Grid>
              {!isEmpty(fileObjects)
                ? Object.keys(fileObjects).map(key => {
                    return fileObjects[key].map((docFile, index) => {
                      let nameSuffix = fileNames[key] !== 'misc' && index > 0 ? "-" + index:"";
                      let name = fileNames[key] === 'misc' && !isEmpty(docFile.referral_document_name) ? docFile.referral_document_name:((key in fileNames) ? fileNames[key]:"");
                      let extension = StringUtils.getFileNameExtension(docFile.file.name);
                      return (
                        <>
                          <Grid
                            key={'grid-file-' + Math.random()}
                            item
                            sm={6}
                            xs={12}
                            style={{ paddingTop: 0 }}
                          >
                            <Stack direction="row">
                              <MyButton
                                style={{ justifyContent: 'left' }}
                                key={'file' + Math.random()}
                                // onClick={() => downloadFile(doc.file)}
                                color="primary"
                                variant="text"
                              >
                                {name +nameSuffix + (!isEmpty(name) && name.includes('.') ? '':'.' + extension)}
                              </MyButton>
                              <MyIconButton
                                color="error"
                                label="Delete Document"
                                style={{'fontSize': '14px'}}
                                onClick={e => {
                                  e.stopPropagation();
                                  deleteReferralDocument(key, index);
                                }}
                              >
                                <IconX style={{ height: '16px', width: '16px' }} />
                                Remove
                              </MyIconButton>
                            </Stack>
                          </Grid>
                        </>
                      );
                    });
                  })
                : null}
            </>
          ):null}
      </Grid>
    </>
  );
}

import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQuery(asJson=false) {
    const { search } = useLocation();

    return useMemo(() => {
        const query = new URLSearchParams(search);
        return asJson
               ? Object.fromEntries(query)
               : query;
    }, [search, asJson]);
}
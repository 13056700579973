import { gql } from '@apollo/client';
import { GET_PROVIDER_FIELDS } from '../../referral/gql/Fragment';
import {
    CLINIC_SHORT_FIELDS
} from './Fragment';

const GET_CLINIC_DETAILS = gql`
  ${CLINIC_SHORT_FIELDS}
  ${GET_PROVIDER_FIELDS}
  query getClinic($uuid: String!) {
    clinic: clinic_by_user_uuid(user_uuid: $uuid) {
      ...ClinicShortFields
      providers {
        ...ProviderFields,
      }
    }
  }
`;

const GET_CLINIC = gql`
  ${CLINIC_SHORT_FIELDS}
  ${GET_PROVIDER_FIELDS}
  query getClinic($id: ID!) {
    clinic(id: $id) {
      ...ClinicShortFields
      providers {
        ...ProviderFields,
      }
    }
  }
`;

const GET_CLINIC_BY_CODE = gql`
  ${CLINIC_SHORT_FIELDS}
  ${GET_PROVIDER_FIELDS}
  query getClinic($code: String!) {
    clinic_by_code(code: $code) {
      ...ClinicShortFields
      providers {
        ...ProviderFields,
      }
    }
  }
`;


export { GET_CLINIC_DETAILS,GET_CLINIC, GET_CLINIC_BY_CODE };

import {
  Box,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import React, { useCallback } from 'react';
import Swal from 'sweetalert2';
import { gridSpacing } from '../../common/themes/constants';
import { MyButton, MyTypography } from '../common/components';
import { useSetAppointmentReferral } from './redux/setAppointmentReferral';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import HookFormDateTimePicker from '../common/components/hook-form/HookFormDateTimePicker';
import moment from 'moment-timezone';
import dayjs from 'dayjs';

const today = new Date(
  moment()
    .local()
    .format('YYYY-MM-DDTHH:mm:ss'),
);
const initialValues = {
  appointment_date: today,
};

export const createValidationSchema = yup.object().shape({
  appointment_date: yup
    .date('Appointment date and time is invalid.')
    // .min(today, 'Appointment date and time must be today or a future date.')
    .required('Appointment date and time is required')
    .typeError('Appointment date and time is invalid.'),
});

export default function RescheduleAppointmentReferral({ history, uuid, handleClose }) {
  const {
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(createValidationSchema),
    shouldFocusError: true,
    mode: 'all',
    reValidateMode: 'onChange',
    keepDirty: false,
  });

  const { setAppointmentReferral, setAppointmentReferralPending } = useSetAppointmentReferral();

  const onClickSetAppointmentReferral = useCallback(
    (title, message) => {
      const formData = new FormData();
      formData.append(
        'appointment_date',
        moment(new Date(getValues('appointment_date'))).format('YYYY-MM-DD HH:mm:ss'),
      );
      formData.append('is_reschedule', true);
      setAppointmentReferral(uuid, formData).then(data => {
        Swal.fire({
          icon: 'success',
          title: title,
          text: message,
          showConfirmButton: true,
        }).then(() => {
          handleClose();
          history.push('/referrals/active', { value: 1 });
        });
      });
    },
    [uuid, setAppointmentReferral, handleClose, getValues, history],
  );

  return (
    <>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Reschedule Referral Appointment
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Card style={{ padding: '0' }}>
            <CardContent>
              <Grid container spacing={gridSpacing}>
                <Grid item md={12} xs={12}>
                  <MyTypography variant="h5" component="h5" color="text.primary">
                    Please set a new schedule appointment date and time
                  </MyTypography>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <HookFormDateTimePicker
                    id="appointment_date"
                    name="appointment_date"
                    placeholder="Enter Appointment Date"
                    label="Appointment Date and Time *"
                    schema="appointment_date"
                    openTo="day"
                    prefix={null}
                    control={control}
                    errors={errors}
                    minDateTime={dayjs(new Date().toISOString())}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </DialogContent>
      <DialogActions>
        <MyButton
          type="submit"
          color="primary"
          variant="outlined"
          loading={setAppointmentReferralPending}
          disabled={!isValid}
          onClick={() => onClickSetAppointmentReferral('Appointment Date Set', '')}
          fullWidth={false}
        >
          Confirm
        </MyButton>
        <MyButton color="primary" variant="outlined" onClick={handleClose} fullWidth={false}>
          Close
        </MyButton>
      </DialogActions>
    </>
  );
}

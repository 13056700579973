export const CLINICS_ENROLL_PSR_AND_CLINIC_BEGIN = 'CLINICS_ENROLL_PSR_AND_CLINIC_BEGIN';
export const CLINICS_ENROLL_PSR_AND_CLINIC_SUCCESS = 'CLINICS_ENROLL_PSR_AND_CLINIC_SUCCESS';
export const CLINICS_ENROLL_PSR_AND_CLINIC_FAILURE = 'CLINICS_ENROLL_PSR_AND_CLINIC_FAILURE';
export const CLINICS_ENROLL_PSR_AND_CLINIC_DISMISS_ERROR = 'CLINICS_ENROLL_PSR_AND_CLINIC_DISMISS_ERROR';

export const CLINICS_DELETE_CLINIC_BEGIN = 'CLINICS_DELETE_CLINIC_BEGIN';
export const CLINICS_DELETE_CLINIC_SUCCESS = 'CLINICS_DELETE_CLINIC_SUCCESS';
export const CLINICS_DELETE_CLINIC_FAILURE = 'CLINICS_DELETE_CLINIC_FAILURE';
export const CLINICS_DELETE_CLINIC_DISMISS_ERROR = 'CLINICS_DELETE_CLINIC_DISMISS_ERROR';

export const CLINICS_UPDATE_CLINIC_BEGIN = 'CLINICS_UPDATE_CLINIC_BEGIN';
export const CLINICS_UPDATE_CLINIC_SUCCESS = 'CLINICS_UPDATE_CLINIC_SUCCESS';
export const CLINICS_UPDATE_CLINIC_FAILURE = 'CLINICS_UPDATE_CLINIC_FAILURE';
export const CLINICS_UPDATE_CLINIC_DISMISS_ERROR = 'CLINICS_UPDATE_CLINIC_DISMISS_ERROR';

export const CLINICS_ADD_CLINIC_USER_BEGIN = 'CLINICS_ADD_CLINIC_USER_BEGIN';
export const CLINICS_ADD_CLINIC_USER_SUCCESS = 'CLINICS_ADD_CLINIC_USER_SUCCESS';
export const CLINICS_ADD_CLINIC_USER_FAILURE = 'CLINICS_ADD_CLINIC_USER_FAILURE';
export const CLINICS_ADD_CLINIC_USER_DISMISS_ERROR = 'CLINICS_ADD_CLINIC_USER_DISMISS_ERROR';

export const CLINICS_DELETE_CLINIC_USER_BEGIN = 'CLINICS_DELETE_CLINIC_USER_BEGIN';
export const CLINICS_DELETE_CLINIC_USER_SUCCESS = 'CLINICS_DELETE_CLINIC_USER_SUCCESS';
export const CLINICS_DELETE_CLINIC_USER_FAILURE = 'CLINICS_DELETE_CLINIC_USER_FAILURE';
export const CLINICS_DELETE_CLINIC_USER_DISMISS_ERROR = 'CLINICS_DELETE_CLINIC_USER_DISMISS_ERROR';

import React from 'react';
import {
    MenuItem,
} from '@mui/material';
import {HookFormSelect} from '../../common/components';
import {startCase} from 'lodash';
import { TITLE_DR, TITLE_NP } from '../models/Provider';

var options = [TITLE_DR, TITLE_NP];

export default function ProviderTitleOptions({prefix=null, control, errors}) {


    return (
        <HookFormSelect
            id="title"
            type="select"
            select
            name="title"
            placeholder="Select Title"
            label="Title *"
            schema="title"
            prefix={prefix}
            control={control}
            errors={errors}
            fullWidth
        >
            {
                options.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                        {startCase(option.label)}
                    </MenuItem>
                ))
            }
        </HookFormSelect>
    );
}
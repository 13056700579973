import { useQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import User from '../auth/models/User';
import { ClinicProvider } from '../clinics/context/ClinicContext';
import PageContent from '../common/MainLayout/Page';
import UsersTable from './table/UsersTable';
import clientGraphql from '../../common/apollo-graphql';
import { GET_USERS } from '../user/gql/Query';

function ViewUserDetails({userData, loading}) {
  return (
      <Stack direction="column"
             spacing={1}>
          <UsersTable data={userData}
                            loading={loading} />
      </Stack>
  );
};

export default function ManageUsers({history}) {
  const {id} = 1;
  const [keyword, setKeyword] = useState(""); 
  const [userData, setUserData] = useState({rows: [], count: 0});
  const { loading, data, refetch } = useQuery(GET_USERS, {
    client: clientGraphql,
      variables: {
        keyword: keyword ,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
      if (data) {
          setUserData({
              rows: data.rows.map(item => {
                  return {
                      ...item,
                      userObj: new User({...item}),
                  };
              }),
              count: data.rows.count,
          });
      } 
  }, [data]);

  return (
    <PageContent contentClass="manage-users">
      <ClinicProvider
                value={{
                    id,
                    refetch
                }}>
            {
                <ViewUserDetails loading={loading}
                userData={userData} />
            }
            </ClinicProvider>
      
    </PageContent>
  );
};

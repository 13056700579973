import React from 'react';
import { Button, Modal, Typography, Box, Fade } from '@mui/material';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  p: 3,
};

const closeButtonStyle = {
  position: 'absolute',
  top: '0px',
  right: '-15px',
  fontSize: '16px',
};

const MyModal = ({ open, onClose, title, content, showClose = false }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-content"
    >
      <Fade in={open}>
        <Box sx={style}>
          {showClose && (
            <Button sx={closeButtonStyle} onClick={onClose}>
              ✖
            </Button>
          )}
          <Typography id="spring-modal-title" variant="h4" component="h5" color="primary">
            {title}
          </Typography>
          <Typography id="spring-modal-description" sx={{ mt: 2 }}>
            {content}
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
};

export default MyModal;

import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { MyDataGrid, MyTypography } from '../../common/components';
import DateTimeUtils from '../../common/services/DateTimeUtils';

const columns = [
  {
    field: 'patient',
    headerName: 'Patient Name',
    width: 180,
    valueGetter: params => {
      return params.row.referral.getFullName();
    },
  },
  {
    field: 'referrer',
    headerName: 'Referral Source',
    width: 250,
    // valueGetter: params => {
    //   return params.row.referral.getReferrerClinic();
    // },
    renderCell: params => {
      return (
        <Stack direction="column" spacing={1}>
          <MyTypography variant="h5" component="h5" color="text.primary" style={{'margin': '0'}}>
            {params.row.referral.getReferrerClinic()}
          </MyTypography>
          {params.row.referral.getReferrerClinic().includes('★') ? (<MyTypography variant="h6" component="h6" color="text.primary" style={{'fontStyle': 'italic', 'margin': '0'}}>
            Submitted via Public Form
          </MyTypography>):null}
          
        </Stack>
      );
    },
  },
  {
    field: 'provider',
    headerName: 'Provider',
    width: 160,
    valueGetter: params => {
      return params.row.referral.getProviderName();
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    valueGetter: params => {
      return params.row.referral.getReceiverStatus();
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 180,
    headerAlign: 'center',
    sortable: false,
    editable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: params => {
      return (
        <Stack direction="row" spacing={1}>
          <Button
            onClick={e => {
              e.stopPropagation();
              params.row.actions.onViewNotes(params.row.id);
            }}
          >
            View Log
          </Button>
          <Button
            onClick={e => {
              e.stopPropagation();
              params.row.actions.onView(params.row.id);
            }}
          >
            Review
          </Button>
        </Stack>
      );
    },
  },
  { field: 'reason', headerName: 'Declined Reason', width: 200,
    valueGetter: params => {
    return params.row.referral.getDateOrReason();
    }, 
  },
  {
    field: 'accepted_at',
    headerName: 'Date Received',
    width: 250,
    valueGetter: params => {
      return params.row.referral.receivedFormattedDate();
    },
    sortComparator: DateTimeUtils.dateSortComparator
  },
  {
    field: 'updated_by',
    headerName: 'Last Updated By',
    width: 250,
    valueGetter: params => {
      return params.row.referral.getLastUpdatedByFullName();
    },
  },
  {
    field: 'updated_at',
    headerName: 'Last Updated At',
    width: 250,
    valueGetter: params => {
      return params.row.referral.formattedDate();
    },
    sortComparator: DateTimeUtils.dateSortComparator
  },
];

export default function DeclinedReceivedReferralsTable({
  onFilterChanged,
  onSelectedRowsChanged,
  loading,
  data,
  onClick,
}) {
  return (
    <MyDataGrid
      onFilterChanged={onFilterChanged}
      onSelectedRowsChanged={onSelectedRowsChanged}
      loading={loading}
      data={data}
      columns={columns}
      onClick={onClick}
      showToolbar={false}
      minTableHeight={'calc(100vh - 192px)'}
      getRowId={row => {
        return row.id;
      }}
    />
  );
}

import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ApiService from '../../common/services/ApiService';
import {
  SEND_REFERRAL_BEGIN,
  SEND_REFERRAL_SUCCESS,
  SEND_REFERRAL_FAILURE,
  SEND_REFERRAL_DISMISS_ERROR,
} from './constants';

export function sendReferral(referralUUID) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: SEND_REFERRAL_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.sendReferral(referralUUID).then(
        (res) => {
          dispatch({
            type: SEND_REFERRAL_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: SEND_REFERRAL_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissSendReferralError() {
  return {
    type: SEND_REFERRAL_DISMISS_ERROR,
  };
}

export function useSendReferral() {
  const dispatch = useDispatch();

  const { sendReferralPending, sendReferralError } = useSelector(
    state => ({
      sendReferralPending: state.referral.sendReferralPending,
      sendReferralError: state.referral.sendReferralError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((referralUUID, args) => {
    return dispatch(sendReferral(referralUUID, args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissSendReferralError());
  }, [dispatch]);

  return {
    sendReferral: boundAction,
    sendReferralPending,
    sendReferralError,
    dismissSendReferralError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SEND_REFERRAL_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        sendReferralPending: true,
        sendReferralError: null,
      };

    case SEND_REFERRAL_SUCCESS:
      // The request is success
      return {
        ...state,
        sendReferralPending: false,
        sendReferralError: null,
      };

    case SEND_REFERRAL_FAILURE:
      // The request is failed
      return {
        ...state,
        sendReferralPending: false,
        sendReferralError: action.data.error,
      };

    case SEND_REFERRAL_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        sendReferralError: null,
      };

    default:
      return state;
  }
}

import React from 'react';
import {
    MenuItem,
} from '@mui/material';
import {startCase} from 'lodash';
import HookFormSelectOnly from '../../common/components/hook-form/HookFormSelectOnly';
var options = [{label: 'All', value: 'all'}, {label: 'Faxed', value: 'fax'}, {label: 'Zipreferred', value: 'none'}];

export default function ReferralTypeOptions({prefix=null, control=null, errors=null, onChange=null}) {


    return (
        <HookFormSelectOnly
            id="view"
            type="select"
            select
            placeholder="Select View"
            label="View *"
            schema="view"
            prefix={prefix}
            control={control}
            errors={errors}
            fullWidth
            onChange={onChange}
            options={options}
        >
            {
                options.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                        {startCase(option.label)}
                    </MenuItem>
                ))
            }
        </HookFormSelectOnly>
    );
}
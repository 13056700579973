import { capitalize, isEmpty } from 'lodash';
import ApiService from './ApiService';
import * as PDFJS from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import StringUtils from './StringUtils';
import moment from 'moment';
import domtoimage from 'dom-to-image';
import Tiff from 'tiff.js';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import FileSaver from 'file-saver';
import HelperUtils from './HelperUtils';
import DateTimeUtils from './DateTimeUtils';

PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default class FileUtils {
  static downloadFile = doc => {
    if (doc.url) {
      ApiService.getFile(
        doc.url,
        !isEmpty(doc.referral_document_name) ? doc.referral_document_name : doc.name,
      );
      return;
    }
  };

  static exportFileAsPDF = doc => {
    var filename = !isEmpty(doc.referral_document_name) ? doc.referral_document_name : doc.name;
    if (doc.url && StringUtils.getFileExtension('path', doc.url) !== '.zip') {
      ApiService.getPDFFileAsBlob(doc.url).then(res => {
        const blob = new Blob([res.data], { type: res.data.type });
        var documents = [blob];

        FileUtils.exportAsTif(documents, filename, null, null, false);
      });
    }
  };

  static exportAllFileAsTIF = (uploadedFileObjects, patientDetails, referralDetails) => {
    var filename = patientDetails.patient.full_name.replace(' ', '_');
    filename += '-' + moment(new Date()).format('YYYY-MM-DD');
    var count = 0;
    if (!isEmpty(uploadedFileObjects)) {
      new Promise((resolve, reject) => {
        var files = [];
        Object.keys(uploadedFileObjects).map(key => {
          uploadedFileObjects['' + key].map((doc, index) => {
            count++;
            if (doc.url && StringUtils.getFileExtension('path', doc.url) !== '.zip') {
              ApiService.getPDFFileAsBlob(doc.url).then(res => {
                const blob = new Blob([res.data], { type: res.data.type });
                files.push(blob);
                if (count === files.length) {
                  resolve(files);
                }
              });
            }
          });
        });
      }).then(documents => {
        FileUtils.exportAsTif(documents, filename, patientDetails, referralDetails, false);
      });
    }
  };

  static exportAsTif = (documents, filename, patientDetails, referralDetails, isPrint) => {
    var myWindow = window.open();
    myWindow.document.body.style.width = '216mm';
    myWindow.document.body.style.margin = '0';
    if (!isEmpty(patientDetails) && !isEmpty(referralDetails)) {
      FileUtils.printPatientDemographics(patientDetails, myWindow, referralDetails);
    }
    if (!isEmpty(documents) && documents[documents.length - 1].type == 'application/pdf') {
      FileUtils.convertDocumentsToTiff(documents, myWindow, filename, isPrint);
    } else if (!isEmpty(documents)) {
      FileUtils.convertImageUrlToTiff(documents, myWindow, filename, isPrint);
    }
  };

  static printAll = (patientDetails, uploadedFileObjects, referralDetails, currentUser = null) => {
    // var filename = patientDetails.patient.full_name.replace(' ', '_');
    // filename += '-' + moment(new Date()).format('YYYY-MM-DD');
    // return FileUtils.generatePatientDetailsPDF(
    //   patientDetails,
    //   uploadedFileObjects,
    //   referralDetails,
    //   filename,
    //   currentUser,
    //   false,
    // ).then(res => {
    //   if (!isEmpty(filename)) {
    //     // window.open( URL.createObjectURL(res));
    //     // window.print();
    //     // var myWindow = window.open(URL.createObjectURL(res));
    //   }
    // });

    var filename = patientDetails.patient.full_name.replace(' ', '_');
    filename += '-' + moment(new Date()).format('YYYY-MM-DD');
    var count = 0;
    if (!isEmpty(uploadedFileObjects)) {
      new Promise((resolve, reject) => {
        var files = [];
        Object.keys(uploadedFileObjects).map(key => {
          uploadedFileObjects['' + key].map((doc, index) => {
            count++;
            if (doc.url && StringUtils.getFileExtension('path', doc.url) !== '.zip') {
              ApiService.getPDFFileAsBlob(doc.url).then(res => {
                const blob = new Blob([res.data], { type: res.data.type });
                files.push(blob);
                if (count === files.length) {
                  resolve(files);
                }
              });
            }
          });
        });
      }).then(documents => {
        console.log(documents);
        FileUtils.exportAsTif(documents, filename, patientDetails, referralDetails, true);
      });
    }
  };

  static printPatientDemographics = (patientDetails, myWindow, referralDetails, currentUser) => {
    var myStringOfstyles =
      'body { margin: 0; width: 210mm; height: 296mm; }' +
      ' .sheet-outer { margin: 0; }' +
      ' .sheet { margin: 0; padding: 4 12; overflow: hidden; position: relative; box-sizing: border-box;page-break-after: always;}' +
      ' .sheet-outer.A4 .sheet {  width: 210mm; height: 296mm }';

    if (myWindow == null) {
      myWindow = window.open();
      myWindow.document.body.style.width = '216mm';
      myWindow.document.body.style.margin = '0';
    }
    var linkElement = myWindow.document.createElement('link');
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute('type', 'text/css');
    linkElement.setAttribute(
      'href',
      'data:text/css;charset=UTF-8,' + encodeURIComponent(myStringOfstyles),
    );

    myWindow.document.head.innerHTML +=
      '<link rel="stylesheet" href="styles.css" type="text/css"/>';
    myWindow.document.body.innerHTML += FileUtils.patientDemographicsPage(
      patientDetails,
      referralDetails,
      currentUser,
    );
  };

  static downloadFileAsPDF = doc => {
    if (doc.url) {
      ApiService.getFileDownload(
        doc.url,
        !isEmpty(doc.referral_document_name) ? doc.referral_document_name : doc.name,
      );
      return;
    }
  };

  static convertImageUrlToTiff = (pages, myWindow, name, isPrint) => {
    return new Promise(function(resolve, reject) {
      var page = pages.pop();
      if (!page.type.includes('tif')) {
        var URI = window.URL.createObjectURL(page);
        if (URI == null) return reject();
        var canvas = document.createElement('canvas'),
          context = canvas.getContext('2d'),
          image = new Image();

        canvas.width = 850;
        canvas.height = 1118.74;
        context.fillStyle = 'white';
        context.fillRect(0, 0, canvas.width, canvas.height);
        image.addEventListener(
          'load',
          function() {
            var hRatio = canvas.width / image.width;
            var vRatio = canvas.height / image.height;
            var ratio = Math.min(hRatio, vRatio);

            var centerShift_x = (canvas.width - image.width * ratio) / 2;
            var centerShift_y = (canvas.height - image.height * ratio) / 2;
            context.drawImage(
              image,
              0,
              0,
              image.width,
              image.height,
              centerShift_x,
              centerShift_y,
              image.width * ratio,
              image.height * ratio,
            );
            context.getImageData(0, 0, image.width, image.height);
            if (myWindow == null) {
              myWindow = window.open();
              myWindow.document.body.style.width = '216mm';
              myWindow.document.body.style.margin = '0';
            }
            myWindow.document.body.appendChild(canvas);
            resolve(pages);
          },
          false,
        );
        image.src = URI;
      } else {
        let reader = new FileReader();
        reader.onload = e => {
          const tiff = new Tiff({ buffer: e.target.result });
          const canvasTiff = tiff.toCanvas();

          var canvas = document.createElement('canvas'),
            context = canvas.getContext('2d'),
            image = new Image();
          image.addEventListener(
            'load',
            function() {
              canvas.width = 850;
              canvas.height = 1118.74;
              context.fillStyle = 'white';
              context.fillRect(0, 0, canvas.width, canvas.height);
              context.drawImage(image, 0, 0, image.width, image.height);
              context.getImageData(0, 0, image.width, image.height);
              if (myWindow == null) {
                myWindow = window.open();
                myWindow.document.body.style.width = '216mm';
                myWindow.document.body.style.margin = '0';
              }
              myWindow.document.body.appendChild(canvas);
              resolve(pages);
            },
            false,
          );
          image.src = canvasTiff.toDataURL('image/png');
        };

        reader.readAsArrayBuffer(page);
      }
    }).then(pagesResult => {
      if (pagesResult.length === 0) {
        if (isPrint) {
          myWindow.print();
          // myWindow.close();
        } else {
          myWindow.document.body.style.width = '800px';
          domtoimage.toPng(myWindow.document.body, { quality: 1.0 }).then(function(dataUrl) {
            var link = document.createElement('a');
            link.setAttribute('download', name + '.tif');
            link.href = dataUrl;
            link.click();
            // myWindow.close();
          });
        }
      } else if (pagesResult != null) {
        if (pagesResult[pagesResult.length - 1].type == 'application/pdf') {
          FileUtils.convertDocumentsToTiff(pagesResult, myWindow, name, isPrint);
        } else {
          FileUtils.convertImageUrlToTiff(pagesResult, myWindow, name, isPrint);
        }
      }
    });
  };

  static convertDocumentsToTiff = (pages, myWindow, name, isPrint) => {
    var pagesNum = 0;
    new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      canvas.setAttribute('className', 'canv');
      let reader = new FileReader();
      reader.onload = e => {
        const data = atob(e.target.result.replace(/.*base64,/, ''));
        const pdf = PDFJS.getDocument({ data }).promise.then(pdf => {
          if(pdf.numPages > 0){
            FileUtils.handleGetPage(resolve, pagesNum, pdf, myWindow, pages);
          } else {
            resolve(pages);
          }
        });
      };
      reader.readAsDataURL(pages.pop());
    }).then(pagesResult => {
      if (pagesResult.length === 0) {
        if (isPrint) {
          myWindow.print();
          // myWindow.close();
        } else {
          myWindow.document.body.style.width = '800px';
          domtoimage.toPng(myWindow.document.body, { quality: 1.0 }).then(function(dataUrl) {
            var link = document.createElement('a');
            link.setAttribute('download', name + '.tif');
            link.href = dataUrl;
            link.click();
            // myWindow.close();
          });
        }
      } else if (pagesResult != null) {
        if (pagesResult[pagesResult.length - 1].type == 'application/pdf') {
          FileUtils.convertDocumentsToTiff(pagesResult, myWindow, name, isPrint);
        } else {
          FileUtils.convertImageUrlToTiff(pagesResult, myWindow, name, isPrint);
        }
      }
    });
  };

  static handleGetPage = (resolve, pagesNum, pdf, myWindow, pages) => {
    pdf.getPage(pagesNum + 1).then(page => {
      const scale = '1.5';
      const viewport = page.getViewport({
        scale: scale,
      });
      const canvas = document.createElement('canvas');
      const canvasContext = canvas.getContext('2d');

      canvas.height = viewport.height || viewport.viewBox[3]; /* viewport.height is NaN */
      canvas.width = viewport.width || viewport.viewBox[2];

      page
        .render({
          canvasContext,
          viewport,
          intent: 'print',
        })
        .promise.then(res => {
          pagesNum++;
          myWindow.document.body.appendChild(canvas);
          if (myWindow == null) {
            myWindow = window.open();
            myWindow.document.body.style.width = '216mm';
            myWindow.document.body.style.margin = '0';
          }
          if (pagesNum === pdf.numPages) {
            resolve(pages);
          } else {
            FileUtils.handleGetPage(resolve, pagesNum, pdf, myWindow, pages);
          }
        });
    });
  }

  static faxSend = (patientDetails, uploadedFileObjects, referralDetails, currentUser) => {
    return FileUtils.generatePatientDetailsPDF(
      patientDetails,
      uploadedFileObjects,
      referralDetails,
      '',
      currentUser,
      true,
    );
  };

  static faxNewFiles = (patientDetails, documents, referralDetails, currentUser) => {
    return new Promise((resolveVal, reject) => {
      console.log(documents);
      FileUtils.preparePdf(
        documents,
        patientDetails,
        referralDetails,
        resolveVal,
        '',
        currentUser,
        true,
      );
    });
  };

  static generatePatientDetailsPDF = (
    patientDetails,
    uploadedFileObjects,
    referralDetails,
    filename,
    currentUser,
    is_fax,
  ) => {
    var count = 0;
    return new Promise((resolveVal, reject) => {
      if (!isEmpty(uploadedFileObjects)) {
        new Promise((resolve, reject) => {
          var files = [];
          Object.keys(uploadedFileObjects).map(key => {
            uploadedFileObjects['' + key].map((doc, index) => {
              count++;
              if (doc.url && StringUtils.getFileExtension('path', doc.url) !== '.zip') {
                ApiService.getPDFFileAsBlob(doc.url).then(res => {
                  const blob = new Blob([res.data], { type: res.data.type });
                  files.push(blob);
                  if (count === files.length) {
                    resolve(files);
                  }
                });
              }
            });
          });
        }).then(documents => {
          FileUtils.preparePdf(
            documents,
            patientDetails,
            referralDetails,
            resolveVal,
            filename,
            currentUser,
            is_fax,
          );
        });
      }
    });
  };

  static preparePdf = (
    documents,
    patientDetails,
    referralDetails,
    resolveVal,
    filename,
    currentUser,
    is_fax,
  ) => {
    const doc = new jsPDF({
      format: 'legal',
      unit: 'mm',
    });
    if (!isEmpty(patientDetails) && !isEmpty(referralDetails)) {
      FileUtils.appendReferralDetails(
        patientDetails,
        doc,
        referralDetails,
        documents,
        resolveVal,
        filename,
        currentUser,
        is_fax,
      );
    } else if (!isEmpty(documents) && documents[documents.length - 1].type == 'application/pdf') {
      FileUtils.appendDocuments(documents, doc, resolveVal, filename);
    } else if (!isEmpty(documents)) {
      FileUtils.appendImages(documents, doc, resolveVal, filename);
    }
  };

  static appendDocuments = (pages, doc, resolveVal, filename) => {
    var pagesNum = 0;
    new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      canvas.setAttribute('className', 'canv');
      let reader = new FileReader();
      reader.onload = e => {
        const data = atob(e.target.result.replace(/.*base64,/, ''));
        const pdf = PDFJS.getDocument({ data }).promise.then(pdf => {
          if(pdf.numPages > 0){
            FileUtils.handleAppendGetPage(pdf, doc, resolve, pages, pagesNum);
          } else {
            resolve(pages);
          }
        });
      };
      reader.readAsDataURL(pages.pop());
    }).then(pagesResult => {
      if (pagesResult.length === 0) {
        FileUtils.generateFile(doc, resolveVal, filename);
      } else if (pagesResult != null) {
        if (pagesResult[pagesResult.length - 1].type == 'application/pdf') {
          FileUtils.appendDocuments(pagesResult, doc, resolveVal, filename);
        } else {
          FileUtils.appendImages(pagesResult, doc, resolveVal, filename);
        }
      }
    });
  };

  static handleAppendGetPage = (pdf, doc, resolve, pages, pagesNum) =>{
    pdf.getPage(pagesNum + 1).then(page => {
      const scale = '1.5';
      const viewport = page.getViewport({
        scale: scale,
      });
      const canvas = document.createElement('canvas');
      const canvasContext = canvas.getContext('2d');
      canvas.height = viewport.height || viewport.viewBox[3]; /* viewport.height is NaN */
      canvas.width = viewport.width || viewport.viewBox[2]; /* viewport.width is also NaN */
      page
        .render({
          canvasContext,
          viewport,
          intent: 'print',
        })
        .promise.then(res => {
          pagesNum++;
          let imgData = canvas.toDataURL('image/jpeg');
          doc.addPage();
          var percent = doc.internal.pageSize.getWidth() / canvas.width;
          doc.addImage(
            imgData,
            'JPEG',
            0,
            0,
            doc.internal.pageSize.getWidth(),
            percent * canvas.height,
            null,
            'MEDIUM',
          );

          if (pagesNum === pdf.numPages) {
            resolve(pages);
          } else {
            FileUtils.handleAppendGetPage(pdf, doc, resolve, pages, pagesNum);
          }
        })
        .catch(err => {
          console.log(err);
        });
    });
  }

  static appendImages = (pages, doc, resolveVal, filename) => {
    return new Promise(function(resolve, reject) {
      var page = pages.pop();
      if (!page.type.includes('tif')) {
        var URI = window.URL.createObjectURL(page);
        if (URI == null) return reject();
        var canvas = document.createElement('canvas'),
          context = canvas.getContext('2d'),
          image = new Image();

        canvas.width = 850;
        canvas.height = 1118.74;
        context.fillStyle = 'white';
        context.fillRect(0, 0, canvas.width, canvas.height);
        image.addEventListener(
          'load',
          function() {
            var hRatio = canvas.width / image.width;
            var vRatio = canvas.height / image.height;
            var ratio = Math.min(hRatio, vRatio);

            var centerShift_x = (canvas.width - image.width * ratio) / 2;
            var centerShift_y = (canvas.height - image.height * ratio) / 2;
            context.drawImage(
              image,
              0,
              0,
              image.width,
              image.height,
              centerShift_x,
              centerShift_y,
              image.width * ratio,
              image.height * ratio,
            );
            context.getImageData(0, 0, image.width, image.height);
            doc.addPage();
            let imgData = canvas.toDataURL('image/jpeg');
            var percent = doc.internal.pageSize.getWidth() / canvas.width;
            doc.addImage(
              imgData,
              'JPEG',
              0,
              0,
              doc.internal.pageSize.getWidth(),
              percent * canvas.height,
              null,
              'MEDIUM',
            );
            resolve(pages);
          },
          false,
        );
        image.src = URI;
      } else {
        let reader = new FileReader();
        reader.onload = e => {
          const tiff = new Tiff({ buffer: e.target.result });
          const canvasTiff = tiff.toCanvas();

          var canvas = document.createElement('canvas'),
            context = canvas.getContext('2d'),
            image = new Image();
          image.addEventListener(
            'load',
            function() {
              canvas.width = 850;
              canvas.height = 1118.74;
              context.fillStyle = 'white';
              context.fillRect(0, 0, canvas.width, canvas.height);
              context.drawImage(image, 0, 0, image.width, image.height);
              context.getImageData(0, 0, image.width, image.height);
              doc.addPage();
              let imgData = canvas.toDataURL('image/jpeg');
              var percent = doc.internal.pageSize.getWidth() / canvas.width;
              doc.addImage(
                imgData,
                'JPEG',
                0,
                0,
                doc.internal.pageSize.getWidth(),
                percent * canvas.height,
                null,
                'MEDIUM',
              );
              resolve(pages);
            },
            false,
          );
          image.src = canvasTiff.toDataURL('image/png');
        };

        reader.readAsArrayBuffer(page);
      }
    }).then(pagesResult => {
      if (pagesResult.length === 0) {
        FileUtils.generateFile(doc, resolveVal, filename);
      } else if (pagesResult != null) {
        if (pagesResult[pagesResult.length - 1].type == 'application/pdf') {
          FileUtils.appendDocuments(pagesResult, doc, resolveVal, filename);
        } else {
          FileUtils.appendImages(pagesResult, doc, resolveVal, filename);
        }
      }
    });
  };

  static generateFile = (doc, resolveVal, filename) => {
    let blob = doc.output('blob');
    if (!isEmpty(filename)) {
      doc.autoPrint();
      doc.output('dataurlnewwindow');
    }
    resolveVal(blob);
  };

  static sendFaxMessage = (referralDetails, message, patientDetails, currentUser) => {
    return new Promise(function(resolve, reject) {
      let iframe = window.document.createElement('iframe');
      iframe.style.visibility = 'hidden';
      iframe.style.width = '216mm';
      iframe.style.margin = '0';
      window.document.body.appendChild(iframe);
      let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
      var innerHTML = FileUtils.faxMessageFormat(
        referralDetails,
        message,
        patientDetails,
        currentUser,
      );
      iframedoc.body.innerHTML = innerHTML;
      const doc = new jsPDF({
        format: 'a4',
        unit: 'mm',
      });

      html2canvas(iframedoc.body).then(canvas => {
        let imgData = canvas.toDataURL('image/jpeg');

        var percent = doc.internal.pageSize.getWidth() / canvas.width;
        doc.addImage(
          imgData,
          'JPEG',
          0,
          0,
          doc.internal.pageSize.getWidth(),
          percent * canvas.height,
          null,
          'MEDIUM',
        );

        let blob = doc.output('blob');
        window.document.body.removeChild(iframe);
        resolve(blob);
      });
    });
  };

  static faxMessageFormat = (referralDetails, message, patientDetails, currentUser) => {
    var referral = {};
    if (!isEmpty(referralDetails?.referral) && isEmpty(referralDetails?.referral?.referral)) {
      referral = referralDetails?.referral;
    } else if (!isEmpty(referralDetails?.referral?.referral)) {
      referral = referralDetails?.referral?.referral;
    } else {
      return '';
    }

    var innerHTML = '';
    innerHTML += FileUtils.getHeader(true);

    innerHTML += FileUtils.getReferreringClinic(referral);

    innerHTML += FileUtils.getAddress(referral);

    innerHTML += FileUtils.getContactPerson(null, currentUser);

    innerHTML += FileUtils.getFaxAndPhoneNumber(referral);

    innerHTML += '<hr/>';

    innerHTML +=
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>" +
      'Provider: ' +
      referralDetails.provider.display_name +
      '</span></section>';

    innerHTML +=
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>" +
      'Patient Name: ' +
      patientDetails.patient.full_name +
      '</span></section>';

    innerHTML += '<hr/>';

    innerHTML +=
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>" +
      'Message: ' +
      message +
      '</span></section>';

    innerHTML += '<hr/>';

    innerHTML += '</div></body></html>';

    return innerHTML;
  };

  static appendReferralDetails = async (
    patientDetails,
    doc,
    referralDetails,
    documents,
    resolveVal,
    filename,
    currentUser,
    is_fax,
  ) => {
    let iframe = window.document.createElement('iframe');
    iframe.style.visibility = 'hidden';
    iframe.style.width = '216mm';
    iframe.style.margin = '0';
    window.document.body.appendChild(iframe);
    let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
    var innerHTML = FileUtils.patientDemographicsPage(
      patientDetails,
      referralDetails,
      currentUser,
      is_fax,
    );
    iframedoc.body.innerHTML = innerHTML;

    let canvas = await html2canvas(iframedoc.body, {});
    let imgData = canvas.toDataURL('image/jpeg');

    var percent = doc.internal.pageSize.getWidth() / canvas.width;
    doc.addImage(
      imgData,
      'JPEG',
      0,
      0,
      doc.internal.pageSize.getWidth(),
      percent * canvas.height,
      null,
      'MEDIUM',
    );

    let blob = doc.output('blob');
    window.document.body.removeChild(iframe);
    if (!isEmpty(documents) && documents[documents.length - 1]?.type == 'application/pdf') {
      FileUtils.appendDocuments(documents, doc, resolveVal, filename);
    } else if (!isEmpty(documents)) {
      FileUtils.appendImages(documents, doc, resolveVal, filename);
    } else {
      resolveVal(blob);
      if (!isEmpty(filename)) {
        doc.autoPrint();
        doc.output('dataurlnewwindow');
      }
    }
  };

  static patientDemographicsReferral = patient => {
    var innerHTML = '';
    if (isEmpty(patient)) {
      return innerHTML;
    }
    innerHTML +=
      "<section class='sheet' style='padding: 4 12;' ><span style='font-weight: bold;'>Patient Name : </span>" +
      "<span style='text-decoration: underline;'>" +
      (!isEmpty(patient?.full_name) ? patient.full_name : 'N/A') +
      '</span></section>';

    innerHTML +=
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>Address : </span>" +
      "<span style='text-decoration: underline;'>" +
      (!isEmpty(patient?.full_address) ? patient.full_address : 'N/A') +
      '</span></section>';

    innerHTML +=
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>Phone : </span>" +
      "<span style='text-decoration: underline;'>" +
      (!isEmpty(patient?.phone) ? patient.phone.splice(6, 0, '-').splice(3, 0, '-') : 'N/A') +
      '</span></section>';

    innerHTML +=
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>SSN : </span>" +
      "<span style='text-decoration: underline;'>" +
      (!isEmpty(patient?.ssn) ? patient.ssn.splice(5, 0, '-').splice(3, 0, '-') : 'N/A') +
      '</span></section>';

    innerHTML +=
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>DOB : </span>" +
      "<span style='text-decoration: underline;'>" +
      (!isEmpty(patient?.birthdate) ? patient.birthdate : 'N/A') +
      '</span></section>';

    innerHTML +=
      "<section class='sheet' style='padding: 4 12;'>" +
      "<span style='font-weight: bold;'>Primary Insurance : </span>" +
      "<span style='text-decoration: underline;'>" +
      (!isEmpty(patient?.insurance_provider) ? patient.insurance_provider : 'N/A') +
      '</span>' +
      "<span style='font-weight: bold;'>&ensp;&ensp;ID #: </span>" +
      "<span style='text-decoration: underline;'>" +
      (!isEmpty(patient?.policy_number) ? patient.policy_number : 'N/A') +
      '</span>' +
      '</section>';

    return innerHTML;
  };

  static receivingClinicDetails = referral => {
    var innerHTML = '';

    innerHTML +=
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>" +
      (!isEmpty(referral.provider?.display_name)
        ? referral.provider?.display_name + ' M.D.'
        : 'N/A') +
      '</span></section>';

    innerHTML +=
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>" +
      (!isEmpty(referral.provider?.specializations)
        ? referral.provider?.specializations
            .map(function(item) {
              return item['name'];
            })
            .toString()
        : 'N/A') +
      '</span></section>';

    innerHTML +=
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>" +
      (!isEmpty(referral.receiver?.name) ? referral.receiver?.name : 'N/A') +
      '</span></section>';

    return innerHTML;
  };

  static receivingComment = referral => {
    var innerHTML = '';

    innerHTML +=
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>Additional Comment : </span>" +
      "<span style='text-decoration: underline;'>" +
      (!isEmpty(referral.comment) ? referral.comment : 'N/A') +
      '</span></section>';

    return innerHTML;
  };

  static isDate = (date) => {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

  static referralExtraInfo = referral => {
    var innerHTML = '';
    var extra_info = JSON.parse(referral.extra_info);
    if (!isEmpty(extra_info)) {
      for (const [key, value] of Object.entries(extra_info)) {
        if (Object.prototype.toString.call(value).indexOf('Array') > -1) {
          innerHTML +=
            "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>" +
            capitalize(key.replaceAll('custom_', '').replaceAll("_"," ")) +
            ' : </span>';
          if (isEmpty(value)) {
            innerHTML += "<span style='text-decoration: underline;'>N/A</span>";
          }
          value.map((val, index) => {
            innerHTML +=
              "<span style='text-decoration: underline;'>" +
              val +
              (value.length > index + 1 ? ', ' : '') +
              '</span>';
          });

          innerHTML += '</section>';
        } else {
          innerHTML +=
            "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>" +
            capitalize(key.replaceAll('custom_', '').replaceAll("_"," ")) +
            ' : </span>';

          if (isEmpty(value)) {
            innerHTML += "<span style='text-decoration: underline;'>N/A</span>";
          } else if(FileUtils.isDate(value) && value.length > 10){
            innerHTML += "<span style='text-decoration: underline;'>" + value.substring(0,10) + '</span>';
          } else {
            innerHTML += "<span style='text-decoration: underline;'>" + value + '</span>';
          }

          innerHTML += '</section>';
        }
      }
    }

    return innerHTML;
  };

  static printDiagnosis = patient => {
    var innerHTML = '';

    innerHTML +=
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>DIAGNOSIS : </span>" +
      "<span style='font-weight: bold;'>" +
      (!isEmpty(patient?.diagnosis) ? patient.diagnosis.toUpperCase() : 'N/A') +
      '</span></section>';

    return innerHTML;
  };

  static patientDemographicsPage = (patientDetails, referralDetails, currentUser, is_fax) => {
    var innerHTML = '';
    var referral = {};
    var patient = {};

    if (!isEmpty(patientDetails) && !isEmpty(patientDetails.patient)) {
      patient = patientDetails.patient;
    }

    if (!isEmpty(referralDetails?.referral) && isEmpty(referralDetails?.referral?.referral)) {
      referral = referralDetails?.referral;
    } else if (!isEmpty(referralDetails?.referral?.referral)) {
      referral = referralDetails?.referral?.referral;
    } else {
      return;
    }
    innerHTML += FileUtils.getHeader(is_fax);

    innerHTML += FileUtils.getReferreringClinic(referral);

    innerHTML += FileUtils.getAddress(referral);

    innerHTML += FileUtils.getContactPerson(referral, currentUser);

    innerHTML += FileUtils.getFaxAndPhoneNumber(referral);

    innerHTML += '<hr/>';

    innerHTML += FileUtils.receivingClinicDetails(referral);

    innerHTML += '<hr/>';

    innerHTML +=
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>Referral Date : </span>" +
      "<span style='text-decoration: underline;'>" +
      (!isEmpty(referral.sent_at)
        ? moment(referral.sent_at, 'YYYY-MM-DD HH:mm:ss')
            .local()
            .format('YYYY-MM-DD')
        : DateTimeUtils.now().format('YYYY-MM-DD')) +
      '</span></section>';

    innerHTML += FileUtils.patientDemographicsReferral(patient);

    innerHTML += FileUtils.referralExtraInfo(referral);

    innerHTML += FileUtils.printDiagnosis(patient);

    innerHTML += FileUtils.receivingComment(referral);

    innerHTML += '<hr/>';

    innerHTML +=
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>Appt : </span>" +
      '<span>_______________________ @ ______________</span>' +
      "<span style='font-weight: bold;'>Scheduled by : </span> <span>___________________________________</span>" +
      '</section>';

    innerHTML += '</div></body></html>';

    return innerHTML;
  };

  static getHeader(is_fax) {
    var innerHTML = "<!DOCTYPE html><html><body><div class='sheet-outer A4'>";
    innerHTML += "<div class='sheet-outer A4'>";
    innerHTML += "<section class='sheet' style='padding: 4 12; margin-left: 220;'>";
    if (is_fax) {
      innerHTML +=
        "<img src='/static/media/black-logo.8688d0e1.png' style=' width: 300; height: 66; object-fit: cover; transition-property: opacity; transition-duration: 0ms; transition-timing-function: cubic-bezier(0.7, 0, 0.6, 1); opacity: 1; animation: 0ms cubic-bezier(0.7, 0, 0.6, 1) 0s 1 normal none running materialize; -webkit-filter: grayscale(1) invert(1);filter: grayscale(1) invert(1);' />";
      innerHTML += '</section>';
      innerHTML +=
        "<section class='sheet' style='padding: 4 12; margin-left: 100;'><h2 style='font-style: italic;'>Want to get this referral online? contact@ziprefer.com</h2></section>";
    } else {
      innerHTML +=
        "<img src='/static/media/ziprefer-transparent.43b797ae.png' style=' width: 300; height: 66; object-fit: cover; transition-property: opacity; transition-duration: 0ms; transition-timing-function: cubic-bezier(0.7, 0, 0.6, 1); opacity: 1; animation: 0ms cubic-bezier(0.7, 0, 0.6, 1) 0s 1 normal none running materialize; -webkit-filter: grayscale(1) invert(1);filter: grayscale(1) invert(1);' />";
      innerHTML += '</section>';
      innerHTML +=
        "<section class='sheet' style='padding: 4 12; margin-left: 210;'><h4 style='font-style: italic;'>This referral has been brought to you by ZipRefer!</h4></section>";
    }

    return innerHTML;
  }

  static getReferreringClinic(referral) {
    var innerHTML =
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>Referring Clinic : </span>" +
      "<span style='text-decoration: underline;'>";
    if (!isEmpty(referral.referrer?.name)) {
      innerHTML += referral.referrer?.name;
    } else {
      innerHTML += 'N/A';
    }
    innerHTML += '</span></section>';
    return innerHTML;
  }

  static getAddress(referral) {
    var innerHTML =
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>Address : </span>" +
      "<span style='text-decoration: underline;'>";
    if (!isEmpty(referral.referrer?.full_address)) {
      innerHTML += referral.referrer?.full_address;
    } else {
      innerHTML += 'N/A';
    }
    innerHTML += '</span></section>';
    return innerHTML;
  }

  static getContactPerson(referral, currentUser) {
    var innerHTML =
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>Contact : </span>" +
      "<span style='text-decoration: underline;'>";
    if (!isEmpty(referral) && !isEmpty(referral.referrer?.referrer_name)) {
      innerHTML += referral.referrer?.referrer_name;
    } else if (!isEmpty(currentUser)) {
      innerHTML += currentUser.firstname + ' ' + currentUser.lastname;
    } else {
      innerHTML += 'N/A';
    }
    innerHTML +=
      '</span>' +
      "<span style='font-weight: bold;'>&ensp;&ensp;E-mail Address : </span>" +
      "<span style='text-decoration: underline;'>";

    if (!isEmpty(referral) && !isEmpty(referral.referrer?.referrer_email)) {
      innerHTML += referral.referrer?.referrer_email;
    } else if (!isEmpty(currentUser)) {
      innerHTML += currentUser.email;
    } else {
      innerHTML += 'N/A';
    }

    innerHTML += '</span></section>';
    return innerHTML;
  }

  static getFaxAndPhoneNumber(referral) {
    var innerHTML =
      "<section class='sheet' style='padding: 4 12;'><span style='font-weight: bold;'>Fax # : </span>" +
      "<span style='text-decoration: underline;'>";
    if (!isEmpty(HelperUtils.getReferrerFaxNumber(referral))) {
      innerHTML += HelperUtils.getReferrerFaxNumber(referral);
    } else {
      innerHTML += 'N/A';
    }
    innerHTML +=
      '</span>' +
      "<span style='font-weight: bold;'>&ensp;&ensp;Phone # : </span>" +
      "<span style='text-decoration: underline;'>";

    if (!isEmpty(HelperUtils.getReferrerPhone(referral))) {
      innerHTML += HelperUtils.getReferrerPhone(referral);
    } else {
      innerHTML += 'N/A';
    }

    innerHTML += '</span></section>';
    return innerHTML;
  }
}

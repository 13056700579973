import React, { useCallback, useEffect, useState } from 'react';
import {  Grid } from '@mui/material';
import { HookFormAutocomplete, HookFormTextField, MyTypography } from '../../common/components';
import MobileNumberMask from '../../common/components/mask/MobileNumberMask';
import * as yup from 'yup';
import 'yup-phone';
import { gridSpacing } from '../../../common/themes/constants';
import RoleOptions from '../AddUser/RoleOptions';
import { capitalize, isEmpty, isObject } from 'lodash';
import PermissionOptions from './PermissionOptions';
import NotificationOptions from './NotificationOptions';
import { GET_NOTIFICATIONS_QUERY, GET_PERMISSIONS_QUERY } from '../../clinics/gql/Query';
import { useQuery } from '@apollo/client';
import clientGraphql from '../../../common/apollo-graphql';

export const initialValues = {
  role_id: 2,
  email: '',
  firstname: '',
  lastname: '',
  phone: '',
  provider_ids: [],
  permission: parseInt('100', 2),
  notification: parseInt('1', 2),
};

function ProviderOptions({ prefix = null, control, errors, providers }) {
  return (
    <HookFormAutocomplete
      id="provider_ids"
      name="provider_ids"
      placeholder="Assign to Provider(s)"
      label="Providers *"
      schema="provider_ids"
      prefix={prefix}
      control={control}
      errors={errors}
      autocompleteProps={{
        includeInputInList: true,
        multiple: true,
        freeSolo: true,
        fullWidth: true,
      }}
      options={providers.map(i => ({
        value:
          isObject(i) && i.hasOwnProperty('firstname')
            ? capitalize(i.firstname) + ' ' + capitalize(i.lastname)
            : i.display_name,
        label:
          isObject(i) && i.hasOwnProperty('firstname')
            ? capitalize(i.firstname) + ' ' + capitalize(i.lastname)
            : i.display_name,
      }))}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(props, option) => (
        <li key={option.value} {...props}>
          {capitalize(option.label)}
        </li>
      )}
    />
  );
}

export const registerUserValidationSchema = yup.object().shape({
  role_id: yup
    .number()
    .typeError('Please select role')
    .required('Please select role'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is required'),
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  phone: yup.string().matches(/.{12,}/, {
    excludeEmptyString: true,
    message: 'Phone number must have 10 digits',
  }),
  provider_ids: yup.array(),
});

export default function RegisterUserForm({
  control,
  errors,
  prefix = null,
  permissions = [],
  setValue,
  disabled=false,
  trigger,
  providers = null,
}) {
  const [permissionBinary, setPermissionBinary] = useState({});
  const [notificationsBinary, setNotificationsBinary] = useState({});
  const [parsedData, setParsedData] = useState({});
  const [parsedNotificationData, setParsedNotificationData] = useState({});

  const { data } = useQuery(GET_PERMISSIONS_QUERY, {
    client: clientGraphql,
  });
  const { data: dataNotificationSettings } = useQuery(GET_NOTIFICATIONS_QUERY, {
    client: clientGraphql,
  });

  useEffect(() => {
    if (!isEmpty(data)) {
        const newData = data.user_permission_settings.map(permission => {
          return { value: permission.toLowerCase().includes('schedule'),
            label: permission };
        });
        setParsedData(newData);
    }
  }, [data, setParsedData]);

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(permissions)) {
        const newData = data.user_permission_settings.map((permission, index) => {
          return { value: permissions[index].value,
            label: permission,
          name: permission };
        });
        setParsedData(newData);
    }
  }, [data, setParsedData, permissions]);

  useEffect(() => {
    if (!isEmpty(parsedData) && isEmpty(permissionBinary)) {
      setPermissionBinary({
        ...Object.fromEntries(parsedData.map(v => [v.label.replace(' ', '_'), v.value])),
      });
    }
  }, [parsedData, setPermissionBinary, permissionBinary]);

  useEffect(() => {
    if (!isEmpty(parsedNotificationData) && isEmpty(notificationsBinary)) {
      setNotificationsBinary({
        ...Object.fromEntries(parsedNotificationData.map(v => [v.label.replace(' ', '_'), v.value])),
      });
      // setValue(!isEmpty(prefix) ? `${prefix}.role_id`:'role_id',2);
      // trigger(!isEmpty(prefix) ?`${prefix}.role_id`:'role_id');
    }
  }, [parsedNotificationData, setNotificationsBinary, notificationsBinary,trigger, setValue, prefix]);

  useEffect(() => {
    if (!isEmpty(dataNotificationSettings)) {
        const newData = dataNotificationSettings.user_notification_settings.map(notification => {
            return { value: true,
            label: notification,
            name: notification };
        });
        setParsedNotificationData(newData);
    }
  }, [dataNotificationSettings, setParsedNotificationData]);


  const handlePermissionChange = useCallback(
    (value, permision) => {
      var binary = permissionBinary;
      permision.value = !permision.value;
      binary[permision.label.replace(' ', '_')] = permision.value;
      setPermissionBinary(binary);
      var strPermission = '';
      Object.keys(permissionBinary)
        .reverse()
        .forEach(function(key, index) {
          if (permissionBinary[key]) {
            strPermission += '1';
          } else {
            strPermission += '0';
          }
        });
      var permissionInt = parseInt(strPermission, 2);
      setValue(!isEmpty(prefix) ? `${prefix}.permission` : 'permission', permissionInt);
      trigger(!isEmpty(prefix) ? `${prefix}.permission` : 'permission');
    },
    [setValue, trigger, prefix, permissionBinary],
  );

  const handleNotificationChange = useCallback(
    (value, notification) => {
      var binary = notificationsBinary;
      notification.value = !notification.value;
      binary[notification.label.replace(' ', '_')] = notification.value;
      setNotificationsBinary(binary);
      var strNotification = '';

      Object.keys(notificationsBinary)
        .reverse()
        .forEach(function(key, index) {
          if (notificationsBinary[key]) {
            strNotification += '1';
          } else {
            strNotification += '0';
          }
        });
      var notificationInt = parseInt(strNotification, 2);
      setValue(!isEmpty(prefix) ? `${prefix}.notification` : 'notification', notificationInt);
      trigger(!isEmpty(prefix) ? `${prefix}.notification` : 'notification');
    },
    [setValue, trigger, prefix, notificationsBinary],
  );

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item sm={6} xs={12}>
        <RoleOptions prefix={prefix} control={control} errors={errors} 
          disabled={disabled}/>
      </Grid>

      <Grid item sm={6} xs={12}>
        <HookFormTextField
          id="email"
          name="email"
          type="email"
          placeholder="Enter email address"
          label="Email address *"
          autoComplete="email"
          schema="email"
          prefix={prefix}
          control={control}
          errors={errors}
          autoFocus
          fullWidth
          disabled={disabled}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <HookFormTextField
          id="firstname"
          name="firstname"
          placeholder="Enter First Name"
          label="First Name *"
          autoComplete="firstname"
          schema="firstname"
          prefix={prefix}
          control={control}
          errors={errors}
          fullWidth
          disabled={disabled}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <HookFormTextField
          id="lastname"
          name="lastname"
          placeholder="Enter Last Name"
          label="Last Name *"
          autoComplete="lastname"
          schema="lastname"
          prefix={prefix}
          control={control}
          errors={errors}
          fullWidth
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12}>
        <HookFormTextField
          id="phone"
          name="phone"
          type="tel"
          placeholder="Enter Phone"
          label="Phone"
          autoComplete="phone"
          schema="phone"
          prefix={prefix}
          control={control}
          errors={errors}
          InputProps={{
            inputComponent: MobileNumberMask,
          }}
          fullWidth
          disabled={disabled}
        />
      </Grid>
      {!isEmpty(providers) && (
        <Grid item xs={12}>
          <ProviderOptions
            id="provider_ids"
            name="provider_ids"
            prefix={prefix}
            control={control}
            errors={errors}
            providers={providers}
            schema="provider_ids"
            placeholder="Enter the Providers"
            label="Assign to Provider(s)"
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <MyTypography variant="h5" component="h5" color="text.primary">
          Permissions
        </MyTypography>
      </Grid>
      <PermissionOptions
        placeholder="Enter the Permissions"
        label="Permissions"
        permissions={parsedData}
        handleChange={handlePermissionChange}
      />
      <Grid item xs={12}>
        <MyTypography variant="h5" component="h5" color="text.primary">
          Notifications
        </MyTypography>
      </Grid>
      <NotificationOptions
        placeholder="Enter the Notications"
        label="Notifications"
        notificationSettings={parsedNotificationData}
        handleChange={handleNotificationChange}
      />
    </Grid>
  );
}


import { Chip, Stack } from '@mui/material';
import { capitalize } from 'lodash';
import React from 'react';

export default function SpecializationList({params}) {
    return (
        <Stack direction="row" spacing={{ xs: 1, sm: 2 }} flexWrap="wrap" useFlexGap>
        {params.row.specializations.map((item,index) => (
          <Chip
            color="secondary"
            size="small"
            variant="outlined"
            label={capitalize(item.name)}
            key={'chip-' + index}
            style={{margin: 4}}
          />
        ))}
      </Stack>
    );
  }
// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import {
  ManageClinics,
  ManageClinicDetails,
} from '../clinics/Management';
import {
  Authenticated,
} from '../auth';
import { ManageDocuments, ManageRoles, ManageUsers, ManageReferrals, ManageFax } from './';
import ManageDocumentQuestions from './ManageDocumentQuestions';
import ManageReferralGroups from '../clinics/Management/ManageReferralGroups';
import ManageReferralGroupDetails from '../clinics/Management/ManageReferralGroupDetails';
import AddReferralGroup from '../clinics/Management/ReferralGroups/AddReferralGroup';
import ManageReferralFormClinicDetails from '../clinics/Management/ManageReferralFormClinicDetails';

export default {
  path: 'manage',
  component: Authenticated,
  childRoutes: [
    {
      path: 'clinics',
      childRoutes: [
        { path: '', component: ManageClinics, props: {title: 'Manage Clinics', subtitle: 'Manage clinics and users'} },
        { path: ':id', 
          childRoutes: [
            { path: '', component: ManageClinicDetails, props: {title: 'Clinics Details', subtitle: 'Manage clinics details and users'} },
            { path: 'settings', component: ManageReferralFormClinicDetails, props: {title: 'Referral Form Clinics Details', subtitle: 'Manage Referral Form clinics details and users'}},
          ],
        },
      ],
    },
    { path: 'roles', component: ManageRoles, props: {title: 'Manage Roles', subtitle: 'Manage Roles'} },
    { path: 'users', component: ManageUsers, props: {title: 'Manage Users', subtitle: 'Manage Users'} },
    { path: 'referral_network/enroll', component: AddReferralGroup, props: {title: 'Create Referral Group', subtitle: 'Create Referral Group'}},
    { path: 'referral_networks', 
    childRoutes: [
      { path: '', component: ManageReferralGroups, props: {title: 'Manage Referral Groups', subtitle: 'Manage Referral Groups'} },
      { path: ':id', component: ManageReferralGroupDetails, props: {title: 'Edit Referral Group Details', subtitle: 'Edit Referral Group Details'} },
    ]},
    {
      path: 'documents',
      childRoutes: [
        { path: '', component: ManageDocuments, props: {title: 'Manage Documents', subtitle: 'Manage Documents'} },
        { path: ':id', component: ManageDocumentQuestions, props: {title: 'Manage Document Questions', subtitle: 'Manage Document Questions'} },
      ],
    },
    { path: 'referrals', component: ManageReferrals, props: {title: 'Manage Referrals', subtitle: 'Manage Referrals'} },
    { path: 'fax', component: ManageFax, props: {title: 'Manage Fax', subtitle: 'Manage Fax'} },
  ],
};

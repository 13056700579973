import React, { useCallback } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { IconDownload, IconEye, IconSend, IconX } from '@tabler/icons';
import { capitalize, isEmpty } from 'lodash';
import { gridSpacing } from '../../common/themes/constants';
import { MyButton, MyIconButton, MyTypography } from '../common/components';
import StringUtils from '../common/services/StringUtils';
import FileUtils from '../common/services/FileUtils';

export default function UploadedDocuments({
  label = 'Referral Documents',
  patientDetails = {},
  referralDetails = {},
  data_referral_answers,
  uploadedFileObjects,
  deleteReferralDocument = null,
  isReferrer = false,
  currentUser = null,
  isHidePrintAll = false,
  isFax = false,
  newUploadedFiles = null,
  sendFax=null
}) {
  const referralAnswers = useCallback(
    (question, name) => {
      var result = 'No';
      if (!isEmpty(data_referral_answers) && !isEmpty(data_referral_answers.answers)) {
        data_referral_answers.answers.map(ref => {
          if (ref.question.toLowerCase() === question.toLowerCase() || name === ref.name) {
            result = ref.answer;
            return;
          }
        });
      }

      return result;
    },
    [data_referral_answers],
  );

  return (
    <Grid
      container
      spacing={gridSpacing}
      key={'grid-container-' + Math.random()}
      style={label !== 'Referral Documents' ? { marginTop: 16, marginLeft: 16 } : null}
    >
      <Grid item md={12} xs={12}>
        <Stack direction="row">
          <MyTypography variant="h4">{label}</MyTypography>
          {!isEmpty(uploadedFileObjects) && (
            <>
              <Box sx={{ flex: '1 1 auto' }} />
              {!isHidePrintAll && (
                <MyButton
                  style={{ width: 100, padding: 0, marginRight: 20 }}
                  key={'print-All' + Math.random()}
                  onClick={() =>
                    FileUtils.printAll(
                      patientDetails,
                      uploadedFileObjects,
                      referralDetails,
                      isReferrer ? currentUser : null,
                    )
                  }
                  color="primary"
                  variant="outlined"
                >
                  Print All
                </MyButton>
              )}

              {!isHidePrintAll && (
                <MyButton
                  style={{ width: 150, padding: 0 }}
                  key={'Export All as TIF' + Math.random()}
                  onClick={() =>
                    FileUtils.exportAllFileAsTIF(
                      uploadedFileObjects,
                      patientDetails,
                      referralDetails,
                    )
                  }
                  color="primary"
                  variant="outlined"
                >
                  Export All as TIF
                </MyButton>
              )}
            </>
          )}
        </Stack>
      </Grid>
      {!isEmpty(uploadedFileObjects) ? (
        Object.keys(uploadedFileObjects).map(key => {
          return (
            <Grid key={'grid-file-' + Math.random()} item sm={6} xs={12}>
              {uploadedFileObjects['' + key].map((uploadedDoc, index) => {
                if (newUploadedFiles != null && isEmpty(newUploadedFiles) && index == 0) {
                  return (
                    <MyTypography variant="h5" key={'question-name-' + Math.random()}>
                      {capitalize(uploadedDoc.name)}
                    </MyTypography>
                  );
                } else if (newUploadedFiles != null && isEmpty(newUploadedFiles)) {
                  return;
                } else if (
                  !isEmpty(newUploadedFiles) &&
                  !newUploadedFiles.includes(uploadedDoc.uuid) &&
                  index == 0
                ) {
                  return (
                    <MyTypography variant="h5" key={'question-name-' + Math.random()}>
                      {capitalize(uploadedDoc.name)}
                    </MyTypography>
                  );
                } else if (
                  !isEmpty(newUploadedFiles) &&
                  !newUploadedFiles.includes(uploadedDoc.uuid) 
                ) {
                  return;
                }
                return (
                  <>
                    {!isEmpty(uploadedDoc.question) &&
                    !isEmpty(data_referral_answers) &&
                    !isEmpty(data_referral_answers.answers) &&
                    data_referral_answers.answers.filter(
                      ref =>
                        ref.question.toLowerCase() ===
                        uploadedDoc.question[0].question.toLowerCase(),
                    ).length > 0 &&
                    uploadedDoc.question[0].question !== '?' &&
                    index == 0 ? (
                      <MyTypography variant="h5" key={'question-question-' + Math.random()}>
                        {uploadedDoc.question[0].question +
                          '  ' +
                          referralAnswers(uploadedDoc.question[0].question, uploadedDoc.name)}
                      </MyTypography>
                    ) : (
                      index == 0 && (
                        <MyTypography variant="h5" key={'question-name-' + Math.random()}>
                          {capitalize(uploadedDoc.name)}
                        </MyTypography>
                      )
                    )}
                    <Stack direction="row">
                      <MyButton
                        style={{ justifyContent: 'left' }}
                        key={'file' + Math.random()}
                        onClick={() => FileUtils.downloadFile(uploadedDoc)}
                        color="primary"
                        variant="text"
                        startIcon={<IconEye style={{ height: '16px', width: '16px' }} />}
                      >
                        {(!isEmpty(uploadedDoc.referral_document_name)
                          ? uploadedDoc.referral_document_name
                          : uploadedDoc.name + (index > 0 ? '-' + index : '')) +
                          StringUtils.getFileExtension('path', uploadedDoc.url)}
                      </MyButton>
                      {deleteReferralDocument != null && (
                        <MyIconButton
                          color="error"
                          label="Delete Document"
                          style={{ fontSize: '14px' }}
                          onClick={e => {
                            e.stopPropagation();
                            deleteReferralDocument(uploadedDoc);
                          }}
                        >
                          <IconX style={{ height: '16px', width: '16px' }} />
                          Remove
                        </MyIconButton>
                      )}
                      {sendFax != null && (
                        <MyIconButton
                          color="error"
                          label="Fax Document"
                          style={{ fontSize: '14px' }}
                          onClick={e => {
                            e.stopPropagation();
                            sendFax(uploadedDoc, key);
                          }}
                        >
                          <IconSend style={{ height: '16px', width: '16px' }} />
                          Fax
                        </MyIconButton>
                      )}
                      {StringUtils.getFileExtension('path', uploadedDoc.url) == '.pdf' && (
                        <MyIconButton
                          color="error"
                          label="PDF"
                          style={{ fontSize: '14px' }}
                          onClick={e => {
                            FileUtils.downloadFileAsPDF(uploadedDoc);
                          }}
                        >
                          <IconDownload style={{ height: '16px', width: '16px' }} />
                          PDF
                        </MyIconButton>
                      )}
                      {StringUtils.getFileExtension('path', uploadedDoc.url) == '.pdf' && (
                        <MyIconButton
                          color="error"
                          label="TIF"
                          style={{ fontSize: '14px' }}
                          onClick={e => {
                            FileUtils.exportFileAsPDF(uploadedDoc, null);
                          }}
                        >
                          <IconDownload style={{ height: '16px', width: '16px' }} />
                          TIF
                        </MyIconButton>
                      )}

                      {(StringUtils.getFileExtension('path', uploadedDoc.url) == '.jpg' ||
                        StringUtils.getFileExtension('path', uploadedDoc.url) == '.png') && (
                        <MyIconButton
                          color="error"
                          label="TIF"
                          style={{ fontSize: '14px' }}
                          onClick={e => {
                            FileUtils.exportFileAsPDF(uploadedDoc, null);
                          }}
                        >
                          <IconDownload style={{ height: '16px', width: '16px' }} />
                          TIF
                        </MyIconButton>
                      )}
                    </Stack>
                  </>
                );
              })}
            </Grid>
          );
        })
      ) : (
        <Grid item md={12} xs={12}>
          <MyTypography variant="h5" style={{ textAlign: 'center' }}>
            No Uploaded Documents.
          </MyTypography>
        </Grid>
      )}
      {!isEmpty(uploadedFileObjects) &&
      !isEmpty(data_referral_answers) &&
      !isEmpty(data_referral_answers.answers)
        ? data_referral_answers.answers.map(question => {
            return !isEmpty(uploadedFileObjects) &&
              !isEmpty(uploadedFileObjects[question.name]) &&
              question.question != '?' &&
              uploadedFileObjects[question.name].filter(doc => !isEmpty(doc.question)).length >
                0 ? (
              uploadedFileObjects[question.name]
                .filter(doc => !isEmpty(doc.question))[0]
                .question.filter(quest => quest.question == question.question).length <= 0 ? (
                <Grid key={'grid-file-' + Math.random()} item sm={6} xs={12}>
                  <MyTypography variant="h5">
                    {question.question + '  ' + question.answer}
                  </MyTypography>
                </Grid>
              ) : null
            ) : !isEmpty(question) && question.question != '?' ? (
              <Grid key={'grid-file-' + Math.random()} item sm={6} xs={12}>
                <MyTypography variant="h5">
                  {question.question + '  ' + question.answer}
                </MyTypography>
              </Grid>
            ) : null;
          })
        : null}
    </Grid>
  );
}

import React from 'react';
import * as yup from 'yup';
import { gridSpacing } from '../../../../common/themes/constants';
import { Grid } from '@mui/material';
import DocumentOptionalOptions from '../../../auth/RegisterUser/DocumentOptionalOptions';

export const initialValues = {
  document: '',
  question: ''
};

export const createOptionalDocumentSchema = yup.object().shape({
  document: yup.string().required('Optional Document is required'),
  question: yup.string().required('Question is required'),
});

export default function CreateOptionalDocumentForm({ control, errors, prefix = null, setValue, trigger}) {
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <DocumentOptionalOptions
          prefix={prefix}
          control={control}
          errors={errors}
          skip={'misc'}
        />
      </Grid>
    </Grid>
  );
}

import React from 'react';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';
import {MyTypography} from '../../common/components';
import moment from 'moment';
import { IconFileInvoice } from '@tabler/icons';
import DateTimeUtils from '../../common/services/DateTimeUtils';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

const chipSX = {
    height: 24,
    padding: '0 6px'
};

// const dataStyle = {
//     overflow: 'hidden',
//     textOverflow: 'ellipsis',
//     display: '-webkit-box !important',
//     '-webkit-line-clamp': '2 !important',
//     'line-clamp': '2 !important',
//     boxOrient: 'vertical !important',
//     '-webkit-box-orient': 'vertical !important',
// };

const dataStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box !important',
    WebkitLineClamp: '2 !important',
    lineClamp: '2 !important',
    boxOrient: 'vertical !important',
    WebkitBoxOrient: 'vertical !important',
};

const now = moment();

// const chipErrorSX = {
//     ...chipSX,
//     color: theme.palette.orange.dark,
//     backgroundColor: theme.palette.orange.light,
//     marginRight: '5px'
// };
//
// const chipWarningSX = {
//     ...chipSX,
//     color: theme.palette.warning.dark,
//     backgroundColor: theme.palette.warning.light
// };
//
// const chipSuccessSX = {
//     ...chipSX,
//     color: theme.palette.success.dark,
//     backgroundColor: theme.palette.success.light,
//     height: 28
// };

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

function NotificationListItem({
    theme,
    onRead,
    model,
}) {
    const title = model.getTitle(),
          content = model.getContent(),
          notifiedAtTimeObj = model.getCreatedAt(true),
          isRead = model.isRead();

    return (<>
        <ListItemWrapper
            onClick={onRead.bind(this, model.getOnReadParams())}
            sx={{
                py: 1,
                my: 1,
                borderLeft: `2px solid ${theme.palette.primary.main}`,
                bgcolor: isRead ? '#fff' : theme.palette.primary['100'],
            }}>
            <ListItem alignItems="center"
                      >
                <ListItemAvatar sx={{minWidth: 44}}>
                    <Avatar
                        sx={{
                            width: 28,
                            height: 28,
                            backgroundColor: 'none',
                            border: 'none',
                            borderColor: theme.palette.success.main
                        }}
                    >
                        <IconFileInvoice stroke={1.5} size="1rem" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                              primary={title}
                              primaryTypographyProps={{
                                  variant: 'body2',
                                  sx: {
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      flex: '0 0 auto',
                                      maxWidth: 200,
                                  }
                              }}
                              secondary={moment.duration(DateTimeUtils.now().diff(notifiedAtTimeObj)).humanize({m: 59, h: 24, d: 7, w: 4})}
                              secondaryTypographyProps={{
                                 variant: 'caption',
                                 sx: {
                                     flex: '0 0 auto',
                                 }
                              }} />
            </ListItem>
            <Grid container direction="column" className="list-container">
                <Grid item xs={12} sx={{ maxWidth: '100%', width: '100%' }}>
                    <Typography variant="subtitle2"
                                dangerouslySetInnerHTML={{__html: content}}
                                sx={dataStyle}
                    />
                </Grid>
            </Grid>
        </ListItemWrapper>
        {/*<Divider*/}
        {/*    variant="middle"*/}
        {/*    sx={{*/}
        {/*        borderColor: '#fff'*/}
        {/*    }}*/}
        {/*/>*/}
    </>);
}

const NotificationList = ({notifications, onRead, bgcolor='transparent', maxWidth='330px'}) => {
    const theme = useTheme();

    return (
        <List
            sx={{
                bgcolor: bgcolor,
                width: '100%',
                maxWidth: maxWidth,
                py: 0,
                px: 1,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: maxWidth
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 5
                }
            }}
        >
            {
                (!notifications || notifications.length <= 0) &&
                <ListItemWrapper>
                    <ListItem alignItems="center">
                        <MyTypography variant="subtitle2">No notifications</MyTypography>
                    </ListItem>
                </ListItemWrapper>
            }
            {
                (notifications && notifications.length > 0) &&
                notifications.map(item => (<NotificationListItem key={"notif-" + Math.random()}
                                                                 theme={theme}
                                                                 onRead={onRead}
                                                                 model={item.model} />))
            }
        </List>
    );
};

export default NotificationList;

import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Box, Stepper, Step, StepButton, Stack, Grid } from '@mui/material';
import PatientDemographics from '../patient/PatientDemographics';
import PatientReferralDocuments from '../patient/PatientReferralDocuments';
import FindClinic from './FindClinic';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { MyButton } from '../common/components';
import ReferralDetails from './ReferralDetails';
import { useSendReferral } from './redux/sendReferral';
import Swal from 'sweetalert2';
import { GET_REFERRAL_BY_UUID } from './gql';
import clientGraphql from '../../common/apollo-graphql';
import AuthContext from '../auth/context/AuthContext';
import { SAVE_REFERRAL_QUESTIONS_MUTATION } from './gql/Mutation';
import {
  createPatientValidationSchema,
  createReferralValidationSchema,
  initialValues,
} from '../patient/PatientDemographicsForm';
import { GET_CLINIC_REFERRAL_FORM_ATTRIBUTES, MANAGE_CLINIC_DETAILS_QUERY } from '../clinics/gql/Query';
import {
  DEFAULT_INTERNAL_REFERRAL_FORM_FIELD,
  DEFAULT_REFERRAL_FORM_FIELD,
} from '../common/services/DefaultValuesUtils';
import * as yup from 'yup';
import { useFaxSend } from './redux/faxSend';
import FileUtils from '../common/services/FileUtils';
import HelperUtils from '../common/services/HelperUtils';
import { useToggleLeftDrawer } from '../common/redux/toggleLeftDrawer';

const steps = ['Find Provider', 'Patient Demographics', 'Referral Documents', 'Send Referral'];
const steps_index = { clinic: 0, patient: 1, documents: 2, referral: 3 };

export default function CreateReferral({ history }) {
  let { id } = useParams();
  const [isFax, setIsFax] = useState(false);
  const [isSaveDraft, setIsSaveDraft] = useState(false);
  const [referralUuid, setReferralUuid] = useState(null);
  const [parsedData, setParsedData] = useState(null);
  const [uploadedFileObjects, setUploadedFileObjects] = useState({});
  const [patientDetails, setPatientDetails] = useState({});
  const [referralDetails, setReferralDetails] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [isSameOrg, setIsSameOrg] = useState(false);
  const [disableSend, setDisableSend] = useState(true);
  const [disableNext, setDisableNext] = useState(true);
  const [qualifyingQuestions, setQualifyingQuestions] = useState({});
  const [parsedDocumentData, setParsedDocumentData] = useState({});
  const [referralSchema, setReferralSchema] = useState({});
  const [patientSchema, setPatientSchema] = useState({});
  const [requiredCustom, setRequiredCustom] = useState(null);
  const [lastRequiredIndex, setLastRequiredIndex] = useState(-1);
  const [initialReferralValues, setInitialReferralValues] = useState({ ...initialValues });
  const [isFaxSending, setIsFaxSending] = useState(false);
  const currentUser = useContext(AuthContext);
  const [providers, setProviders] = useState([]);

  const { loading: clinicLoading, data: dataClinic, refetc: refetchClinic } = useQuery(MANAGE_CLINIC_DETAILS_QUERY, {client: clientGraphql, variables: {id: currentUser.clinics[0].id}});

  const { sendReferral, sendReferralPending, dismissSendReferralError } = useSendReferral();
  const { faxSend, faxSendPending, dismissFaxSendError } = useFaxSend();
  const [getClinicReferralFormSettings, { data: internalReferralFormData }] = useLazyQuery(
    GET_CLINIC_REFERRAL_FORM_ATTRIBUTES,
    {
      client: clientGraphql,
      variables: { id: id, formType: 'internal' },
    },
  );

  const { toggleLeftDrawer, leftDrawerOpened } = useToggleLeftDrawer();

  if (!isEmpty(id) && isEmpty(referralUuid)) {
    setIsSaveDraft(true);
    setReferralUuid(id);
  }

  const handleOnSetReferralId = useCallback(
    uuid => {
      var tempUuid = referralUuid;
      tempUuid = uuid;
      setReferralUuid(tempUuid);
    },
    [setReferralUuid, referralUuid],
  );

  const [save_referral_questions] = useMutation(SAVE_REFERRAL_QUESTIONS_MUTATION, {
    client: clientGraphql,
  });

  const handleOnDisableSendReferral = useCallback(
    isDisabled => {
      if (
        !isEmpty(currentUser.organization) &&
        !isEmpty(parsedData) &&
        parsedData.clinic.organization.toLowerCase() === currentUser.organization.toLowerCase()
      ) {
        setIsSameOrg(true);
        setDisableSend(false);
      } else if (isDisabled !== disableSend) {
        setDisableSend(isDisabled);
      }
    },
    [setDisableSend, currentUser, parsedData, disableSend],
  );

  const handleSetQualifyingQuestions = useCallback(
    temp => {
      if (isEmpty(qualifyingQuestions)) {
        var qtemp = qualifyingQuestions;
        qtemp = { ...temp };
        setQualifyingQuestions(qtemp);
      }
    },
    [setQualifyingQuestions, qualifyingQuestions],
  );

  const handleFileRequiredChange = useCallback(
    (key, value) => {
      var qtemp = qualifyingQuestions;
      if (!isEmpty(qtemp)) {
        qtemp[key].answer = value ? 'Yes' : 'No';
      }
      setQualifyingQuestions(qtemp);
    },
    [qualifyingQuestions],
  );

  const handleOnDisableNext = useCallback(
    isDisabled => {
      setDisableNext(isDisabled);
    },
    [setDisableNext],
  );

  const { data } = useQuery(GET_REFERRAL_BY_UUID, {
    variables: {
      uuid: referralUuid,
    },
    client: clientGraphql,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
  });

  const isLastStep = useCallback(() => {
    return activeStep === steps.length - 1;
  }, [activeStep]);

  const isStepRequiredDataEmpty = () => {
    var disabled = false;
    if (activeStep === 0 && isEmpty(parsedData)) {
      disabled = true;
    }

    if (activeStep === steps_index.documents && disableSend) {
      disabled = true;
    }

    return disabled;
  };

  const handleNext = useCallback(() => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    const newActiveStep = isLastStep() ? activeStep : activeStep + 1;
    if (newActiveStep === steps_index.referral && !isEmpty(qualifyingQuestions)) {
      uploadReferralQuestions(referralUuid, newActiveStep, qualifyingQuestions);
    } else {
      setActiveStep(newActiveStep);
    }
  }, [
    activeStep,
    completed,
    isLastStep,
    uploadReferralQuestions,
    referralUuid,
    qualifyingQuestions,
  ]);

  const handleBack = useCallback(() => {
    if (activeStep === steps_index.clinic) {
      toggleLeftDrawer(true);
      history.replace('/referrals/active');
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  }, [activeStep, history, toggleLeftDrawer]);

  const handleStep = step => () => {
    setActiveStep(step);
  };

  const onSendReferral = useCallback(() => {
    dismissSendReferralError();
    setIsFaxSending(true);

    const handleFaxSend = res => {
      const formData = new FormData();
      const faxNumber = HelperUtils.getFaxNumber(referralDetails.referral);
      formData.append('file', res);
      formData.append('recipient', '001' + faxNumber.replace('-', ''));
      formData.append('referral_uuid', referralUuid);

      return faxSend(formData);
    };

    const handleSendReferral = () => {
      return sendReferral(referralUuid);
    };

    const showSuccessMessage = message => {
      Swal.close();
      Swal.fire({
        icon: 'success',
        text: message,
        showConfirmButton: true,
      }).then(() => {
        toggleLeftDrawer(true);
        history.push('/referrals/active');
      });
    };

    const showErrorMessage = message => {
      Swal.close();
      Swal.fire({
        icon: 'error',
        text: message,
        showConfirmButton: true,
      });
    };

    if (referralDetails.referral.is_fax) {
        Swal.fire({
            title: 'Validating Files for Faxing',
            text: 'Please Wait',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
      
        new Promise(resolve => setTimeout(resolve, 2000))
            .then(() => 
                FileUtils.faxSend(patientDetails, uploadedFileObjects, referralDetails, currentUser)
            )
            .then(res => 
                handleSendReferral().then(data => ({ res, data }))
            )
            .then(({ res, data }) => {
                showSuccessMessage(data.message);
                return res;
            })
            .then(res => { handleFaxSend(res); })
            .catch(error => { showErrorMessage(error.data ? error.data.error : error.message); })
            .finally(() => {
                setIsFaxSending(false);
            });

    } else {
        sendReferral(referralUuid)
            .then(data => {
                showSuccessMessage(data.message);
            })
            .catch(error => showErrorMessage(error.data ? error.data : error.message))
            .finally(() => setIsFaxSending(false));
    }
  }, [
    sendReferral,
    referralUuid,
    history,
    dismissSendReferralError,
    faxSend,
    uploadedFileObjects,
    patientDetails,
    referralDetails,
    currentUser,
    toggleLeftDrawer,
  ]);

  const uploadReferralQuestions = useCallback(
    (uuid, newActiveStep, qualifyingQuestions) => {
      var keys = Object.keys(qualifyingQuestions);
      var answers = [];
      for (let key in qualifyingQuestions) {
        answers.push(qualifyingQuestions[key]);
      }
      if (keys.length == 0) {
        return;
      }
      var newData = { uuid: uuid, answers: answers };
      save_referral_questions({
        client: clientGraphql,
        variables: newData,
      }).then(res => {
        if (!isEmpty(res.data) && res.data.save_referral_answers) {
          setActiveStep(newActiveStep);
        } else {
          Swal.fire({
            icon: 'error',
            text: 'Failed to submit a referral.',
            showConfirmButton: true,
          }).then(() => {
            //
          });
        }
      });
    },
    [save_referral_questions, setActiveStep],
  );

  const handleCustomFormAttributes = useCallback(formAttrs => {
    var initialVals = { ...initialValues };
    var refSchema = {};
    var patSchema = {};
    var _lastRequiredIndex = -1;
    var form_attributes = formAttrs;

    form_attributes.map((custom_field, index) => {
      if (custom_field.required) {
        _lastRequiredIndex = index;
      }
      if (custom_field.field in createPatientValidationSchema && custom_field.required) {
        patSchema[custom_field.field] = createPatientValidationSchema[custom_field.field];
      }
      if (custom_field.field in createReferralValidationSchema && custom_field.required) {
        refSchema[custom_field.field] = createReferralValidationSchema[custom_field.field];
      }
      if (custom_field.field.includes('custom_') && custom_field.kind !== 'multiple_choice') {
        initialVals.referral[custom_field.field] = '';
      } else if (
        custom_field.field.includes('custom_') &&
        custom_field.kind === 'multiple_choice'
      ) {
        initialVals.referral[custom_field.field] = [];
      }
      if (
        custom_field.field.includes('custom_') &&
        custom_field.required &&
        custom_field.kind === 'multiple_choice'
      ) {
        refSchema[custom_field.field] = yup.array().min(1, custom_field.label + ' is required');
      } else if (
        custom_field.field.includes('custom_') &&
        custom_field.required &&
        custom_field.kind !== 'multiple_choice'
      ) {
        refSchema[custom_field.field] = yup.string().required(custom_field.label + ' is required');
      }
    });
    setLastRequiredIndex(_lastRequiredIndex);
    setPatientSchema(patSchema);
    setReferralSchema(refSchema);
    setInitialReferralValues(initialVals);
    setRequiredCustom(form_attributes.slice());
  }, []);

  const handleOnClickClinic = useCallback(
    data => {
      var isOrg =
        !isEmpty(currentUser.organization) &&
        !isEmpty(data) &&
        !isEmpty(data.row.clinic.organization) &&
        !isEmpty(data.row.clinic.organization.name) &&
        data.row.clinic.organization.name.toLowerCase() === currentUser.organization.toLowerCase();
      getClinicReferralFormSettings({
        client: clientGraphql,
        variables: { id: data.row.clinic.id, formType: isOrg ? 'internal' : 'external' },
      })
        .then(res => {
          setIsFax(isEmpty(data.row.clinic.clinic_users));
          setParsedData({
            ...parsedData,
            specialty: {
              name: data.row.specializations.map(item => item.name).join(', '),
            },
            clinic: {
              isFax: isEmpty(data.row.clinic.clinic_users),
              provider_id: data.row.id,
              provider_name: data.row.display_name,
              organization: !isEmpty(data.row.clinic.organization)
                ? data.row.clinic.organization.name
                : '',
              name: data.row.clinic.name,
              id: data.row.clinic.id,
              address: data.row.clinic.full_address,
              documents: data.row.requiredDocuments,
              optionalDocuments: data.row.optionalDocuments,
            },
          });
          setIsSameOrg(isOrg);
          if (!isEmpty(res.data) && !isEmpty(res.data.clinic_custom_form_attributes)) {
            var form_attributes = res.data.clinic_custom_form_attributes.filter(
              attr => !attr.hidden,
            );
            handleCustomFormAttributes(
              isOrg ? form_attributes.sort((a, b) => b.required - a.required) : form_attributes,
            );
          } else {
            handleCustomFormAttributes(
              isOrg ? DEFAULT_INTERNAL_REFERRAL_FORM_FIELD : DEFAULT_REFERRAL_FORM_FIELD,
            );
          }

          handleNext();
        })
        .catch(err => {
          console.log(err);
        });
    },
    [
      handleNext,
      parsedData,
      currentUser,
      setIsSameOrg,
      getClinicReferralFormSettings,
      handleCustomFormAttributes,
      setIsFax,
    ],
  );
  useEffect(() => {
    if (!isEmpty(dataClinic) && !isEmpty(dataClinic.clinic) && !isEmpty(dataClinic.clinic.providers) && isEmpty(providers)){
      setProviders(dataClinic.clinic.providers);
    }},[setProviders, providers, dataClinic]);
  useEffect(() => {
    if (!isEmpty(data) && isEmpty(parsedData)) {
      const { referral } = data;
      var isOrg =
        !isEmpty(currentUser.organization) &&
        !isEmpty(referral) &&
        !isEmpty(referral.receiver.organization) &&
        !isEmpty(referral.receiver.organization.name) &&
        referral.receiver.organization.name.toLowerCase() ===
          currentUser.organization.toLowerCase();
      getClinicReferralFormSettings({
        client: clientGraphql,
        variables: { id: referral.receiver.id, formType: isOrg ? 'internal' : 'external' },
      })
        .then(res => {
          if (!isEmpty(res.data) && !isEmpty(res.data.clinic_custom_form_attributes)) {
            var form_attributes = res.data.clinic_custom_form_attributes.filter(
              attr => !attr.hidden,
            );
            handleCustomFormAttributes(
              isOrg ? form_attributes.sort((a, b) => b.required - a.required) : form_attributes,
            );
          } else {
            handleCustomFormAttributes(
              isOrg ? DEFAULT_INTERNAL_REFERRAL_FORM_FIELD : DEFAULT_REFERRAL_FORM_FIELD,
            );
          }
          setIsFax(referral.is_fax);
          setParsedData({
            specialty: {
              name: referral.provider.specializations.map(item => item.name).join(', '),
            },
            clinic: {
              isFax: referral.is_fax,
              provider_id: referral.provider.id,
              provider_name: referral.provider.display_name,
              organization: !isEmpty(referral.receiver.organization)
                ? referral.receiver.organization.name
                : '',
              name: referral.receiver.name,
              id: referral.receiver.id,
              address: referral.receiver.full_address,
              documents: referral.provider.documents.filter(doc => {
                return doc.required;
              }),
              optionalDocuments: referral.provider.documents.filter(doc => {
                return !doc.required;
              }),
            },
            status: referral.status,
          });
          setIsSameOrg(isOrg);
          const newCompleted = completed;
          newCompleted[0] = true;
          setCompleted(newCompleted);
          setActiveStep(!isEmpty(id) && (activeStep === 0 || activeStep === 1) ? 1 : activeStep);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [
    data,
    id,
    completed,
    activeStep,
    parsedData,
    currentUser,
    setIsSameOrg,
    getClinicReferralFormSettings,
    handleCustomFormAttributes,
    setIsFax,
  ]);

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton
              color="inherit"
              onClick={handleStep(index)}
              disabled={
                isEmpty(parsedData) || (activeStep >= steps_index.patient && isEmpty(referralUuid))
              }
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <React.Fragment>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item md={12} xs={12} sx={{ px: 2, mt: 3, py: 2 }}>
              {activeStep === steps_index.clinic && (
                <FindClinic handleOnClickClinic={handleOnClickClinic} />
              )}

              {activeStep === steps_index.patient &&
                !isEmpty(requiredCustom) &&
                !isEmpty(parsedData) && (
                  <PatientDemographics
                    data={data}
                    prefix="patient"
                    parsedData={parsedData}
                    referralUuid={referralUuid}
                    onSetReferralId={handleOnSetReferralId}
                    onDisableNext={handleOnDisableNext}
                    isSameOrg={isSameOrg}
                    isSavedDraft={isSaveDraft}
                    referralSchema={referralSchema}
                    patientSchema={patientSchema}
                    requiredCustom={requiredCustom}
                    initialValues={initialReferralValues}
                    lastRequiredIndex={lastRequiredIndex}
                    providers={providers}
                  />
                )}
              {activeStep === steps_index.documents && !isEmpty(parsedData) && (
                <PatientReferralDocuments
                  isSameOrg={isSameOrg}
                  parsedDocumentData={parsedDocumentData}
                  setParsedDocumentData={setParsedDocumentData}
                  qualifyingQuestions={qualifyingQuestions}
                  handleSetQualifyingQuestions={handleSetQualifyingQuestions}
                  parsedData={parsedData}
                  referralUuid={referralUuid}
                  onDisableSendReferral={handleOnDisableSendReferral}
                  handleFileRequiredChange={handleFileRequiredChange}
                />
              )}
              {activeStep === steps_index.referral && (
                <ReferralDetails
                  referralId={referralUuid}
                  onDisableSendReferral={handleOnDisableSendReferral}
                  setCreateFileObjects={setUploadedFileObjects}
                  referralDetails={referralDetails}
                  setReferralDetails={setReferralDetails}
                  patientDetails={patientDetails}
                  setPatientDetails={setPatientDetails}
                  isReferrer={true}
                />
              )}
              <Stack direction="row" spacing={1} sx={{ pt: 2 }}>
                <MyButton
                  color="primary"
                  variant="outlined"
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                  fullWidth={false}
                >
                  {activeStep === steps_index.clinic ? 'Exit' : 'Back'}
                </MyButton>
                <Box sx={{ flex: '1 1 auto' }} />
                <MyButton
                  onClick={handleNext}
                  disabled={
                    isLastStep() ||
                    (activeStep === steps_index.patient && disableNext) ||
                    isStepRequiredDataEmpty() ||
                    (activeStep >= steps_index.patient && isEmpty(referralUuid))
                  }
                  color="primary"
                  variant="outlined"
                  sx={{ mr: 1 }}
                  fullWidth={false}
                >
                  Next
                </MyButton>
                {isLastStep() ? (
                  <MyButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isEmpty(referralUuid) || disableSend || isFaxSending}
                    loading={sendReferralPending || faxSendPending || isFaxSending}
                    onClick={onSendReferral}
                    fullWidth={false}
                  >
                    {isFax ? 'Send Referral Fax' : 'Send Referral'}
                  </MyButton>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        </React.Fragment>
      </div>
    </Box>
  );
}

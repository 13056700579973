import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ApiService from '../../common/services/ApiService';
import {
  GET_REFERRAL_BEGIN,
  GET_REFERRAL_SUCCESS,
  GET_REFERRAL_FAILURE,
  GET_REFERRAL_DISMISS_ERROR,
} from './constants';

export function getReferral(referralUUID) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: GET_REFERRAL_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.getReferral(referralUUID).then(
        (res) => {
          dispatch({
            type: GET_REFERRAL_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: GET_REFERRAL_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetReferralError() {
  return {
    type: GET_REFERRAL_DISMISS_ERROR,
  };
}

export function useGetReferral() {
  const dispatch = useDispatch();

  const { getReferralPending, getReferralError } = useSelector(
    state => ({
      getReferralPending: state.referral.getReferralPending,
      getReferralError: state.referral.getReferralError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((referralUUID, args) => {
    return dispatch(getReferral(referralUUID, args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetReferralError());
  }, [dispatch]);

  return {
    getReferral: boundAction,
    getReferralPending,
    getReferralError,
    dismissGetReferralError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case GET_REFERRAL_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getReferralPending: true,
        getReferralError: null,
      };

    case GET_REFERRAL_SUCCESS:
      // The request is success
      return {
        ...state,
        getReferralPending: false,
        getReferralError: null,
      };

    case GET_REFERRAL_FAILURE:
      // The request is failed
      return {
        ...state,
        getReferralPending: false,
        getReferralError: action.data.error,
      };

    case GET_REFERRAL_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getReferralError: null,
      };

    default:
      return state;
  }
}


export default class NavigationManager {
    static redirectTo(currentUser) {
        if (!currentUser.role) return '/';
        if (currentUser.role.toUpperCase() === 'ADMIN') return '/dashboard';
        if (currentUser.referral_permissions.filter(e => e.key === 'ACCEPT_REFERRAL' && e.value).length > 0 ||
            currentUser.referral_permissions.filter(e => e.key === 'SCHEDULE_REFERRAL' && e.value).length > 0 ||
            currentUser.referral_permissions.filter(e => e.key === 'SEND_REFERRAL' && e.value).length > 0) {
            return '/referrals/active';
        } else {
            return '/referrals/declined';
        }
    }
}
import React from 'react';
import {
    Grid,
} from '@mui/material';
import {gridSpacing} from '../../common/themes/constants';
import MainCard from '../common/ui-component/cards/MainCard';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
    MyButton,
} from '../common/components';
import useQuery from '../common/hooks/useQuery';
import {useActivateAccount} from './redux/hooks';
import AuthPageContainer from './AuthPageContainer';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import ActivateUserForm, {initialValues, validationSchema} from './ActivateAccount/ActivateUserForm';
import { logout } from './hooks/useAuth';


export default function ActivateUserAccount() {
    const queryParams = useQuery(true);
    const history = useHistory();


    const {activateAccount, activateAccountPending, activateAccountError, dismissActivateAccountError} = useActivateAccount(queryParams);

    // TODO: add a function to check if the user is activated, then if activated redirect to dashboard

    const { handleSubmit, control, formState: { errors, isValid } } = useForm({
        defaultValues: {...initialValues, ...queryParams},
        resolver: yupResolver(validationSchema),
        shouldFocusError: true,
    });

    const onSubmit = (data) => {
        dismissActivateAccountError();

        activateAccount(data).then((data) => {
            const {message, redirect_to} = data;
            Swal.fire({
                icon: 'success',
                text: message,
                footer: 'Please login after activation.',
                showConfirmButton: true,
            }).then(() => {
                // history.push('/referrals/active');   
                logout();
            });
        });
    };

    return (
      <AuthPageContainer>
          <MainCard title="Activate your account"
                    subheader="To activate account, please setup password"
                    contentClass="auth-activate-user-account">
              <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container
                        direction="column"
                        spacing={gridSpacing}>
                      <ActivateUserForm control={control}
                                        errors={errors} />
                      <Grid item
                            direction="row"
                            container
                            alignItems="center"
                            justifyContent="flex-end">
                        <Grid item
                              xs={12}>
                            <MyButton type="submit"
                                      variant="contained"
                                      loading={activateAccountPending}
                                      fullWidth={true}>
                                Activate account
                            </MyButton>
                        </Grid>
                      </Grid>
                  </Grid>
              </form>
          </MainCard>
      </AuthPageContainer>
    );
};

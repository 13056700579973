import React, { useState, useEffect, useContext } from 'react';
import {
    Grid,
} from '@mui/material';
import { gridSpacing } from '../../common/themes/constants';
import { Redirect } from 'react-router-dom';
import TotalReferralPerMonth from './TotalReferralPerMonth';
import TotalReferralPreviousMonth from './TotalReferralPreviousMonth';
import TotalReferralsByMonth from './TotalReferralsByMonth';
import AuthContext from '../auth/context/AuthContext';
import NavigationManager from '../common/services/NavigationManager';
import { isEmpty } from 'lodash';
import TotalWaitingTimeDashboard from './TotalWaitingTimeDashboard';
import ReferralReceivedByMonthAndYear from './ReferralReceivedByMonthAndYear';
import ReferralRejectedByMonthAndYear from './ReferralRejectedByMonthAndYear';
import TotalReceivedPerClinicByMonthAndYear from './TotalReceivedPerClinicByMonthAndYear';



const chartSetting = {
    height: 500,
  };
  
  const valueFormatter = (value) => `${value} referrals`;

export default function Dashboard() {
    const [isLoading, setLoading] = useState(true);
    const currentUser = useContext(AuthContext);
    const redirectTo = NavigationManager.redirectTo(currentUser);

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <>
            {!isEmpty(currentUser) && !isEmpty(currentUser.role) && currentUser.role.toUpperCase() === 'ADMIN' ? (<Grid container
                spacing={gridSpacing}
                className="dashboard-dashboard">
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item sm={6} xs={12} md={6} lg={12}>
                                    <TotalReferralPerMonth isLoading={isLoading} month={"" + ((new Date()).getMonth() + 1) + ""} year={"" + ((new Date()).getFullYear())}/>
                                </Grid>
                                <Grid item sm={6} xs={12} md={6} lg={12}>
                                    <TotalReferralPreviousMonth isLoading={isLoading} month={"" + ((new Date()).getMonth()) + ""} year={"" + ((new Date()).getMonth() == 0 ? (new Date()).getFullYear() - 1:(new Date()).getFullYear())}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} md={12}>
                            <TotalReferralsByMonth isLoading={isLoading} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TotalWaitingTimeDashboard />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <ReferralReceivedByMonthAndYear />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <ReferralRejectedByMonthAndYear />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TotalReceivedPerClinicByMonthAndYear />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>) : <Redirect to={redirectTo} />}
        </>

    );
};
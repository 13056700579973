import React, { useCallback, useEffect } from 'react';
import { gridSpacing } from '../../common/themes/constants';
import { Grid } from '@mui/material';
import {
  HookFormDatePicker,
  HookFormRedDatePicker,
  HookFormRedTextField,
  HookFormTextAreaAutoSize,
  HookFormTextField,
} from '../common/components';
import * as yup from 'yup';
import MobileNumberMask from '../common/components/mask/MobileNumberMask';
import SSNMask from '../common/components/mask/SSNMask';
import moment from 'moment';
import AddressZipMask from '../common/components/mask/AddressZipMask';
import { AddressAutofill, config } from '@mapbox/search-js-react';
import { isEmpty } from 'lodash';
import HookFormRadio from '../common/components/hook-form/HookFormRadio';
import HookFormCheckboxGroup from '../common/components/hook-form/HookFormCheckboxGroup';
import { MAPBOX_ACCESS_TOKEN, geocoderApiOptions } from '../common/services/DefaultValuesUtils';

const today = moment().subtract(0, 'days');

export const initialValues = {
  firstname: '',
  lastname: '',
  phone: '',
  ssn: '',
  birthdate: '',
  address_line1: '',
  address_city: '',
  address_state: '',
  address_zip: '',
  insurance_provider: '',
  policy_number: '',
};

export const createPublicPatientValidationSchema = {
  firstname: yup.string().required(''),
  lastname: yup.string().required(''),
  ssn: yup
    .string()
    .required('')
    .length(11, 'SSN must have 9 digits')
    .typeError('SSN is required'),
  birthdate: yup
    .date('')
    .required('')
    .max(today, 'Birthdate must be today or a past date.')
    .typeError(''),
  phone: yup
    .string()
    .required('')
    .length(12, 'Phone number must have 10 digits'),
  address_line1: yup.string().required(''),
  address_city: yup.string().required(''),
  address_state: yup.string().required(''),
  address_zip: yup
    .string()
    .required('')
    .length(5, 'Zip code must have 5 digits'),
  insurance_provider: yup.string().required(''),
  policy_number: yup.string().required(''),
};

export default function PublicPatientDemographicsForm({
  control,
  errors,
  prefix = null,
  handleRetrieve,
  requiredCustom = [],
}) {
  useEffect(() => {
    config.accessToken = MAPBOX_ACCESS_TOKEN;
  }, []);

  const getPrefix = useCallback((fieldName, prefix) => {
    return fieldName.includes('custom_') ||
      fieldName.includes('diagnosis') ||
      fieldName.includes('comment')
      ? 'referral'
      : prefix;
  }, []);

  const getPlaceholder = useCallback((fieldName, label) => {
    if (fieldName === 'ssn') {
      return '###-##-####';
    } else {
      return 'Enter ' + label;
    }
  }, []);

  const getLabel = useCallback(custom => {
    return custom.label + (custom.required ? ' *' : '');
  }, []);

  const getWidth = useCallback(custom => {
    return !isEmpty(custom.size) ? custom.size : 6;
  }, []);

  const getInputProps = useCallback(fieldName => {
    if (fieldName === 'ssn') {
      return {
        inputComponent: SSNMask,
      };
    } else if (fieldName === 'phone') {
      return { inputComponent: MobileNumberMask };
    } else if (fieldName === 'address_zip') {
      return {
        inputComponent: AddressZipMask,
      };
    } else {
      return null;
    }
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item sm={12} xs={12}>
        <h3 style={{ margin: '0' }}>Patient Demographics</h3>
      </Grid>
      {!isEmpty(requiredCustom) &&
        requiredCustom.map(custom => {
          return (
            <Grid item sm={getWidth(custom)} xs={12}>
              {custom.kind === 'single_choice' && (
                <HookFormRadio
                  id={custom.field}
                  name={custom.field}
                  choices={!isEmpty(custom.choices) ? custom.choices : []}
                  placeholder={getPlaceholder(custom.field, custom.label)}
                  label={getLabel(custom)}
                  schema={custom.field}
                  prefix={getPrefix(custom.field, prefix)}
                  control={control}
                  errors={errors}
                  fullWidth
                />
              )}
              {custom.kind === 'multiple_choice' && (
                <HookFormCheckboxGroup
                  id={custom.field}
                  name={custom.field}
                  choices={!isEmpty(custom.choices) ? custom.choices : []}
                  placeholder={getPlaceholder(custom.field, custom.label)}
                  label={getLabel(custom)}
                  schema={custom.field}
                  prefix={getPrefix(custom.field, prefix)}
                  control={control}
                  errors={errors}
                  fullWidth
                />
              )}
              {custom.kind === 'text' && custom.required && (
                <HookFormRedTextField
                  id={custom.field}
                  name={custom.field}
                  placeholder={getPlaceholder(custom.field, custom.label)}
                  label={getLabel(custom)}
                  schema={custom.field}
                  prefix={getPrefix(custom.field, prefix)}
                  control={control}
                  errors={errors}
                  InputProps={getInputProps(custom.field)}
                  fullWidth
                  autoFocus={custom.field === 'ssn'}
                />
              )}
              {custom.kind === 'text' && !custom.required && (
                <HookFormTextField
                  id={custom.field}
                  name={custom.field}
                  placeholder={getPlaceholder(custom.field, custom.label)}
                  label={getLabel(custom)}
                  schema={custom.field}
                  prefix={getPrefix(custom.field, prefix)}
                  control={control}
                  errors={errors}
                  InputProps={getInputProps(custom.field)}
                  fullWidth
                  autoFocus={custom.field === 'ssn'}
                />
              )}
              {custom.kind === 'phone' && custom.required && (
                <HookFormRedTextField
                  id={custom.field}
                  name={custom.field}
                  placeholder={getPlaceholder(custom.field, custom.label)}
                  label={getLabel(custom)}
                  schema={custom.field}
                  prefix={getPrefix(custom.field, prefix)}
                  control={control}
                  errors={errors}
                  InputProps={getInputProps(custom.field)}
                  fullWidth
                  autoFocus={custom.field === 'ssn'}
                />
              )}
              {custom.kind === 'phone' && !custom.required && (
                <HookFormTextField
                  id={custom.field}
                  name={custom.field}
                  placeholder={getPlaceholder(custom.field, custom.label)}
                  label={getLabel(custom)}
                  schema={custom.field}
                  prefix={getPrefix(custom.field, prefix)}
                  control={control}
                  errors={errors}
                  InputProps={getInputProps(custom.field)}
                  fullWidth
                  autoFocus={custom.field === 'ssn'}
                />
              )}
              {custom.kind === 'date' && custom.required && (
                <HookFormRedDatePicker
                  id={custom.field}
                  name={custom.field}
                  placeholder={getPlaceholder(custom.field, custom.label)}
                  label={getLabel(custom)}
                  schema={custom.field}
                  disableFuture
                  prefix={getPrefix(custom.field, prefix)}
                  control={control}
                  errors={errors}
                  fullWidth
                />
              )}
              {custom.kind === 'date' && !custom.required && (
                <HookFormDatePicker
                  id={custom.field}
                  name={custom.field}
                  placeholder={getPlaceholder(custom.field, custom.label)}
                  label={getLabel(custom)}
                  schema={custom.field}
                  disableFuture
                  prefix={getPrefix(custom.field, prefix)}
                  control={control}
                  errors={errors}
                  fullWidth
                />
              )}
              {custom.kind === 'address' && (
                <AddressAutofill
                  accessToken={MAPBOX_ACCESS_TOKEN}
                  options={geocoderApiOptions}
                  onRetrieve={handleRetrieve}
                >
                  {custom.required ? (
                    <HookFormRedTextField
                      id={custom.field}
                      name={custom.field}
                      placeholder={getPlaceholder(custom.field, custom.label)}
                      label={getLabel(custom)}
                      autoComplete={custom.field}
                      schema={custom.field}
                      className="input mb12"
                      prefix={getPrefix(custom.field, prefix)}
                      InputProps={getInputProps(custom.field)}
                      control={control}
                      errors={errors}
                      fullWidth
                    />
                  ) : (
                    <HookFormTextField
                      id={custom.field}
                      name={custom.field}
                      placeholder={getPlaceholder(custom.field, custom.label)}
                      label={getLabel(custom)}
                      autoComplete={custom.field}
                      schema={custom.field}
                      className="input mb12"
                      prefix={getPrefix(custom.field, prefix)}
                      InputProps={getInputProps(custom.field)}
                      control={control}
                      errors={errors}
                      fullWidth
                    />
                  )}
                </AddressAutofill>
              )}
              {custom.kind === 'textarea' && (
                <HookFormTextAreaAutoSize
                  id={custom.field}
                  name={custom.field}
                  placeholder={getPlaceholder(custom.field, custom.label)}
                  label={getLabel(custom)}
                  schema={custom.field}
                  prefix={getPrefix(custom.field, prefix)}
                  control={control}
                  errors={errors}
                  fullWidth
                  style={{ width: '100%' }}
                  minRows={3}
                />
              )}
            </Grid>
          );
        })}
    </Grid>
  );
}

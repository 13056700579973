import React, {useMemo} from 'react';
import {
    Grid,
    Card,
    CardContent,
    CardHeader,
} from '@mui/material';
import {IconUsers} from '@tabler/icons';
import useParsedTableData from '../../../common/hooks/useParsedTableData';
import ManageProviderListTable from '../../../auth/Management/ManageProviderListTable';

export default function ClinicProviderListTable({data, loading}) {

    const actions = useMemo(() => {
        return {onEdit: null, };
    }, []);

    const {parsedData} = useParsedTableData(data, actions);

    return (<>
        <Card>
            <CardHeader
                avatar={<IconUsers />}
                title="Providers"
            />
            <CardContent>
                <Grid sx={{m: -3}}>
                    <ManageProviderListTable
                        loading={loading}
                        data={parsedData}
                        showToolbar={false}
                    />
                </Grid>
            </CardContent>
        </Card>
    </>);
}
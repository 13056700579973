import React, { useCallback } from 'react';
import { Grid, Card, CardContent, Divider } from '@mui/material';
import { gridSpacing } from '../../../common/themes/constants';
import ReviewCardGroup from './EnrollPsr/ReviewInformation';
import { isEmpty, omit } from 'lodash';
import clientGraphql from '../../../common/apollo-graphql';
import { useQuery } from '@apollo/client';
import { GET_ROLE_OPTIONS_QUERY } from '../../auth/gql/Query';

import * as Roles from '../../auth/constants/Role';
import StringUtils from '../../common/services/StringUtils';


export default function EnrollPsrReviewInfo({ data, onEditUser, onEditClinic, onEditProvider, permissions = [], notificationSettings=[] }) {
  const { users, clinic, providers } = data;
  const { loading, data: dataRoles } = useQuery(GET_ROLE_OPTIONS_QUERY, {
    client: clientGraphql,
    variables: {
      excludedRoles: [Roles.ROLE_ADMIN['value']],
    },
  });
  const parseUserData = useCallback(
    userData => {
      const parsedUserdata =
        !isEmpty(userData) &&
        !isEmpty(dataRoles) &&
        !isEmpty(permissions) &&
        !isEmpty(notificationSettings) &&
        !isEmpty(dataRoles.rows.find(e => parseInt(e.id, 10) === userData.role_id))
          ? {
              ...userData,
              role_id: StringUtils.toTitleCase(
                dataRoles.rows.find(e => parseInt(e.id, 10) === userData.role_id).name,
              ),
              notification: notificationSettings.filter(
                (_val, index) =>
                  userData.notification
                    .toString(2)
                    .split('')
                    .reverse()[index] === '1'
              ),
              permission: permissions.filter(
                (_val, index) =>
                  userData.permission
                    .toString(2)
                    .split('')
                    .reverse()[index] === '1',
              ),
              
            }
          : {};
      return parsedUserdata;
    },
    [dataRoles, permissions, notificationSettings],
  );

  return (
    <Card>
      <CardContent>
        <Grid container direction="column" spacing={gridSpacing}>
          {!isEmpty(users) && users.map(user => {
            return (
              <ReviewCardGroup
                key={`details-user$-${user.email}`}
                title="User details"
                data={parseUserData(user)}
                onEditGroup={onEditUser}
              />
            );
          })}

          <Grid item>
            <Divider />
          </Grid>

          <ReviewCardGroup
            title="Clinic details"
            data={omit(clinic, ['users', 'providers'])}
            onEditGroup={onEditClinic}
          />

          <Grid item>
            <Divider />
          </Grid>
          {!isEmpty(providers) && providers.map(provider => {
            return (
              <ReviewCardGroup
                title="Provider Details"
                key={`details-provider$-${provider.firstname}`}
                data={provider}
                onEditGroup={onEditProvider}
              />
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
}


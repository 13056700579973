const ROLE_ADMIN = {
    value: 'admin',
    label: 'Admin',
};

const ROLE_PSR = {
    value: 'psr',
    label: 'PSR',
};

const ROLE_MED_DIRECTOR = {
    value: 'med_director',
    label: 'Medical Admin',
};

const ROLE_NURSE = {
    value: 'nurse',
    label: 'Nurse',
};

const ROLE_MED_ASSISTANT = {
    value: 'med_assistant',
    label: 'Medical Assistant',
};

export {
  ROLE_ADMIN,
  ROLE_PSR,
  ROLE_MED_DIRECTOR,
  ROLE_NURSE,
  ROLE_MED_ASSISTANT,
};
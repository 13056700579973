import React, { useContext } from 'react';
// material-ui
import { Typography } from '@mui/material';
// project imports
import NavGroup from './NavGroup';
import MenuContext from '../../../../auth/context/MenuContext';
import AuthContext from '../../../../auth/context/AuthContext';
import { isEmpty } from 'lodash';

const MenuList = () => {
    const menuItems = useContext(MenuContext);
    const currentUser = useContext(AuthContext);

    const navItems = menuItems.items.map((item,index) => {
        switch (item.type) {
            case 'group':
                return !isEmpty(currentUser) ? <NavGroup key={`navgroup-${index}`}
                                 item={item} permissions={currentUser.referral_permissions} />:null;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;

import React from 'react';
import { Controller } from 'react-hook-form';
import HookFormUtils from './HookFormUtils';
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { capitalize } from 'lodash';
import StringUtils from '../../services/StringUtils';

export default function HookFormRadio({
  control,
  schema,
  variant,
  type = 'text',
  loading,
  errors,
  label,
  choices = [],
  prefix = null,
  ...others
}) {
  const error = HookFormUtils.getFieldError(errors, schema, prefix);
  const _schema = HookFormUtils.getSchemaName(schema, prefix);

  return (
    <>
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        control={control}
        name={_schema}
        render={({ field: { onChange, value } }) => (
          <RadioGroup onChange={onChange} value={value} row>
            {choices.map(choice => {
              return (
                <FormControlLabel value={choice} control={<Radio />} label={StringUtils.toTitleCase2(choice)} />
              );
            })}
          </RadioGroup>
        )}
      />
      {error && (
        <FormLabel
          component="radio-error"
          color="error"
          style={{ margin: '3px 14px 0px', color: 'rgb(244, 67, 54)' , fontFamily: 'Roboto, sans-serif', fontSize: '0.75rem', fontWeight: '400', lineHeight: '1.66' }}
        >
          {error && error.message}
        </FormLabel>
      )}
    </>
  );
}

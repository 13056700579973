import React from 'react';
import {
    Button,
} from '@mui/material';
import { MyDataGrid } from '../common/components';

const columns = [
    { field: 'name', headerName: 'Clinic Name', width: 250, flex: 1},
    { field: 'full_address', headerName: 'Address', width: 500, flex: 1 },
    {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        width: 210,
        flex: 1,
        headerAlign: 'center',
        renderCell: (params) => {
            return  (
                <Button
                onClick={e => {
                  e.stopPropagation();
                  params.row.actions.onViewClinicForm(params.row.code);
                }}
              >
                View Referral Form
              </Button>
            );
        }
    },
];

export default function PublicClinicsTable({
    onFilterChanged,
    onSelectedRowsChanged,
    loading,
    data,
    history
}) {
    return (
        <MyDataGrid
            onFilterChanged={onFilterChanged}
            onSelectedRowsChanged={onSelectedRowsChanged}
            loading={loading}
            data={data}
            columns={columns}
            getRowId={(row) => {
                return row.id;
            }}
            minTableHeight={'calc(100vh - 105px)'}
            
        />
    );
}

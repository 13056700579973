import React, { useState, useCallback, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import { isEmpty } from 'lodash';
import {  useQuery } from '@apollo/client';
import clientGraphqlPublic from '../../common/apollo-graphql-public';
import { PUBLIC_CLINICS_QUERY } from '../clinics/gql/Query';
import PublicClinicsTable from './PublicClinicsTable';


export default function PublicClinicDirectory({ history }) {
  const [parsedData, setParsedData] = useState({});
  const [keyword, setKeyword] = useState("");

  const handleFilterChanged = useCallback(e => {
    setKeyword(e.target.value);
  }, []);

  const handleOnSelectedRowsChanged = useCallback((rowId) => {
    }, []);

    const handleOnViewClinicForm = useCallback((code) => {
        history.replace('/directory/' +code);
    }, [history]);

    

  const { loading: clinicLoading, data: clinicData } = useQuery(PUBLIC_CLINICS_QUERY, {
    client: clientGraphqlPublic,
    variables: { keyword: keyword, is_public: true },
  });

  useEffect(()=>{
    if(!isEmpty(clinicData)){
        let _data = {
            rows: clinicData.rows.map(item => ({
                ...item,
                actions: {
                    onViewClinicForm: handleOnViewClinicForm,
                  },
            }))
        };
        setParsedData(_data);
    }
  },[setParsedData,clinicData, handleOnViewClinicForm]);

  return (
    <Card>
      <CardHeader
          title="Clinics Directory"
        />
      <CardContent>
        {!isEmpty(parsedData) ? (<PublicClinicsTable onFilterChanged={handleFilterChanged}
                    onSelectedRowsChanged={handleOnSelectedRowsChanged}
                    loading={clinicLoading}
                    data={parsedData}
                    history={history}
      />):null}
      
      </CardContent>
    </Card>
  );
}

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { gridSpacing } from '../../common/themes/constants';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  Alert,
  Stack,
  Fade,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DropzoneAreaBase } from 'react-mui-dropzone';
import { isEmpty } from 'lodash';
import { MyTypography } from '../common/components';
import { useGetReferral } from '../referral/redux/getReferral';
import { DELETE_REFERRAL_DOCUMENT, UPDATE_REFERRAL_DOCUMENT } from '../referral/gql/Mutation';
import clientGraphql from '../../common/apollo-graphql';
import { useMutation, useQuery } from '@apollo/client';
import Swal from 'sweetalert2';
import ReferralQualifyingQuestions from '../widget/ReferralQualifyingQuestions';
import { GET_REFERRAL_BY_UUID, REFERRAL_ANSWERS_QUERY } from '../referral/gql/Query';
import { useStyles } from './styles/documentStyle';
import { handlePreviewIcon } from './styles/documentPreviewIcon';
import UploadedDocuments from './UploadedDocuments';
import { REFERRAL_PATIENT_DETAIL } from '../referral/gql/Referral/Query';
import AuthContext from '../auth/context/AuthContext';
import { useUploadReferralMultipleDocuments } from '../referral/redux/uploadReferralMultipleDocuments';
import RenameDocDialog from './RenameDocDialog';

export default function ReferralDocuments({
  isSameOrg = false,
  parsedData,
  referralUuid,
  onDisableSendReferral = null,
  handleFileRequiredChange = null,
  handleSetQualifyingQuestions = null,
  qualifyingQuestions,
  setParsedDocumentData,
  parsedDocumentData,
}) {
  const currentUser = useContext(AuthContext);
  const classes = useStyles();
  const [renameDoc, setRenameDoc] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [fileObjects, setFileObjects] = useState({});
  const [fileObjectsRequired, setFileObjectsRequired] = useState(null);
  const [uploadedFileObjects, setUploadedFileObjects] = useState({});
  const [doneUploadedFileObjects, setDoneUploadedFileObjects] = useState([]);
  const [isSaveMisc, setIsSaveMisc] = useState(false);
  const {
    uploadReferralMultipleDocuments,
    uploadReferralMultipleDocumentsPending,
  } = useUploadReferralMultipleDocuments();
  const [openRenameFile, setOpenRenameFile] = useState(false);
  const handleClose = () => {
    setOpenRenameFile(false);
    getReferral(referralUuid).then(response => {
      handleUploadedFileChange(response, false);
    });
  };
  const [uploadedMiscFiles, setUploadedMiscFiles] = useState([]);
  const { getReferral } = useGetReferral();

  const { data } = useQuery(GET_REFERRAL_BY_UUID, {
    variables: {
      uuid: referralUuid,
    },
    client: clientGraphql,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
  });

  const { data: data_referral_answers, loading: loading_referral_answers } = useQuery(
    REFERRAL_ANSWERS_QUERY,
    {
      variables: {
        uuid: referralUuid,
      },
      client: clientGraphql,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      refetchOnWindowFocus: false,
    },
  );

  const { data: patientFullDetail } = useQuery(REFERRAL_PATIENT_DETAIL, {
    client: clientGraphql,
    variables: {
      uuid: referralUuid,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
  });

  const handleQuestionChange = useCallback(
    (value, question, index) => {
      var temp = JSON.parse(JSON.stringify(parsedDocumentData));
      temp[index].value = value;
      handleFileRequiredChange(question.doc_id, value);
      var tempRequired = { ...fileObjectsRequired };
      tempRequired[question.name] = value;
      setFileObjectsRequired(tempRequired);
      setParsedDocumentData(temp);
    },
    [
      parsedDocumentData,
      setParsedDocumentData,
      fileObjectsRequired,
      setFileObjectsRequired,
      handleFileRequiredChange,
    ],
  );

  useEffect(() => {
    if (
      isEmpty(parsedDocumentData) &&
      !isEmpty(parsedData) &&
      !isEmpty(parsedData.clinic.optionalDocuments) &&
      !isEmpty(data_referral_answers)
    ) {
      var newData = parsedData.clinic.optionalDocuments.map(doc => {
        if (doc.question != null && doc.document.name != 'misc') {
          var ref = null;
          if (!isEmpty(doc.question) && !isEmpty(data_referral_answers)) {
            if (!isEmpty(data_referral_answers.answers)) {
              data_referral_answers.answers.map(item => {
                if (item.question == doc.question.question) {
                  ref = item;
                  return;
                }
              });
            }
          }
          return {
            doc_id: doc.document.id,
            value: ref != null && !isEmpty(ref.answer) && ref.answer === 'Yes',
            name: doc.document.name,
            ...doc.question,
          };
        }
      });
      if (!isEmpty(newData)) {
        newData = newData.filter(function(element) {
          return element !== undefined;
        });
      }
      if (!isEmpty(newData) && newData[0] !== undefined && isEmpty(parsedDocumentData)) {
        setParsedDocumentData(newData);
      }
    }
  }, [parsedData, setParsedDocumentData, parsedDocumentData, data_referral_answers]);

  const [delete_referral_document] = useMutation(DELETE_REFERRAL_DOCUMENT, {
    client: clientGraphql,
  });

  const [update_referral_document] = useMutation(UPDATE_REFERRAL_DOCUMENT, {
    client: clientGraphql,
  });

  const deleteReferralDocument = useCallback(
    data => {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          delete_referral_document({
            client: clientGraphql,
            variables: { referralUuid: referralUuid, referralDocumentUuid: data.uuid },
          }).then(() => {
            getReferral(referralUuid).then(response => {
              handleUploadedFileChange(response, false);
            });
          });
        }
      });
    },
    [delete_referral_document, referralUuid, getReferral, handleUploadedFileChange],
  );

  const onFileObjectChanged = useCallback(
    (fileList, name, doc) => {
      if(name === 'misc'){
        setOpenRenameFile(true);
        var temp2 = { ...fileList };
        var renameDocs = temp2[parseInt(doc.document.id)];
        renameDocs = renameDocs.map(item => {
          if(isEmpty(item.referral_document_name)){
            var filename = item.file.name;
            item.referral_document_name = filename.substring(0, filename.lastIndexOf('.')) || filename;
          }
          return item;
        });
        setRenameDoc(renameDocs);
      } else {
        uploadMultiDocTypeFiles(fileList, name);
      }
    },
    [uploadMultiDocTypeFiles, setOpenRenameFile, setRenameDoc],
  );

  useEffect(() => {
    if (!isEmpty(renameDoc) && isSaveMisc) {
      setIsSaveMisc(false);
      uploadMultiDocTypeFiles(fileObjects, 'misc');
    }
  }, [isSaveMisc, setIsSaveMisc, fileObjects, uploadMultiDocTypeFiles, renameDoc]);

  const uploadMultiDocTypeFiles = useCallback(
    (fileList, name) => {
      for (let key in fileList) {
        if (fileList.hasOwnProperty(key)) {
          if (!isEmpty(fileList[key])) {
            const formData = new FormData();
            console.log(name);
            if (name === 'misc') {
              formData.append('use_filename', true);
              fileList[key].map((file, index) => {
                formData.append('files[]', file.file, renameDoc[index].referral_document_name);
              });
            } else {
              fileList[key].map(file => {
                formData.append('files[]', file.file, key);
              });
            }
            formData.append('document_id', Number(key));
            formData.append('type', 'internal');
            uploadReferralMultipleDocuments(referralUuid, formData)
              .then(data => {
                setShowAlert(true);
                setShowError(false);
                reloadReferralDocumentList(name, key);
              })
              .catch(err => {
                setShowAlert(false);
                setShowError(true);
              });
          }
        }
      }
    },
    [referralUuid, uploadReferralMultipleDocuments, reloadReferralDocumentList, renameDoc],
  );

  const reloadReferralDocumentList = useCallback(
    (name, key) => {
      getReferral(referralUuid).then(response => {
        handleUploadedFileChange(response, true);
        if (key != null) {
          var objectList = { ...fileObjects };
          if (key !== -1) {
            objectList[key] = [];
            setFileObjects(objectList);
          }
        }
      });
    },
    [referralUuid, fileObjects, getReferral, handleUploadedFileChange],
  );

  const handleUploadedFileChange = useCallback(
    (response, isRename) => {
      var docsObj = {};
      for (const key in response.data.documents) {
        var docs = [];

        if(!isEmpty(response.data.documents[key])){
          response.data.documents[key].map(doc => {
            docs.push(doc);
          });
        }
        
        docsObj[key] = docs;
        if (isRename && key === 'misc' && !isEmpty(response.data.documents[key])) {
          setUploadedMiscFiles(
            response.data.documents[key].filter(doc => {
              return (
                isEmpty(doc.referral_document_name) ||
                (!isEmpty(doc.referral_document_name) &&
                  doc.referral_document_name === 'misc')
              );
            }),
          );

        }
      }
      setUploadedFileObjects(docsObj);
    },
    [setUploadedFileObjects],
  );

  useEffect(() => {
    if (!isEmpty(parsedData.clinic) && isEmpty(fileObjects)) {
      var objectList = { ...fileObjects };
      parsedData.clinic.documents.map(doc => {
        objectList[doc.document.id] = [];
      });
      parsedData.clinic.optionalDocuments.map(doc => {
        objectList[doc.document.id] = [];
      });
      setFileObjects(objectList);
    }
  }, [parsedData, handleSetQualifyingQuestions, fileObjects, qualifyingQuestions]);

  useEffect(() => {
    if (!isEmpty(parsedData.clinic) && isEmpty(fileObjectsRequired)) {
      var objectListRequired = { ...fileObjectsRequired };
      parsedData.clinic.documents.map(doc => {
        objectListRequired[doc.document.name] = true;
      });
      parsedData.clinic.optionalDocuments.map(doc => {
        objectListRequired[doc.document.name] = false;
      });
      setFileObjectsRequired(objectListRequired);
    }
  }, [parsedData, handleSetQualifyingQuestions, fileObjectsRequired, qualifyingQuestions]);

  useEffect(() => {
    var objectQualifyingQuestions = {};
    if (
      !isEmpty(parsedData) &&
      !isEmpty(parsedData.clinic) &&
      isEmpty(qualifyingQuestions) &&
      !isEmpty(data_referral_answers)
    ) {
      parsedData.clinic.optionalDocuments.map(doc => {
        if (!isEmpty(doc.question) && !isEmpty(data_referral_answers)) {
          var ref = null;
          if (!isEmpty(data_referral_answers.answers)) {
            data_referral_answers.answers.map(item => {
              if (item.question == doc.question.question) {
                ref = item;
                return;
              }
            });
          }

          objectQualifyingQuestions[doc.document.id] = {
            name: doc.document.name,
            question: doc.question.question,
            answer: ref != null ? ref.answer : 'No',
          };
        }
      });
      if (!isEmpty(objectQualifyingQuestions) && handleSetQualifyingQuestions !== null) {
        handleSetQualifyingQuestions(objectQualifyingQuestions);
      }
    }
  }, [parsedData, handleSetQualifyingQuestions, qualifyingQuestions, data_referral_answers]);

  useEffect(() => {
    if (!isEmpty(referralUuid)) {
      getReferral(referralUuid).then(response => {
        if (
          isEmpty(doneUploadedFileObjects) &&
          !isEmpty(parsedData) &&
          parsedData.status !== 'draft'
        ) {
          var arrUUID = doneUploadedFileObjects;
          for (const key in response.data.documents) {
            if(!isEmpty(response.data.documents[key])){
              response.data.documents[key].map(doc => {
                arrUUID.push(doc.uuid);
              });
            }
          }
          setDoneUploadedFileObjects(arrUUID);
        }

        handleUploadedFileChange(response, false);
      });
    }
  }, [referralUuid, getReferral, doneUploadedFileObjects, handleUploadedFileChange, parsedData]);

  useEffect(() => {
    if (!isEmpty(uploadedFileObjects) && onDisableSendReferral !== null) {
      var isDisabled = false;
      Object.keys(fileObjectsRequired).map(name => {
        if (fileObjectsRequired[name] && !(name in uploadedFileObjects)) {
          isDisabled = true;
        }
      });
      onDisableSendReferral(isDisabled);
    } else if (onDisableSendReferral !== null && isEmpty(parsedData.clinic.documents)) {
      onDisableSendReferral(false);
    } else if (
      onDisableSendReferral !== null &&
      !isEmpty(parsedData.clinic.documents) &&
      isEmpty(uploadedFileObjects)
    ) {
      onDisableSendReferral(true);
    }
  }, [fileObjectsRequired, parsedData, fileObjects, uploadedFileObjects, onDisableSendReferral]);

  return (
    <>
      {uploadReferralMultipleDocumentsPending ? (
        <>
          <Grid item sm={3} xs={12}>
            <MyTypography variant="h4">Uploading Files</MyTypography>
          </Grid>
          <Grid item sm={12} xs={12}>
            <LinearProgress />
          </Grid>
        </>
      ) : null}
      <Grid container spacing={gridSpacing}>
        {!isEmpty(patientFullDetail) && !isEmpty(data) && (
          <UploadedDocuments
            label={'Uploaded Documents'}
            referralDetails={data}
            patientDetails={patientFullDetail}
            deleteReferralDocument={deleteReferralDocument}
            data_referral_answers={data_referral_answers}
            uploadedFileObjects={uploadedFileObjects}
            currentUser={currentUser}
            isHidePrintAll={true}
          />
        )}

        <Fade
          in={showAlert} //Write the needed condition here to make it appear
          timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
          addEndListener={() => {
            setTimeout(() => {
              setShowAlert(false);
            }, 3000);
          }}
        >
          <Alert
            severity="success"
            className={classes.alert}
            onClose={() => {
              setShowAlert(false);
            }}
          >
            <MyTypography variant="h2" component="h2" className={classes.typography}>
              Successfully Uploaded Files
            </MyTypography>
          </Alert>
        </Fade>
        {showError ? (
          <Grid item sm={12} xs={12}>
            <Alert
              color="error"
              style={{ position: 'fixed', bottom: '10px', right: '10px', zIndex: '100' }}
              onClose={() => {
                setShowError(false);
              }}
            >
              Failed to upload files
            </Alert>
          </Grid>
        ) : null}
        {!isEmpty(referralUuid) &&
        !isEmpty(parsedDocumentData) &&
        handleSetQualifyingQuestions !== null ? (
          <Grid item sm={12} xs={12} style={{ paddingTop: 0 }}>
            <ReferralQualifyingQuestions
              parsedData={parsedDocumentData}
              referralUuid={referralUuid}
              reloadReferralDocumentList={reloadReferralDocumentList}
              handleQuestionChange={handleQuestionChange}
            />
          </Grid>
        ) : null}
        {isSameOrg ? (
          <Grid item sm={12} xs={12}>
            <MyTypography variant="h5" component="h5" color="text.primary">
              Since you are sending to a clinic within your organization, you may send the referral
              without uploading any documents; however, sending the requested documentation is
              recommended to help in the processing of the referral.
            </MyTypography>
          </Grid>
        ) : null}
        {!isEmpty(parsedData) &&
          !isEmpty(parsedData.clinic) &&
          !isEmpty(parsedData.clinic.documents) && (
            <>
              {!isEmpty(parsedData.clinic.documents) ? (
                // && !isEmpty(fileObjects)
                <Grid item sm={12} xs={12} style={{ paddingTop: 0 }}>
                  {parsedData.clinic.documents.map(doc => {
                    return (
                      <Accordion key={'upload-' + Math.random()} defaultExpanded={true}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          style={{ minHeight: '32px', margin: 0 }}
                          classes={{ content: classes.content, expanded: classes.expanded }}
                        >
                          <h4>
                            {!isEmpty(doc.document.name) ? doc.document.name.toUpperCase() : ''}
                            {isSameOrg ? '' : '- Required *'}
                          </h4>
                        </AccordionSummary>
                        <AccordionDetails>
                          <DropzoneAreaBase
                            acceptedFiles={['.pdf', '.png', '.jpeg', '.zip', '.tif', '.tiff']}
                            fileObjects={fileObjects[doc.document.id]}
                            showPreviews={true}
                            showFileNamesInPreview={true}
                            showPreviewsInDropzone={false}
                            filesLimit={10}
                            maxFileSize={50000000}
                            onAdd={newFileObjs => {
                              var temp = fileObjects;
                              if (temp[doc.document.id] === undefined) {
                                temp[doc.document.id] = [];
                              }
                              temp[doc.document.id].push(...newFileObjs);
                              onFileObjectChanged(temp, doc.document.name, doc);
                            }}
                            onDelete={deleteFileObj => {
                              const index = fileObjects[doc.document.id].indexOf(deleteFileObj);
                              fileObjects[doc.document.id].splice(index, 1);
                              onFileObjectChanged(fileObjects, doc.document.name, doc);
                            }}
                            getPreviewIcon={handlePreviewIcon}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Grid>
              ) : null}
              {!isEmpty(parsedData.clinic.optionalDocuments) ? (
                // && !isEmpty(fileObjects)
                <Grid item sm={12} xs={12} style={{ paddingTop: 0 }}>
                  {parsedData.clinic.optionalDocuments.map(doc => {
                    if (doc.document.name === 'misc') {
                      return (
                        <Accordion key={'upload-' + Math.random()} defaultExpanded={true}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Stack direction="column">
                              <h4>
                                {!isEmpty(doc.document.name) ? doc.document.name.toUpperCase() : ''}{' '}
                                - Optional
                              </h4>
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={gridSpacing}>
                              <Grid
                                key={'grid-note-' + Math.random()}
                                item
                                sm={12}
                                style={{ paddingTop: '0' }}
                              >
                                <MyTypography variant="h5" component="h5" color="text.primary">
                                  Use this section to upload documents that could be useful for the
                                  receiving provider that are not listed as an option above.
                                </MyTypography>
                              </Grid>
                            </Grid>
                            <DropzoneAreaBase
                              acceptedFiles={['.pdf', '.png', '.jpeg', '.zip', '.tif', '.tiff']}
                              fileObjects={fileObjects[doc.document.id]}
                              showPreviews={true}
                              showFileNamesInPreview={true}
                              showPreviewsInDropzone={false}
                              filesLimit={10}
                              maxFileSize={50000000}
                              onAdd={newFileObjs => {
                                var temp = fileObjects;
                                if (temp[doc.document.id] === undefined) {
                                  temp[doc.document.id] = [];
                                }
                                temp[doc.document.id].push(...newFileObjs);
                                onFileObjectChanged(temp, doc.document.name, doc);
                              }}
                              onDelete={deleteFileObj => {
                                const index = fileObjects[doc.document.id].indexOf(deleteFileObj);
                                fileObjects[doc.document.id].splice(index, 1);
                                onFileObjectChanged(fileObjects, doc.document.name, doc);
                              }}
                              getPreviewIcon={handlePreviewIcon}
                            />
                          </AccordionDetails>
                        </Accordion>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Grid>
              ) : null}
            </>
          )}
          
        <RenameDocDialog
          handleClose={handleClose}
          openRenameFile={openRenameFile}
          renameDoc={renameDoc}
          setRenameDoc={setRenameDoc}
          setIsSaveMisc={setIsSaveMisc}
        />
      </Grid>
    </>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import { GET_REFERRAL_BY_UUID } from '../referral/gql';
import { gridSpacing } from '../../common/themes/constants';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  Alert,
  Stack,
  Fade,
  Box,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DropzoneAreaBase } from 'react-mui-dropzone';
import { isEmpty } from 'lodash';
import { useUploadReferralMultipleDocuments } from '../referral/redux/uploadReferralMultipleDocuments';
import { MyButton, MyTypography } from '../common/components';
import { useGetReferral } from '../referral/redux/getReferral';
import { DELETE_REFERRAL_DOCUMENT } from '../referral/gql/Mutation';
import clientGraphql from '../../common/apollo-graphql';
import { useMutation, useQuery } from '@apollo/client';
import Swal from 'sweetalert2';

import { REFERRAL_ANSWERS_QUERY } from '../referral/gql/Query';
import { useStyles } from './styles/documentStyle';
import { handlePreviewIcon } from './styles/documentPreviewIcon';
import MyPaperComponent from '../common/components/MyPaperComponent';
import UploadedDocuments from './UploadedDocuments';
import { REFERRAL_PATIENT_DETAIL } from '../referral/gql/Referral/Query';
import HelperUtils from '../common/services/HelperUtils';

export default function UploadReferralDocuments({
  uuid,
  open,
  setOpenDialog,
  parsedDocumentData,
  setParsedDocumentData,
  setOpenRenameFile,
  setUploadedMiscFiles,
  onResendFax = null,
  isFax = false,
  refresh = false,
  setRefresh = null,
  currentUser = null,
  renameDoc,
  setRenameDoc,
  isSaveMisc,
  setIsSaveMisc,
  newFileList = [],
  setNewFileList = null,
}) {
  const classes = useStyles();

  const [delete_referral_document] = useMutation(DELETE_REFERRAL_DOCUMENT, {
    client: clientGraphql,
  });

  const [parsedData, setParsedData] = useState(undefined);
  const [newlyUploadedDocs, setNewlyUploadedDocs] = useState([]);

  const { data } = useQuery(GET_REFERRAL_BY_UUID, {
    variables: {
      uuid: uuid,
    },
    client: clientGraphql,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
  });

  const { data: patientFullDetail } = useQuery(REFERRAL_PATIENT_DETAIL, {
    client: clientGraphql,
    variables: {
      uuid: uuid,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
  });

  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileObjects, setFileObjects] = useState({});
  const [fileObjectsRequired, setFileObjectsRequired] = useState(null);
  const [uploadedFileObjects, setUploadedFileObjects] = useState({});
  const [doneUploadedFileObjects, setDoneUploadedFileObjects] = useState([]);
  const {
    uploadReferralMultipleDocuments,
    uploadReferralMultipleDocumentsPending,
  } = useUploadReferralMultipleDocuments();

  const { getReferral } = useGetReferral();

  const { data: data_referral_answers } = useQuery(REFERRAL_ANSWERS_QUERY, {
    variables: {
      uuid: uuid,
    },
    client: clientGraphql,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (
      isEmpty(parsedDocumentData) &&
      !isEmpty(parsedData) &&
      !isEmpty(parsedData.clinic.optionalDocuments) &&
      !isEmpty(data_referral_answers)
    ) {
      var newData = parsedData.clinic.optionalDocuments.map(doc => {
        if (doc.question != null && doc.document.name != 'misc') {
          var ref = null;
          if (!isEmpty(doc.question) && !isEmpty(data_referral_answers)) {
            if (!isEmpty(data_referral_answers.answers)) {
              data_referral_answers.answers.map(item => {
                if (item.question == doc.question.question) {
                  ref = item;
                  return;
                }
              });
            }
          }
          return {
            doc_id: doc.document.id,
            value: ref != null && !isEmpty(ref.answer) && ref.answer === 'Yes',
            name: doc.document.name,
            ...doc.question,
          };
        }
      });
      if (!isEmpty(newData)) {
        newData = newData.filter(function(element) {
          return element !== undefined;
        });
      }
      if (!isEmpty(newData) && newData[0] !== undefined && isEmpty(parsedDocumentData)) {
        setParsedDocumentData(newData);
      }
    }
  }, [parsedData, setParsedDocumentData, parsedDocumentData, data_referral_answers]);

  const onFileObjectChanged = useCallback(
    (temp, doc) => {
      setFileObjects(temp);
      if (doc.document.name === 'misc' && !isEmpty(temp[parseInt(doc.document.id)])) {
        var temp2 = { ...temp };
        var renameDocs = temp2[parseInt(doc.document.id)];
        renameDocs = renameDocs.map(item => {
          if (isEmpty(item.referral_document_name)) {
            var filename = item.file.name;
            item.referral_document_name =
              filename.substring(0, filename.lastIndexOf('.')) || filename;
          }
          return item;
        });
        setRenameDoc(renameDocs);
        setOpenRenameFile(true);
      } else {
        uploadMultiDocTypeFiles(temp);
      }
    },
    [setFileObjects, uploadMultiDocTypeFiles, setOpenRenameFile, setRenameDoc],
  );

  const deleteReferralDocument = useCallback(
    data => {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          delete_referral_document({
            client: clientGraphql,
            variables: { referralUuid: uuid, referralDocumentUuid: data.uuid },
          }).then(() => {
            getReferral(uuid).then(response => {
              handleUploadedFileChange(response, false);
            });
          });
        }
      });
    },
    [delete_referral_document, uuid, getReferral, handleUploadedFileChange],
  );

  const uploadMultiDocTypeFiles = useCallback(
    (fileList, filetype) => {
      var tempList = [];
      tempList.concat(newFileList);
      for (let key in fileList) {
        if (fileList.hasOwnProperty(key)) {
          if (!isEmpty(fileList[key])) {
            const formData = new FormData();
            if (filetype === 'misc') {
              formData.append('use_filename', true);
              fileList[key].map((file, index) => {
                formData.append('files[]', file.file, renameDoc[index].referral_document_name);
                if (setNewFileList != null) {
                  tempList.push(
                    HelperUtils.renameFile(file.file, renameDoc[index].referral_document_name),
                  );
                }
              });
            } else {
              fileList[key].map(file => {
                formData.append('files[]', file.file, key);
                if (setNewFileList != null) {
                  tempList.push(HelperUtils.renameFile(file.file, file.file.name));
                }
              });
            }

            formData.append('document_id', Number(key));
            formData.append('type', 'internal');

            setIsUploading(true);
            uploadReferralMultipleDocuments(uuid, formData)
              .then(data => {
                console.log(data);
                var temp = newlyUploadedDocs;
                temp = temp.concat(data.document_ids);
                setNewlyUploadedDocs(temp);
                setShowAlert(true);
                setShowError(false);
                setIsUploading(false);
                reloadReferralDocumentList();
              })
              .catch(err => {
                setShowAlert(false);
                setIsUploading(false);
                setShowError(true);
              });
          }
        }
      }

      setNewFileList(tempList);
    },
    [
      uuid,
      uploadReferralMultipleDocuments,
      reloadReferralDocumentList,
      renameDoc,
      setNewFileList,
      newFileList,
      newlyUploadedDocs,
      setNewlyUploadedDocs
    ],
  );

  const sendFaxUploadFiles = useCallback(() => {
    setOpenDialog(false);
    if (onResendFax != null) {
      onResendFax(null);
    }
  }, [onResendFax, setOpenDialog]);

  const cancelSendingFax = useCallback(() => {
    Swal.fire({
      html: 'Are you sure you want to cancel uploading and resending the files?',
      icon: 'warning',
      showCancelButton: false,
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then(result => {
      if (result.isConfirmed && !isEmpty(newlyUploadedDocs)) {
        newlyUploadedDocs.map(item => {
          delete_referral_document({
            client: clientGraphql,
            variables: { referralUuid: uuid, referralDocumentUuid: item },
          }).then(() => {
            getReferral(uuid).then(response => {
              handleUploadedFileChange(response, false);
              setOpenDialog(false);
            });
          });
        })
      } else {
        console.log(result, newFileList);
      }
    });
  }, [setOpenDialog, newFileList, uuid, delete_referral_document, newlyUploadedDocs, getReferral, handleUploadedFileChange]);

  const reloadReferralDocumentList = useCallback(() => {
    getReferral(uuid).then(response => {
      handleUploadedFileChange(response, true);

      var objectList = { ...fileObjects };
      for (let key in objectList) {
        if (objectList.hasOwnProperty(key)) {
          objectList[key] = [];
        }
      }
      setFileObjects(objectList);
    });
  }, [uuid, fileObjects, getReferral, handleUploadedFileChange]);

  const handleUploadedFileChange = useCallback(
    (response, isRename) => {
      var docsObj = {};
      if (isEmpty(response.data.documents)) {
        return;
      }
      for (const key in response.data.documents) {
        var docs = [];
        response.data.documents[key].map(doc => {
          docs.push(doc);
        });

        docsObj[key] = docs;
        if (isRename && key === 'misc' && !isEmpty(response.data.documents[key])) {
          var miscFiles = response.data.documents[key].filter(doc => {
            return (
              isEmpty(doc.referral_document_name) ||
              (!isEmpty(doc.referral_document_name) && doc.referral_document_name === 'misc')
            );
          });
          setUploadedMiscFiles(miscFiles);
        }
      }
      setUploadedFileObjects(docsObj);
    },
    [setUploadedFileObjects, setUploadedMiscFiles],
  );

  useEffect(() => {
    if (!isEmpty(renameDoc) && isSaveMisc) {
      setIsSaveMisc(false);
      uploadMultiDocTypeFiles(fileObjects, 'misc');
    }
  }, [isSaveMisc, setIsSaveMisc, fileObjects, uploadMultiDocTypeFiles, renameDoc]);

  useEffect(() => {
    if (!isEmpty(parsedData) && !isEmpty(parsedData.clinic) && isEmpty(fileObjects)) {
      var objectList = {};
      parsedData.clinic.documents.map(doc => {
        objectList[doc.document.id] = [];
      });
      parsedData.clinic.optionalDocuments.map(doc => {
        objectList[doc.document.id] = [];
      });
      setFileObjects(objectList);
    }
  }, [parsedData, fileObjects]);

  useEffect(() => {
    if (!isEmpty(parsedData) && !isEmpty(parsedData.clinic) && isEmpty(fileObjectsRequired)) {
      var objectListRequired = { ...fileObjectsRequired };
      parsedData.clinic.documents.map(doc => {
        objectListRequired[doc.document.name] = true;
      });
      parsedData.clinic.optionalDocuments.map(doc => {
        objectListRequired[doc.document.name] = false;
      });
      setFileObjectsRequired(objectListRequired);
    }
  }, [parsedData, fileObjectsRequired]);

  useEffect(() => {
    if (!isEmpty(uuid) || refresh) {
      getReferral(uuid).then(response => {
        if (
          isEmpty(doneUploadedFileObjects) &&
          !isEmpty(parsedData) &&
          parsedData.status !== 'draft'
        ) {
          var arrUUID = doneUploadedFileObjects;
          for (const key in response.data.documents) {
            if (!isEmpty(response.data.documents)) {
              response.data.documents[key].map(doc => {
                arrUUID.push(doc.uuid);
              });
            }
          }
          setDoneUploadedFileObjects(arrUUID);
        }

        handleUploadedFileChange(response, false);
        if (refresh && setRefresh !== null) {
          setRefresh(false);
        }
      });
    }
  }, [
    uuid,
    getReferral,
    doneUploadedFileObjects,
    handleUploadedFileChange,
    parsedData,
    refresh,
    setRefresh,
  ]);

  useEffect(() => {
    if (!isEmpty(data) && isEmpty(parsedData)) {
      const { referral } = data;
      setParsedData({
        clinic: {
          provider_id: referral.provider.id,
          provider_name: referral.provider.display_name,
          name: referral.receiver.name,
          id: referral.receiver.id,
          address: referral.receiver.full_address,
          documents: referral.provider.documents.filter(doc => {
            return doc.required;
          }),
          optionalDocuments: referral.provider.documents.filter(doc => {
            return !doc.required;
          }),
        },
        status: referral.status,
      });
    }
  }, [data, parsedData]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (isFax && !isEmpty(newFileList)) {
          cancelSendingFax();
        } else {
          setOpenDialog(false);
        }
      }}
      PaperComponent={MyPaperComponent}
      aria-labelledby="draggable-dialog-title"
      style={{ minWidth: '800px' }}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {isFax ? 'Upload New Documents to Fax' : 'Upload Documents'}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Card style={{ padding: '0' }}>
            <CardContent>
              {uploadReferralMultipleDocumentsPending || isUploading ? (
                <>
                  <Grid item sm={3} xs={12}>
                    <MyTypography variant="h4">Uploading Files</MyTypography>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <LinearProgress />
                  </Grid>
                </>
              ) : null}
              {!isEmpty(parsedData) ? (
                <>
                  <Grid container spacing={gridSpacing}>
                    {!isEmpty(patientFullDetail) && !isEmpty(data) && (
                      <UploadedDocuments
                        label={'Uploaded Documents'}
                        referralDetails={data}
                        patientDetails={patientFullDetail}
                        deleteReferralDocument={deleteReferralDocument}
                        data_referral_answers={data_referral_answers}
                        uploadedFileObjects={uploadedFileObjects}
                        style={{ paddingLeft: 16, paddingTop: 16 }}
                        isReferrer={true}
                        currentUser={currentUser}
                        isHidePrintAll={true}
                        isFax={isFax}
                        newUploadedFiles={newlyUploadedDocs}
                      />
                    )}
                    <Fade
                      in={showAlert} //Write the needed condition here to make it appear
                      timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
                      addEndListener={() => {
                        setTimeout(() => {
                          setShowAlert(false);
                        }, 3000);
                      }}
                    >
                      <Alert
                        severity="success"
                        className={classes.alert}
                        onClose={() => {
                          setShowAlert(false);
                        }}
                      >
                        <MyTypography variant="h2" component="h2" className={classes.typography}>
                          Successfully Uploaded Files
                        </MyTypography>
                      </Alert>
                    </Fade>
                    {showError ? (
                      <Grid item sm={12} xs={12}>
                        <Alert
                          color="error"
                          style={{
                            position: 'fixed',
                            bottom: '10px',
                            right: '10px',
                            zIndex: '100',
                          }}
                          onClose={() => {
                            setShowError(false);
                          }}
                        >
                          Failed to upload files
                        </Alert>
                      </Grid>
                    ) : null}
                    {!isEmpty(parsedData) &&
                      !isEmpty(parsedData.clinic) &&
                      !isEmpty(parsedData.clinic.documents) && (
                        <>
                          {!isEmpty(parsedData.clinic.documents) ? (
                            // && !isEmpty(fileObjects)
                            <Grid item sm={12} xs={12} style={{ paddingTop: 0 }}>
                              {parsedData.clinic.documents.map(doc => {
                                return (
                                  <Accordion key={'upload-' + Math.random()} defaultExpanded={true}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      style={{ minHeight: '32px', margin: 0 }}
                                      classes={{
                                        content: classes.content,
                                        expanded: classes.expanded,
                                      }}
                                    >
                                      <h4>
                                        {!isEmpty(doc.document.name)
                                          ? doc.document.name.toUpperCase()
                                          : ''}
                                      </h4>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <DropzoneAreaBase
                                        acceptedFiles={[
                                          '.pdf',
                                          '.png',
                                          '.jpeg',
                                          '.zip',
                                          '.tif',
                                          '.tiff',
                                        ]}
                                        fileObjects={fileObjects[doc.document.id]}
                                        showPreviews={true}
                                        showFileNamesInPreview={true}
                                        showPreviewsInDropzone={false}
                                        filesLimit={10}
                                        maxFileSize={50000000}
                                        onAdd={newFileObjs => {
                                          var temp = { ...fileObjects };
                                          if (temp[doc.document.id] === undefined) {
                                            temp[doc.document.id] = [];
                                          }
                                          temp[doc.document.id].push(...newFileObjs);
                                          onFileObjectChanged(temp, doc);
                                        }}
                                        onDelete={deleteFileObj => {
                                          const index = fileObjects[doc.document.id].indexOf(
                                            deleteFileObj,
                                          );
                                          var temp = { ...fileObjects };
                                          temp[doc.document.id].splice(index, 1);
                                          onFileObjectChanged(temp, doc);
                                        }}
                                        getPreviewIcon={handlePreviewIcon}
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                );
                              })}
                            </Grid>
                          ) : null}
                          {!isEmpty(parsedData.clinic.optionalDocuments) ? (
                            <Grid item sm={12} xs={12} style={{ paddingTop: 0 }}>
                              {parsedData.clinic.optionalDocuments.map(doc => {
                                if (doc.document.name === 'misc') {
                                  return (
                                    <Accordion
                                      key={'upload-' + Math.random()}
                                      defaultExpanded={true}
                                    >
                                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Stack direction="column">
                                          <h4>
                                            {!isEmpty(doc.document.name)
                                              ? doc.document.name.toUpperCase()
                                              : ''}{' '}
                                            - Optional
                                          </h4>
                                        </Stack>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Grid container spacing={gridSpacing}>
                                          <Grid
                                            key={'grid-note-' + Math.random()}
                                            item
                                            sm={12}
                                            style={{ paddingTop: '0' }}
                                          >
                                            <MyTypography
                                              variant="h5"
                                              component="h5"
                                              color="text.primary"
                                            >
                                              Use this section to upload documents that could be
                                              useful for the receiving provider that are not listed
                                              as an option above.
                                            </MyTypography>
                                          </Grid>
                                        </Grid>
                                        <DropzoneAreaBase
                                          acceptedFiles={[
                                            '.pdf',
                                            '.png',
                                            '.jpeg',
                                            '.zip',
                                            '.tif',
                                            '.tiff',
                                          ]}
                                          fileObjects={fileObjects[doc.document.id]}
                                          showPreviews={true}
                                          showFileNamesInPreview={true}
                                          showPreviewsInDropzone={false}
                                          filesLimit={10}
                                          maxFileSize={50000000}
                                          onAdd={newFileObjs => {
                                            var temp = { ...fileObjects };
                                            if (temp[doc.document.id] === undefined) {
                                              temp[doc.document.id] = [];
                                            }
                                            temp[doc.document.id].push(...newFileObjs);
                                            onFileObjectChanged(temp, doc);
                                          }}
                                          onDelete={deleteFileObj => {
                                            const index = fileObjects[doc.document.id].indexOf(
                                              deleteFileObj,
                                            );
                                            var temp = { ...fileObjects };
                                            temp[doc.document.id].splice(index, 1);
                                            onFileObjectChanged(temp, doc);
                                          }}
                                          getPreviewIcon={handlePreviewIcon}
                                        />
                                      </AccordionDetails>
                                    </Accordion>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </Grid>
                          ) : null}
                        </>
                      )}
                  </Grid>
                </>
              ) : null}
            </CardContent>
          </Card>
        </Box>
      </DialogContent>
      <DialogActions>
        {onResendFax != null && isFax ? (
          <MyButton
            color="success"
            variant="contained"
            disabled={isEmpty(newFileList) || isUploading}
            onClick={e => {
              e.preventDefault();
              sendFaxUploadFiles();
            }}
            loading={uploadReferralMultipleDocumentsPending || isUploading}
            fullWidth={false}
          >
            Send Fax
          </MyButton>
        ) : null}

        <MyButton
          color="error"
          variant="contained"
          disabled={isUploading}
          loading={uploadReferralMultipleDocumentsPending || isUploading}
          onClick={e => {
            e.preventDefault();
            if (isFax && !isEmpty(newFileList)) {
              cancelSendingFax();
            } else {
              setOpenDialog(false);
            }
          }}
          fullWidth={false}
        >
          {isFax ? 'Cancel' : 'Done'}
        </MyButton>
      </DialogActions>
    </Dialog>
  );
}

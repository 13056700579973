import React from 'react';
import { startCase } from 'lodash';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export default function HookFormSelectOnly({
  onChange = null,
  options,
}) {
  return (
    <FormControl style={{width: 200}}>
      <InputLabel id="demo-simple-select-label">View</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="View"
        defaultValue={'all'}
        onChange={onChange}
      >
        {options.map(option => (
          <MenuItem key={option.label} value={option.value}>
            {startCase(option.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  AUTH_FORGOT_PASSWORD_BEGIN,
  AUTH_FORGOT_PASSWORD_SUCCESS,
  AUTH_FORGOT_PASSWORD_FAILURE,
  AUTH_FORGOT_PASSWORD_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';

export function forgotPassword(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: AUTH_FORGOT_PASSWORD_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.forgotPassword(args).then(
        (res) => {
            dispatch({
              type: AUTH_FORGOT_PASSWORD_SUCCESS,
              data: res,
            });
            resolve(res);
          },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: AUTH_FORGOT_PASSWORD_FAILURE,
            data: { error: err.data },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissForgotPasswordError() {
  return {
    type: AUTH_FORGOT_PASSWORD_DISMISS_ERROR,
  };
}

export function useForgotPassword(params) {
  const dispatch = useDispatch();

  const { forgotPasswordPending, forgotPasswordError } = useSelector(
    state => ({
      forgotPasswordPending: state.auth.forgotPasswordPending,
      forgotPasswordError: state.auth.forgotPasswordError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(forgotPassword(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissForgotPasswordError());
  }, [dispatch]);

  return {
    forgotPassword: boundAction,
    forgotPasswordPending,
    forgotPasswordError,
    dismissForgotPasswordError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_FORGOT_PASSWORD_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        forgotPasswordPending: true,
        forgotPasswordError: null,
      };

    case AUTH_FORGOT_PASSWORD_SUCCESS:
      // The request is success
      return {
        ...state,
        forgotPasswordPending: false,
        forgotPasswordError: null,
      };

    case AUTH_FORGOT_PASSWORD_FAILURE:
      // The request is failed
      return {
        ...state,
        forgotPasswordPending: false,
        forgotPasswordError: action.data.error,
      };

    case AUTH_FORGOT_PASSWORD_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        forgotPasswordError: null,
      };

    default:
      return state;
  }
}

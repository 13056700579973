import { useQuery } from '@apollo/client';
import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { Stack } from '@mui/system';
import { capitalize, isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import clientGraphql from '../../common/apollo-graphql';
import AuthContext from '../auth/context/AuthContext';
import { MyTypography } from '../common/components';
import Error from '../common/Error';
import { GET_REFERRAL_BY_UUID } from './gql';
import Referral from './models/Referral';
import PatientDetails from './patient/PatientDetails';
import { useGetReferral } from './redux/getReferral';
import { useSendReferral } from './redux/sendReferral';
import { REFERRAL_ANSWERS_QUERY } from './gql/Query';
import UploadedDocuments from '../patient/UploadedDocuments';
import { REFERRAL_PATIENT_DETAIL } from './gql/Referral/Query';
import FileUtils from '../common/services/FileUtils';
import HelperUtils from '../common/services/HelperUtils';
import { useFaxSend } from './redux/faxSend';
import Swal from 'sweetalert2';
import StringUtils from '../common/services/StringUtils';

export default function ReferralDetails({
  referralId,
  onDisableSendReferral = null,
  onSetParsedData = null,
  referralStatus = null,
  refresh = false,
  setRefresh = null,
  setCreateFileObjects = null,
  referralDetails = null,
  setReferralDetails = null,
  patientDetails = null,
  setPatientDetails = null,
  isReferrer = false,
  setIsFaxSending = null
}) {
  const [parsedData, setParsedData] = useState({});
  const [uploadedFileObjects, setUploadedFileObjects] = useState({});
  const currentUser = useContext(AuthContext);

  const { faxSend, faxSendPending } = useFaxSend();
  const { data } = useQuery(GET_REFERRAL_BY_UUID, {
    variables: {
      uuid: referralId,
    },
    client: clientGraphql,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
  });

  const { data: patientFullDetail } = useQuery(REFERRAL_PATIENT_DETAIL, {
    client: clientGraphql,
    variables: {
      uuid: referralId,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
  });

  const { data: data_referral_answers } = useQuery(REFERRAL_ANSWERS_QUERY, {
    variables: {
      uuid: referralId,
    },
    client: clientGraphql,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    refetchOnWindowFocus: false,
  });

  const { getReferral } = useGetReferral();
  const { sendReferralError } = useSendReferral();

  const handleSendFax = useCallback((uploadedDoc, key) => {
    console.log(uploadedDoc, key);
    sendFax(uploadedDoc, key);
  }, [sendFax]);

  const sendFax = useCallback((uploadedDoc, key) => {
    var uploadedDocuments = {};
    uploadedDocuments[key] = [uploadedDoc];
    console.log(uploadedDocuments);
    FileUtils.faxSend(patientDetails, uploadedDocuments, parsedData, currentUser)
      .then(res => {
        var faxNumber = HelperUtils.getFaxNumber(parsedData);
        const formData = new FormData();
        formData.append('file', res);
        formData.append('recipient', '001' + faxNumber.replace('-', ''));
        formData.append('referral_uuid', referralId);
        faxSend(formData)
          .then(resultData => {
            Swal.fire({
              icon: 'success',
              text: resultData.message,
              showConfirmButton: true,
            }).then(() => {
              setIsFaxSending(false);
            });
          })
          .catch(errorData => {
            console.log('faxSend error', errorData);
            Swal.fire({
              icon: 'error',
              text: errorData.data.error,
              showConfirmButton: true,
            }).then(() => {
              setIsFaxSending(false);
            });
          });
      })
      .catch(errorData => {
        Swal.fire({
          icon: 'error',
          text: !isEmpty(errorData) ? errorData : 'Error generating the file to fax.',
          showConfirmButton: true,
        }).then(() => {
          setIsFaxSending(false);
        });
      });
  }, [setIsFaxSending, faxSend, currentUser, patientDetails, parsedData, referralId]);

  useEffect(() => {
    if (!isEmpty(data)) {
      var { referral } = data;
      referral['user_uuid'] = currentUser.uuid;
      if (setReferralDetails != null && isEmpty(referralDetails)) {
        setReferralDetails(data);
      }
      setParsedData({
        ...referral,
        referral: new Referral(referral),
      });
    } else {
      setParsedData({});
    }
  }, [data, currentUser, setReferralDetails, referralDetails]);

  useEffect(() => {
    if (!isEmpty(patientFullDetail) && setPatientDetails != null && isEmpty(patientDetails)) {
      setPatientDetails(patientFullDetail);
    }
  }, [patientFullDetail, setPatientDetails, patientDetails]);

  useEffect(() => {
    if (onSetParsedData !== null && !isEmpty(parsedData)) {
      onSetParsedData(parsedData);
    }
  }, [parsedData, onSetParsedData]);

  useEffect(() => {
    if (!isEmpty(referralId) || refresh) {
      getReferral(referralId).then(response => {
        var documents = response.data.documents;
        var questions = [];
        Object.keys(documents).map(key => {
          documents['' + key].map((uploadedDoc, index) => {
            if (
              !isEmpty(uploadedDoc.question) &&
              uploadedDoc.question[0].question != '?' &&
              questions.includes(uploadedDoc.question[0].question)
            ) {
              documents['' + key][index].question = [];
            } else if (
              !isEmpty(uploadedDoc.question) &&
              !isEmpty(data_referral_answers) &&
              !isEmpty(data_referral_answers.answers) &&
              data_referral_answers.answers.filter(
                ref =>
                  ref.question.toLowerCase() === uploadedDoc.question[0].question.toLowerCase(),
              ).length > 0 &&
              uploadedDoc.question[0].question !== '?'
            ) {
              questions.push(uploadedDoc.question[0].question);
            }
          });
        });
        setUploadedFileObjects(documents);
        if (setCreateFileObjects != null) {
          setCreateFileObjects(documents);
        }
        if (!isEmpty(setRefresh)) {
          setRefresh(false);
        }
      });
    }
  }, [
    referralId,
    getReferral,
    parsedData,
    refresh,
    setRefresh,
    setUploadedFileObjects,
    data_referral_answers,
    setCreateFileObjects,
  ]);

  useEffect(() => {
    if (!isEmpty(parsedData) && !isEmpty(uploadedFileObjects) && onDisableSendReferral !== null) {
      var isDisabled = false;
      const { referral } = parsedData;
      referral.referral.provider.documents.map(doc => {
        if (doc.required && !(doc.document.name in uploadedFileObjects)) {
          isDisabled = true;
        }
        onDisableSendReferral(isDisabled);
      });
    }
  }, [referralId, uploadedFileObjects, onDisableSendReferral, parsedData]);

  return (
    <Card>
      <CardHeader
        title={
          !isEmpty(parsedData)
            ? parsedData.referral.getClinic(currentUser) +
              (!isEmpty(parsedData.referrer.metadata)
                ? ' - Dr. ' + parsedData.referrer.metadata.physician
                : '')
            : ''
        }
        subheader={
          <ul style={{ marginTop: 0, marginBottom: 0 }}>
            {!isEmpty(parsedData) && !isEmpty(parsedData.referral.getClinicAddress(currentUser)) ? (
              <li>{!isEmpty(parsedData) && parsedData.referral.getClinicAddress(currentUser)}</li>
            ) : null}
            {!isEmpty(parsedData) &&
            !isEmpty(parsedData.referral.getClinicOfficePhone(currentUser)) ? (
              <li>
                {!isEmpty(parsedData) &&
                  'OFFICE PHONE: ' + parsedData.referral.getClinicOfficePhone(currentUser)}
              </li>
            ) : null}
            {!isEmpty(parsedData) && !isEmpty(parsedData.referral.getClinicFax(currentUser)) ? (
              <li>
                {!isEmpty(parsedData) && 'FAX: ' + parsedData.referral.getClinicFax(currentUser)}
              </li>
            ) : null}
            {!isEmpty(parsedData) && !isEmpty(parsedData.referrer.metadata) ? (
              <>
                <li>{!isEmpty(parsedData) && 'SENDER: ' + parsedData.referrer.metadata.contact}</li>
                <li>
                  {!isEmpty(parsedData) && 'EMAIL: ' + parsedData.referrer.metadata.contact_email}
                </li>
                <li>
                  {!isEmpty(parsedData) && 'PHONE #: ' + parsedData.referrer.metadata.office_phone}
                </li>
                <li>{!isEmpty(parsedData) && 'FAX #: ' + parsedData.referrer.metadata.fax}</li>
              </>
            ) : null}
          </ul>
        }
      />
      <CardContent>
        <Grid item direction="column" container>
          {sendReferralError && (
            <Grid item md={12} xs={12} sx={{ pb: 2 }}>
              <Error error={sendReferralError} />
            </Grid>
          )}
          <Grid item md={12} xs={12}>
            <Stack direction="row" spacing={1}>
              <MyTypography variant="h4">Patient Demographics</MyTypography>
              <Box sx={{ flex: '1 1 auto' }} />
              <MyTypography variant="h4">Referral Status: </MyTypography>
              <MyTypography variant="h4" style={{ fontWeight: 'normal' }}>
                {!isEmpty(referralStatus)
                  ? StringUtils.toTitleCase2(referralStatus)
                  : !isEmpty(parsedData)
                  ? parsedData.referral.getReferralStatus(currentUser)
                  : ''}
              </MyTypography>
            </Stack>
          </Grid>
          <Grid item md={12} xs={12}>
            <Stack direction="row" spacing={1}>
              <Box sx={{ flex: '1 1 auto' }} />
              {!isEmpty(parsedData.referral) &&
              !isEmpty(parsedData.referral.referral.appointment) ? (
                <>
                  <MyTypography variant="h4">Appointment Date: </MyTypography>
                  <MyTypography variant="h4" style={{ fontWeight: 'normal' }}>
                    {parsedData.referral.getDateOrReason()}
                  </MyTypography>
                </>
              ) : null}
              {!isEmpty(parsedData.referral) && !isEmpty(parsedData.referral.referral.reason) ? (
                <>
                  <MyTypography variant="h4">Decline Reason: </MyTypography>
                  <MyTypography variant="h4" style={{ fontWeight: 'normal', color: 'red' }}>
                    {parsedData.referral.getDateOrReason()}
                  </MyTypography>
                </>
              ) : null}
            </Stack>
          </Grid>

          <PatientDetails
            referralId={referralId}
            isDraft={parsedData?.referral?.isDraft()}
            parsedData={parsedData}
          />

          <Grid item md={12} xs={12}>
            <Divider sx={{ my: 1.5 }} />
          </Grid>
          {!isEmpty(patientFullDetail) && !isEmpty(data) && (
            <UploadedDocuments
              isReferrer={isReferrer}
              referralDetails={data}
              patientDetails={patientFullDetail}
              data_referral_answers={data_referral_answers}
              uploadedFileObjects={uploadedFileObjects}
              currentUser={currentUser}
              sendFax={!isEmpty(parsedData) && parsedData.is_fax ? handleSendFax : null}
            />
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

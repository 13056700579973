import { useSubscription } from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import AuthContext from '../auth/context/AuthContext';
import { GET_REFERRAL_NOTES_BY_UUID } from './gql';
import ReferralNotesTable from './tables/ReferralNotesTable';
import { Grid } from '@mui/material';
import { gridSpacing } from '../../common/themes/constants';

export default function ReferralSystemLogs({ referralUUID }) {
  const [userUuid, setUserUuid] = useState({});
  const currentUser = useContext(AuthContext);
  useEffect(() => {
    if (!isEmpty(currentUser)) {
      setUserUuid(currentUser.uuid);
    }
  }, [currentUser]);

  const { loading, data } = useSubscription(GET_REFERRAL_NOTES_BY_UUID, {
    variables: {
      uuid: referralUUID,
      userUUID: userUuid,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const handleOnSelectedRowsChanged = useCallback(selectedRows => {}, []);

  const handleFilterChanged = useCallback(e => {}, []);

  return (
    <Grid container spacing={gridSpacing} sx={{ marginTop: '12px' }}>
      <Grid item md={12} xs={12}>
        <ReferralNotesTable
          onFilterChanged={handleFilterChanged}
          onSelectedRowsChanged={handleOnSelectedRowsChanged}
          loading={loading}
          data={
            !isEmpty(data) && !loading
              ? {
                  rows: data.rows.filter(i => {
                    return i.group.name === 'system';
                  }),
                  count: data.rows.filter(i => {
                    return i.group.name === 'system';
                  }).count,
                }
              : { rows: [], count: 0 }
          }
        />
      </Grid>
    </Grid>
  );
}

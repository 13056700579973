import React, {useCallback, useMemo} from 'react';
import {
    Grid,
    Card,
    CardContent,
    CardHeader,
} from '@mui/material';
import {IconUsers} from '@tabler/icons';
import useParsedTableData from '../../../common/hooks/useParsedTableData';
import ManageReferralGroupClinicsTable from './ManageReferralGroupClinicsTable';

export default function ReferralGroupClinicsTable({history, data, loading}) {
    const handleOnAdd = useCallback(() => {
    }, []);

    const handleOnView = useCallback((rowId) => {
        history.push(`/manage/clinics/${rowId}`);
    }, [history]);

    const actions = useMemo(() => {
        return { onView: handleOnView};
        return {};
    }, [handleOnView]);

    const {parsedData} = useParsedTableData(data, actions);

    return (<>
        <Card>
            <CardHeader
                avatar={<IconUsers />}
                title="Clinics"
            />
            <CardContent>
                <Grid sx={{m: -3}}>
                    <ManageReferralGroupClinicsTable
                        onAdd={handleOnAdd}
                        loading={loading}
                        data={parsedData}
                        showToolbar={false}
                    />
                </Grid>
            </CardContent>
        </Card>
    </>);
}
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  AUTH_LOG_IN_BEGIN,
  AUTH_LOG_IN_SUCCESS,
  AUTH_LOG_IN_FAILURE,
  AUTH_LOG_IN_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';
import { logoutWithoutForward } from '../hooks/useAuth';
import Swal from 'sweetalert2';
import { isElement, isEmpty } from 'lodash';

export function logIn(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: AUTH_LOG_IN_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.logIn(args).then(
        () => {

          ApiService
              .getCurrentUser()
              .then((userData) => {
                if(!isEmpty(userData) && isEmpty(userData.clinics) && userData.role !== 'Admin'){
                  var data = {message : "You are not part of any clinics. Please contact the system administrator.", error: 401};
                  dispatch({
                    type: AUTH_LOG_IN_FAILURE,
                    data: { error: data },
                  });
                  logoutWithoutForward();
                } else {
                  const _userData = {
                    ...userData,
                    loggedInAt: userData.last_login_at,
                  };

                  dispatch({
                    type: AUTH_LOG_IN_SUCCESS,
                    data: _userData,
                  });
                  resolve(_userData);
                }

              }, err => {
                dispatch({
                  type: AUTH_LOG_IN_FAILURE,
                  data: { error: err.data },
                });
                reject(err);
                logoutWithoutForward();
                
              });

        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: AUTH_LOG_IN_FAILURE,
            data: { error: err?.data },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissLogInError() {
  return {
    type: AUTH_LOG_IN_DISMISS_ERROR,
  };
}

export function checkAuth() {
  return ApiService.checkAuth();
}

export function useLogIn(params) {
  const dispatch = useDispatch();

  const { logInPending, logInError } = useSelector(
    state => ({
      logInPending: state.auth.logInPending,
      logInError: state.auth.logInError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(logIn(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissLogInError());
  }, [dispatch]);

  return {
    logIn: boundAction,
    logInPending,
    logInError,
    dismissLogInError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_LOG_IN_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        logInPending: true,
        logInError: null,
      };

    case AUTH_LOG_IN_SUCCESS:
      // The request is success
      return {
        ...state,
        logInPending: false,
        logInError: null,
      };

    case AUTH_LOG_IN_FAILURE:
      // The request is failed
      return {
        ...state,
        logInPending: false,
        logInError: action.data.error,
      };

    case AUTH_LOG_IN_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        logInError: null,
      };

    default:
      return state;
  }
}

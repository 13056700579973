import React from 'react';
import { IconPencil, IconTrash } from '@tabler/icons';
import {
    Chip,
    Stack,
} from '@mui/material';
import { MyDataGrid, MyIconButton } from '../../common/components';
import { isEmpty } from 'lodash';

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        width: 250,
        valueGetter: (params) => {
            return params.row.userObj.getFullName();
        },
    },
    {
        field: 'email',
        headerName: 'Email',
        sortable: true,
        width: 300,
    },
    {
        field: 'role',
        headerName: 'Role',
        sortable: true,
        width: 150,
        renderCell: (params) => {
            const components = [
                <Chip size="small" label={params.row.userObj.getRoleName()} color="secondary" key={params.id} />
            ];
            if (params.row.is_primary) {
                components.push(
                    <Chip size="small" label="Primary" color="primary" key={"primary-" + params.id} />
                );
            }
            return (
                <Stack direction="row" spacing={0.5}>
                    {components}
                </Stack>
            );
        },
    },
    {
        field: 'clinics',
        headerName: 'Clinics',
        sortable: false,
        width: 500,
        renderCell: (params) => {
            let components = [];

            params.row.clinics.map((clinic, index) => (
                components.push(
                    <Chip 
                        size="small" 
                        label={index === 0 ? clinic.name + '' : clinic.name} 
                        color="secondary" 
                        key={index} 
                        sx={{ mb: '5px' }} 
                    />
                )
            ));

            return (
                <Stack direction="row" spacing={0.5} sx={{ flexWrap: 'wrap', m: '5px' }}>
                    {components}
                </Stack>
            );
        },
    },
    {
        field: 'activated_at',
        headerName: 'Activated',
        sortable: true,
        width: 100,
        renderCell: (params) => {
            const value = params.row.userObj.getActivationStatus();
            const color = params.row.userObj.isActivated() ? 'success' : 'error';
            return <Chip size="small" label={value} color={color} sx={{ textTransform: 'capitalize' }} />;
        },
    },
    {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        width: 200,
        renderCell: (params) => {
            return (
                <Stack direction="row" spacing={1}>
                    {
                        (
                            params.row.actions.onEdit
                            && typeof params.row.actions.onEdit === 'function'
                        ) &&
                        <MyIconButton
                            label="Edit user"
                            onClick={(e) => {
                                e.stopPropagation();
                                params.row.actions.onEdit(params.row.uuid);
                            }}>
                            <IconPencil />
                        </MyIconButton>
                    }
                    {
                        (
                            params.row.actions.onDelete
                            && typeof params.row.actions.onDelete === 'function'
                        ) &&
                        <MyIconButton
                            label="Delete user"
                            onClick={(e) => {
                                e.stopPropagation();
                                params.row.actions.onDelete(!isEmpty(params.row.clinic) ? params.row.clinic.id : null, params.row.id);
                            }}>
                            <IconTrash />
                        </MyIconButton>
                    }
                </Stack>
            );
        }
    },
];

export default function ManageUsersWithClinicTable({ data, loading, onAdd, showToolbar = true }) {
    return (
        <MyDataGrid
            onAdd={onAdd}
            loading={loading}
            data={data}
            columns={columns}
            showToolbar={showToolbar}
            getRowId={(row) => {
                return row.id;
            }}
            minTableHeight={'calc(100vh - 302px)'}
        />
    );
}
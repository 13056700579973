import React from 'react';
import Cleave from 'cleave.js/dist/cleave-react';

export default React.forwardRef(function MobileNumberMask(props, ref) {
    const { inputRef, ...other } = props;
    
    return (
        <Cleave
            {...other}
            ref={(ref) => {
                if (inputRef) {
                    inputRef(ref ? ref.inputElement : null);
                }
            }}
            options={{
                delimiter: '-',
                blocks: [3,3,4],
                numericOnly: true,
                swapHiddenInput: true,
            }}
        />
    );
});

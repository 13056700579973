import {
  Box,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextareaAutosize,
} from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import { gridSpacing } from '../../common/themes/constants';
import { MyButton, MyTypography } from '../common/components';
import { useDeclineReferral } from './redux/declineReferral';

export default function DeclineReferral({ history, uuid, setOpenDialog}) {
  const [declineReason, setDeclineReason] = useState('');
  const handleDeclineReasonChange = useCallback(event => {
    setDeclineReason(event.target.value);
  }, []);
  const handleClose = useCallback(() => setOpenDialog(false), [setOpenDialog]);

  const {
    declineReferralPending,
    declineReferral
  } = useDeclineReferral();

  const onClickDeclineReferral = useCallback(
    (reason, title, message) => {
      declineReferral(uuid, reason).then(data => {
        Swal.fire({
          icon: 'success',
          title: title,
          text: message,
          showConfirmButton: true,
        }).then(() => {
          handleClose();
          history.push("/referrals/active",{value:1});
        });
      }).catch(error=>{
        Swal.fire({
          icon: 'error',
          title: "Failed to decline referral.",
          text: (!isEmpty(error.data.message) ? error.data.message : JSON.stringify(error)) + " Please try to reload the referral.",
          showConfirmButton: true,
        }).then(() => {
          handleClose();
        });
      });
    },
    [history, uuid, declineReferral, handleClose],
  );

  return (
    <>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Decline Referral
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Card style={{ padding: '0' }}>
            <CardContent>
              <Grid container spacing={gridSpacing}>
                <Grid item md={12} xs={12}>
                  <MyTypography variant="h4" component="h1" color="text.primary">
                    Are you sure you want to decline this referral?
                  </MyTypography>
                  <MyTypography variant="h5" component="h5" color="text.primary">
                    Please input your reason, and click confirm.
                  </MyTypography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextareaAutosize
                    key={'declineReason-declineReason'}
                    aria-label="declineReason-textarea"
                    placeholder="Enter your reason here."
                    minRows={3}
                    value={declineReason}
                    style={{ width: '100%' }}
                    onChange={e => handleDeclineReasonChange(e)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </DialogContent>
      <DialogActions>
        <MyButton
          color="primary"
          variant="outlined"
          disabled={isEmpty(declineReason) || declineReferralPending}
          onClick={() =>
            !isEmpty(declineReason)
              ? onClickDeclineReferral({ reason: declineReason }, 'Referral Declined.', '')
              : null
          }
          fullWidth={false}
        >
          Confirm
        </MyButton>
        <MyButton color="primary" variant="outlined" onClick={handleClose} fullWidth={false}>
          Close
        </MyButton>
      </DialogActions>
    </>
  );
}
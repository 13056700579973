import React, { useEffect, useState } from 'react';
import { Stack, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { MyButton, MyTypography } from '../../common/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useMutation, useQuery } from '@apollo/client';
import { capitalize, isEmpty } from 'lodash';
import DocumentQuestionForm, {
    initialValues,
    addDocumentQuestionValidationSchema,
  } from '../form/DocumentQuestionForm';
import { SAVE_QUESTION_MUTATION } from '../gql/Mutation';
import clientGraphql from '../../../common/apollo-graphql';
import { gridSpacing } from '../../../common/themes/constants';

const validationSchema = addDocumentQuestionValidationSchema;

export default function UpdateQuestionDialog({ open, onClose, documentId, question=null}) {
  const [parsedNotificationData, setParsedNotificationData] = useState({});
  const [
    save_document_question,
    { data: saveDocumentQuestionData, loading: saveDocumentQuestionLoading, error: saveDocumentQuestionError },
  ] = useMutation(SAVE_QUESTION_MUTATION, {
    client: clientGraphql,
  });

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const onSubmit = data => {
    var newData = JSON.parse(JSON.stringify(data));

    save_document_question({
      client: clientGraphql,
      variables: { documentId: documentId, question: data.question },
    });
  };

  useEffect(() => {
    if (!isEmpty(saveDocumentQuestionError)) {
      const errors = !isEmpty(saveDocumentQuestionError.graphQLErrors)
        ? saveDocumentQuestionError.graphQLErrors[0]
        : {};
      if (
        !isEmpty(errors.extensions) &&
        !isEmpty(errors.extensions.validation) &&
        JSON.stringify(errors.extensions.validation).includes('user')
      ) {
        Swal.fire({
          icon: 'error',
          text: JSON.stringify(errors.extensions.validation),
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Error Message: ' + saveDocumentQuestionError.message,
          showConfirmButton: true,
        });
      }
    }
    if (!isEmpty(saveDocumentQuestionData)) {
      Swal.fire({
        icon: 'success',
        text: 'Successfully updated a question',
        showConfirmButton: true,
      }).then(() => {
        reset();
        onClose(true);
      });
    }
  }, [saveDocumentQuestionData, saveDocumentQuestionError, onClose, reset]);

  useEffect(() => {
    if(!isEmpty(question)){
        setValue('question', question);
        trigger('question');
    }
  }, [setValue, trigger, question]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={open} onClose={onClose} disablePortal={true}>
        <DialogContent>
          <Grid container direction="column" spacing={gridSpacing}>
            <Grid item xs={10}>
              <MyTypography variant="h3">Update Qualifying Questions</MyTypography>
            </Grid>
            <Grid item xs={10}>
              <DocumentQuestionForm
                control={control}
                errors={errors}
                setValue={setValue}
                trigger={trigger}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 3 }}>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <MyButton onClick={onClose} disabled={saveDocumentQuestionLoading} fullWidth={false}>
              Cancel
            </MyButton>
            <MyButton
              type="submit"
              variant="contained"
              loading={saveDocumentQuestionLoading}
              fullWidth={false}
            >
              Update
            </MyButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </form>
  );
}

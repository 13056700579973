import { gql } from '@apollo/client';

const MARK_ALL_AS_READ_NOTIFICATIONS = gql`
  mutation markAllAsReadNotifications($uuid: String!) {
    user_notification_mark_all_as_read(user_uuid:$uuid) 
  }
  
`;

const GET_DOCUMENTS_QUERY = gql`
  query getDocuments {
    documents {
      id
      name
      question {
        id
        uuid
        question
      }
    }
  }
  
`;

const GET_DOCUMENT_QUERY = gql`
  query getDocument($document_id: String!) {
    document(id: $document_id) {
      id
      name
      question {
        id
        uuid
        question
      }
    }
  }
  
`;


const GET_PROVIDER_BY_CLINIC_QUERY = gql`
query getProviderByClinicId($clinicId: ID!, $keyword: String) {
  providers_by_clinic_id(clinic_id: $clinicId, keyword: $keyword) {
    id
    uuid
    display_name
    firstname
    lastname
  }
}
  
`;


export {
  MARK_ALL_AS_READ_NOTIFICATIONS,
  GET_DOCUMENTS_QUERY,
  GET_DOCUMENT_QUERY,
  GET_PROVIDER_BY_CLINIC_QUERY
};

import React, {useState, useCallback, useMemo, useContext} from 'react';
import {
    Grid,
    Card,
    CardContent,
    CardHeader,
} from '@mui/material';
import {MyButton} from '../../../common/components';
import {IconUsers, IconPlus} from '@tabler/icons';
import useParsedTableData from '../../../common/hooks/useParsedTableData';
import ClinicContext from '../../context/ClinicContext';
import AddClinicProviderDialog from './AddClinicProviderDialog';
import ManageProvidersTable from '../../../auth/Management/ManageProvidersTable';
import clientGraphql from '../../../../common/apollo-graphql';
import { useMutation } from '@apollo/client';
import { DELETE_PROVIDER_MUTATION } from '../../gql/Mutation';
import Swal from 'sweetalert2';
import UpdateClinicProviderDialog from './UpdateClinicProviderDialog';

export default function ClinicProvidersTable({data, loading}) {
    const {id: clinicId, refetch} = useContext(ClinicContext);
    const [providerData, setProviderData] = useState({});
    const [isProviderDialogVisible, setIsProviderDialogVisible] = useState(false);
    const [updateProviderDialogVisible, setUpdateProviderDialogVisible] = useState(false);

    const [
        delete_clinic_provider,
        { data: deleteClinicProviderData, loading: deleteClinicProviderLoading, error: deleteClinicProviderError },
      ] = useMutation(DELETE_PROVIDER_MUTATION, {
        client: clientGraphql,
      });

    const handleCloseAddProviderDialog = useCallback((isSuccess=false) => {
        setIsProviderDialogVisible(false);
        if (isSuccess) {
            refetch();
        }
    }, [refetch]);

    const handleUpdateProviderDialogClose = useCallback((isSuccess=false) => {
        setUpdateProviderDialogVisible(false);
        if (isSuccess) {
            refetch();
        }
    }, [refetch]);

    const handleOnAdd = useCallback(() => {
    }, []);

    const handleOnEdit = useCallback((rowId, params) => {
        setUpdateProviderDialogVisible(true);
        setProviderData(params.row);
    }, [setUpdateProviderDialogVisible, setProviderData]);

    const handleDeleteProvider = useCallback((uuid) => {
        Swal.fire({
            title: "Are you sure?" ,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((result) => {
            if (result.isConfirmed) {
                delete_clinic_provider({
                    client: clientGraphql,
                    variables: { uuid: uuid },
                  }).then(() => {
                    refetch();
                  })
            }
          });
    }, [ delete_clinic_provider, refetch]);

    const actions = useMemo(() => {
        return { onDelete: handleDeleteProvider, onEditProvider: handleOnEdit};
    }, [handleDeleteProvider, handleOnEdit]);

    const {parsedData} = useParsedTableData(data, actions);

    return (<>
        <Card>
            <CardHeader
                avatar={<IconUsers />}
                action={
                    <MyButton startIcon={<IconPlus />}
                              onClick={setIsProviderDialogVisible.bind(this, true)}
                              sx={{color: '#fff'}}>
                        Add Provider
                    </MyButton>
                }
                title="Providers"
            />
            <CardContent>
                <Grid sx={{m: -3}}>
                    <ManageProvidersTable
                        onAdd={handleOnAdd}
                        loading={loading || deleteClinicProviderLoading}
                        data={parsedData}
                        showToolbar={false}
                    />
                </Grid>
            </CardContent>
        </Card>
        <AddClinicProviderDialog clinicId={clinicId}
                             open={isProviderDialogVisible}
                             onClose={handleCloseAddProviderDialog} />
        
        <UpdateClinicProviderDialog clinicId={clinicId}
                                open={updateProviderDialogVisible}
                                onClose={handleUpdateProviderDialogClose}
                                providerData={providerData} />
    </>);
}
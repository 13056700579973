import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  COMMON_TOGGLE_LEFT_DRAWER,
} from './constants';
import {isNull} from 'lodash';

export function toggleLeftDrawer(flag=null) {
  return {
    type: COMMON_TOGGLE_LEFT_DRAWER,
    open: flag,
  };
}

export function useToggleLeftDrawer() {
  const dispatch = useDispatch();
  const leftDrawerOpened = useSelector(state => state.common.leftDrawerOpened);
  const boundAction = useCallback((flag=null) => dispatch(toggleLeftDrawer(flag)), [dispatch]);
  return { leftDrawerOpened, toggleLeftDrawer: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_TOGGLE_LEFT_DRAWER:
      return {
        ...state,
        leftDrawerOpened: isNull(action.open) ? !state.leftDrawerOpened : action.open,
      };

    default:
      return state;
  }
}

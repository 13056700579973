import React from 'react';
import {
    Autocomplete,
    TextField,
    Chip,
} from '@mui/material';
import {Controller} from 'react-hook-form';
import HookFormUtils from './HookFormUtils';
import MyTextField from '../MyTextField';
import {capitalize, isEmpty, isObject} from 'lodash';

export default function HookFormAutocomplete({
    control,
    schema,
    options,
    getOptionLabel,
    renderOption,
    label,
    errors,
    variant='outlined',
    defaultValue=null,
    prefix=null,
    autocompleteProps={},
}) {
    const error = HookFormUtils.getFieldError(errors, schema, prefix);
    const errorMessage = error && error.message;
    const _schema = HookFormUtils.getSchemaName(schema, prefix);

    return (
        <Controller
            control={control}
            name={_schema}
            defaultValue={defaultValue}
            onChange={([, data]) => data}
            render={({ field: { onChange, ...props } }) => {
                return (
                    <Autocomplete
                        {...autocompleteProps}
                        autoSelect
                        options={options}
                        getOptionLabel={getOptionLabel}
                        renderOption={renderOption}
                        renderInput={(params) => {
                            return (
                                <MyTextField {...params}
                                             variant={variant}
                                             label={label}
                                             error={error ? true : false}
                                             helperText={errorMessage} />
                            );
                        }}

                        value={props.value}
                        name={props.name}

                        onChange={(_, data) => {
                            if (Array.isArray(data)) {
                                const parsedData = data.map(_item => {
                                    return isObject(_item) && _item.hasOwnProperty('value')
                                        ? _item.value
                                        : _item;
                                });
                                onChange(parsedData);
                            } else if (!isEmpty(data) && typeof data === 'object') {
                                if (data.inputValue) {
                                    onChange(data.inputValue);
                                } else if (data.value) {
                                    onChange(data.value);
                                }
                            }
                        }}

                        renderTags={(value, getTagProps) => {
                            if (!value) {
                                return value;
                            }

                            if (typeof value === 'string') {
                                const index = options.length;
                                return (<Chip variant="outlined" label={value} {...getTagProps({ index })} />);
                            }

                            return value.map((option, index) => (
                                <Chip variant="outlined" label={capitalize(option)} {...getTagProps({ index })} />
                            ));
                        }}

                        // onInputChange={(_, data) => {
                        //     onChange(data);
                        // }}

                        isOptionEqualToValue={(option, value) => {
                            if (!value) return false;

                            return typeof option === 'object'
                                ? option.value === value
                                : option === value;
                        }}
                    />
                );
            }}
        />
    );
}

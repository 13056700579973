import React, { useEffect, useContext } from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Header from './Header';
import Sidebar from './Sidebar';
import {drawerWidth} from '../../../common/themes/constants';

// assets
import {useToggleLeftDrawer} from '../redux/toggleLeftDrawer';
import AuthContext from '../../auth/context/AuthContext';
import { useHistory } from 'react-router-dom';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// this contains the sidenav and topnav
function TopNav({theme, toggleLeftDrawer, leftDrawerOpened}) {
    return (
        <AppBar
            enableColorOnDark
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{
                transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
            }}
        >
            <Toolbar>
                <Header handleLeftDrawerToggle={toggleLeftDrawer} />
            </Toolbar>
        </AppBar>
    );
}

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({children}) => {
    const theme = useTheme();
    const history = useHistory();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const {toggleLeftDrawer, leftDrawerOpened} = useToggleLeftDrawer();
    const {id} = useContext(AuthContext);

    useEffect(() => {
        toggleLeftDrawer(!history.location.pathname.includes('/referrals/send'));
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>

            <CssBaseline />

            {/* <LatestNotifications userId={id} /> */}

            {/* header */}
            <TopNav theme={theme}
                    toggleLeftDrawer={toggleLeftDrawer}
                    leftDrawerOpened={leftDrawerOpened} />

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened}
                     drawerToggle={toggleLeftDrawer} />

            {/* main content */}
            <Main theme={theme}
                  open={leftDrawerOpened}>
                {/* breadcrumb */}
                {/*<Breadcrumbs separator={IconChevronRight} navigation={menuItems} icon title rightAlign />*/}
                {children}
            </Main>
        </Box>
    );
};

export default MainLayout;

const TITLE_DR = {
    value: 'dr',
    label: 'Doctor',
};

const TITLE_NP = {
    value: 'np',
    label: 'Nurse Practitioner',
};

export {
    TITLE_NP,
    TITLE_DR
};
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    useMediaQuery,
    Stack,
    FormControlLabel,
    Checkbox,
    Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AuthPageContainer from './AuthPageContainer';
import {
    HookFormTextField,
    HookFormPasswordField,
    MyButton,
    MyTypography,
    MyAlert,
} from '../common/components';
import { useHistory } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';
import {TWO_FACTOR_UNVERIFIED_ERROR_CODE} from './constants/TwoFactor';

const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
    password: yup.string().min(10, 'Password must be at least 10 characters').required('Password is required'),
    remember_me: yup.boolean().oneOf([true,false]),
});

export default function LogIn() {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();

  const { login, logInPending, logInError, dismissLogInError } = useAuth({
      middleware: 'guest',
      redirectIfAuthenticated: 'dashboard',
  });

  const { handleSubmit, control, setValue, formState: {errors}, reset } = useForm({
      defaultValues: {
          email: '',
          password: '',
          remember_me: false,
      },
      resolver: yupResolver(validationSchema),
      shouldFocusError: true,
  });

  const rememberMe = useWatch({
      control,
      name: 'remember_me'
  });

  const onSubmit = (data) => {
      dismissLogInError();

      login(data);
  };

  useEffect(() => {
    if (!logInError) return;

    if (logInError?.code === TWO_FACTOR_UNVERIFIED_ERROR_CODE) {
        window.location.href = '/auth/2fa/verify';
    }
  }, [logInError, history]);

  useEffect(() => {
      return () => {
          reset();
          dismissLogInError();
      };
  }, [reset, dismissLogInError]);

  return (
    <AuthPageContainer>
        <Stack
            direction={matchDownSM ? 'column-reverse' : 'row'}
            alignItems="center"
            justifyContent="center"
        >
            <Box>
                <Stack alignItems="center" justifyContent="center" spacing={1}>
                    <MyTypography color={theme.palette.secondary.main}
                                  gutterBottom
                                  variant={matchDownSM ? 'h3' : 'h2'}>
                        Hi, Welcome Back
                    </MyTypography>
                    <MyTypography
                        variant="caption"
                        fontSize="14px"
                        textAlign={matchDownSM ? 'center' : 'inherit'}
                    >
                        Enter your credentials to continue
                    </MyTypography>
                </Stack>
            </Box>
        </Stack>

        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack className="auth-log-in"
                   direction="column"
                   spacing={2}
                   sx={{
                       px: matchDownSM ? 1 : 3,
                       py: 4,
                   }}>
                {
                    logInError &&
                    <MyAlert severity="error"
                             sx={{mb: 2}}>{logInError.message}</MyAlert>
                }

                <HookFormTextField
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter email address"
                    label="Email address *"
                    autoComplete="email"
                    schema="email"
                    control={control}
                    errors={errors}
                    autoFocus
                    fullWidth
                />

                <HookFormPasswordField
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter password"
                    label="Password"
                    schema="password"
                    control={control}
                    errors={errors}
                    fullWidth
                />

                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}
                       sx={{mb: 2}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={rememberMe}
                                onChange={(event) => setValue('remember_me', event.target.checked)}
                                name="checked"
                                color="primary"
                            />
                        }
                        label="Remember me"
                    />
                    <MyTypography variant="subtitle1" color="secondary"
                                  component={Link}
                                  to="/forgot-password"
                                  sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                        Forgot Password?
                    </MyTypography>
                </Stack>

                <MyButton type="submit"
                          variant="contained"
                          size="large"
                          loading={logInPending}
                          disableElevation>Login</MyButton>
            </Stack>
        </form>
    </AuthPageContainer>
  );
};

import React, { useEffect } from 'react';
import {useAuth} from './hooks/useAuth';
import { useLocation } from 'react-router-dom';
import {AuthProvider} from './context/AuthContext';
import {MenuProvider} from './context/MenuContext';
import MainLayout from '../common/MainLayout';
import {NotificationsProvider} from '../notifications/context/NotificationsProvider';
import useGetNotifications from '../notifications/hooks/useGetNotifications';
import { SnackbarProvider } from 'notistack';
import CONFIG from './../../config';
import { useSubscription } from '@apollo/client';
import { CURRENT_USER_ACTIVE_SUBSCRIPTION } from '../user/gql/Subscription';

const NOTIFICATION_MAX_SNACK_STACK = CONFIG.NOTIFICATION_MAX_SNACK_STACK || 6;
const NOTIFICATION_SNACK_VISIBILITY_DURATION = CONFIG.NOTIFICATION_SNACK_VISIBILITY_DURATION || 10000;

export default function Authenticated({children}) {
  const location = useLocation();

  const { user, menuItems, logout } = useAuth({
      middleware: 'guest',
      redirectIfAuthenticated: `${location.pathname}${location.search}` ,
  });

  const notificationData = useGetNotifications(user);

    const { data: currentUser } = useSubscription(CURRENT_USER_ACTIVE_SUBSCRIPTION, { 
        variables: {
            email: user.email,
            sessionId: user.last_session_id
        }
    })

    useEffect(() => {
        if (currentUser?.user?.length == 0) {
            logout();
        }
    }, [currentUser, logout]);

  return (
    <AuthProvider value={user}>
        <MenuProvider value={menuItems}>
            <NotificationsProvider value={notificationData}>
                <SnackbarProvider maxSnack={parseInt(NOTIFICATION_MAX_SNACK_STACK)}
                                  autoHideDuration={parseInt(NOTIFICATION_SNACK_VISIBILITY_DURATION)}
                                  anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                  }}>
                    <MainLayout>{children}</MainLayout>
                </SnackbarProvider>
            </NotificationsProvider>
        </MenuProvider>
    </AuthProvider>
  );
};

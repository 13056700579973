import { Chip, Stack } from '@mui/material';
import { capitalize } from 'lodash';
import React from 'react';

export default function DocumentList({ params }) {
  return (
    <Stack direction="row" spacing={{ xs: 1, sm: 2 }} flexWrap="wrap" useFlexGap>
      {params.row.documents.map(item => (
        <Chip
          color="primary"
          size="small"
          label={capitalize(item.document.name)}
          key={Math.random() + '-' + item.id}
          style={{ margin: 4 }}
        />
      ))}
    </Stack>
  );
}

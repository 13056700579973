import { Box, Tabs, Tab } from '@mui/material';
import React, { useState, useContext, useEffect, useCallback } from 'react';

import { TabPanel } from '../common';
import AuthContext from '../auth/context/AuthContext';
import { isEmpty } from 'lodash';
import FaxedSentReferrals from './tabs/FaxedSentReferrals';

export default function FaxedReferrals({ history }) {
  const [uuid, setUuid] = useState({});
  const [isNurse, setIsNurse] = useState(false);
  const currentUser = useContext(AuthContext);
  const [value, setValue] = useState(0);

  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
    },
    [setValue],
  );

  useEffect(() => {
    if (!isEmpty(currentUser)) {
      setUuid(currentUser.uuid);
      setIsNurse(currentUser.role_id === 4);
    }
    if (
      !isEmpty(currentUser) &&
      currentUser.referral_permissions.filter(e => e.key === 'SEND_REFERRAL' && e.value).length ===
        0
    ) {
      handleChange(null, 0);
    }
  }, [currentUser, history, handleChange]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="active"
          centered
          textColor="secondary"
        >
          <Tab label="Faxed Sent Referrals" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <FaxedSentReferrals history={history} uuid={uuid} />
      </TabPanel>
    </Box>
  );
}

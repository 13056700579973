import { Box, Tabs, Tab } from '@mui/material';
import React, { useState, useContext, useCallback } from 'react';

import { TabPanel } from '../common';
import AuthContext from '../auth/context/AuthContext';
import { isEmpty } from 'lodash';
import DeclinedSentReferrals from './tabs/DeclinedSentReferrals';
import DeclinedReceivedReferrals from './tabs/DeclinedReceivedReferrals';
import DialogReferralNotes from './dialogs/DialogReferralNotes';

export default function DeclinedReferrals({history}) {
  const currentUser = useContext(AuthContext);
  const [value, setValue] = useState(0);

  const [referralUuid, setreferralUuid] = useState('');
  const [openNotes, setOpenNotes] = useState(false);
  const handleClose = () => setOpenNotes(false);

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const handleOnViewNotes = useCallback(
    rowId => {
      setreferralUuid(rowId);
      setOpenNotes(true);
    },
    [setreferralUuid, setOpenNotes],
  );

  const handleOnReview = useCallback(
    (rowId) => {
      history.push(`/referrals/${rowId}/review`);
    },
    [history],
  );

  return (
    <>
    {(!isEmpty(currentUser) && currentUser.referral_permissions.filter(item => item.key === 'SEND_REFERRAL' && item.value).length > 0) ? (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="active"
            centered
            textColor="secondary"
          >
            <Tab label="Declined Sent Referrals" />
            {!isEmpty(currentUser) && currentUser.clinic_providers > 0 && currentUser.referral_permissions.filter(item => (item.key === "ACCEPT_REFERRAL" || item.key === 'SCHEDULE_REFERRAL') && item.value).length > 0 ? (
              <Tab label="Declined Received Referrals" />
            ) : null}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {!isEmpty(currentUser) && <DeclinedSentReferrals uuid={currentUser.uuid} onViewLogs={handleOnViewNotes} onView={handleOnReview}/> }
        </TabPanel>
        {!isEmpty(currentUser) && currentUser.clinic_providers > 0 && currentUser.referral_permissions.filter(item => (item.key === "ACCEPT_REFERRAL" || item.key === 'SCHEDULE_REFERRAL') && item.value).length > 0 ? (
          <TabPanel value={value} index={1}>
            <DeclinedReceivedReferrals uuid={currentUser.uuid} onViewLogs={handleOnViewNotes} onView={handleOnReview}/>
          </TabPanel>
        ) : null}
      </Box>):(<Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="active"
              centered
              textColor="secondary"
            >
              <Tab label="Declined Received Referrals" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <DeclinedReceivedReferrals uuid={currentUser.uuid} onViewLogs={handleOnViewNotes} onView={handleOnReview}/>
          </TabPanel>
        </Box>)
      }

      <DialogReferralNotes
        referralUuid={referralUuid}
        openNotes={openNotes}
        handleClose={handleClose}
      />
    </>
  );
}

import { MyDataGrid, MyTypography } from '../../common/components';
import React from 'react';
import { Stack } from '@mui/material';
import { IconSquareRounded } from '@tabler/icons';
import moment from 'moment';

const columns = [
  { field: 'note', headerName: 'Note', minWidth: 300, 
  flex: 1,},
  {
    field: 'user',
    headerName: 'Created By',
    width: 200,
    valueGetter: params => {
      let user = params.row.user;
      let name = "System Generated";
      if(user) {
        name = user.firstname + ' ' + user.lastname;
        return name;
      }
      if('clinic' in params.row) {
        name = params.row.clinic;
      }
      return name;
    },
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 250,
    valueGetter: params => {
      const date = moment(params.row.created_at).local().format('DD MMM YYYY - hh:mm:ss A') ;
      return date;
    },
  },
];

export default function ReferralNotesTable({
  onFilterChanged,
  onSelectedRowsChanged,
  loading,
  data,
  onClick,
  isFromNotes=false
}) {
  return (
    <>
      {isFromNotes ? (
      <Stack direction="row" spacing={2} sx={{ pb: 1.5 }}>
        <MyTypography variant="h4" sx={{ paddingTop: '3px' }}>Legend:</MyTypography>
        <IconSquareRounded fill="#FF494999" />
        <MyTypography sx={{ paddingTop: '3px' }}>Internal</MyTypography>
        <IconSquareRounded fill="#F5F4F4" />
        <MyTypography sx={{ paddingTop: '3px' }}>External</MyTypography>
      </Stack>):null}
      

      <MyDataGrid
        key={'note-data-grid'}
        onFilterChanged={onFilterChanged}
        onSelectedRowsChanged={onSelectedRowsChanged}
        loading={loading}
        data={data}
        columns={columns}
        onClick={onClick}
        showToolbar={false}
        hideFooter={false}
        hideFooterPagination={false}
        sx={{
          '& .super-app-theme--external': {
            bgcolor: '#F5F4F4',
          },
          '& .super-app-theme--internal': {
            bgcolor: '#FF494988',
          },
          '& .MuiDataGrid-cellContent': {
            padding: '16px 8px',
          }
        }}
        getRowId={row => {
          return row.id;
        }}
        getRowClassName={params => `super-app-theme--${params.row.group.name}`}

        
      />
    </>
  );
}

import { useQuery } from '@apollo/client';
import React, { useState, useCallback, useEffect, useContext } from 'react';
import { GET_PROVIDERS, GET_PROVIDERS_FAVORITE, GET_PROVIDERS_MOST_RECENT } from './gql/Query';
import { isEmpty } from 'lodash';
import FindClinicTable from './tables/FindClinicTable';
import clientGraphql from '../../common/apollo-graphql';
import { Grid, Stack } from '@mui/material';
import FindClinicFaveRecentTable from './tables/FileClinicFaveRecentTable';

export default function FindClinic({ handleOnClickClinic }) {
  const [parsedData, setParsedData] = useState({});
  const [parsedFavorites, setParsedFavorites] = useState({});
  const [parsedMostRecent, setParsedMostRecent] = useState({});
  const [keyword, setKeyword] = useState('');
  const { loading, data } = useQuery(GET_PROVIDERS, {
    variables: {
      keyword: keyword,
    },
    client: clientGraphql,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const { loading: loadingFavorites, data: favorites } = useQuery(GET_PROVIDERS_FAVORITE, {
    variables: {},
    client: clientGraphql,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const { loading: loadingMostRecent, data: mostRecent } = useQuery(GET_PROVIDERS_MOST_RECENT, {
    variables: {},
    client: clientGraphql,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const handleOnSelectedRowsChanged = useCallback(selectedRows => {}, []);

  const handleFilterChanged = useCallback(e => {
    setKeyword(e.target.value);
  }, []);

  useEffect(() => {
    if (!isEmpty(mostRecent) && isEmpty(parsedMostRecent)) {
      var rows = [];
      mostRecent.rows.map(provider => {
        if (provider.clinics.length > 1) {
          provider.clinics.map(clinic => {
            var newProvider = {};
            newProvider['id'] = provider.id;
            newProvider['display_name'] = provider.display_name;
            newProvider['clinic'] = clinic;
            newProvider['specializations'] = provider.specializations;
            newProvider['documents'] = provider.documents;
            newProvider['requiredDocuments'] = provider.documents.filter(doc => {
              return doc.required;
            });
            newProvider['optionalDocuments'] = provider.documents.filter(doc => {
              return !doc.required;
            });
            rows.push(newProvider);
          });
        } else if (provider.clinics.length === 1) {
          var newProvider = {};
          newProvider['id'] = provider.id;
          newProvider['display_name'] = provider.display_name;
          newProvider['clinic'] = provider.clinics[0];
          newProvider['specializations'] = provider.specializations;
          newProvider['documents'] = provider.documents;
          newProvider['requiredDocuments'] = provider.documents.filter(doc => {
            return doc.required;
          });
          newProvider['optionalDocuments'] = provider.documents.filter(doc => {
            return !doc.required;
          });
          rows.push(newProvider);
        }
      });
      setParsedMostRecent({
        rows: rows,
      });
    }
  }, [mostRecent, parsedMostRecent]);

  useEffect(() => {
    if (!isEmpty(favorites)) {
      var rows = [];
      favorites.rows.map(provider => {
        if (provider.clinics.length > 1) {
          provider.clinics.map(clinic => {
            var newProvider = {};
            newProvider['id'] = provider.id;
            newProvider['display_name'] = provider.display_name;
            newProvider['clinic'] = clinic;
            newProvider['specializations'] = provider.specializations;
            newProvider['documents'] = provider.documents;
            newProvider['requiredDocuments'] = provider.documents.filter(doc => {
              return doc.required;
            });
            newProvider['optionalDocuments'] = provider.documents.filter(doc => {
              return !doc.required;
            });
            rows.push(newProvider);
          });
        } else if (provider.clinics.length === 1) {
          var newProvider = {};
          newProvider['id'] = provider.id;
          newProvider['display_name'] = provider.display_name;
          newProvider['clinic'] = provider.clinics[0];
          newProvider['specializations'] = provider.specializations;
          newProvider['documents'] = provider.documents;
          newProvider['requiredDocuments'] = provider.documents.filter(doc => {
            return doc.required;
          });
          newProvider['optionalDocuments'] = provider.documents.filter(doc => {
            return !doc.required;
          });
          rows.push(newProvider);
        }
      });
      setParsedFavorites({
        rows: rows,
      });
    }
  }, [favorites]);

  useEffect(() => {
    if (!isEmpty(data)) {
      var rows = [];
      data.rows.filter(i=> i.can_receive).map(provider => {
        if (provider.clinics.length > 1) {
          provider.clinics.map(clinic => {
            var newProvider = {};
            newProvider['id'] = provider.id;
            newProvider['display_name'] = provider.display_name;
            newProvider['clinic'] = clinic;
            newProvider['specializations'] = provider.specializations;
            newProvider['documents'] = provider.documents;
            newProvider['requiredDocuments'] = provider.documents.filter(doc => {
              return doc.required;
            });
            newProvider['optionalDocuments'] = provider.documents.filter(doc => {
              return !doc.required;
            });
            rows.push(newProvider);
          });
        } else if (provider.clinics.length === 1) {
          var newProvider = {};
          newProvider['id'] = provider.id;
          newProvider['display_name'] = provider.display_name;
          newProvider['clinic'] = provider.clinics[0];
          newProvider['specializations'] = provider.specializations;
          newProvider['documents'] = provider.documents;
          newProvider['requiredDocuments'] = provider.documents.filter(doc => {
            return doc.required;
          });
          newProvider['optionalDocuments'] = provider.documents.filter(doc => {
            return !doc.required;
          });
          rows.push(newProvider);
        }
      });
      setParsedData({
        rows: rows,
      });
    }
  }, [data]);

  return (
    <Grid container justifyContent="center" alignItems="flex-start">
      {!isEmpty(parsedMostRecent) || !isEmpty(parsedFavorites) ? (
        <Grid item md={3} xs={12} sx={{ px: 2, mt: 0, py: 2 }}>
          <Stack direction="column" spacing={1}>
            <FindClinicFaveRecentTable
              label={'Favorites'}
              onClick={handleOnClickClinic}
              data={
                !isEmpty(parsedFavorites)
                  ? { rows: parsedFavorites.rows, count: parsedFavorites.rows.length }
                  : { rows: [], count: 0 }
              }
            />
            <FindClinicFaveRecentTable
              label={'Most Recent'}
              onClick={handleOnClickClinic}
              data={
                !isEmpty(parsedMostRecent)
                  ? { rows: parsedMostRecent.rows, count: parsedMostRecent.rows.length }
                  : { rows: [], count: 0 }
              }
            />
          </Stack>
        </Grid>
      ) : null}

      <Grid
        item
        md={!isEmpty(parsedMostRecent) || !isEmpty(parsedFavorites) ? 9 : 12}
        xs={12}
        sx={{ px: 2, mt: 0, py: 2 }}
      >
        <FindClinicTable
          onFilterChanged={handleFilterChanged}
          onSelectedRowsChanged={handleOnSelectedRowsChanged}
          loading={loading}
          onClick={handleOnClickClinic}
          data={
            !isEmpty(parsedData) && !loading
              ? { rows: parsedData.rows, count: parsedData.rows.length }
              : { rows: [], count: 0 }
          }
        />
      </Grid>
    </Grid>
  );
}

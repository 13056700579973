import React from 'react';
import {
    Grid,
    Card,
    CardContent,
} from '@mui/material';
import {gridSpacing} from '../../../../common/themes/constants';
import ReviewCardGroup from '../EnrollPsr/ReviewInformation';

export default function ReferralGroupDetails({data}) {
    return (
        <Card>
            <CardContent>
                <Grid container
                      direction="column"
                      spacing={gridSpacing}>
                    <ReviewCardGroup title="Referral Group Details" data={data} />
                </Grid>
            </CardContent>
        </Card>
    );
}

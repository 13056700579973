import { capitalize, isEmpty } from "lodash";

export default class ReferralUtils {

    static getStatus(status, reason) {
        if((status === 'ref_completed' || status === 'rec_completed') && !isEmpty(reason)){
            status = 'Declined';
        } else if(status === 'rec_completed' && isEmpty(reason)){
            status = "Scheduled";
        } else if(status === 'ref_completed' && isEmpty(reason)){
            status = "Completed";
        }
        return capitalize((status.replace('_', ' ')));
    }
}
import React from 'react';
import { IconEye} from '@tabler/icons';
import {
    IconButton,
    Stack,
} from '@mui/material';
import { capitalize } from 'lodash';
import { MyDataGrid } from '../../../common/components';

const columns = [
    {
        field: 'name',
        headerName: 'Clinic Name',
        sortable: true,
        maxWidth: 180,
        flex: 1,
    },
    { field: 'code', headerName: 'Clinic Code', width: 130 },
    { field: 'organization', headerName: 'Organization', width: 150, valueGetter: params => {
        const name = params.row?.organization?.name;
        return capitalize(name);
      }, },
    { field: 'full_address', headerName: 'Address', width: 300, flex: 1 },
    {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        maxWidth: 200,
        renderCell: (params) => {
            return  (
                <Stack direction="row" spacing={1}>
                    {
                        (
                            params.row.actions.onView
                            && typeof params.row.actions.onView === 'function'
                        ) &&
                        <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            params.row.actions.onView(params.row.id);
                        }}>
                        <IconEye />
                    </IconButton>
                    }
                </Stack>
            );
        }
    },
];

export default function ManageReferralGroupClinicsTable({data, loading, onAdd, showToolbar=true}) {
    return (
        <MyDataGrid
            onAdd={onAdd}
            loading={loading}
            data={data}
            columns={columns}
            showToolbar={showToolbar}
            getRowId={(row) => {
                return row.id;
            }}
            minTableHeight={'calc(100vh - 442px)'}
        />
    );
}
import React, { useState, useEffect } from 'react';
import {initialTableDataValue} from '../constants/table';
import {isEmpty} from 'lodash';

export default function useParsedTableData(data, actions, clinicId=null) {
    const [parsedData, setParsedData] = useState(initialTableDataValue);

    useEffect(() => {
        if (!isEmpty(data) && clinicId != null) {
            let _data = {
                rows: data.rows.map(item => ({
                    ...item,
                    'clinicId':  clinicId,
                    actions,
                }))
            };
            if (data.aggr && data.aggr.res) {
                _data['count'] = data.aggr.res.count;
            }
            setParsedData(_data);
        } else if (!isEmpty(data)) {
            let _data = {
                rows: data.rows.map(item => ({
                    ...item,
                    actions,
                }))
            };
            if (data.aggr && data.aggr.res) {
                _data['count'] = data.aggr.res.count;
            }
            setParsedData(_data);
        } else {
            setParsedData(initialTableDataValue);
        }
    }, [data, actions, clinicId]);

    return {
        parsedData,
        setParsedData,
    };
}
import { Box, Tabs, Tab } from '@mui/material';
import React, { useState, useContext, useEffect, useCallback } from 'react';

import { TabPanel } from '../common';
import ActiveSentReferrals from './tabs/ActiveSentReferrals';
import ActiveReceivedReferrals from './tabs/ActiveReceivedReferrals';
import AuthContext from '../auth/context/AuthContext';
import { isEmpty } from 'lodash';

export default function ActiveReferrals({ history }) {
  const [uuid, setUuid] = useState({});
  const [isNurse, setIsNurse] = useState(false);
  const currentUser = useContext(AuthContext);
  const [value, setValue] = useState(0);

  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
    },
    [setValue],
  );

  useEffect(() => {
    if (!isEmpty(currentUser)) {
      setUuid(currentUser.uuid);
      setIsNurse(currentUser.role_id === 4);
    }
    if (!isEmpty(currentUser) && currentUser.referral_permissions.filter(e => e.key === 'SEND_REFERRAL' && e.value).length === 0)  {
      handleChange(null, 0);
    } 
  }, [currentUser, history, handleChange]);

  return (
    <>
      {(!isEmpty(currentUser) && currentUser.referral_permissions.filter(item => item.key === 'SEND_REFERRAL' && item.value).length > 0) ? (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="active"
              centered
              textColor="secondary"
            >
              <Tab label="Active Sent Referrals" />
              {!isEmpty(currentUser) && currentUser.clinic_providers > 0 && currentUser.referral_permissions.filter(item => (item.key === "ACCEPT_REFERRAL" || item.key === 'SCHEDULE_REFERRAL') && item.value).length > 0 ? (
                <Tab label="Active Received Referrals" />
              ) : null}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ActiveSentReferrals history={history} uuid={uuid} />
          </TabPanel>
          {!isEmpty(currentUser) && currentUser.clinic_providers > 0 && currentUser.referral_permissions.filter(item => (item.key === "ACCEPT_REFERRAL" || item.key === 'SCHEDULE_REFERRAL') && item.value).length > 0 ? (
            <TabPanel value={value} index={1}>
              <ActiveReceivedReferrals history={history} uuid={uuid}/>
            </TabPanel>
          ) : null}
        </Box>
      ) : (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="active"
              centered
              textColor="secondary"
            >
              <Tab label="Active Received Referrals" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ActiveReceivedReferrals history={history} uuid={uuid} isNurse={isNurse} />
          </TabPanel>
        </Box>
      )}
    </>
  );
}

import React, { useEffect } from 'react';
import * as yup from 'yup';
import {gridSpacing} from '../../../../common/themes/constants';
import {
    Grid,
} from '@mui/material';
import {HookFormTextField} from '../../../common/components';
import AddressZipMask from '../../../common/components/mask/AddressZipMask';

import { AddressAutofill, config } from '@mapbox/search-js-react';
import MobileNumberMask from '../../../common/components/mask/MobileNumberMask';
import { MAPBOX_ACCESS_TOKEN, geocoderApiOptions } from '../../../common/services/DefaultValuesUtils';

export const initialValues = {
    name: '',
    // code: '',
    // referral_network: '',
    // organization: '',
    office_phone: '',
    fax: '',
    address_line1: '',
    address_city: '',
    address_state: '',
    address_zip: ''
};

export const createClinicValidationSchema = yup.object().shape({
    name: yup.string().required('Clinic name is required'),
    office_phone: yup
        .string()
        .length(12, 'Office phone number must have 10 digits').required('Office phone number is required'),
        // .matches(/.{12,}/, {
        //     excludeEmptyString: true,
        //     message: 'Office phone number must have 10 digits',
        //   }),
    fax: yup
      .string()
      .length(12, 'Fax must have 10 digits').required('Fax is required'),
    //   .matches(/.{12,}/, {
    //     excludeEmptyString: true,
    //     message: 'Fax must have 10 digits',
    //   }),
    address_line1: yup.string().required('Address Line 1 is required'),
    address_city: yup.string().required('City is required'),
    address_state: yup.string().required('State is required'),
    address_zip: yup.string().length(5, 'Zip code must have 5 digits').required('Zipcode is required')
});

export default function CreateNonAffiliatedClinicForm({control, errors, prefix=null, handleRetrieve}) {
    useEffect(() => {
        config.accessToken = MAPBOX_ACCESS_TOKEN;
    }, []);
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item
                  sm={12}
                  xs={12}>
                <HookFormTextField
                    id="name"
                    name="name"
                    placeholder="Enter clinic name"
                    label="Clinic name *"
                    schema="name"
                    prefix={prefix}
                    control={control}
                    errors={errors}
                    autoFocus
                    fullWidth
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                <HookFormTextField
                id="office_phone"
                name="office_phone"
                type="tel"
                placeholder="Enter Office Phone"
                label="Office Phone *"
                autoComplete="office_phone"
                schema="office_phone"
                prefix={prefix}
                control={control}
                errors={errors}
                InputProps={{
                    inputComponent: MobileNumberMask,
                }}
                fullWidth
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                <HookFormTextField
                id="fax"
                name="fax"
                type="tel"
                placeholder="Enter Fax number"
                label="Fax number *"
                autoComplete="fax"
                schema="fax"
                prefix={prefix}
                control={control}
                errors={errors}
                InputProps={{
                    inputComponent: MobileNumberMask,
                }}
                fullWidth
                />
            </Grid>
            <Grid item
                  sm={6}
                  xs={12}>
                    
          <AddressAutofill accessToken={MAPBOX_ACCESS_TOKEN} options={geocoderApiOptions} onRetrieve={handleRetrieve} >
                <HookFormTextField
                    id="address_line1"
                    name="address_line1"
                    placeholder="Enter Line 1"
                    label="Line 1 *"
                    autoComplete="address_line1"
                    schema="address_line1"
                    prefix={prefix}
                    control={control}
                    errors={errors}
                    fullWidth
                />
                </AddressAutofill>
            </Grid>

            <Grid item
                  sm={6}
                  xs={12}>
                    
          <AddressAutofill accessToken={MAPBOX_ACCESS_TOKEN} options={geocoderApiOptions} onRetrieve={handleRetrieve} >
                <HookFormTextField
                    id="address_city"
                    name="address_city"
                    placeholder="Enter City"
                    label="City *"
                    autoComplete="address_city"
                    schema="address_city"
                    prefix={prefix}
                    control={control}
                    errors={errors}
                    fullWidth
                />
                </AddressAutofill>
            </Grid>

            <Grid item
                  sm={6}
                  xs={12}>
                    
          <AddressAutofill accessToken={MAPBOX_ACCESS_TOKEN} options={geocoderApiOptions} onRetrieve={handleRetrieve} >
                <HookFormTextField
                    id="address_state"
                    name="address_state"
                    placeholder="Enter State"
                    label="State *"
                    autoComplete="address_state"
                    schema="address_state"
                    prefix={prefix}
                    control={control}
                    errors={errors}
                    fullWidth
                />
                </AddressAutofill>
            </Grid>

            <Grid item
                  sm={6}
                  xs={12}>
                    
          <AddressAutofill accessToken={MAPBOX_ACCESS_TOKEN} options={geocoderApiOptions} onRetrieve={handleRetrieve} >
                <HookFormTextField
                    id="address_zip"
                    name="address_zip"
                    placeholder="Enter Zipcode"
                    label="Zipcode *"
                    autoComplete="address_zip"
                    schema="address_zip"
                    prefix={prefix}
                    control={control}
                    errors={errors}
                    fullWidth
                    InputProps={{
                        inputComponent: AddressZipMask,
                      }}
                />
                </AddressAutofill>
            </Grid>


        </Grid>
    );
}